import React, { useState } from 'react'

const UttarPradeshLGnearme = (props) => {
    return (
        <>
            <div id='footerdetail' >
                <div id='callus'>
                    <div className="container">
                        <div class="row">
                            {/*<!-- End Col --> */}
                            <div class="col-md-12 col-lg-12">

                                <div class="popular-city" style={{ display: 'flex' }}>
                                    <div class="footer-widget footer-left-widgetcties-pop popular-city-footers">
                                        <div class="section-heading">
                                            <h3>RO Service in Near you</h3>
                                            <span class="animate-border border-black"></span>
                                        </div>
                                        <ul>
                                            <li>  <a href="/lg-ro-service-Agra">Agra</a></li>
                                            <li>  <a href="/lg-ro-service-Aligarh">Aligarh</a></li>
                                            <li>  <a href="/lg-ro-service-Ambedkar-Nagar">Ambedkar Nagar</a></li>
                                            <li>  <a href="/lg-ro-service-Ayodhya">Ayodhya</a></li>
                                            <li>  <a href="/lg-ro-service-Amethi">Amethi</a></li>
                                            <li>  <a href="/lg-ro-service-Amroha">Amroha</a></li>
                                            <li>  <a href="/lg-ro-service-Auraiya">Auraiya</a></li>
                                            <li>  <a href="/lg-ro-service-Azamgarh">Azamgarh</a></li>
                                            <li>  <a href="/lg-ro-service-Budaun">Budaun</a></li>
                                            <li>  <a href="/lg-ro-service-Bagpat">Bagpat</a></li>
                                            <li>  <a href="/lg-ro-service-Bahraich">Bahraich</a></li>
                                            <li>  <a href="/lg-ro-service-Ballia">Ballia</a></li>
                                            <li>  <a href="/lg-ro-service-Balrampur">Balrampur</a></li>
                                            <li>  <a href="/lg-ro-service-Banda">Banda</a></li>
                                            <li>  <a href="/lg-ro-service-Barabanki">Barabanki</a></li>
                                            <li>  <a href="/lg-ro-service-Bareilly">Bareilly</a></li>
                                            <li>  <a href="/lg-ro-service-Basti">Basti</a></li>
                                            <li>  <a href="/lg-ro-service-Bijnor">Bijnor</a></li>
                                            <li>  <a href="/lg-ro-service-Bulandshahr">Bulandshahr</a></li>
                                            <li>  <a href="/lg-ro-service-Chandauli">Chandauli</a></li>
                                            <li>  <a href="/lg-ro-service-Chitrakoot">Chitrakoot</a></li>
                                            <li>  <a href="/lg-ro-service-Deoria">Deoria</a></li>
                                            <li>  <a href="/lg-ro-service-Etah">Etah</a></li>
                                            <li>  <a href="/lg-ro-service-Etawah">Etawah</a></li>
                                            <li>  <a href="/lg-ro-service-Farrukhabad">Farrukhabad</a></li>
                                            <li>  <a href="/lg-ro-service-Fatehpur">Fatehpur</a></li>
                                            <li>  <a href="/lg-ro-service-Firozabad">Firozabad</a></li>
                                            <li>  <a href="/lg-ro-service-Gautam-Buddha-Nagar">Gautam Buddha Nagar</a></li>
                                            <li>  <a href="/lg-ro-service-Ghaziabad">Ghaziabad</a></li>
                                            <li>  <a href="/lg-ro-service-Ghazipur">Ghazipur</a></li>
                                            <li>  <a href="/lg-ro-service-Gonda">Gonda</a></li>
                                            <li>  <a href="/lg-ro-service-Gorakhpur">Gorakhpur</a></li>
                                            <li>  <a href="/lg-ro-service-Hamirpur">Hamirpur</a></li>
                                            <li>  <a href="/lg-ro-service-Hapur">Hapur</a></li>
                                            <li>  <a href="/lg-ro-service-Hardoi">Hardoi</a></li>
                                            <li>  <a href="/lg-ro-service-Hathras">Hathras</a></li>
                                            <li>  <a href="/lg-ro-service-Jalaun">Jalaun</a></li>
                                            <li>  <a href="/lg-ro-service-Jaunpur">Jaunpur</a></li>
                                            <li>  <a href="/lg-ro-service-Jhansi">Jhansi</a></li>
                                            <li>  <a href="/lg-ro-service-Kannauj">Kannauj</a></li>
                                            <li>  <a href="/lg-ro-service-Kanpur-Dehat">Kanpur Dehat</a></li>
                                            <li>  <a href="/lg-ro-service-Kanpur">Kanpur</a></li>
                                            <li>  <a href="/lg-ro-service-Kasganj">Kasganj</a></li>
                                            <li>  <a href="/lg-ro-service-Kaushambi">Kaushambi</a></li>
                                            <li>  <a href="/lg-ro-service-Kushinagar">Kushinagar</a></li>
                                            <li>  <a href="/lg-ro-service-Lakhimpur-Kheri">Lakhimpur Kheri</a></li>
                                            <li>  <a href="/lg-ro-service-Lalitpur">Lalitpur</a></li>
                                            <li>  <a href="/lg-ro-service-Lucknow">Lucknow</a></li>
                                            <li>  <a href="/lg-ro-service-Maharajganj">Maharajganj</a></li>
                                            <li>  <a href="/lg-ro-service-Mahoba">Mahoba</a></li>
                                            <li>  <a href="/lg-ro-service-Mainpuri">Mainpuri</a></li>
                                            <li>  <a href="/lg-ro-service-Mathura">Mathura</a></li>
                                            <li>  <a href="/lg-ro-service-Mau">Mau</a></li>
                                            <li>  <a href="/lg-ro-service-Meerut">Meerut</a></li>
                                            <li>  <a href="/lg-ro-service-Mirzapur">Mirzapur</a></li>
                                            <li>  <a href="/lg-ro-service-Moradabad">Moradabad</a></li>
                                            <li>  <a href="/lg-ro-service-Muzaffarnagar">Muzaffarnagar</a></li>
                                            <li>  <a href="/lg-ro-service-Pilibhit">Pilibhit</a></li>
                                            <li>  <a href="/lg-ro-service-Pratapgarh">Pratapgarh</a></li>
                                            <li>  <a href="/lg-ro-service-Prayagraj">Prayagraj</a></li>
                                            <li>  <a href="/lg-ro-service-Rae Bareli">Rae Bareli</a></li>
                                            <li>  <a href="/lg-ro-service-Rampur">Rampur</a></li>
                                            <li>  <a href="/lg-ro-service-Saharanpur">Saharanpur</a></li>
                                            <li>  <a href="/lg-ro-service-Sant-Kabir-Nagar">Sant Kabir Nagar</a></li>
                                            <li>  <a href="/lg-ro-service-Bhadohi">Bhadohi</a></li>
                                            <li>  <a href="/lg-ro-service-Sambhal">Sambhal</a></li>
                                            <li>  <a href="/lg-ro-service-Shahjahanpur">Shahjahanpur</a></li>
                                            <li>  <a href="/lg-ro-service-Shamli">Shamli</a></li>
                                            <li>  <a href="/lg-ro-service-Shravasti">Shravasti</a></li>
                                            <li>  <a href="/lg-ro-service-Siddharthnagar">Siddharthnagar</a></li>
                                            <li>  <a href="/lg-ro-service-Sitapur">Sitapur</a></li>
                                            <li>  <a href="/lg-ro-service-Sonbhadra">Sonbhadra</a></li>
                                            <li>  <a href="/lg-ro-service-Sultanpur">Sultanpur</a></li>
                                            <li>  <a href="/lg-ro-service-Unnao">Unnao</a></li>
                                            <li>  <a href="/lg-ro-service-Varanasi">Varanasi</a></li>
                                            <li>  <a href="/lg-ro-service-Robertsganj">Robertsganj</a></li>
                                            <li>  <a href="/lg-ro-service-Bahjoi">Bahjoi</a></li>
                                            <li>  <a href="/lg-ro-service-Khalilabad">Khalilabad</a></li>
                                            <li>  <a href="/lg-ro-service-Akbarpur">Akbarpur</a></li>
                                            <li>  <a href="/lg-ro-service-Orai">Orai</a></li>
                                            <li>  <a href="/lg-ro-service-Manjhanpur">Manjhanpur</a></li>
                                            <li>  <a href="/lg-ro-service-Padarauna">Padarauna</a></li>
                                            <li>  <a href="/lg-ro-service-Kheri">Kheri</a></li>
                                            <li>  <a href="/lg-ro-service-Fatehgarh">Fatehgarh</a></li>
                                            <li>  <a href="/lg-ro-service-Noida">Noida</a></li>
                                            <li>  <a href="/lg-ro-service-Greater-Noida">Greater Noida</a></li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default UttarPradeshLGnearme;