import React, { useState } from 'react'

const RajasthanHavellsNearme = (props) => {
    return (
        <>
            <div id='footerdetail' >
                <div id='callus'>
                    <div className="container">
                        <div className="row">
                            {/*<!-- End Col --> */}
                            <div className="col-md-12 col-lg-12">

                                <div className="popular-city" style={{ display: 'flex' }}>
                                    <div className="footer-widget footer-left-widgetcties-pop popular-city-footers">
                                        <div className="section-heading">
                                            <h3>Havells RO Service Near you</h3>
                                            <span className="animate-border border-black"></span>
                                        </div>
                                        <ul>
                                        <li> <a href="/havells-ro-service-Ajmer">Ajmer</a></li>
                                        <li> <a href="/havells-ro-service-Alwar">Alwar</a></li>
                                        <li> <a href="/havells-ro-service-Balotra">Balotra</a></li>
                                        <li> <a href="/havells-ro-service-Banswara">Banswara</a></li>
                                        <li> <a href="/havells-ro-service-Baran">Baran</a></li>
                                        <li> <a href="/havells-ro-service-Barmer">Barmer</a></li>
                                        <li> <a href="/havells-ro-service-Beawar">Beawar</a></li>
                                        <li> <a href="/havells-ro-service-Bharatpur">Bharatpur</a></li>
                                        <li> <a href="/havells-ro-service-Bhilwara">Bhilwara</a></li>
                                        <li> <a href="/havells-ro-service-Bikaner">Bikaner</a></li>
                                        <li> <a href="/havells-ro-service-Bundi">Bundi</a></li>
                                        <li> <a href="/havells-ro-service-Chittorgarh">Chittorgarh</a></li>
                                        <li> <a href="/havells-ro-service-Churu">Churu</a></li>
                                        <li> <a href="/havells-ro-service-Dausa">Dausa</a></li>
                                        <li> <a href="/havells-ro-service-Deeg">Deeg</a></li>
                                        <li> <a href="/havells-ro-service-Deedwana">Deedwana</a></li>
                                        <li> <a href="/havells-ro-service-Dholpur">Dholpur</a></li>
                                        <li> <a href="/havells-ro-service-Dungarpur">Dungarpur</a></li>
                                        <li> <a href="/havells-ro-service-Hanumangarh">Hanumangarh</a></li>
                                        <li> <a href="/havells-ro-service-Jaipur">Jaipur</a></li>
                                        <li> <a href="/havells-ro-service-Jaisalmer">Jaisalmer</a></li>
                                        <li> <a href="/havells-ro-service-Jalore">Jalore</a></li>
                                        <li> <a href="/havells-ro-service-Jhalawar">Jhalawar</a></li>
                                        <li> <a href="/havells-ro-service-Jhunjhunu">Jhunjhunu</a></li>
                                        <li> <a href="/havells-ro-service-Jodhpur">Jodhpur</a></li>
                                        <li> <a href="/havells-ro-service-Karauli">Karauli</a></li>
                                        <li> <a href="/havells-ro-service-Tijara">Tijara</a></li>
                                        <li> <a href="/havells-ro-service-Kotputli">Kotputli</a></li>
                                        <li> <a href="/havells-ro-service-Kota">Kota</a></li>
                                        <li> <a href="/havells-ro-service-Nagaur">Nagaur</a></li>
                                        <li> <a href="/havells-ro-service-Pali">Pali</a></li>
                                        <li> <a href="/havells-ro-service-Phalodi">Phalodi</a></li>
                                        <li> <a href="/havells-ro-service-Pratapgarh">Pratapgarh</a></li>
                                        <li> <a href="/havells-ro-service-Rajsamand">Rajsamand</a></li>
                                        <li> <a href="/havells-ro-service-Salumbar">Salumbar</a></li>
                                        <li> <a href="/havells-ro-service-Sawai-Madhopur">Sawai-Madhopur</a></li>
                                        <li> <a href="/havells-ro-service-Sikar">Sikar</a></li>
                                        <li> <a href="/havells-ro-service-Sirohi">Sirohi</a></li>
                                        <li> <a href="/havells-ro-service-Sri-Ganganagar">Sri-Ganganagar</a></li>
                                        <li> <a href="/havells-ro-service-Tonk">Tonk</a></li>
                                        <li> <a href="/havells-ro-service-Udaipur">Udaipur</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default RajasthanHavellsNearme;
