export const Faqcmn = ({ title, number }) => {
    return (
        <>
            <h4> Frequently Asked Questions</h4>
            <div className="demo">
                <div>

                    <div className="panel-group" id="accordion">
                        <div className="card panel panel-default">
                            <div className="card-header" id="headingOne">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> Aqua RO Service Center Toll Free No in {title} ?
                                    </button>
                                </h5>
                                <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div className="panel-body" >
                                        Aquapure RO Customer Care Number is <strong>9031062011</strong>.
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="card panel panel-default">
                            <div className="card-header" id="headingTwo">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> Why RO Service is Needed in {title} ?
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                <div className="panel-body">
                                    Buying a RO water purifier is not enough. You have to maintain it properly to ensure that harmful contaminants don?t pollute your drinking water.
                                </div>
                            </div>
                        </div>
                        <div className="card panel panel-default">
                            <div className="card-header" id="headingThree">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> How Much Time is Required For RO Installation in {title} ?
                                    </button>
                                </h5>
                            </div>
                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                <div className="panel-body">
                                    We provides RO service in {title} in same day when service is booked. In Some Place we provides services within 90 minutes. No other service provider is as quick as Aquapure RO Services installation service centre.
                                </div>
                            </div>
                        </div>
                        <div className="card panel panel-default">
                            <div className="card-header" id="headingOne">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                        <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> Why Should I Purchase RO AMC Plans in {title} ?
                                    </button>
                                </h5>
                            </div>

                            <div id="collapseFour" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                <div className="panel-body">
                                    Your water purifier demands regular repair, maintenance, and service to effective purification, which can be costly. RO AMC plans in {title} offer periodic maintenance and repair service of your water purifier. They provide various kinds of customized AMC plans, thus choose according to your budget and requirement.
                                </div>
                            </div>
                        </div>
                        <div className="card panel panel-default">
                            <div className="card-header" id="headingFive">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                                        <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> When Should I Go For RO Repair Services in {title} ?
                                    </button>
                                </h5>
                            </div>

                            <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                <div className="panel-body">
                                    Your water purifier starts showing some signs for it like odour in purified water, leakage, suddenly stop working and many other. Identify these signs and book RO repair services from Aquapure RO Services. you can book your service by calling on <a href="tel:9031062011">+91-9031062011</a>. You can also register your service / complain through Whatsapp <a href="https://api.whatsapp.com/send?phone=919031062011" target="_blank">+91-9031062011</a> or Visit <a href="www.aquapureroservices.com" target="_blank">www.aquapureroservices.com</a> for more details.
                                </div>
                            </div>
                        </div>
                        <div className="card panel panel-default">
                            <div className="card-header" id="headingSix">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                        <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> Why Choose RO Water Purifier service Center in {title} ?
                                    </button>
                                </h5>
                            </div>

                            <div id="collapseSix" className="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                                <div className="panel-body">
                                    RO water purifier service centre in {title} is available for all process such as installation, maintenance, and repairing of water purifier. They also understand customer budget and requirement thus choose Aquapure RO Services at best and affordable market price In PAN India.
                                </div>
                            </div>
                        </div>
                        <div className="card panel panel-default">
                            <div className="card-header" id="headingSeven">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                                        <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> Do you charge for inspection and diagnosis of the problem?
                                    </button>
                                </h5>
                            </div>

                            <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                                <div className="panel-body">
                                    Yes, we charge Rs 300/- as a visiting charge. However, this price may vary from place to place. And once you ask our service engineer to resolve the issues, this price gets adjusted.
                                </div>
                            </div>
                        </div>
                        <div className="card panel panel-default">
                            <div className="card-header" id="headingEight">
                                <h5 className="mb-0">
                                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                                        <i className="more-less glyphicon glyphicon-plus">&nbsp;</i> Which brands of water purifiers do you repair?
                                    </button>
                                </h5>
                            </div>

                            <div id="collapseEight" className="collapse" aria-labelledby="headingEight" data-parent="#accordion">
                                <div className="panel-body">
                                    We mainly deal in Kent, Aqua Guard, Aqua Fresh, Eureka Forbes, LivPure, PureIt and various other brands water purifiers. However, our RO water purifier services are available for all brands and kinds of water purifiers. So whenever you need services for your water purifier, do contact us via phone call or SMS.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}