import React from "react";
import "./ReadersRejoice.css";

const SarthakJeevan = (props) => {
    return (
        <div className="readerscard">
            <a href="https://sarthakjeevannasamuktikendra.in/" target="_blank" rel="noreferrer">
                <div className="sarthakimgback">
                    <div className="sarthakheader">
                        <img src="img/location/sarthaklogobg.png" alt="Rehab Center Logo" className="sarthaklogo" />
                        <h1>सार्थक जीवन</h1>
                        <h2 className="sarthaktagline">नशा मुक्ति केंद्र</h2>
                    </div>
                    <div className="sarthakcontent">
                        <p>
                            इससे पहले की नशा आपके किसी अपने को छीन ले, उन्हें बचा लीजिये। हम देते
                            हैं आपको नशा पीड़ित को घर से ले जाने की सुविधा।
                        </p>
                        <div className="sarthakcontact">
                            <a href="tel:+919044605877">9044605877</a>
                        </div>
                    </div>
                    <div className="sarthakcontact-item">
                        <a href="https://www.google.com/search?q=Sarthak+jeevan+nasa+mukti+kendra+!!+Best+rehabilitation+centre&stick=H4sIAAAAAAAA_wXBQQ6CMBAF0JXGYwwm7ocCWl248Aqc4FOmoRaGpIyG83hS3zsdzxfem3t0uDHztYnOt_HBO3zn2XeC0I6hrt3v8OxRbEKmt8gXSooNtHyyJcqiYwFVFb1kMyoyYUhzMlhalYKoFfkD8jtO-GoAAAA&hl=en&mat=CXNseHMokUDhElcBmzl_pdIrX4twFZ01uVou1Af2siAedWcaREjr5jREFoUT_kajQJvxedC111j5tY8tYtg9vxOnWUqkz_NEzr14Co7ge8fLh4_EAcw89q9BaQluZrWCa6g&authuser=0" target="_blank" rel="noreferrer">
                            <img src="img/location/sarthaklogobg.png" alt="Rehab Center Logo" className="maplogo" />
                        </a>
                        <a
                            href="https://maps.app.goo.gl/7ofZLLoDXwdvJ7NT9"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            नहर पर, इटा फैक्ट्री, संपतचक, बैरिया, थाना – गोपालपुरा, जिला – पटना,
                            पिन कोड – 800007, बिहार
                        </a>

                    </div>
                </div>
            </a>
        </div>
    );
};

export default SarthakJeevan;
