import { useState, useEffect } from "react";
import { Route, Routes, Switch } from 'react-router-dom';
import JsonData from "./data/data.json";
import locdata from "./data/locdata.json";
import rocompany from "./data/rocompany.json";

import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Navigation } from "./components/navigation";
import Home from "./Home";
import { Errorpage } from "./components/pages/errorpage";
import { Thanks } from "./components/pages/thanks";
import { Footer } from "./components/footer";
import Footerdetail from "./components/Footerdetail";
import { Bihar } from "./components/location/Bihar";
import { Jharkhand } from "./components/location/Jharkhand";
import { Bengal } from "./components/location/Bengal";
import { Popularcitydata } from "./components/location/Popularcitydata";
import { Usefulnearme } from "./components/location/Usefulnearme";
import { Returnypolicy } from "./components/pages/returnpolicy";
import { TermsCondition } from "./components/pages/termscondition";
import { Faqp } from "./components/pages/faqp";
import { Privacypolicy } from "./components/pages/privacypolicy";
import { Helpcenter } from "./components/pages/helpcenter";

import { Companylist } from "./components/location/Companylist";
import { Kentro } from "./components/pages/brandpage/kent/kentro";
import { AOSmithro } from "./components/pages/brandpage/aosmith/aosmithro";
import { Aquafreshro } from "./components/pages/brandpage/aquafresh/aquafreshro";
import { Aquaguardro } from "./components/pages/brandpage/aquaguard/aquaguardro";
import { BlueStarro } from "./components/pages/brandpage/bluestar/bluestarro";
//import { Eurekaforbesro } from "./components/pages/brandpage/edurekaforbes/eurekaforbesro";
import { Havellsro } from "./components/pages/brandpage/havells/havellsro";
import { LGro } from "./components/pages/brandpage/lg/lgro";
import { Livepurero } from "./components/pages/brandpage/livepure/livepurero";
import { MarQro } from "./components/pages/brandpage/marq/marqro";
import { PureItro } from "./components/pages/brandpage/pureit/pureitro";
import { Whirlpoolro } from "./components/pages/brandpage/whirlpool/whirlpoolro";
import { Kuchinaro } from "./components/pages/brandpage/kuchina/kuchinaro";
import { Aquagrandro } from "./components/pages/brandpage/aquagrand/aquagrandro";
import { BiharAquafresh } from "./components/pages/brandpage/aquafresh/biharaquafresh";
import { JharkhandAquafresh } from "./components/pages/brandpage/aquafresh/jharkhandaquafresh";
import { BengalAquafresh } from "./components/pages/brandpage/aquafresh/bengalaquafresh";
import { BiharAquagrand } from "./components/pages/brandpage/aquagrand/biharaquagrand";
import { JharkhandAquagrand } from "./components/pages/brandpage/aquagrand/jharkhandaquagrand";
import { BengalAquagrand } from "./components/pages/brandpage/aquagrand/bengalaquagrand";
import { BiharAOSmith } from "./components/pages/brandpage/aosmith/biharsosmith";
import { JharkhandAOSmith } from "./components/pages/brandpage/aosmith/jharkhandaosmith";
import { BengalAOSmith } from "./components/pages/brandpage/aosmith/bengalaosmith";
import { BiharAquaguard } from "./components/pages/brandpage/aquaguard/biharaquaguard";
import { JharkhandAquaguard } from "./components/pages/brandpage/aquaguard/jharkhandaquaguard";
import { BengalAquaguard } from "./components/pages/brandpage/aquaguard/bengalaquaguard";
//import { BiharEdurekaforbes } from "./components/pages/brandpage/edurekaforbes/biharedurekaforbes";
//import { JharkhandEdurekaforbes } from "./components/pages/brandpage/edurekaforbes/jharkhandedurekaforbes";
//import { BengalEdurekaforbes } from "./components/pages/brandpage/edurekaforbes/bengaledurekaforbes";
import { BiharHavells } from "./components/pages/brandpage/havells/biharhavells";
import { JharkhandHavells } from "./components/pages/brandpage/havells/jharkhandhavells";
import { BengalHavells } from "./components/pages/brandpage/havells/bengalhavells";
import { BiharKent } from "./components/pages/brandpage/kent/biharkent";
import { JharkhandKent } from "./components/pages/brandpage/kent/jharkhandkent";
import { BengalKent } from "./components/pages/brandpage/kent/bengalkent";
import { BengalKuchina } from "./components/pages/brandpage/kuchina/bengalkuchina";
import { JharkhandKuchina } from "./components/pages/brandpage/kuchina/jharkhandkuchina";
import { BiharKuchina } from "./components/pages/brandpage/kuchina/biharkuchina";
import { BengalLG } from "./components/pages/brandpage/lg/bengallg";
import { JharkhandLG } from "./components/pages/brandpage/lg/jharkhandlg";
import { BiharLG } from "./components/pages/brandpage/lg/biharlg";
import { BengalLivepure } from "./components/pages/brandpage/livepure/bengallivepure";
import { JharkhandLivepure } from "./components/pages/brandpage/livepure/jharkhandlivepure";
import { BiharLivepure } from "./components/pages/brandpage/livepure/biharlivepure";
import { BengalMarq } from "./components/pages/brandpage/marq/bengalmarq";
import { JharkhandMarq } from "./components/pages/brandpage/marq/jharkhandmarq";
import { BiharMarq } from "./components/pages/brandpage/marq/biharmarq";
import { BengalPureit } from "./components/pages/brandpage/pureit/bengalpureit";
import { JharkhandPureit } from "./components/pages/brandpage/pureit/jharkhandpureit";
import { BiharPureit } from "./components/pages/brandpage/pureit/biharpureit";
import { BengalWhirlpool } from "./components/pages/brandpage/whirlpool/bengalwhirlpool";
import { JharkhandWhirlpool } from "./components/pages/brandpage/whirlpool/jharkhandwhirlpool";
import { BiharWhirlpool } from "./components/pages/brandpage/whirlpool/biharwhirlpool";
import { BiharBluestar } from "./components/pages/brandpage/bluestar/biharbluestar";
import { JharkhandBluestar } from "./components/pages/brandpage/bluestar/jharkhandbluestar";
import { BengalBluestar } from "./components/pages/brandpage/bluestar/bengalbluestar";
import { UttarPradesh } from "./components/location/Uttarpradesh";
import { UttarPradeshKent } from "./components/pages/brandpage/kent/uttarpradeshkent";
import { UttarPradeshAquafresh } from "./components/pages/brandpage/aquafresh/UttarPradeshaquafresh";
import { UttarPradeshAquagrand } from "./components/pages/brandpage/aquagrand/uttarpradeshaquagrand";
import { UttarPradeshAOSmith } from "./components/pages/brandpage/aosmith/uttarpradeshaosmith";
import { UttarPradeshAquaguard } from "./components/pages/brandpage/aquaguard/uttarpradeshaquaguard";
import { UttarPradeshBluestar } from "./components/pages/brandpage/bluestar/uttarpradeshbluestar";
import { UttarPradeshHavells } from "./components/pages/brandpage/havells/uttarpradeshhavells";
import { UttarPradeshKuchina } from "./components/pages/brandpage/kuchina/uttarpradeshkuchina";
import { UttarPradeshLG } from "./components/pages/brandpage/lg/uttarpradeshlg";
import { UttarPradeshLivepure } from "./components/pages/brandpage/livepure/uttarpradeshlivepure";
import { UttarPradeshMarq } from "./components/pages/brandpage/marq/uttarpradeshmarq";
import { UttarPradeshPureit } from "./components/pages/brandpage/pureit/uttarpradeshpureit";
import { UttarPradeshWhirlpool } from "./components/pages/brandpage/whirlpool/uttarpradeshwhirlpool";
import { MadhyaPradesh } from "./components/location/MadhyaPradesh";
import { MadhyaPradeshAquafresh } from "./components/pages/brandpage/aquafresh/madhyapradeshaquafresh";
import { MadhyaPradeshAquagrand } from "./components/pages/brandpage/aquagrand/madhyapradeshaquagrand";
import { MadhyaPradeshAOSmith } from "./components/pages/brandpage/aosmith/madhyapradeshaosmith";
import { MadhyaPradeshAquaguard } from "./components/pages/brandpage/aquaguard/MadhyaPradeshAquaguard";
import { MadhyaPradeshBlueStar } from "./components/pages/brandpage/bluestar/madhyapradeshbluestar";
import { MadhyaPradeshHavells } from "./components/pages/brandpage/havells/madhyapradeshhavells";
import { MadhyaPradeshKent } from "./components/pages/brandpage/kent/madhyapradeshkent";
import { MadhyaPradeshKutchina } from "./components/pages/brandpage/kuchina/madhyapradeshkutchina";
import { MadhyaPradeshLG } from "./components/pages/brandpage/lg/madhyapradeshlg";
import { MadhyaPradeshLivpure } from "./components/pages/brandpage/livepure/madhyapradeshlivpure";
import { MadhyaPradeshMarQ } from "./components/pages/brandpage/marq/madhyapradeshmarq";
import { MadhyaPradeshPureit } from "./components/pages/brandpage/pureit/madhyapradeshpureit";
import { MadhyaPradeshWhirlpool } from "./components/pages/brandpage/whirlpool/madhyapradeshwhirlpool";
import { Rajasthan } from "./components/location/Rajasthan"
import { RajasthanAquaFresh } from "./components/pages/brandpage/aquafresh/rajasthanaquafresh";
import { RajasthanAquagrand } from "./components/pages/brandpage/aquagrand/rajasthanaquagrand";
import { RajasthanAOSmith } from "./components/pages/brandpage/aosmith/rajasthanaosmith";
import { RajasthanAquaguard } from "./components/pages/brandpage/aquaguard/rajasthanaquaguard";
import { RajasthanBlueStar } from "./components/pages/brandpage/bluestar/rajasthanbluestar";
import { RajasthanHavells } from "./components/pages/brandpage/havells/rajasthanhavells";
import { RajasthanKent } from "./components/pages/brandpage/kent/rajasthankent";
import { RajasthanKutchina } from "./components/pages/brandpage/kuchina/rajasthankutchina";
import { RajasthanLG } from "./components/pages/brandpage/lg/rajasthanlg";
import { RajasthanLivpure } from "./components/pages/brandpage/livepure/rajasthanlivpure";
import { RajasthanMarQ } from "./components/pages/brandpage/marq/rajasthanmarq";
import { RajasthanPureit } from "./components/pages/brandpage/pureit/rajasthanpureit";
import { RajasthanWhirlpool } from "./components/pages/brandpage/whirlpool/rajasthanwhirlpool";
import { ZeroBro } from "./components/pages/brandpage/zerob/zerobro";
import { BiharZeroB } from "./components/pages/brandpage/zerob/biharzerob";
import { JharkhandZeroB } from "./components/pages/brandpage/zerob/jharkhandzerob";
import { BengalZeroB } from "./components/pages/brandpage/zerob/bengalzerob";
import { RajasthanZeroB } from "./components/pages/brandpage/zerob/rajasthanzerob";
import { MadhyaPradeshZeroB } from "./components/pages/brandpage/zerob/madhyapradeshzerob";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});


const App = () => {
  /*
  const imageUrlkent = 'img/brandcover/kent.jpg';
  const imageUrlAOSmith = 'img/brandcover/aosmith.jpg';
  const imageUrlAquaguard = 'img/brandcover/aquaguard.jpg';
  const imageUrlAquaFresh = 'img/brandcover/aquafreshro.jpg';
  const imageUrlBlueStar = 'img/brandcover/bluestarro.jpg';
  const imageUrlEurekaForbes = 'img/brandcover/eduraka.jpg';
  const imageUrlHavells = 'img/brandcover/havelsro.jpg';
  const imageUrlLG = 'img/brandcover/lgro.jpg';
  const imageUrlLivpure = 'img/brandcover/livepure.jpg';
  const imageUrlMarQ = 'img/brandcover/marqro.jpg';
  const imageUrlPureit = 'img/brandcover/pureit.jpg';
  const imageUrlWhirlpool = 'img/brandcover/whirlpool.jpg';

  and use in component imageUrl={imageUrlAquaFresh}
  */


  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>

      <div className='social'>
        <div>
          <a className="whatsapp" href="https://api.whatsapp.com/send?phone=919031062011" target="_blank" rel="noreferrer">
            <i className="fa fa-whatsapp"></i>
          </a>
        </div>
        <div>
          <a className="phone" href="tel:9031062011">
            <i className="fa fa-phone"></i>
          </a>
        </div>
      </div>
      <Navigation />

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="*" element={<Errorpage />} />
        <Route exact path="/thanks" element={<Thanks />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/return-policy" element={<Returnypolicy />} />
        <Route path="/help-center" element={<Helpcenter />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/faq" element={<Faqp />} />
        {/*<Route path="/xyz/:title" element={<LocationDetail />} />*/}

        <Route path="/ro-service" element={<Usefulnearme title={locdata.RoService.title} number={locdata.RoService.number} />} />
        <Route path="/buy-water-purifier" element={<Usefulnearme title={locdata.WaterPurifier.title} number={locdata.WaterPurifier.number} />} />
        <Route path="/water-purifier-installation" element={<Usefulnearme title={locdata.Installation.title} number={locdata.Installation.number} />} />
        <Route path="/amc-plans" element={<Usefulnearme title={locdata.AMCPlans.title} number={locdata.AMCPlans.number} />} />
        <Route path="/customer-care" element={<Usefulnearme title={locdata.CustomerCare.title} number={locdata.CustomerCare.number} />} />
        <Route path="/charges" element={<Usefulnearme title={locdata.Charges.title} number={locdata.Charges.number} />} />
        <Route path="/ro-service-kit" element={<Usefulnearme title={locdata.ServiceKit.title} number={locdata.ServiceKit.number} />} />
        <Route path="/ro-plant" element={<Usefulnearme title={locdata.Plant.title} number={locdata.Plant.number} />} />
        <Route path="/ro-softener" element={<Usefulnearme title={locdata.Softener.title} number={locdata.Softener.number} />} />
        <Route path="/water-purifier-spare" element={<Usefulnearme title={locdata.Spare.title} number={locdata.Spare.number} />} />
        <Route path="/dm-plant-manufacturers" element={<Usefulnearme title={locdata.Manufacturers.title} number={locdata.Manufacturers.number} />} />
        <Route path="/sewage-treatment-plant-manufacturers" element={<Usefulnearme title={locdata.SewageTreatment.title} number={locdata.SewageTreatment.number} />} />
        <Route path="/atm" element={<Usefulnearme title={locdata.ATM.title} number={locdata.ATM.number} />} />
        <Route path="/effluent-treatment-manufacturers" element={<Usefulnearme title={locdata.EffluentTreatment.title} number={locdata.EffluentTreatment.number} />} />

        <Route path="/ro-service-Araria" element={<Bihar title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/ro-service-Arwal" element={<Bihar title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/ro-service-Aurangabad" element={<Bihar title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/ro-service-Banka" element={<Bihar title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/ro-service-Begusarai" element={<Bihar title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/ro-service-Bhabua" element={<Bihar title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/ro-service-Bhagalpur" element={<Bihar title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/ro-service-Bhojpur" element={<Bihar title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/ro-service-Bihar-Sharif" element={<Bihar title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/ro-service-Buxar" element={<Bihar title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/ro-service-Chhapra" element={<Bihar title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/ro-service-Darbhanga" element={<Bihar title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/ro-service-East-Champaran" element={<Bihar title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/ro-service-Gaya" element={<Bihar title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/ro-service-Gopalganj" element={<Bihar title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/ro-service-Jamui" element={<Bihar title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/ro-service-Jehanabad" element={<Bihar title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/ro-service-Khagaria" element={<Bihar title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/ro-service-Kishanganj" element={<Bihar title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/ro-service-Kaimur" element={<Bihar title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/ro-service-Katihar" element={<Bihar title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/ro-service-Lakhisarai" element={<Bihar title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/ro-service-Madhubani" element={<Bihar title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/ro-service-Munger" element={<Bihar title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/ro-service-Madhepura" element={<Bihar title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/ro-service-Muzaffarpur" element={<Bihar title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/ro-service-Nalanda" element={<Bihar title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/ro-service-Nawada" element={<Bihar title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/ro-service-Patna" element={<Bihar title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/ro-service-Purnia" element={<Bihar title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/ro-service-Rohtas" element={<Bihar title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/ro-service-Saharsa" element={<Bihar title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/ro-service-Samastipur" element={<Bihar title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/ro-service-Sasaram" element={<Bihar title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/ro-service-Sheohar" element={<Bihar title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/ro-service-Sheikhpura" element={<Bihar title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/ro-service-Saran" element={<Bihar title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/ro-service-Sitamarhi" element={<Bihar title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/ro-service-Supaul" element={<Bihar title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/ro-service-Siwan" element={<Bihar title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/ro-service-Vaishali" element={<Bihar title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/ro-service-West-Champaran" element={<Bihar title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/ro-service-Garhwa" element={<Jharkhand title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/ro-service-Palamu" element={<Jharkhand title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/ro-service-Latehar" element={<Jharkhand title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/ro-service-Chatra" element={<Jharkhand title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/ro-service-Hazaribagh" element={<Jharkhand title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/ro-service-Jamshedpur" element={<Jharkhand title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/ro-service-Koderma" element={<Jharkhand title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/ro-service-Giridih" element={<Jharkhand title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/ro-service-Ramgarh" element={<Jharkhand title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/ro-service-Bokaro" element={<Jharkhand title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/ro-service-Dhanbad" element={<Jharkhand title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/ro-service-Lohardaga" element={<Jharkhand title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/ro-service-Gumla" element={<Jharkhand title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/ro-service-Simdega" element={<Jharkhand title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/ro-service-Ranchi" element={<Jharkhand title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/ro-service-Khunti" element={<Jharkhand title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/ro-service-Singhbhum" element={<Jharkhand title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/ro-service-Saraikela-Kharsawan" element={<Jharkhand title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/ro-service-Jamtara" element={<Jharkhand title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/ro-service-Deoghar" element={<Jharkhand title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/ro-service-Dumka" element={<Jharkhand title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/ro-service-Pakur" element={<Jharkhand title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/ro-service-Godda" element={<Jharkhand title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/ro-service-Sahebganj" element={<Jharkhand title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />

        <Route path="/ro-service-Alipurduar" element={<Bengal title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/ro-service-Bankura" element={<Bengal title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/ro-service-Birbhum" element={<Bengal title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/ro-service-Cooch-Behar" element={<Bengal title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/ro-service-Dakshin-Dinajpur" element={<Bengal title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/ro-service-Darjeeling" element={<Bengal title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/ro-service-Durgapur" element={<Bengal title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/ro-service-Hooghly" element={<Bengal title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/ro-service-Howrah" element={<Bengal title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/ro-service-Jalpaiguri" element={<Bengal title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/ro-service-Jhargram" element={<Bengal title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/ro-service-Kalimpong" element={<Bengal title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/ro-service-Kolkata" element={<Bengal title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/ro-service-Malda" element={<Bengal title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/ro-service-Murshidabad" element={<Bengal title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/ro-service-Nadia" element={<Bengal title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/ro-service-North-24-Parganas" element={<Bengal title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/ro-service-Paschim-Bardhaman" element={<Bengal title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/ro-service-Paschim-Medinipur" element={<Bengal title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/ro-service-Purba-Bardhaman" element={<Bengal title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/ro-service-Purba-Medinipur" element={<Bengal title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/ro-service-South-24-Parganas" element={<Bengal title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/ro-service-Uttar-Dinajpur" element={<Bengal title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />

        <Route path="/ro-service-Agra" element={<UttarPradesh title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/ro-service-Aligarh" element={<UttarPradesh title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/ro-service-Ambedkar-Nagar" element={<UttarPradesh title={locdata.AmbedkarNagar.title} number={locdata.AmbedkarNagar.number} />} />
        <Route path="/ro-service-Ayodhya" element={<UttarPradesh title={locdata.Ayodhya.title} number={locdata.Ayodhya.number} />} />
        <Route path="/ro-service-Amethi" element={<UttarPradesh title={locdata.Amethi.title} number={locdata.Amethi.number} />} />
        <Route path="/ro-service-Amroha" element={<UttarPradesh title={locdata.Amroha.title} number={locdata.Amroha.number} />} />
        <Route path="/ro-service-Auraiya" element={<UttarPradesh title={locdata.Auraiya.title} number={locdata.Auraiya.number} />} />
        <Route path="/ro-service-Azamgarh" element={<UttarPradesh title={locdata.Azamgarh.title} number={locdata.Azamgarh.number} />} />
        <Route path="/ro-service-Budaun" element={<UttarPradesh title={locdata.Budaun.title} number={locdata.Budaun.number} />} />
        <Route path="/ro-service-Bagpat" element={<UttarPradesh title={locdata.Bagpat.title} number={locdata.Bagpat.number} />} />
        <Route path="/ro-service-Bahraich" element={<UttarPradesh title={locdata.Bahraich.title} number={locdata.Bahraich.number} />} />
        <Route path="/ro-service-Ballia" element={<UttarPradesh title={locdata.Ballia.title} number={locdata.Ballia.number} />} />
        <Route path="/ro-service-Balrampur" element={<UttarPradesh title={locdata.Balrampur.title} number={locdata.Balrampur.number} />} />
        <Route path="/ro-service-Banda" element={<UttarPradesh title={locdata.Banda.title} number={locdata.Banda.number} />} />
        <Route path="/ro-service-Barabanki" element={<UttarPradesh title={locdata.Barabanki.title} number={locdata.Barabanki.number} />} />
        <Route path="/ro-service-Bareilly" element={<UttarPradesh title={locdata.Bareilly.title} number={locdata.Bareilly.number} />} />
        <Route path="/ro-service-Basti" element={<UttarPradesh title={locdata.Basti.title} number={locdata.Basti.number} />} />
        <Route path="/ro-service-Bijnor" element={<UttarPradesh title={locdata.Bijnor.title} number={locdata.Bijnor.number} />} />
        <Route path="/ro-service-Bulandshahr" element={<UttarPradesh title={locdata.Bulandshahr.title} number={locdata.Bulandshahr.number} />} />
        <Route path="/ro-service-Chandauli" element={<UttarPradesh title={locdata.Chandauli.title} number={locdata.Chandauli.number} />} />
        <Route path="/ro-service-Chitrakoot" element={<UttarPradesh title={locdata.Chitrakoot.title} number={locdata.Chitrakoot.number} />} />
        <Route path="/ro-service-Deoria" element={<UttarPradesh title={locdata.Deoria.title} number={locdata.Deoria.number} />} />
        <Route path="/ro-service-Etah" element={<UttarPradesh title={locdata.Etah.title} number={locdata.Etah.number} />} />
        <Route path="/ro-service-Etawah" element={<UttarPradesh title={locdata.Etawah.title} number={locdata.Etawah.number} />} />
        <Route path="/ro-service-Farrukhabad" element={<UttarPradesh title={locdata.Farrukhabad.title} number={locdata.Farrukhabad.number} />} />
        <Route path="/ro-service-Fatehpur" element={<UttarPradesh title={locdata.Fatehpur.title} number={locdata.Fatehpur.number} />} />
        <Route path="/ro-service-Firozabad" element={<UttarPradesh title={locdata.Firozabad.title} number={locdata.Firozabad.number} />} />
        <Route path="/ro-service-Gautam-Buddha-Nagar" element={<UttarPradesh title={locdata.GautamBuddhaNagar.title} number={locdata.GautamBuddhaNagar.number} />} />
        <Route path="/ro-service-Ghaziabad" element={<UttarPradesh title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/ro-service-Ghazipur" element={<UttarPradesh title={locdata.Ghazipur.title} number={locdata.Ghazipur.number} />} />
        <Route path="/ro-service-Gonda" element={<UttarPradesh title={locdata.Gonda.title} number={locdata.Gonda.number} />} />
        <Route path="/ro-service-Gorakhpur" element={<UttarPradesh title={locdata.Gorakhpur.title} number={locdata.Gorakhpur.number} />} />
        <Route path="/ro-service-Hamirpur" element={<UttarPradesh title={locdata.Hamirpur.title} number={locdata.Hamirpur.number} />} />
        <Route path="/ro-service-Hapur" element={<UttarPradesh title={locdata.Hapur.title} number={locdata.Hapur.number} />} />
        <Route path="/ro-service-Hardoi" element={<UttarPradesh title={locdata.Hardoi.title} number={locdata.Hardoi.number} />} />
        <Route path="/ro-service-Hathras" element={<UttarPradesh title={locdata.Hathras.title} number={locdata.Hathras.number} />} />
        <Route path="/ro-service-Jalaun" element={<UttarPradesh title={locdata.Jalaun.title} number={locdata.Jalaun.number} />} />
        <Route path="/ro-service-Jaunpur" element={<UttarPradesh title={locdata.Jaunpur.title} number={locdata.Jaunpur.number} />} />
        <Route path="/ro-service-Jhansi" element={<UttarPradesh title={locdata.Jhansi.title} number={locdata.Jhansi.number} />} />
        <Route path="/ro-service-Kannauj" element={<UttarPradesh title={locdata.Kannauj.title} number={locdata.Kannauj.number} />} />
        <Route path="/ro-service-Kanpur Dehat" element={<UttarPradesh title={locdata.KanpurDehat.title} number={locdata.KanpurDehat.number} />} />
        <Route path="/ro-service-Kanpur" element={<UttarPradesh title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/ro-service-Kasganj" element={<UttarPradesh title={locdata.Kasganj.title} number={locdata.Kasganj.number} />} />
        <Route path="/ro-service-Kaushambi" element={<UttarPradesh title={locdata.Kaushambi.title} number={locdata.Kaushambi.number} />} />
        <Route path="/ro-service-Kushinagar" element={<UttarPradesh title={locdata.Kushinagar.title} number={locdata.Kushinagar.number} />} />
        <Route path="/ro-service-Lakhimpur-Kheri" element={<UttarPradesh title={locdata.LakhimpurKheri.title} number={locdata.LakhimpurKheri.number} />} />
        <Route path="/ro-service-Lalitpur" element={<UttarPradesh title={locdata.Lalitpur.title} number={locdata.Lalitpur.number} />} />
        <Route path="/ro-service-Lucknow" element={<UttarPradesh title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/ro-service-Maharajganj" element={<UttarPradesh title={locdata.Maharajganj.title} number={locdata.Maharajganj.number} />} />
        <Route path="/ro-service-Mahoba" element={<UttarPradesh title={locdata.Mahoba.title} number={locdata.Mahoba.number} />} />
        <Route path="/ro-service-Mainpuri" element={<UttarPradesh title={locdata.Mainpuri.title} number={locdata.Mainpuri.number} />} />
        <Route path="/ro-service-Mathura" element={<UttarPradesh title={locdata.Mathura.title} number={locdata.Mathura.number} />} />
        <Route path="/ro-service-Mau" element={<UttarPradesh title={locdata.Mau.title} number={locdata.Mau.number} />} />
        <Route path="/ro-service-Meerut" element={<UttarPradesh title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/ro-service-Mirzapur" element={<UttarPradesh title={locdata.Mirzapur.title} number={locdata.Mirzapur.number} />} />
        <Route path="/ro-service-Moradabad" element={<UttarPradesh title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/ro-service-Muzaffarnagar" element={<UttarPradesh title={locdata.Muzaffarnagar.title} number={locdata.Muzaffarnagar.number} />} />
        <Route path="/ro-service-Pilibhit" element={<UttarPradesh title={locdata.Pilibhit.title} number={locdata.Pilibhit.number} />} />
        <Route path="/ro-service-Pratapgarh" element={<UttarPradesh title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/ro-service-Prayagraj" element={<UttarPradesh title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/ro-service-Rae Bareli" element={<UttarPradesh title={locdata.RaeBareli.title} number={locdata.RaeBareli.number} />} />
        <Route path="/ro-service-Rampur" element={<UttarPradesh title={locdata.Rampur.title} number={locdata.Rampur.number} />} />
        <Route path="/ro-service-Saharanpur" element={<UttarPradesh title={locdata.Saharanpur.title} number={locdata.Saharanpur.number} />} />
        <Route path="/ro-service-Sant-Kabir-Nagar" element={<UttarPradesh title={locdata.SantKabirNagar.title} number={locdata.SantKabirNagar.number} />} />
        <Route path="/ro-service-Bhadohi" element={<UttarPradesh title={locdata.Bhadohi.title} number={locdata.Bhadohi.number} />} />
        <Route path="/ro-service-Sambhal" element={<UttarPradesh title={locdata.Sambhal.title} number={locdata.Sambhal.number} />} />
        <Route path="/ro-service-Shahjahanpur" element={<UttarPradesh title={locdata.Shahjahanpur.title} number={locdata.Shahjahanpur.number} />} />
        <Route path="/ro-service-Shamli" element={<UttarPradesh title={locdata.Shamli.title} number={locdata.Shamli.number} />} />
        <Route path="/ro-service-Shravasti" element={<UttarPradesh title={locdata.Shravasti.title} number={locdata.Shravasti.number} />} />
        <Route path="/ro-service-Siddharthnagar" element={<UttarPradesh title={locdata.Siddharthnagar.title} number={locdata.Siddharthnagar.number} />} />
        <Route path="/ro-service-Sitapur" element={<UttarPradesh title={locdata.Sitapur.title} number={locdata.Sitapur.number} />} />
        <Route path="/ro-service-Sonbhadra" element={<UttarPradesh title={locdata.Sonbhadra.title} number={locdata.Sonbhadra.number} />} />
        <Route path="/ro-service-Sultanpur" element={<UttarPradesh title={locdata.Sultanpur.title} number={locdata.Sultanpur.number} />} />
        <Route path="/ro-service-Unnao" element={<UttarPradesh title={locdata.Unnao.title} number={locdata.Unnao.number} />} />
        <Route path="/ro-service-Varanasi" element={<UttarPradesh title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/ro-service-Robertsganj" element={<UttarPradesh title={locdata.Robertsganj.title} number={locdata.Robertsganj.number} />} />
        <Route path="/ro-service-Bahjoi" element={<UttarPradesh title={locdata.Bahjoi.title} number={locdata.Bahjoi.number} />} />
        <Route path="/ro-service-Khalilabad" element={<UttarPradesh title={locdata.Khalilabad.title} number={locdata.Khalilabad.number} />} />
        <Route path="/ro-service-Akbarpur" element={<UttarPradesh title={locdata.Akbarpur.title} number={locdata.Akbarpur.number} />} />
        <Route path="/ro-service-Orai" element={<UttarPradesh title={locdata.Orai.title} number={locdata.Orai.number} />} />
        <Route path="/ro-service-Manjhanpur" element={<UttarPradesh title={locdata.Manjhanpur.title} number={locdata.Manjhanpur.number} />} />
        <Route path="/ro-service-Padarauna" element={<UttarPradesh title={locdata.Padarauna.title} number={locdata.Padarauna.number} />} />
        <Route path="/ro-service-Kheri" element={<UttarPradesh title={locdata.Kheri.title} number={locdata.Kheri.number} />} />
        <Route path="/ro-service-Fatehgarh" element={<UttarPradesh title={locdata.Fatehgarh.title} number={locdata.Fatehgarh.number} />} />
        <Route path="/ro-service-Noida" element={<UttarPradesh title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/ro-service-Greater-Noida" element={<UttarPradesh title={locdata.GreaterNoida.title} number={locdata.GreaterNoida.number} />} />
        <Route path="/ro-service-Indore" element={<MadhyaPradesh title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/ro-service-Bhopal" element={<MadhyaPradesh title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/ro-service-Jabalpur" element={<MadhyaPradesh title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/ro-service-Gwalior" element={<MadhyaPradesh title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/ro-service-Ujjain" element={<MadhyaPradesh title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/ro-service-Sagar" element={<MadhyaPradesh title={locdata.Sagar.title} number={locdata.Sagar.number} />} />
        <Route path="/ro-service-Dewas" element={<MadhyaPradesh title={locdata.Dewas.title} number={locdata.Dewas.number} />} />
        <Route path="/ro-service-Satna" element={<MadhyaPradesh title={locdata.Satna.title} number={locdata.Satna.number} />} />
        <Route path="/ro-service-Ratlam" element={<MadhyaPradesh title={locdata.Ratlam.title} number={locdata.Ratlam.number} />} />
        <Route path="/ro-service-Rewa" element={<MadhyaPradesh title={locdata.Rewa.title} number={locdata.Rewa.number} />} />
        <Route path="/ro-service-Katni" element={<MadhyaPradesh title={locdata.Katni.title} number={locdata.Katni.number} />} />
        <Route path="/ro-service-Singrauli" element={<MadhyaPradesh title={locdata.Singrauli.title} number={locdata.Singrauli.number} />} />
        <Route path="/ro-service-Burhanpur" element={<MadhyaPradesh title={locdata.Burhanpur.title} number={locdata.Burhanpur.number} />} />
        <Route path="/ro-service-Morena" element={<MadhyaPradesh title={locdata.Morena.title} number={locdata.Morena.number} />} />
        <Route path="/ro-service-Khandwa" element={<MadhyaPradesh title={locdata.Khandwa.title} number={locdata.Khandwa.number} />} />
        <Route path="/ro-service-Bhind" element={<MadhyaPradesh title={locdata.Bhind.title} number={locdata.Bhind.number} />} />
        <Route path="/ro-service-Chhindwara" element={<MadhyaPradesh title={locdata.Chhindwara.title} number={locdata.Chhindwara.number} />} />
        <Route path="/ro-service-Guna" element={<MadhyaPradesh title={locdata.Guna.title} number={locdata.Guna.number} />} />
        <Route path="/ro-service-Shivpuri" element={<MadhyaPradesh title={locdata.Shivpuri.title} number={locdata.Shivpuri.number} />} />
        <Route path="/ro-service-Vidisha" element={<MadhyaPradesh title={locdata.Vidisha.title} number={locdata.Vidisha.number} />} />
        <Route path="/ro-service-Chhatarpur" element={<MadhyaPradesh title={locdata.Chhatarpur.title} number={locdata.Chhatarpur.number} />} />
        <Route path="/ro-service-Damoh" element={<MadhyaPradesh title={locdata.Damoh.title} number={locdata.Damoh.number} />} />
        <Route path="/ro-service-Mandsaur" element={<MadhyaPradesh title={locdata.Mandsaur.title} number={locdata.Mandsaur.number} />} />
        <Route path="/ro-service-Khargone" element={<MadhyaPradesh title={locdata.Khargone.title} number={locdata.Khargone.number} />} />
        <Route path="/ro-service-Neemuch" element={<MadhyaPradesh title={locdata.Neemuch.title} number={locdata.Neemuch.number} />} />
        <Route path="/ro-service-Pithampur" element={<MadhyaPradesh title={locdata.Pithampur.title} number={locdata.Pithampur.number} />} />
        <Route path="/ro-service-Narmadapuram" element={<MadhyaPradesh title={locdata.Narmadapuram.title} number={locdata.Narmadapuram.number} />} />
        <Route path="/ro-service-Itarsi" element={<MadhyaPradesh title={locdata.Itarsi.title} number={locdata.Itarsi.number} />} />
        <Route path="/ro-service-Sehore" element={<MadhyaPradesh title={locdata.Sehore.title} number={locdata.Sehore.number} />} />
        <Route path="/ro-service-Betul" element={<MadhyaPradesh title={locdata.Betul.title} number={locdata.Betul.number} />} />
        <Route path="/ro-service-Seoni" element={<MadhyaPradesh title={locdata.Seoni.title} number={locdata.Seoni.number} />} />
        <Route path="/ro-service-Datia" element={<MadhyaPradesh title={locdata.Datia.title} number={locdata.Datia.number} />} />
        <Route path="/ro-service-Nagda" element={<MadhyaPradesh title={locdata.Nagda.title} number={locdata.Nagda.number} />} />
        <Route path="/ro-service-Shahdol" element={<MadhyaPradesh title={locdata.Shahdol.title} number={locdata.Shahdol.number} />} />
        <Route path="/ro-service-Ajmer" element={<Rajasthan title={locdata.Ajmer.title} number={locdata.Ajmer.number} />} />
        <Route path="/ro-service-Alwar" element={<Rajasthan title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/ro-service-Balotra" element={<Rajasthan title={locdata.Balotra.title} number={locdata.Balotra.number} />} />
        <Route path="/ro-service-Banswara" element={<Rajasthan title={locdata.Banswara.title} number={locdata.Banswara.number} />} />
        <Route path="/ro-service-Baran" element={<Rajasthan title={locdata.Baran.title} number={locdata.Baran.number} />} />
        <Route path="/ro-service-Barmer" element={<Rajasthan title={locdata.Barmer.title} number={locdata.Barmer.number} />} />
        <Route path="/ro-service-Beawar" element={<Rajasthan title={locdata.Beawar.title} number={locdata.Beawar.number} />} />
        <Route path="/ro-service-Bharatpur" element={<Rajasthan title={locdata.Bharatpur.title} number={locdata.Bharatpur.number} />} />
        <Route path="/ro-service-Bhilwara" element={<Rajasthan title={locdata.Bhilwara.title} number={locdata.Bhilwara.number} />} />
        <Route path="/ro-service-Bikaner" element={<Rajasthan title={locdata.Bikaner.title} number={locdata.Bikaner.number} />} />
        <Route path="/ro-service-Bundi" element={<Rajasthan title={locdata.Bundi.title} number={locdata.Bundi.number} />} />
        <Route path="/ro-service-Chittorgarh" element={<Rajasthan title={locdata.Chittorgarh.title} number={locdata.Chittorgarh.number} />} />
        <Route path="/ro-service-Churu" element={<Rajasthan title={locdata.Churu.title} number={locdata.Churu.number} />} />
        <Route path="/ro-service-Dausa" element={<Rajasthan title={locdata.Dausa.title} number={locdata.Dausa.number} />} />
        <Route path="/ro-service-Deeg" element={<Rajasthan title={locdata.Deeg.title} number={locdata.Deeg.number} />} />
        <Route path="/ro-service-Deedwana" element={<Rajasthan title={locdata.Deedwana.title} number={locdata.Deedwana.number} />} />
        <Route path="/ro-service-Dholpur" element={<Rajasthan title={locdata.Dholpur.title} number={locdata.Dholpur.number} />} />
        <Route path="/ro-service-Dungarpur" element={<Rajasthan title={locdata.Dungarpur.title} number={locdata.Dungarpur.number} />} />
        <Route path="/ro-service-Hanumangarh" element={<Rajasthan title={locdata.Hanumangarh.title} number={locdata.Hanumangarh.number} />} />
        <Route path="/ro-service-Jaipur" element={<Rajasthan title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/ro-service-Jaisalmer" element={<Rajasthan title={locdata.Jaisalmer.title} number={locdata.Jaisalmer.number} />} />
        <Route path="/ro-service-Jalore" element={<Rajasthan title={locdata.Jalore.title} number={locdata.Jalore.number} />} />
        <Route path="/ro-service-Jhalawar" element={<Rajasthan title={locdata.Jhalawar.title} number={locdata.Jhalawar.number} />} />
        <Route path="/ro-service-Jhunjhunu" element={<Rajasthan title={locdata.Jhunjhunu.title} number={locdata.Jhunjhunu.number} />} />
        <Route path="/ro-service-Jodhpur" element={<Rajasthan title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/ro-service-Karauli" element={<Rajasthan title={locdata.Karauli.title} number={locdata.Karauli.number} />} />
        <Route path="/ro-service-Tijara" element={<Rajasthan title={locdata.Tijara.title} number={locdata.Tijara.number} />} />
        <Route path="/ro-service-Kotputli" element={<Rajasthan title={locdata.Kotputli.title} number={locdata.Kotputli.number} />} />
        <Route path="/ro-service-Kota" element={<Rajasthan title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/ro-service-Nagaur" element={<Rajasthan title={locdata.Nagaur.title} number={locdata.Nagaur.number} />} />
        <Route path="/ro-service-Pali" element={<Rajasthan title={locdata.Pali.title} number={locdata.Pali.number} />} />
        <Route path="/ro-service-Phalodi" element={<Rajasthan title={locdata.Phalodi.title} number={locdata.Phalodi.number} />} />
        <Route path="/ro-service-Pratapgarh" element={<Rajasthan title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/ro-service-Rajsamand" element={<Rajasthan title={locdata.Rajsamand.title} number={locdata.Rajsamand.number} />} />
        <Route path="/ro-service-Salumbar" element={<Rajasthan title={locdata.Salumbar.title} number={locdata.Salumbar.number} />} />
        <Route path="/ro-service-Sawai-Madhopur" element={<Rajasthan title={locdata.SawaiMadhopur.title} number={locdata.SawaiMadhopur.number} />} />
        <Route path="/ro-service-Sikar" element={<Rajasthan title={locdata.Sikar.title} number={locdata.Sikar.number} />} />
        <Route path="/ro-service-Sirohi" element={<Rajasthan title={locdata.Sirohi.title} number={locdata.Sirohi.number} />} />
        <Route path="/ro-service-Sri-Ganganagar" element={<Rajasthan title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/ro-service-Tonk" element={<Rajasthan title={locdata.Tonk.title} number={locdata.Tonk.number} />} />
        <Route path="/ro-service-Udaipur" element={<Rajasthan title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/ro-service-Dhar" element={<MadhyaPradesh title={locdata.Dhar.title} number={locdata.Dhar.number} />} />
        <Route path="/ro-service-Mumbai" element={<Popularcitydata title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/ro-service-Delhi" element={<Popularcitydata title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/ro-service-Bengaluru" element={<Popularcitydata title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/ro-service-Hyderabad" element={<Popularcitydata title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/ro-service-Ahmedabad" element={<Popularcitydata title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/ro-service-Chennai" element={<Popularcitydata title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/ro-service-Surat" element={<Popularcitydata title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/ro-service-Pune" element={<Popularcitydata title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/ro-service-Jaipur" element={<Popularcitydata title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/ro-service-Lucknow" element={<Popularcitydata title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/ro-service-Kanpur" element={<Popularcitydata title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/ro-service-Nagpur" element={<Popularcitydata title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/ro-service-Indore" element={<Popularcitydata title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/ro-service-Thane" element={<Popularcitydata title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/ro-service-Bhopal" element={<Popularcitydata title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/ro-service-Visakhapatnam" element={<Popularcitydata title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/ro-service-Vadodara" element={<Popularcitydata title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/ro-service-Ghaziabad" element={<Popularcitydata title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/ro-service-Ludhiana" element={<Popularcitydata title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/ro-service-Agra" element={<Popularcitydata title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/ro-service-Nashik" element={<Popularcitydata title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/ro-service-Faridabad" element={<Popularcitydata title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/ro-service-Meerut" element={<Popularcitydata title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/ro-service-Rajkot" element={<Popularcitydata title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/ro-service-Varanasi" element={<Popularcitydata title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/ro-service-Srinagar" element={<Popularcitydata title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/ro-service-Amritsar" element={<Popularcitydata title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/ro-service-Navi-Mumbai" element={<Popularcitydata title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/ro-service-Prayagraj" element={<Popularcitydata title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/ro-service-Jabalpur" element={<Popularcitydata title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/ro-service-Gwalior" element={<Popularcitydata title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/ro-service-Coimbatore" element={<Popularcitydata title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/ro-service-Vijayawada" element={<Popularcitydata title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/ro-service-Jodhpur" element={<Popularcitydata title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/ro-service-Madurai" element={<Popularcitydata title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/ro-service-Raipur" element={<Popularcitydata title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/ro-service-Kota" element={<Popularcitydata title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/ro-service-Guwahati" element={<Popularcitydata title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/ro-service-Chandigarh" element={<Popularcitydata title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/ro-service-Thiruvananthapuram" element={<Popularcitydata title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/ro-service-Solapur" element={<Popularcitydata title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/ro-service-Tiruppur" element={<Popularcitydata title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/ro-service-Moradabad" element={<Popularcitydata title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/ro-service-Mysore" element={<Popularcitydata title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/ro-service-Gurgaon" element={<Popularcitydata title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/ro-service-Aligarh" element={<Popularcitydata title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/ro-service-Jalandhar" element={<Popularcitydata title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/ro-service-Bhubaneswar" element={<Popularcitydata title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/ro-service-Noida" element={<Popularcitydata title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/ro-service-Jamshedpur" element={<Popularcitydata title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/ro-service-Cuttack" element={<Popularcitydata title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/ro-service-Bhavnagar" element={<Popularcitydata title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/ro-service-Dehradun" element={<Popularcitydata title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/ro-service-Rourkela" element={<Popularcitydata title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/ro-service-Jamnagar" element={<Popularcitydata title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/ro-service-Ujjain" element={<Popularcitydata title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/ro-service-Jammu" element={<Popularcitydata title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/ro-service-Mangalore" element={<Popularcitydata title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/ro-service-Erode" element={<Popularcitydata title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/ro-service-Udaipur" element={<Popularcitydata title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/ro-service-Rajahmundry" element={<Popularcitydata title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/ro-service-Patiala" element={<Popularcitydata title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/ro-service-Agartala" element={<Popularcitydata title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/ro-service-Tirupati" element={<Popularcitydata title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/ro-service-Sambalpur" element={<Popularcitydata title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/ro-service-Bilaspur" element={<Popularcitydata title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/ro-service-Alwar" element={<Popularcitydata title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/ro-service-Bardhaman" element={<Popularcitydata title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/ro-service-Sonipat" element={<Popularcitydata title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/ro-service-New-Delhi" element={<Popularcitydata title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/ro-service-Puducherry" element={<Popularcitydata title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/ro-service-Haridwar" element={<Popularcitydata title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/ro-service-Vijayanagaram" element={<Popularcitydata title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/ro-service-Sri-Ganganagar" element={<Popularcitydata title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/ro-service-Secunderabad" element={<Popularcitydata title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/ro-service-Yamunanagar" element={<Popularcitydata title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/ro-service-Gandhinagar" element={<Popularcitydata title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/ro-service-Ambala" element={<Popularcitydata title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/ro-service-Vellore" element={<Popularcitydata title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/ro-service-Shimla" element={<Popularcitydata title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/ro-service-Amaravati" element={<Popularcitydata title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />


        <Route path="/ro-service-Kent" element={<Kentro title={rocompany.Kent.title} number={rocompany.Kent.number} />} />
        <Route path="/ro-service-AOSmith" element={<AOSmithro title={rocompany.AOSmith.title} number={rocompany.AOSmith.number} />} />
        <Route path="/ro-service-Aquaguard" element={<Aquaguardro title={rocompany.Aquaguard.title} number={rocompany.Aquaguard.number} />} />
        <Route path="/ro-service-AquaFresh" element={<Aquafreshro title={rocompany.AquaFresh.title} number={rocompany.AquaFresh.number} />} />
        <Route path="/ro-service-BlueStar" element={<BlueStarro title={rocompany.BlueStar.title} number={rocompany.BlueStar.number} />} />
        <Route path="/ro-service-ZeroB" element={<ZeroBro title={rocompany.ZeroB.title} number={rocompany.ZeroB.number} />} />
        <Route path="/ro-service-Havells" element={<Havellsro title={rocompany.Havells.title} number={rocompany.Havells.number} />} />
        <Route path="/ro-service-LG" element={<LGro title={rocompany.LG.title} number={rocompany.LG.number} />} />
        <Route path="/ro-service-Livpure" element={<Livepurero title={rocompany.Livpure.title} number={rocompany.Livpure.number} />} />
        <Route path="/ro-service-MarQ" element={<MarQro title={rocompany.MarQ.title} number={rocompany.MarQ.number} />} />
        <Route path="/ro-service-Pureit" element={<PureItro title={rocompany.Pureit.title} number={rocompany.Pureit.number} />} />
        <Route path="/ro-service-Whirlpool" element={<Whirlpoolro title={rocompany.Whirlpool.title} number={rocompany.Whirlpool.number} />} />
        <Route path="/ro-service-Kutchina" element={<Kuchinaro title={rocompany.MarQ.title} number={rocompany.MarQ.number} />} />
        <Route path="/ro-service-Aquagrand" element={<Aquagrandro title={rocompany.Pureit.title} number={rocompany.Pureit.number} />} />


        <Route path="/aquafresh-ro-service-Araria" element={<BiharAquafresh title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/aquafresh-ro-service-Arwal" element={<BiharAquafresh title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/aquafresh-ro-service-Aurangabad" element={<BiharAquafresh title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/aquafresh-ro-service-Banka" element={<BiharAquafresh title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/aquafresh-ro-service-Begusarai" element={<BiharAquafresh title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/aquafresh-ro-service-Bhabua" element={<BiharAquafresh title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/aquafresh-ro-service-Bhagalpur" element={<BiharAquafresh title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/aquafresh-ro-service-Bhojpur" element={<BiharAquafresh title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/aquafresh-ro-service-Bihar-Sharif" element={<BiharAquafresh title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/aquafresh-ro-service-Buxar" element={<BiharAquafresh title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/aquafresh-ro-service-Chhapra" element={<BiharAquafresh title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/aquafresh-ro-service-Darbhanga" element={<BiharAquafresh title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/aquafresh-ro-service-East-Champaran" element={<BiharAquafresh title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/aquafresh-ro-service-Gaya" element={<BiharAquafresh title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/aquafresh-ro-service-Gopalganj" element={<BiharAquafresh title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/aquafresh-ro-service-Jamui" element={<BiharAquafresh title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/aquafresh-ro-service-Jehanabad" element={<BiharAquafresh title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/aquafresh-ro-service-Khagaria" element={<BiharAquafresh title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/aquafresh-ro-service-Kishanganj" element={<BiharAquafresh title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/aquafresh-ro-service-Kaimur" element={<BiharAquafresh title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/aquafresh-ro-service-Katihar" element={<BiharAquafresh title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/aquafresh-ro-service-Lakhisarai" element={<BiharAquafresh title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/aquafresh-ro-service-Madhubani" element={<BiharAquafresh title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/aquafresh-ro-service-Munger" element={<BiharAquafresh title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/aquafresh-ro-service-Madhepura" element={<BiharAquafresh title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/aquafresh-ro-service-Muzaffarpur" element={<BiharAquafresh title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/aquafresh-ro-service-Nalanda" element={<BiharAquafresh title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/aquafresh-ro-service-Nawada" element={<BiharAquafresh title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/aquafresh-ro-service-Patna" element={<BiharAquafresh title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/aquafresh-ro-service-Purnia" element={<BiharAquafresh title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/aquafresh-ro-service-Rohtas" element={<BiharAquafresh title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/aquafresh-ro-service-Saharsa" element={<BiharAquafresh title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/aquafresh-ro-service-Samastipur" element={<BiharAquafresh title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/aquafresh-ro-service-Sasaram" element={<BiharAquafresh title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/aquafresh-ro-service-Sheohar" element={<BiharAquafresh title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/aquafresh-ro-service-Sheikhpura" element={<BiharAquafresh title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/aquafresh-ro-service-Saran" element={<BiharAquafresh title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/aquafresh-ro-service-Sitamarhi" element={<BiharAquafresh title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/aquafresh-ro-service-Supaul" element={<BiharAquafresh title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/aquafresh-ro-service-Siwan" element={<BiharAquafresh title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/aquafresh-ro-service-Vaishali" element={<BiharAquafresh title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/aquafresh-ro-service-West-Champaran" element={<BiharAquafresh title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/aquafresh-ro-service-Garhwa" element={<JharkhandAquafresh title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/aquafresh-ro-service-Palamu" element={<JharkhandAquafresh title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/aquafresh-ro-service-Latehar" element={<JharkhandAquafresh title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/aquafresh-ro-service-Chatra" element={<JharkhandAquafresh title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/aquafresh-ro-service-Hazaribagh" element={<JharkhandAquafresh title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/aquafresh-ro-service-Jamshedpur" element={<JharkhandAquafresh title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/aquafresh-ro-service-Koderma" element={<JharkhandAquafresh title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/aquafresh-ro-service-Giridih" element={<JharkhandAquafresh title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/aquafresh-ro-service-Ramgarh" element={<JharkhandAquafresh title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/aquafresh-ro-service-Bokaro" element={<JharkhandAquafresh title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/aquafresh-ro-service-Dhanbad" element={<JharkhandAquafresh title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/aquafresh-ro-service-Lohardaga" element={<JharkhandAquafresh title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/aquafresh-ro-service-Gumla" element={<JharkhandAquafresh title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/aquafresh-ro-service-Simdega" element={<JharkhandAquafresh title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/aquafresh-ro-service-Ranchi" element={<JharkhandAquafresh title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/aquafresh-ro-service-Khunti" element={<JharkhandAquafresh title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/aquafresh-ro-service-Singhbhum" element={<JharkhandAquafresh title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/aquafresh-ro-service-Saraikela-Kharsawan" element={<JharkhandAquafresh title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/aquafresh-ro-service-Jamtara" element={<JharkhandAquafresh title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/aquafresh-ro-service-Deoghar" element={<JharkhandAquafresh title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/aquafresh-ro-service-Dumka" element={<JharkhandAquafresh title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/aquafresh-ro-service-Pakur" element={<JharkhandAquafresh title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/aquafresh-ro-service-Godda" element={<JharkhandAquafresh title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/aquafresh-ro-service-Sahebganj" element={<JharkhandAquafresh title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />
        <Route path="/aquafresh-ro-service-Alipurduar" element={<BengalAquafresh title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/aquafresh-ro-service-Bankura" element={<BengalAquafresh title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/aquafresh-ro-service-Birbhum" element={<BengalAquafresh title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/aquafresh-ro-service-Cooch-Behar" element={<BengalAquafresh title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/aquafresh-ro-service-Dakshin-Dinajpur" element={<BengalAquafresh title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/aquafresh-ro-service-Darjeeling" element={<BengalAquafresh title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/aquafresh-ro-service-Durgapur" element={<BengalAquafresh title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/aquafresh-ro-service-Hooghly" element={<BengalAquafresh title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/aquafresh-ro-service-Howrah" element={<BengalAquafresh title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/aquafresh-ro-service-Jalpaiguri" element={<BengalAquafresh title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/aquafresh-ro-service-Jhargram" element={<BengalAquafresh title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/aquafresh-ro-service-Kalimpong" element={<BengalAquafresh title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/aquafresh-ro-service-Kolkata" element={<BengalAquafresh title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/aquafresh-ro-service-Malda" element={<BengalAquafresh title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/aquafresh-ro-service-Murshidabad" element={<BengalAquafresh title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/aquafresh-ro-service-Nadia" element={<BengalAquafresh title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/aquafresh-ro-service-North-24-Parganas" element={<BengalAquafresh title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/aquafresh-ro-service-Paschim-Bardhaman" element={<BengalAquafresh title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/aquafresh-ro-service-Paschim-Medinipur" element={<BengalAquafresh title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/aquafresh-ro-service-Purba-Bardhaman" element={<BengalAquafresh title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/aquafresh-ro-service-Purba-Medinipur" element={<BengalAquafresh title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/aquafresh-ro-service-South-24-Parganas" element={<BengalAquafresh title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/aquafresh-ro-service-Uttar-Dinajpur" element={<BengalAquafresh title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />
        <Route path="/aquafresh-ro-service-Agra" element={<UttarPradeshAquafresh title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/aquafresh-ro-service-Aligarh" element={<UttarPradeshAquafresh title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/aquafresh-ro-service-Ambedkar-Nagar" element={<UttarPradeshAquafresh title={locdata.AmbedkarNagar.title} number={locdata.AmbedkarNagar.number} />} />
        <Route path="/aquafresh-ro-service-Ayodhya" element={<UttarPradeshAquafresh title={locdata.Ayodhya.title} number={locdata.Ayodhya.number} />} />
        <Route path="/aquafresh-ro-service-Amethi" element={<UttarPradeshAquafresh title={locdata.Amethi.title} number={locdata.Amethi.number} />} />
        <Route path="/aquafresh-ro-service-Amroha" element={<UttarPradeshAquafresh title={locdata.Amroha.title} number={locdata.Amroha.number} />} />
        <Route path="/aquafresh-ro-service-Auraiya" element={<UttarPradeshAquafresh title={locdata.Auraiya.title} number={locdata.Auraiya.number} />} />
        <Route path="/aquafresh-ro-service-Azamgarh" element={<UttarPradeshAquafresh title={locdata.Azamgarh.title} number={locdata.Azamgarh.number} />} />
        <Route path="/aquafresh-ro-service-Budaun" element={<UttarPradeshAquafresh title={locdata.Budaun.title} number={locdata.Budaun.number} />} />
        <Route path="/aquafresh-ro-service-Bagpat" element={<UttarPradeshAquafresh title={locdata.Bagpat.title} number={locdata.Bagpat.number} />} />
        <Route path="/aquafresh-ro-service-Bahraich" element={<UttarPradeshAquafresh title={locdata.Bahraich.title} number={locdata.Bahraich.number} />} />
        <Route path="/aquafresh-ro-service-Ballia" element={<UttarPradeshAquafresh title={locdata.Ballia.title} number={locdata.Ballia.number} />} />
        <Route path="/aquafresh-ro-service-Balrampur" element={<UttarPradeshAquafresh title={locdata.Balrampur.title} number={locdata.Balrampur.number} />} />
        <Route path="/aquafresh-ro-service-Banda" element={<UttarPradeshAquafresh title={locdata.Banda.title} number={locdata.Banda.number} />} />
        <Route path="/aquafresh-ro-service-Barabanki" element={<UttarPradeshAquafresh title={locdata.Barabanki.title} number={locdata.Barabanki.number} />} />
        <Route path="/aquafresh-ro-service-Bareilly" element={<UttarPradeshAquafresh title={locdata.Bareilly.title} number={locdata.Bareilly.number} />} />
        <Route path="/aquafresh-ro-service-Basti" element={<UttarPradeshAquafresh title={locdata.Basti.title} number={locdata.Basti.number} />} />
        <Route path="/aquafresh-ro-service-Bijnor" element={<UttarPradeshAquafresh title={locdata.Bijnor.title} number={locdata.Bijnor.number} />} />
        <Route path="/aquafresh-ro-service-Bulandshahr" element={<UttarPradeshAquafresh title={locdata.Bulandshahr.title} number={locdata.Bulandshahr.number} />} />
        <Route path="/aquafresh-ro-service-Chandauli" element={<UttarPradeshAquafresh title={locdata.Chandauli.title} number={locdata.Chandauli.number} />} />
        <Route path="/aquafresh-ro-service-Chitrakoot" element={<UttarPradeshAquafresh title={locdata.Chitrakoot.title} number={locdata.Chitrakoot.number} />} />
        <Route path="/aquafresh-ro-service-Deoria" element={<UttarPradeshAquafresh title={locdata.Deoria.title} number={locdata.Deoria.number} />} />
        <Route path="/aquafresh-ro-service-Etah" element={<UttarPradeshAquafresh title={locdata.Etah.title} number={locdata.Etah.number} />} />
        <Route path="/aquafresh-ro-service-Etawah" element={<UttarPradeshAquafresh title={locdata.Etawah.title} number={locdata.Etawah.number} />} />
        <Route path="/aquafresh-ro-service-Farrukhabad" element={<UttarPradeshAquafresh title={locdata.Farrukhabad.title} number={locdata.Farrukhabad.number} />} />
        <Route path="/aquafresh-ro-service-Fatehpur" element={<UttarPradeshAquafresh title={locdata.Fatehpur.title} number={locdata.Fatehpur.number} />} />
        <Route path="/aquafresh-ro-service-Firozabad" element={<UttarPradeshAquafresh title={locdata.Firozabad.title} number={locdata.Firozabad.number} />} />
        <Route path="/aquafresh-ro-service-Gautam-Buddha-Nagar" element={<UttarPradeshAquafresh title={locdata.GautamBuddhaNagar.title} number={locdata.GautamBuddhaNagar.number} />} />
        <Route path="/aquafresh-ro-service-Ghaziabad" element={<UttarPradeshAquafresh title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/aquafresh-ro-service-Ghazipur" element={<UttarPradeshAquafresh title={locdata.Ghazipur.title} number={locdata.Ghazipur.number} />} />
        <Route path="/aquafresh-ro-service-Gonda" element={<UttarPradeshAquafresh title={locdata.Gonda.title} number={locdata.Gonda.number} />} />
        <Route path="/aquafresh-ro-service-Gorakhpur" element={<UttarPradeshAquafresh title={locdata.Gorakhpur.title} number={locdata.Gorakhpur.number} />} />
        <Route path="/aquafresh-ro-service-Hamirpur" element={<UttarPradeshAquafresh title={locdata.Hamirpur.title} number={locdata.Hamirpur.number} />} />
        <Route path="/aquafresh-ro-service-Hapur" element={<UttarPradeshAquafresh title={locdata.Hapur.title} number={locdata.Hapur.number} />} />
        <Route path="/aquafresh-ro-service-Hardoi" element={<UttarPradeshAquafresh title={locdata.Hardoi.title} number={locdata.Hardoi.number} />} />
        <Route path="/aquafresh-ro-service-Hathras" element={<UttarPradeshAquafresh title={locdata.Hathras.title} number={locdata.Hathras.number} />} />
        <Route path="/aquafresh-ro-service-Jalaun" element={<UttarPradeshAquafresh title={locdata.Jalaun.title} number={locdata.Jalaun.number} />} />
        <Route path="/aquafresh-ro-service-Jaunpur" element={<UttarPradeshAquafresh title={locdata.Jaunpur.title} number={locdata.Jaunpur.number} />} />
        <Route path="/aquafresh-ro-service-Jhansi" element={<UttarPradeshAquafresh title={locdata.Jhansi.title} number={locdata.Jhansi.number} />} />
        <Route path="/aquafresh-ro-service-Kannauj" element={<UttarPradeshAquafresh title={locdata.Kannauj.title} number={locdata.Kannauj.number} />} />
        <Route path="/aquafresh-ro-service-Kanpur-Dehat" element={<UttarPradeshAquafresh title={locdata.KanpurDehat.title} number={locdata.KanpurDehat.number} />} />
        <Route path="/aquafresh-ro-service-Kanpur" element={<UttarPradeshAquafresh title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/aquafresh-ro-service-Kasganj" element={<UttarPradeshAquafresh title={locdata.Kasganj.title} number={locdata.Kasganj.number} />} />
        <Route path="/aquafresh-ro-service-Kaushambi" element={<UttarPradeshAquafresh title={locdata.Kaushambi.title} number={locdata.Kaushambi.number} />} />
        <Route path="/aquafresh-ro-service-Kushinagar" element={<UttarPradeshAquafresh title={locdata.Kushinagar.title} number={locdata.Kushinagar.number} />} />
        <Route path="/aquafresh-ro-service-Lakhimpur-Kheri" element={<UttarPradeshAquafresh title={locdata.LakhimpurKheri.title} number={locdata.LakhimpurKheri.number} />} />
        <Route path="/aquafresh-ro-service-Lalitpur" element={<UttarPradeshAquafresh title={locdata.Lalitpur.title} number={locdata.Lalitpur.number} />} />
        <Route path="/aquafresh-ro-service-Lucknow" element={<UttarPradeshAquafresh title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/aquafresh-ro-service-Maharajganj" element={<UttarPradeshAquafresh title={locdata.Maharajganj.title} number={locdata.Maharajganj.number} />} />
        <Route path="/aquafresh-ro-service-Mahoba" element={<UttarPradeshAquafresh title={locdata.Mahoba.title} number={locdata.Mahoba.number} />} />
        <Route path="/aquafresh-ro-service-Mainpuri" element={<UttarPradeshAquafresh title={locdata.Mainpuri.title} number={locdata.Mainpuri.number} />} />
        <Route path="/aquafresh-ro-service-Mathura" element={<UttarPradeshAquafresh title={locdata.Mathura.title} number={locdata.Mathura.number} />} />
        <Route path="/aquafresh-ro-service-Mau" element={<UttarPradeshAquafresh title={locdata.Mau.title} number={locdata.Mau.number} />} />
        <Route path="/aquafresh-ro-service-Meerut" element={<UttarPradeshAquafresh title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/aquafresh-ro-service-Mirzapur" element={<UttarPradeshAquafresh title={locdata.Mirzapur.title} number={locdata.Mirzapur.number} />} />
        <Route path="/aquafresh-ro-service-Moradabad" element={<UttarPradeshAquafresh title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/aquafresh-ro-service-Muzaffarnagar" element={<UttarPradeshAquafresh title={locdata.Muzaffarnagar.title} number={locdata.Muzaffarnagar.number} />} />
        <Route path="/aquafresh-ro-service-Pilibhit" element={<UttarPradeshAquafresh title={locdata.Pilibhit.title} number={locdata.Pilibhit.number} />} />
        <Route path="/aquafresh-ro-service-Pratapgarh" element={<UttarPradeshAquafresh title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/aquafresh-ro-service-Prayagraj" element={<UttarPradeshAquafresh title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/aquafresh-ro-service-Rae-Bareli" element={<UttarPradeshAquafresh title={locdata.RaeBareli.title} number={locdata.RaeBareli.number} />} />
        <Route path="/aquafresh-ro-service-Rampur" element={<UttarPradeshAquafresh title={locdata.Rampur.title} number={locdata.Rampur.number} />} />
        <Route path="/aquafresh-ro-service-Saharanpur" element={<UttarPradeshAquafresh title={locdata.Saharanpur.title} number={locdata.Saharanpur.number} />} />
        <Route path="/aquafresh-ro-service-Sant-Kabir-Nagar" element={<UttarPradeshAquafresh title={locdata.SantKabirNagar.title} number={locdata.SantKabirNagar.number} />} />
        <Route path="/aquafresh-ro-service-Bhadohi" element={<UttarPradeshAquafresh title={locdata.Bhadohi.title} number={locdata.Bhadohi.number} />} />
        <Route path="/aquafresh-ro-service-Sambhal" element={<UttarPradeshAquafresh title={locdata.Sambhal.title} number={locdata.Sambhal.number} />} />
        <Route path="/aquafresh-ro-service-Shahjahanpur" element={<UttarPradeshAquafresh title={locdata.Shahjahanpur.title} number={locdata.Shahjahanpur.number} />} />
        <Route path="/aquafresh-ro-service-Shamli" element={<UttarPradeshAquafresh title={locdata.Shamli.title} number={locdata.Shamli.number} />} />
        <Route path="/aquafresh-ro-service-Shravasti" element={<UttarPradeshAquafresh title={locdata.Shravasti.title} number={locdata.Shravasti.number} />} />
        <Route path="/aquafresh-ro-service-Siddharthnagar" element={<UttarPradeshAquafresh title={locdata.Siddharthnagar.title} number={locdata.Siddharthnagar.number} />} />
        <Route path="/aquafresh-ro-service-Sitapur" element={<UttarPradeshAquafresh title={locdata.Sitapur.title} number={locdata.Sitapur.number} />} />
        <Route path="/aquafresh-ro-service-Sonbhadra" element={<UttarPradeshAquafresh title={locdata.Sonbhadra.title} number={locdata.Sonbhadra.number} />} />
        <Route path="/aquafresh-ro-service-Sultanpur" element={<UttarPradeshAquafresh title={locdata.Sultanpur.title} number={locdata.Sultanpur.number} />} />
        <Route path="/aquafresh-ro-service-Unnao" element={<UttarPradeshAquafresh title={locdata.Unnao.title} number={locdata.Unnao.number} />} />
        <Route path="/aquafresh-ro-service-Varanasi" element={<UttarPradeshAquafresh title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/aquafresh-ro-service-Robertsganj" element={<UttarPradeshAquafresh title={locdata.Robertsganj.title} number={locdata.Robertsganj.number} />} />
        <Route path="/aquafresh-ro-service-Bahjoi" element={<UttarPradeshAquafresh title={locdata.Bahjoi.title} number={locdata.Bahjoi.number} />} />
        <Route path="/aquafresh-ro-service-Khalilabad" element={<UttarPradeshAquafresh title={locdata.Khalilabad.title} number={locdata.Khalilabad.number} />} />
        <Route path="/aquafresh-ro-service-Akbarpur" element={<UttarPradeshAquafresh title={locdata.Akbarpur.title} number={locdata.Akbarpur.number} />} />
        <Route path="/aquafresh-ro-service-Orai" element={<UttarPradeshAquafresh title={locdata.Orai.title} number={locdata.Orai.number} />} />
        <Route path="/aquafresh-ro-service-Manjhanpur" element={<UttarPradeshAquafresh title={locdata.Manjhanpur.title} number={locdata.Manjhanpur.number} />} />
        <Route path="/aquafresh-ro-service-Padarauna" element={<UttarPradeshAquafresh title={locdata.Padarauna.title} number={locdata.Padarauna.number} />} />
        <Route path="/aquafresh-ro-service-Kheri" element={<UttarPradeshAquafresh title={locdata.Kheri.title} number={locdata.Kheri.number} />} />
        <Route path="/aquafresh-ro-service-Fatehgarh" element={<UttarPradeshAquafresh title={locdata.Fatehgarh.title} number={locdata.Fatehgarh.number} />} />
        <Route path="/aquafresh-ro-service-Noida" element={<UttarPradeshAquafresh title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/aquafresh-ro-service-Greater-Noida" element={<UttarPradeshAquafresh title={locdata.GreaterNoida.title} number={locdata.GreaterNoida.number} />} />
        <Route path="/aquafresh-ro-service-Indore" element={<MadhyaPradeshAquafresh title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/aquafresh-ro-service-Bhopal" element={<MadhyaPradeshAquafresh title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/aquafresh-ro-service-Jabalpur" element={<MadhyaPradeshAquafresh title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/aquafresh-ro-service-Gwalior" element={<MadhyaPradeshAquafresh title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/aquafresh-ro-service-Ujjain" element={<MadhyaPradeshAquafresh title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/aquafresh-ro-service-Sagar" element={<MadhyaPradeshAquafresh title={locdata.Sagar.title} number={locdata.Sagar.number} />} />
        <Route path="/aquafresh-ro-service-Dewas" element={<MadhyaPradeshAquafresh title={locdata.Dewas.title} number={locdata.Dewas.number} />} />
        <Route path="/aquafresh-ro-service-Satna" element={<MadhyaPradeshAquafresh title={locdata.Satna.title} number={locdata.Satna.number} />} />
        <Route path="/aquafresh-ro-service-Ratlam" element={<MadhyaPradeshAquafresh title={locdata.Ratlam.title} number={locdata.Ratlam.number} />} />
        <Route path="/aquafresh-ro-service-Rewa" element={<MadhyaPradeshAquafresh title={locdata.Rewa.title} number={locdata.Rewa.number} />} />
        <Route path="/aquafresh-ro-service-Katni" element={<MadhyaPradeshAquafresh title={locdata.Katni.title} number={locdata.Katni.number} />} />
        <Route path="/aquafresh-ro-service-Singrauli" element={<MadhyaPradeshAquafresh title={locdata.Singrauli.title} number={locdata.Singrauli.number} />} />
        <Route path="/aquafresh-ro-service-Burhanpur" element={<MadhyaPradeshAquafresh title={locdata.Burhanpur.title} number={locdata.Burhanpur.number} />} />
        <Route path="/aquafresh-ro-service-Morena" element={<MadhyaPradeshAquafresh title={locdata.Morena.title} number={locdata.Morena.number} />} />
        <Route path="/aquafresh-ro-service-Khandwa" element={<MadhyaPradeshAquafresh title={locdata.Khandwa.title} number={locdata.Khandwa.number} />} />
        <Route path="/aquafresh-ro-service-Bhind" element={<MadhyaPradeshAquafresh title={locdata.Bhind.title} number={locdata.Bhind.number} />} />
        <Route path="/aquafresh-ro-service-Chhindwara" element={<MadhyaPradeshAquafresh title={locdata.Chhindwara.title} number={locdata.Chhindwara.number} />} />
        <Route path="/aquafresh-ro-service-Guna" element={<MadhyaPradeshAquafresh title={locdata.Guna.title} number={locdata.Guna.number} />} />
        <Route path="/aquafresh-ro-service-Shivpuri" element={<MadhyaPradeshAquafresh title={locdata.Shivpuri.title} number={locdata.Shivpuri.number} />} />
        <Route path="/aquafresh-ro-service-Vidisha" element={<MadhyaPradeshAquafresh title={locdata.Vidisha.title} number={locdata.Vidisha.number} />} />
        <Route path="/aquafresh-ro-service-Chhatarpur" element={<MadhyaPradeshAquafresh title={locdata.Chhatarpur.title} number={locdata.Chhatarpur.number} />} />
        <Route path="/aquafresh-ro-service-Damoh" element={<MadhyaPradeshAquafresh title={locdata.Damoh.title} number={locdata.Damoh.number} />} />
        <Route path="/aquafresh-ro-service-Mandsaur" element={<MadhyaPradeshAquafresh title={locdata.Mandsaur.title} number={locdata.Mandsaur.number} />} />
        <Route path="/aquafresh-ro-service-Khargone" element={<MadhyaPradeshAquafresh title={locdata.Khargone.title} number={locdata.Khargone.number} />} />
        <Route path="/aquafresh-ro-service-Neemuch" element={<MadhyaPradeshAquafresh title={locdata.Neemuch.title} number={locdata.Neemuch.number} />} />
        <Route path="/aquafresh-ro-service-Pithampur" element={<MadhyaPradeshAquafresh title={locdata.Pithampur.title} number={locdata.Pithampur.number} />} />
        <Route path="/aquafresh-ro-service-Narmadapuram" element={<MadhyaPradeshAquafresh title={locdata.Narmadapuram.title} number={locdata.Narmadapuram.number} />} />
        <Route path="/aquafresh-ro-service-Itarsi" element={<MadhyaPradeshAquafresh title={locdata.Itarsi.title} number={locdata.Itarsi.number} />} />
        <Route path="/aquafresh-ro-service-Sehore" element={<MadhyaPradeshAquafresh title={locdata.Sehore.title} number={locdata.Sehore.number} />} />
        <Route path="/aquafresh-ro-service-Betul" element={<MadhyaPradeshAquafresh title={locdata.Betul.title} number={locdata.Betul.number} />} />
        <Route path="/aquafresh-ro-service-Seoni" element={<MadhyaPradeshAquafresh title={locdata.Seoni.title} number={locdata.Seoni.number} />} />
        <Route path="/aquafresh-ro-service-Datia" element={<MadhyaPradeshAquafresh title={locdata.Datia.title} number={locdata.Datia.number} />} />
        <Route path="/aquafresh-ro-service-Nagda" element={<MadhyaPradeshAquafresh title={locdata.Nagda.title} number={locdata.Nagda.number} />} />
        <Route path="/aquafresh-ro-service-Shahdol" element={<MadhyaPradeshAquafresh title={locdata.Shahdol.title} number={locdata.Shahdol.number} />} />
        <Route path="/aquafresh-ro-service-Dhar" element={<MadhyaPradeshAquafresh title={locdata.Dhar.title} number={locdata.Dhar.number} />} />

        <Route path="/aquafresh-ro-service-Ajmer" element={<RajasthanAquaFresh title={locdata.Ajmer.title} number={locdata.Ajmer.number} />} />
        <Route path="/aquafresh-ro-service-Alwar" element={<RajasthanAquaFresh title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/aquafresh-ro-service-Balotra" element={<RajasthanAquaFresh title={locdata.Balotra.title} number={locdata.Balotra.number} />} />
        <Route path="/aquafresh-ro-service-Banswara" element={<RajasthanAquaFresh title={locdata.Banswara.title} number={locdata.Banswara.number} />} />
        <Route path="/aquafresh-ro-service-Baran" element={<RajasthanAquaFresh title={locdata.Baran.title} number={locdata.Baran.number} />} />
        <Route path="/aquafresh-ro-service-Barmer" element={<RajasthanAquaFresh title={locdata.Barmer.title} number={locdata.Barmer.number} />} />
        <Route path="/aquafresh-ro-service-Beawar" element={<RajasthanAquaFresh title={locdata.Beawar.title} number={locdata.Beawar.number} />} />
        <Route path="/aquafresh-ro-service-Bharatpur" element={<RajasthanAquaFresh title={locdata.Bharatpur.title} number={locdata.Bharatpur.number} />} />
        <Route path="/aquafresh-ro-service-Bhilwara" element={<RajasthanAquaFresh title={locdata.Bhilwara.title} number={locdata.Bhilwara.number} />} />
        <Route path="/aquafresh-ro-service-Bikaner" element={<RajasthanAquaFresh title={locdata.Bikaner.title} number={locdata.Bikaner.number} />} />
        <Route path="/aquafresh-ro-service-Bundi" element={<RajasthanAquaFresh title={locdata.Bundi.title} number={locdata.Bundi.number} />} />
        <Route path="/aquafresh-ro-service-Chittorgarh" element={<RajasthanAquaFresh title={locdata.Chittorgarh.title} number={locdata.Chittorgarh.number} />} />
        <Route path="/aquafresh-ro-service-Churu" element={<RajasthanAquaFresh title={locdata.Churu.title} number={locdata.Churu.number} />} />
        <Route path="/aquafresh-ro-service-Dausa" element={<RajasthanAquaFresh title={locdata.Dausa.title} number={locdata.Dausa.number} />} />
        <Route path="/aquafresh-ro-service-Deeg" element={<RajasthanAquaFresh title={locdata.Deeg.title} number={locdata.Deeg.number} />} />
        <Route path="/aquafresh-ro-service-Deedwana" element={<RajasthanAquaFresh title={locdata.Deedwana.title} number={locdata.Deedwana.number} />} />
        <Route path="/aquafresh-ro-service-Dholpur" element={<RajasthanAquaFresh title={locdata.Dholpur.title} number={locdata.Dholpur.number} />} />
        <Route path="/aquafresh-ro-service-Dungarpur" element={<RajasthanAquaFresh title={locdata.Dungarpur.title} number={locdata.Dungarpur.number} />} />
        <Route path="/aquafresh-ro-service-Hanumangarh" element={<RajasthanAquaFresh title={locdata.Hanumangarh.title} number={locdata.Hanumangarh.number} />} />
        <Route path="/aquafresh-ro-service-Jaipur" element={<RajasthanAquaFresh title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/aquafresh-ro-service-Jaisalmer" element={<RajasthanAquaFresh title={locdata.Jaisalmer.title} number={locdata.Jaisalmer.number} />} />
        <Route path="/aquafresh-ro-service-Jalore" element={<RajasthanAquaFresh title={locdata.Jalore.title} number={locdata.Jalore.number} />} />
        <Route path="/aquafresh-ro-service-Jhalawar" element={<RajasthanAquaFresh title={locdata.Jhalawar.title} number={locdata.Jhalawar.number} />} />
        <Route path="/aquafresh-ro-service-Jhunjhunu" element={<RajasthanAquaFresh title={locdata.Jhunjhunu.title} number={locdata.Jhunjhunu.number} />} />
        <Route path="/aquafresh-ro-service-Jodhpur" element={<RajasthanAquaFresh title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/aquafresh-ro-service-Karauli" element={<RajasthanAquaFresh title={locdata.Karauli.title} number={locdata.Karauli.number} />} />
        <Route path="/aquafresh-ro-service-Tijara" element={<RajasthanAquaFresh title={locdata.Tijara.title} number={locdata.Tijara.number} />} />
        <Route path="/aquafresh-ro-service-Kotputli" element={<RajasthanAquaFresh title={locdata.Kotputli.title} number={locdata.Kotputli.number} />} />
        <Route path="/aquafresh-ro-service-Kota" element={<RajasthanAquaFresh title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/aquafresh-ro-service-Nagaur" element={<RajasthanAquaFresh title={locdata.Nagaur.title} number={locdata.Nagaur.number} />} />
        <Route path="/aquafresh-ro-service-Pali" element={<RajasthanAquaFresh title={locdata.Pali.title} number={locdata.Pali.number} />} />
        <Route path="/aquafresh-ro-service-Phalodi" element={<RajasthanAquaFresh title={locdata.Phalodi.title} number={locdata.Phalodi.number} />} />
        <Route path="/aquafresh-ro-service-Pratapgarh" element={<RajasthanAquaFresh title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/aquafresh-ro-service-Rajsamand" element={<RajasthanAquaFresh title={locdata.Rajsamand.title} number={locdata.Rajsamand.number} />} />
        <Route path="/aquafresh-ro-service-Salumbar" element={<RajasthanAquaFresh title={locdata.Salumbar.title} number={locdata.Salumbar.number} />} />
        <Route path="/aquafresh-ro-service-Sawai-Madhopur" element={<RajasthanAquaFresh title={locdata.SawaiMadhopur.title} number={locdata.SawaiMadhopur.number} />} />
        <Route path="/aquafresh-ro-service-Sikar" element={<RajasthanAquaFresh title={locdata.Sikar.title} number={locdata.Sikar.number} />} />
        <Route path="/aquafresh-ro-service-Sirohi" element={<RajasthanAquaFresh title={locdata.Sirohi.title} number={locdata.Sirohi.number} />} />
        <Route path="/aquafresh-ro-service-Sri-Ganganagar" element={<RajasthanAquaFresh title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/aquafresh-ro-service-Tonk" element={<RajasthanAquaFresh title={locdata.Tonk.title} number={locdata.Tonk.number} />} />
        <Route path="/aquafresh-ro-service-Udaipur" element={<RajasthanAquaFresh title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />

        <Route path="/aquafresh-ro-service-Mumbai" element={<Aquafreshro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/aquafresh-ro-service-Delhi" element={<Aquafreshro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/aquafresh-ro-service-Bengaluru" element={<Aquafreshro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/aquafresh-ro-service-Hyderabad" element={<Aquafreshro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/aquafresh-ro-service-Ahmedabad" element={<Aquafreshro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/aquafresh-ro-service-Chennai" element={<Aquafreshro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/aquafresh-ro-service-Surat" element={<Aquafreshro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/aquafresh-ro-service-Pune" element={<Aquafreshro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/aquafresh-ro-service-Jaipur" element={<Aquafreshro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/aquafresh-ro-service-Lucknow" element={<Aquafreshro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/aquafresh-ro-service-Kanpur" element={<Aquafreshro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/aquafresh-ro-service-Nagpur" element={<Aquafreshro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/aquafresh-ro-service-Indore" element={<Aquafreshro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/aquafresh-ro-service-Thane" element={<Aquafreshro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/aquafresh-ro-service-Bhopal" element={<Aquafreshro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/aquafresh-ro-service-Visakhapatnam" element={<Aquafreshro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/aquafresh-ro-service-Vadodara" element={<Aquafreshro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/aquafresh-ro-service-Ghaziabad" element={<Aquafreshro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/aquafresh-ro-service-Ludhiana" element={<Aquafreshro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/aquafresh-ro-service-Agra" element={<Aquafreshro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/aquafresh-ro-service-Nashik" element={<Aquafreshro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/aquafresh-ro-service-Faridabad" element={<Aquafreshro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/aquafresh-ro-service-Meerut" element={<Aquafreshro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/aquafresh-ro-service-Rajkot" element={<Aquafreshro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/aquafresh-ro-service-Varanasi" element={<Aquafreshro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/aquafresh-ro-service-Srinagar" element={<Aquafreshro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/aquafresh-ro-service-Amritsar" element={<Aquafreshro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/aquafresh-ro-service-Navi-Mumbai" element={<Aquafreshro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/aquafresh-ro-service-Prayagraj" element={<Aquafreshro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/aquafresh-ro-service-Jabalpur" element={<Aquafreshro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/aquafresh-ro-service-Gwalior" element={<Aquafreshro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/aquafresh-ro-service-Coimbatore" element={<Aquafreshro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/aquafresh-ro-service-Vijayawada" element={<Aquafreshro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/aquafresh-ro-service-Jodhpur" element={<Aquafreshro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/aquafresh-ro-service-Madurai" element={<Aquafreshro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/aquafresh-ro-service-Raipur" element={<Aquafreshro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/aquafresh-ro-service-Kota" element={<Aquafreshro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/aquafresh-ro-service-Guwahati" element={<Aquafreshro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/aquafresh-ro-service-Chandigarh" element={<Aquafreshro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/aquafresh-ro-service-Thiruvananthapuram" element={<Aquafreshro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/aquafresh-ro-service-Solapur" element={<Aquafreshro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/aquafresh-ro-service-Tiruppur" element={<Aquafreshro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/aquafresh-ro-service-Moradabad" element={<Aquafreshro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/aquafresh-ro-service-Mysore" element={<Aquafreshro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/aquafresh-ro-service-Gurgaon" element={<Aquafreshro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/aquafresh-ro-service-Aligarh" element={<Aquafreshro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/aquafresh-ro-service-Jalandhar" element={<Aquafreshro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/aquafresh-ro-service-Bhubaneswar" element={<Aquafreshro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/aquafresh-ro-service-Noida" element={<Aquafreshro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/aquafresh-ro-service-Jamshedpur" element={<Aquafreshro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/aquafresh-ro-service-Cuttack" element={<Aquafreshro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/aquafresh-ro-service-Bhavnagar" element={<Aquafreshro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/aquafresh-ro-service-Dehradun" element={<Aquafreshro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/aquafresh-ro-service-Rourkela" element={<Aquafreshro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/aquafresh-ro-service-Jamnagar" element={<Aquafreshro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/aquafresh-ro-service-Ujjain" element={<Aquafreshro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/aquafresh-ro-service-Jammu" element={<Aquafreshro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/aquafresh-ro-service-Mangalore" element={<Aquafreshro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/aquafresh-ro-service-Erode" element={<Aquafreshro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/aquafresh-ro-service-Udaipur" element={<Aquafreshro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/aquafresh-ro-service-Rajahmundry" element={<Aquafreshro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/aquafresh-ro-service-Patiala" element={<Aquafreshro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/aquafresh-ro-service-Agartala" element={<Aquafreshro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/aquafresh-ro-service-Tirupati" element={<Aquafreshro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/aquafresh-ro-service-Sambalpur" element={<Aquafreshro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/aquafresh-ro-service-Bilaspur" element={<Aquafreshro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/aquafresh-ro-service-Alwar" element={<Aquafreshro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/aquafresh-ro-service-Bardhaman" element={<Aquafreshro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/aquafresh-ro-service-Sonipat" element={<Aquafreshro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/aquafresh-ro-service-New-Delhi" element={<Aquafreshro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/aquafresh-ro-service-Puducherry" element={<Aquafreshro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/aquafresh-ro-service-Haridwar" element={<Aquafreshro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/aquafresh-ro-service-Vijayanagaram" element={<Aquafreshro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/aquafresh-ro-service-Sri-Ganganagar" element={<Aquafreshro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/aquafresh-ro-service-Secunderabad" element={<Aquafreshro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/aquafresh-ro-service-Yamunanagar" element={<Aquafreshro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/aquafresh-ro-service-Gandhinagar" element={<Aquafreshro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/aquafresh-ro-service-Ambala" element={<Aquafreshro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/aquafresh-ro-service-Vellore" element={<Aquafreshro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/aquafresh-ro-service-Shimla" element={<Aquafreshro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/aquafresh-ro-service-Amaravati" element={<Aquafreshro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />


        <Route path="/aquagrand-ro-service-Araria" element={<BiharAquagrand title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/aquagrand-ro-service-Arwal" element={<BiharAquagrand title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/aquagrand-ro-service-Aurangabad" element={<BiharAquagrand title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/aquagrand-ro-service-Banka" element={<BiharAquagrand title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/aquagrand-ro-service-Begusarai" element={<BiharAquagrand title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/aquagrand-ro-service-Bhabua" element={<BiharAquagrand title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/aquagrand-ro-service-Bhagalpur" element={<BiharAquagrand title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/aquagrand-ro-service-Bhojpur" element={<BiharAquagrand title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/aquagrand-ro-service-Bihar-Sharif" element={<BiharAquagrand title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/aquagrand-ro-service-Buxar" element={<BiharAquagrand title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/aquagrand-ro-service-Chhapra" element={<BiharAquagrand title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/aquagrand-ro-service-Darbhanga" element={<BiharAquagrand title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/aquagrand-ro-service-East-Champaran" element={<BiharAquagrand title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/aquagrand-ro-service-Gaya" element={<BiharAquagrand title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/aquagrand-ro-service-Gopalganj" element={<BiharAquagrand title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/aquagrand-ro-service-Jamui" element={<BiharAquagrand title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/aquagrand-ro-service-Jehanabad" element={<BiharAquagrand title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/aquagrand-ro-service-Khagaria" element={<BiharAquagrand title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/aquagrand-ro-service-Kishanganj" element={<BiharAquagrand title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/aquagrand-ro-service-Kaimur" element={<BiharAquagrand title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/aquagrand-ro-service-Katihar" element={<BiharAquagrand title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/aquagrand-ro-service-Lakhisarai" element={<BiharAquagrand title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/aquagrand-ro-service-Madhubani" element={<BiharAquagrand title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/aquagrand-ro-service-Munger" element={<BiharAquagrand title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/aquagrand-ro-service-Madhepura" element={<BiharAquagrand title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/aquagrand-ro-service-Muzaffarpur" element={<BiharAquagrand title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/aquagrand-ro-service-Nalanda" element={<BiharAquagrand title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/aquagrand-ro-service-Nawada" element={<BiharAquagrand title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/aquagrand-ro-service-Patna" element={<BiharAquagrand title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/aquagrand-ro-service-Purnia" element={<BiharAquagrand title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/aquagrand-ro-service-Rohtas" element={<BiharAquagrand title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/aquagrand-ro-service-Saharsa" element={<BiharAquagrand title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/aquagrand-ro-service-Samastipur" element={<BiharAquagrand title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/aquagrand-ro-service-Sasaram" element={<BiharAquagrand title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/aquagrand-ro-service-Sheohar" element={<BiharAquagrand title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/aquagrand-ro-service-Sheikhpura" element={<BiharAquagrand title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/aquagrand-ro-service-Saran" element={<BiharAquagrand title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/aquagrand-ro-service-Sitamarhi" element={<BiharAquagrand title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/aquagrand-ro-service-Supaul" element={<BiharAquagrand title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/aquagrand-ro-service-Siwan" element={<BiharAquagrand title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/aquagrand-ro-service-Vaishali" element={<BiharAquagrand title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/aquagrand-ro-service-West-Champaran" element={<BiharAquagrand title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/aquagrand-ro-service-Garhwa" element={<JharkhandAquagrand title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/aquagrand-ro-service-Palamu" element={<JharkhandAquagrand title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/aquagrand-ro-service-Latehar" element={<JharkhandAquagrand title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/aquagrand-ro-service-Chatra" element={<JharkhandAquagrand title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/aquagrand-ro-service-Hazaribagh" element={<JharkhandAquagrand title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/aquagrand-ro-service-Jamshedpur" element={<JharkhandAquagrand title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/aquagrand-ro-service-Koderma" element={<JharkhandAquagrand title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/aquagrand-ro-service-Giridih" element={<JharkhandAquagrand title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/aquagrand-ro-service-Ramgarh" element={<JharkhandAquagrand title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/aquagrand-ro-service-Bokaro" element={<JharkhandAquagrand title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/aquagrand-ro-service-Dhanbad" element={<JharkhandAquagrand title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/aquagrand-ro-service-Lohardaga" element={<JharkhandAquagrand title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/aquagrand-ro-service-Gumla" element={<JharkhandAquagrand title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/aquagrand-ro-service-Simdega" element={<JharkhandAquagrand title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/aquagrand-ro-service-Ranchi" element={<JharkhandAquagrand title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/aquagrand-ro-service-Khunti" element={<JharkhandAquagrand title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/aquagrand-ro-service-Singhbhum" element={<JharkhandAquagrand title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/aquagrand-ro-service-Saraikela-Kharsawan" element={<JharkhandAquagrand title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/aquagrand-ro-service-Jamtara" element={<JharkhandAquagrand title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/aquagrand-ro-service-Deoghar" element={<JharkhandAquagrand title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/aquagrand-ro-service-Dumka" element={<JharkhandAquagrand title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/aquagrand-ro-service-Pakur" element={<JharkhandAquagrand title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/aquagrand-ro-service-Godda" element={<JharkhandAquagrand title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/aquagrand-ro-service-Sahebganj" element={<JharkhandAquagrand title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />
        <Route path="/aquagrand-ro-service-Alipurduar" element={<BengalAquagrand title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/aquagrand-ro-service-Bankura" element={<BengalAquagrand title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/aquagrand-ro-service-Birbhum" element={<BengalAquagrand title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/aquagrand-ro-service-Cooch-Behar" element={<BengalAquagrand title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/aquagrand-ro-service-Dakshin-Dinajpur" element={<BengalAquagrand title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/aquagrand-ro-service-Darjeeling" element={<BengalAquagrand title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/aquagrand-ro-service-Durgapur" element={<BengalAquagrand title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/aquagrand-ro-service-Hooghly" element={<BengalAquagrand title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/aquagrand-ro-service-Howrah" element={<BengalAquagrand title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/aquagrand-ro-service-Jalpaiguri" element={<BengalAquagrand title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/aquagrand-ro-service-Jhargram" element={<BengalAquagrand title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/aquagrand-ro-service-Kalimpong" element={<BengalAquagrand title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/aquagrand-ro-service-Kolkata" element={<BengalAquagrand title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/aquagrand-ro-service-Malda" element={<BengalAquagrand title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/aquagrand-ro-service-Murshidabad" element={<BengalAquagrand title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/aquagrand-ro-service-Nadia" element={<BengalAquagrand title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/aquagrand-ro-service-North-24-Parganas" element={<BengalAquagrand title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/aquagrand-ro-service-Paschim-Bardhaman" element={<BengalAquagrand title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/aquagrand-ro-service-Paschim-Medinipur" element={<BengalAquagrand title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/aquagrand-ro-service-Purba-Bardhaman" element={<BengalAquagrand title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/aquagrand-ro-service-Purba-Medinipur" element={<BengalAquagrand title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/aquagrand-ro-service-South-24-Parganas" element={<BengalAquagrand title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/aquagrand-ro-service-Uttar-Dinajpur" element={<BengalAquagrand title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />
        <Route path="/aquagrand-ro-service-Agra" element={<UttarPradeshAquagrand title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/aquagrand-ro-service-Aligarh" element={<UttarPradeshAquagrand title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/aquagrand-ro-service-Ambedkar-Nagar" element={<UttarPradeshAquagrand title={locdata.AmbedkarNagar.title} number={locdata.AmbedkarNagar.number} />} />
        <Route path="/aquagrand-ro-service-Ayodhya" element={<UttarPradeshAquagrand title={locdata.Ayodhya.title} number={locdata.Ayodhya.number} />} />
        <Route path="/aquagrand-ro-service-Amethi" element={<UttarPradeshAquagrand title={locdata.Amethi.title} number={locdata.Amethi.number} />} />
        <Route path="/aquagrand-ro-service-Amroha" element={<UttarPradeshAquagrand title={locdata.Amroha.title} number={locdata.Amroha.number} />} />
        <Route path="/aquagrand-ro-service-Auraiya" element={<UttarPradeshAquagrand title={locdata.Auraiya.title} number={locdata.Auraiya.number} />} />
        <Route path="/aquagrand-ro-service-Azamgarh" element={<UttarPradeshAquagrand title={locdata.Azamgarh.title} number={locdata.Azamgarh.number} />} />
        <Route path="/aquagrand-ro-service-Budaun" element={<UttarPradeshAquagrand title={locdata.Budaun.title} number={locdata.Budaun.number} />} />
        <Route path="/aquagrand-ro-service-Bagpat" element={<UttarPradeshAquagrand title={locdata.Bagpat.title} number={locdata.Bagpat.number} />} />
        <Route path="/aquagrand-ro-service-Bahraich" element={<UttarPradeshAquagrand title={locdata.Bahraich.title} number={locdata.Bahraich.number} />} />
        <Route path="/aquagrand-ro-service-Ballia" element={<UttarPradeshAquagrand title={locdata.Ballia.title} number={locdata.Ballia.number} />} />
        <Route path="/aquagrand-ro-service-Balrampur" element={<UttarPradeshAquagrand title={locdata.Balrampur.title} number={locdata.Balrampur.number} />} />
        <Route path="/aquagrand-ro-service-Banda" element={<UttarPradeshAquagrand title={locdata.Banda.title} number={locdata.Banda.number} />} />
        <Route path="/aquagrand-ro-service-Barabanki" element={<UttarPradeshAquagrand title={locdata.Barabanki.title} number={locdata.Barabanki.number} />} />
        <Route path="/aquagrand-ro-service-Bareilly" element={<UttarPradeshAquagrand title={locdata.Bareilly.title} number={locdata.Bareilly.number} />} />
        <Route path="/aquagrand-ro-service-Basti" element={<UttarPradeshAquagrand title={locdata.Basti.title} number={locdata.Basti.number} />} />
        <Route path="/aquagrand-ro-service-Bijnor" element={<UttarPradeshAquagrand title={locdata.Bijnor.title} number={locdata.Bijnor.number} />} />
        <Route path="/aquagrand-ro-service-Bulandshahr" element={<UttarPradeshAquagrand title={locdata.Bulandshahr.title} number={locdata.Bulandshahr.number} />} />
        <Route path="/aquagrand-ro-service-Chandauli" element={<UttarPradeshAquagrand title={locdata.Chandauli.title} number={locdata.Chandauli.number} />} />
        <Route path="/aquagrand-ro-service-Chitrakoot" element={<UttarPradeshAquagrand title={locdata.Chitrakoot.title} number={locdata.Chitrakoot.number} />} />
        <Route path="/aquagrand-ro-service-Deoria" element={<UttarPradeshAquagrand title={locdata.Deoria.title} number={locdata.Deoria.number} />} />
        <Route path="/aquagrand-ro-service-Etah" element={<UttarPradeshAquagrand title={locdata.Etah.title} number={locdata.Etah.number} />} />
        <Route path="/aquagrand-ro-service-Etawah" element={<UttarPradeshAquagrand title={locdata.Etawah.title} number={locdata.Etawah.number} />} />
        <Route path="/aquagrand-ro-service-Farrukhabad" element={<UttarPradeshAquagrand title={locdata.Farrukhabad.title} number={locdata.Farrukhabad.number} />} />
        <Route path="/aquagrand-ro-service-Fatehpur" element={<UttarPradeshAquagrand title={locdata.Fatehpur.title} number={locdata.Fatehpur.number} />} />
        <Route path="/aquagrand-ro-service-Firozabad" element={<UttarPradeshAquagrand title={locdata.Firozabad.title} number={locdata.Firozabad.number} />} />
        <Route path="/aquagrand-ro-service-Gautam-Buddha-Nagar" element={<UttarPradeshAquagrand title={locdata.GautamBuddhaNagar.title} number={locdata.GautamBuddhaNagar.number} />} />
        <Route path="/aquagrand-ro-service-Ghaziabad" element={<UttarPradeshAquagrand title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/aquagrand-ro-service-Ghazipur" element={<UttarPradeshAquagrand title={locdata.Ghazipur.title} number={locdata.Ghazipur.number} />} />
        <Route path="/aquagrand-ro-service-Gonda" element={<UttarPradeshAquagrand title={locdata.Gonda.title} number={locdata.Gonda.number} />} />
        <Route path="/aquagrand-ro-service-Gorakhpur" element={<UttarPradeshAquagrand title={locdata.Gorakhpur.title} number={locdata.Gorakhpur.number} />} />
        <Route path="/aquagrand-ro-service-Hamirpur" element={<UttarPradeshAquagrand title={locdata.Hamirpur.title} number={locdata.Hamirpur.number} />} />
        <Route path="/aquagrand-ro-service-Hapur" element={<UttarPradeshAquagrand title={locdata.Hapur.title} number={locdata.Hapur.number} />} />
        <Route path="/aquagrand-ro-service-Hardoi" element={<UttarPradeshAquagrand title={locdata.Hardoi.title} number={locdata.Hardoi.number} />} />
        <Route path="/aquagrand-ro-service-Hathras" element={<UttarPradeshAquagrand title={locdata.Hathras.title} number={locdata.Hathras.number} />} />
        <Route path="/aquagrand-ro-service-Jalaun" element={<UttarPradeshAquagrand title={locdata.Jalaun.title} number={locdata.Jalaun.number} />} />
        <Route path="/aquagrand-ro-service-Jaunpur" element={<UttarPradeshAquagrand title={locdata.Jaunpur.title} number={locdata.Jaunpur.number} />} />
        <Route path="/aquagrand-ro-service-Jhansi" element={<UttarPradeshAquagrand title={locdata.Jhansi.title} number={locdata.Jhansi.number} />} />
        <Route path="/aquagrand-ro-service-Kannauj" element={<UttarPradeshAquagrand title={locdata.Kannauj.title} number={locdata.Kannauj.number} />} />
        <Route path="/aquagrand-ro-service-Kanpur-Dehat" element={<UttarPradeshAquagrand title={locdata.KanpurDehat.title} number={locdata.KanpurDehat.number} />} />
        <Route path="/aquagrand-ro-service-Kanpur" element={<UttarPradeshAquagrand title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/aquagrand-ro-service-Kasganj" element={<UttarPradeshAquagrand title={locdata.Kasganj.title} number={locdata.Kasganj.number} />} />
        <Route path="/aquagrand-ro-service-Kaushambi" element={<UttarPradeshAquagrand title={locdata.Kaushambi.title} number={locdata.Kaushambi.number} />} />
        <Route path="/aquagrand-ro-service-Kushinagar" element={<UttarPradeshAquagrand title={locdata.Kushinagar.title} number={locdata.Kushinagar.number} />} />
        <Route path="/aquagrand-ro-service-Lakhimpur-Kheri" element={<UttarPradeshAquagrand title={locdata.LakhimpurKheri.title} number={locdata.LakhimpurKheri.number} />} />
        <Route path="/aquagrand-ro-service-Lalitpur" element={<UttarPradeshAquagrand title={locdata.Lalitpur.title} number={locdata.Lalitpur.number} />} />
        <Route path="/aquagrand-ro-service-Lucknow" element={<UttarPradeshAquagrand title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/aquagrand-ro-service-Maharajganj" element={<UttarPradeshAquagrand title={locdata.Maharajganj.title} number={locdata.Maharajganj.number} />} />
        <Route path="/aquagrand-ro-service-Mahoba" element={<UttarPradeshAquagrand title={locdata.Mahoba.title} number={locdata.Mahoba.number} />} />
        <Route path="/aquagrand-ro-service-Mainpuri" element={<UttarPradeshAquagrand title={locdata.Mainpuri.title} number={locdata.Mainpuri.number} />} />
        <Route path="/aquagrand-ro-service-Mathura" element={<UttarPradeshAquagrand title={locdata.Mathura.title} number={locdata.Mathura.number} />} />
        <Route path="/aquagrand-ro-service-Mau" element={<UttarPradeshAquagrand title={locdata.Mau.title} number={locdata.Mau.number} />} />
        <Route path="/aquagrand-ro-service-Meerut" element={<UttarPradeshAquagrand title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/aquagrand-ro-service-Mirzapur" element={<UttarPradeshAquagrand title={locdata.Mirzapur.title} number={locdata.Mirzapur.number} />} />
        <Route path="/aquagrand-ro-service-Moradabad" element={<UttarPradeshAquagrand title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/aquagrand-ro-service-Muzaffarnagar" element={<UttarPradeshAquagrand title={locdata.Muzaffarnagar.title} number={locdata.Muzaffarnagar.number} />} />
        <Route path="/aquagrand-ro-service-Pilibhit" element={<UttarPradeshAquagrand title={locdata.Pilibhit.title} number={locdata.Pilibhit.number} />} />
        <Route path="/aquagrand-ro-service-Pratapgarh" element={<UttarPradeshAquagrand title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/aquagrand-ro-service-Prayagraj" element={<UttarPradeshAquagrand title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/aquagrand-ro-service-Rae-Bareli" element={<UttarPradeshAquagrand title={locdata.RaeBareli.title} number={locdata.RaeBareli.number} />} />
        <Route path="/aquagrand-ro-service-Rampur" element={<UttarPradeshAquagrand title={locdata.Rampur.title} number={locdata.Rampur.number} />} />
        <Route path="/aquagrand-ro-service-Saharanpur" element={<UttarPradeshAquagrand title={locdata.Saharanpur.title} number={locdata.Saharanpur.number} />} />
        <Route path="/aquagrand-ro-service-Sant-Kabir-Nagar" element={<UttarPradeshAquagrand title={locdata.SantKabirNagar.title} number={locdata.SantKabirNagar.number} />} />
        <Route path="/aquagrand-ro-service-Bhadohi" element={<UttarPradeshAquagrand title={locdata.Bhadohi.title} number={locdata.Bhadohi.number} />} />
        <Route path="/aquagrand-ro-service-Sambhal" element={<UttarPradeshAquagrand title={locdata.Sambhal.title} number={locdata.Sambhal.number} />} />
        <Route path="/aquagrand-ro-service-Shahjahanpur" element={<UttarPradeshAquagrand title={locdata.Shahjahanpur.title} number={locdata.Shahjahanpur.number} />} />
        <Route path="/aquagrand-ro-service-Shamli" element={<UttarPradeshAquagrand title={locdata.Shamli.title} number={locdata.Shamli.number} />} />
        <Route path="/aquagrand-ro-service-Shravasti" element={<UttarPradeshAquagrand title={locdata.Shravasti.title} number={locdata.Shravasti.number} />} />
        <Route path="/aquagrand-ro-service-Siddharthnagar" element={<UttarPradeshAquagrand title={locdata.Siddharthnagar.title} number={locdata.Siddharthnagar.number} />} />
        <Route path="/aquagrand-ro-service-Sitapur" element={<UttarPradeshAquagrand title={locdata.Sitapur.title} number={locdata.Sitapur.number} />} />
        <Route path="/aquagrand-ro-service-Sonbhadra" element={<UttarPradeshAquagrand title={locdata.Sonbhadra.title} number={locdata.Sonbhadra.number} />} />
        <Route path="/aquagrand-ro-service-Sultanpur" element={<UttarPradeshAquagrand title={locdata.Sultanpur.title} number={locdata.Sultanpur.number} />} />
        <Route path="/aquagrand-ro-service-Unnao" element={<UttarPradeshAquagrand title={locdata.Unnao.title} number={locdata.Unnao.number} />} />
        <Route path="/aquagrand-ro-service-Varanasi" element={<UttarPradeshAquagrand title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/aquagrand-ro-service-Robertsganj" element={<UttarPradeshAquagrand title={locdata.Robertsganj.title} number={locdata.Robertsganj.number} />} />
        <Route path="/aquagrand-ro-service-Bahjoi" element={<UttarPradeshAquagrand title={locdata.Bahjoi.title} number={locdata.Bahjoi.number} />} />
        <Route path="/aquagrand-ro-service-Khalilabad" element={<UttarPradeshAquagrand title={locdata.Khalilabad.title} number={locdata.Khalilabad.number} />} />
        <Route path="/aquagrand-ro-service-Akbarpur" element={<UttarPradeshAquagrand title={locdata.Akbarpur.title} number={locdata.Akbarpur.number} />} />
        <Route path="/aquagrand-ro-service-Orai" element={<UttarPradeshAquagrand title={locdata.Orai.title} number={locdata.Orai.number} />} />
        <Route path="/aquagrand-ro-service-Manjhanpur" element={<UttarPradeshAquagrand title={locdata.Manjhanpur.title} number={locdata.Manjhanpur.number} />} />
        <Route path="/aquagrand-ro-service-Padarauna" element={<UttarPradeshAquagrand title={locdata.Padarauna.title} number={locdata.Padarauna.number} />} />
        <Route path="/aquagrand-ro-service-Kheri" element={<UttarPradeshAquagrand title={locdata.Kheri.title} number={locdata.Kheri.number} />} />
        <Route path="/aquagrand-ro-service-Fatehgarh" element={<UttarPradeshAquagrand title={locdata.Fatehgarh.title} number={locdata.Fatehgarh.number} />} />
        <Route path="/aquagrand-ro-service-Noida" element={<UttarPradeshAquagrand title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/aquagrand-ro-service-Greater-Noida" element={<UttarPradeshAquagrand title={locdata.GreaterNoida.title} number={locdata.GreaterNoida.number} />} />
        <Route path="/aquagrand-ro-service-Indore" element={<MadhyaPradeshAquagrand title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/aquagrand-ro-service-Bhopal" element={<MadhyaPradeshAquagrand title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/aquagrand-ro-service-Jabalpur" element={<MadhyaPradeshAquagrand title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/aquagrand-ro-service-Gwalior" element={<MadhyaPradeshAquagrand title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/aquagrand-ro-service-Ujjain" element={<MadhyaPradeshAquagrand title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/aquagrand-ro-service-Sagar" element={<MadhyaPradeshAquagrand title={locdata.Sagar.title} number={locdata.Sagar.number} />} />
        <Route path="/aquagrand-ro-service-Dewas" element={<MadhyaPradeshAquagrand title={locdata.Dewas.title} number={locdata.Dewas.number} />} />
        <Route path="/aquagrand-ro-service-Satna" element={<MadhyaPradeshAquagrand title={locdata.Satna.title} number={locdata.Satna.number} />} />
        <Route path="/aquagrand-ro-service-Ratlam" element={<MadhyaPradeshAquagrand title={locdata.Ratlam.title} number={locdata.Ratlam.number} />} />
        <Route path="/aquagrand-ro-service-Rewa" element={<MadhyaPradeshAquagrand title={locdata.Rewa.title} number={locdata.Rewa.number} />} />
        <Route path="/aquagrand-ro-service-Katni" element={<MadhyaPradeshAquagrand title={locdata.Katni.title} number={locdata.Katni.number} />} />
        <Route path="/aquagrand-ro-service-Singrauli" element={<MadhyaPradeshAquagrand title={locdata.Singrauli.title} number={locdata.Singrauli.number} />} />
        <Route path="/aquagrand-ro-service-Burhanpur" element={<MadhyaPradeshAquagrand title={locdata.Burhanpur.title} number={locdata.Burhanpur.number} />} />
        <Route path="/aquagrand-ro-service-Morena" element={<MadhyaPradeshAquagrand title={locdata.Morena.title} number={locdata.Morena.number} />} />
        <Route path="/aquagrand-ro-service-Khandwa" element={<MadhyaPradeshAquagrand title={locdata.Khandwa.title} number={locdata.Khandwa.number} />} />
        <Route path="/aquagrand-ro-service-Bhind" element={<MadhyaPradeshAquagrand title={locdata.Bhind.title} number={locdata.Bhind.number} />} />
        <Route path="/aquagrand-ro-service-Chhindwara" element={<MadhyaPradeshAquagrand title={locdata.Chhindwara.title} number={locdata.Chhindwara.number} />} />
        <Route path="/aquagrand-ro-service-Guna" element={<MadhyaPradeshAquagrand title={locdata.Guna.title} number={locdata.Guna.number} />} />
        <Route path="/aquagrand-ro-service-Shivpuri" element={<MadhyaPradeshAquagrand title={locdata.Shivpuri.title} number={locdata.Shivpuri.number} />} />
        <Route path="/aquagrand-ro-service-Vidisha" element={<MadhyaPradeshAquagrand title={locdata.Vidisha.title} number={locdata.Vidisha.number} />} />
        <Route path="/aquagrand-ro-service-Chhatarpur" element={<MadhyaPradeshAquagrand title={locdata.Chhatarpur.title} number={locdata.Chhatarpur.number} />} />
        <Route path="/aquagrand-ro-service-Damoh" element={<MadhyaPradeshAquagrand title={locdata.Damoh.title} number={locdata.Damoh.number} />} />
        <Route path="/aquagrand-ro-service-Mandsaur" element={<MadhyaPradeshAquagrand title={locdata.Mandsaur.title} number={locdata.Mandsaur.number} />} />
        <Route path="/aquagrand-ro-service-Khargone" element={<MadhyaPradeshAquagrand title={locdata.Khargone.title} number={locdata.Khargone.number} />} />
        <Route path="/aquagrand-ro-service-Neemuch" element={<MadhyaPradeshAquagrand title={locdata.Neemuch.title} number={locdata.Neemuch.number} />} />
        <Route path="/aquagrand-ro-service-Pithampur" element={<MadhyaPradeshAquagrand title={locdata.Pithampur.title} number={locdata.Pithampur.number} />} />
        <Route path="/aquagrand-ro-service-Narmadapuram" element={<MadhyaPradeshAquagrand title={locdata.Narmadapuram.title} number={locdata.Narmadapuram.number} />} />
        <Route path="/aquagrand-ro-service-Itarsi" element={<MadhyaPradeshAquagrand title={locdata.Itarsi.title} number={locdata.Itarsi.number} />} />
        <Route path="/aquagrand-ro-service-Sehore" element={<MadhyaPradeshAquagrand title={locdata.Sehore.title} number={locdata.Sehore.number} />} />
        <Route path="/aquagrand-ro-service-Betul" element={<MadhyaPradeshAquagrand title={locdata.Betul.title} number={locdata.Betul.number} />} />
        <Route path="/aquagrand-ro-service-Seoni" element={<MadhyaPradeshAquagrand title={locdata.Seoni.title} number={locdata.Seoni.number} />} />
        <Route path="/aquagrand-ro-service-Datia" element={<MadhyaPradeshAquagrand title={locdata.Datia.title} number={locdata.Datia.number} />} />
        <Route path="/aquagrand-ro-service-Nagda" element={<MadhyaPradeshAquagrand title={locdata.Nagda.title} number={locdata.Nagda.number} />} />
        <Route path="/aquagrand-ro-service-Shahdol" element={<MadhyaPradeshAquagrand title={locdata.Shahdol.title} number={locdata.Shahdol.number} />} />
        <Route path="/aquagrand-ro-service-Dhar" element={<MadhyaPradeshAquagrand title={locdata.Dhar.title} number={locdata.Dhar.number} />} />

        <Route path="/aquagrand-ro-service-Ajmer" element={<RajasthanAquagrand title={locdata.Ajmer.title} number={locdata.Ajmer.number} />} />
        <Route path="/aquagrand-ro-service-Alwar" element={<RajasthanAquagrand title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/aquagrand-ro-service-Balotra" element={<RajasthanAquagrand title={locdata.Balotra.title} number={locdata.Balotra.number} />} />
        <Route path="/aquagrand-ro-service-Banswara" element={<RajasthanAquagrand title={locdata.Banswara.title} number={locdata.Banswara.number} />} />
        <Route path="/aquagrand-ro-service-Baran" element={<RajasthanAquagrand title={locdata.Baran.title} number={locdata.Baran.number} />} />
        <Route path="/aquagrand-ro-service-Barmer" element={<RajasthanAquagrand title={locdata.Barmer.title} number={locdata.Barmer.number} />} />
        <Route path="/aquagrand-ro-service-Beawar" element={<RajasthanAquagrand title={locdata.Beawar.title} number={locdata.Beawar.number} />} />
        <Route path="/aquagrand-ro-service-Bharatpur" element={<RajasthanAquagrand title={locdata.Bharatpur.title} number={locdata.Bharatpur.number} />} />
        <Route path="/aquagrand-ro-service-Bhilwara" element={<RajasthanAquagrand title={locdata.Bhilwara.title} number={locdata.Bhilwara.number} />} />
        <Route path="/aquagrand-ro-service-Bikaner" element={<RajasthanAquagrand title={locdata.Bikaner.title} number={locdata.Bikaner.number} />} />
        <Route path="/aquagrand-ro-service-Bundi" element={<RajasthanAquagrand title={locdata.Bundi.title} number={locdata.Bundi.number} />} />
        <Route path="/aquagrand-ro-service-Chittorgarh" element={<RajasthanAquagrand title={locdata.Chittorgarh.title} number={locdata.Chittorgarh.number} />} />
        <Route path="/aquagrand-ro-service-Churu" element={<RajasthanAquagrand title={locdata.Churu.title} number={locdata.Churu.number} />} />
        <Route path="/aquagrand-ro-service-Dausa" element={<RajasthanAquagrand title={locdata.Dausa.title} number={locdata.Dausa.number} />} />
        <Route path="/aquagrand-ro-service-Deeg" element={<RajasthanAquagrand title={locdata.Deeg.title} number={locdata.Deeg.number} />} />
        <Route path="/aquagrand-ro-service-Deedwana" element={<RajasthanAquagrand title={locdata.Deedwana.title} number={locdata.Deedwana.number} />} />
        <Route path="/aquagrand-ro-service-Dholpur" element={<RajasthanAquagrand title={locdata.Dholpur.title} number={locdata.Dholpur.number} />} />
        <Route path="/aquagrand-ro-service-Dungarpur" element={<RajasthanAquagrand title={locdata.Dungarpur.title} number={locdata.Dungarpur.number} />} />
        <Route path="/aquagrand-ro-service-Hanumangarh" element={<RajasthanAquagrand title={locdata.Hanumangarh.title} number={locdata.Hanumangarh.number} />} />
        <Route path="/aquagrand-ro-service-Jaipur" element={<RajasthanAquagrand title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/aquagrand-ro-service-Jaisalmer" element={<RajasthanAquagrand title={locdata.Jaisalmer.title} number={locdata.Jaisalmer.number} />} />
        <Route path="/aquagrand-ro-service-Jalore" element={<RajasthanAquagrand title={locdata.Jalore.title} number={locdata.Jalore.number} />} />
        <Route path="/aquagrand-ro-service-Jhalawar" element={<RajasthanAquagrand title={locdata.Jhalawar.title} number={locdata.Jhalawar.number} />} />
        <Route path="/aquagrand-ro-service-Jhunjhunu" element={<RajasthanAquagrand title={locdata.Jhunjhunu.title} number={locdata.Jhunjhunu.number} />} />
        <Route path="/aquagrand-ro-service-Jodhpur" element={<RajasthanAquagrand title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/aquagrand-ro-service-Karauli" element={<RajasthanAquagrand title={locdata.Karauli.title} number={locdata.Karauli.number} />} />
        <Route path="/aquagrand-ro-service-Tijara" element={<RajasthanAquagrand title={locdata.Tijara.title} number={locdata.Tijara.number} />} />
        <Route path="/aquagrand-ro-service-Kotputli" element={<RajasthanAquagrand title={locdata.Kotputli.title} number={locdata.Kotputli.number} />} />
        <Route path="/aquagrand-ro-service-Kota" element={<RajasthanAquagrand title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/aquagrand-ro-service-Nagaur" element={<RajasthanAquagrand title={locdata.Nagaur.title} number={locdata.Nagaur.number} />} />
        <Route path="/aquagrand-ro-service-Pali" element={<RajasthanAquagrand title={locdata.Pali.title} number={locdata.Pali.number} />} />
        <Route path="/aquagrand-ro-service-Phalodi" element={<RajasthanAquagrand title={locdata.Phalodi.title} number={locdata.Phalodi.number} />} />
        <Route path="/aquagrand-ro-service-Pratapgarh" element={<RajasthanAquagrand title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/aquagrand-ro-service-Rajsamand" element={<RajasthanAquagrand title={locdata.Rajsamand.title} number={locdata.Rajsamand.number} />} />
        <Route path="/aquagrand-ro-service-Salumbar" element={<RajasthanAquagrand title={locdata.Salumbar.title} number={locdata.Salumbar.number} />} />
        <Route path="/aquagrand-ro-service-Sawai-Madhopur" element={<RajasthanAquagrand title={locdata.SawaiMadhopur.title} number={locdata.SawaiMadhopur.number} />} />
        <Route path="/aquagrand-ro-service-Sikar" element={<RajasthanAquagrand title={locdata.Sikar.title} number={locdata.Sikar.number} />} />
        <Route path="/aquagrand-ro-service-Sirohi" element={<RajasthanAquagrand title={locdata.Sirohi.title} number={locdata.Sirohi.number} />} />
        <Route path="/aquagrand-ro-service-Sri-Ganganagar" element={<RajasthanAquagrand title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/aquagrand-ro-service-Tonk" element={<RajasthanAquagrand title={locdata.Tonk.title} number={locdata.Tonk.number} />} />
        <Route path="/aquagrand-ro-service-Udaipur" element={<RajasthanAquagrand title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />

        <Route path="/aquagrand-ro-service-Mumbai" element={<Aquagrandro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/aquagrand-ro-service-Delhi" element={<Aquagrandro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/aquagrand-ro-service-Bengaluru" element={<Aquagrandro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/aquagrand-ro-service-Hyderabad" element={<Aquagrandro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/aquagrand-ro-service-Ahmedabad" element={<Aquagrandro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/aquagrand-ro-service-Chennai" element={<Aquagrandro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/aquagrand-ro-service-Surat" element={<Aquagrandro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/aquagrand-ro-service-Pune" element={<Aquagrandro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/aquagrand-ro-service-Jaipur" element={<Aquagrandro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/aquagrand-ro-service-Lucknow" element={<Aquagrandro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/aquagrand-ro-service-Kanpur" element={<Aquagrandro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/aquagrand-ro-service-Nagpur" element={<Aquagrandro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/aquagrand-ro-service-Indore" element={<Aquagrandro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/aquagrand-ro-service-Thane" element={<Aquagrandro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/aquagrand-ro-service-Bhopal" element={<Aquagrandro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/aquagrand-ro-service-Visakhapatnam" element={<Aquagrandro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/aquagrand-ro-service-Vadodara" element={<Aquagrandro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/aquagrand-ro-service-Ghaziabad" element={<Aquagrandro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/aquagrand-ro-service-Ludhiana" element={<Aquagrandro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/aquagrand-ro-service-Agra" element={<Aquagrandro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/aquagrand-ro-service-Nashik" element={<Aquagrandro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/aquagrand-ro-service-Faridabad" element={<Aquagrandro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/aquagrand-ro-service-Meerut" element={<Aquagrandro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/aquagrand-ro-service-Rajkot" element={<Aquagrandro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/aquagrand-ro-service-Varanasi" element={<Aquagrandro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/aquagrand-ro-service-Srinagar" element={<Aquagrandro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/aquagrand-ro-service-Amritsar" element={<Aquagrandro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/aquagrand-ro-service-Navi-Mumbai" element={<Aquagrandro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/aquagrand-ro-service-Prayagraj" element={<Aquagrandro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/aquagrand-ro-service-Jabalpur" element={<Aquagrandro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/aquagrand-ro-service-Gwalior" element={<Aquagrandro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/aquagrand-ro-service-Coimbatore" element={<Aquagrandro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/aquagrand-ro-service-Vijayawada" element={<Aquagrandro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/aquagrand-ro-service-Jodhpur" element={<Aquagrandro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/aquagrand-ro-service-Madurai" element={<Aquagrandro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/aquagrand-ro-service-Raipur" element={<Aquagrandro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/aquagrand-ro-service-Kota" element={<Aquagrandro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/aquagrand-ro-service-Guwahati" element={<Aquagrandro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/aquagrand-ro-service-Chandigarh" element={<Aquagrandro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/aquagrand-ro-service-Thiruvananthapuram" element={<Aquagrandro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/aquagrand-ro-service-Solapur" element={<Aquagrandro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/aquagrand-ro-service-Tiruppur" element={<Aquagrandro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/aquagrand-ro-service-Moradabad" element={<Aquagrandro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/aquagrand-ro-service-Mysore" element={<Aquagrandro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/aquagrand-ro-service-Gurgaon" element={<Aquagrandro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/aquagrand-ro-service-Aligarh" element={<Aquagrandro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/aquagrand-ro-service-Jalandhar" element={<Aquagrandro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/aquagrand-ro-service-Bhubaneswar" element={<Aquagrandro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/aquagrand-ro-service-Noida" element={<Aquagrandro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/aquagrand-ro-service-Jamshedpur" element={<Aquagrandro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/aquagrand-ro-service-Cuttack" element={<Aquagrandro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/aquagrand-ro-service-Bhavnagar" element={<Aquagrandro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/aquagrand-ro-service-Dehradun" element={<Aquagrandro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/aquagrand-ro-service-Rourkela" element={<Aquagrandro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/aquagrand-ro-service-Jamnagar" element={<Aquagrandro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/aquagrand-ro-service-Ujjain" element={<Aquagrandro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/aquagrand-ro-service-Jammu" element={<Aquagrandro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/aquagrand-ro-service-Mangalore" element={<Aquagrandro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/aquagrand-ro-service-Erode" element={<Aquagrandro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/aquagrand-ro-service-Udaipur" element={<Aquagrandro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/aquagrand-ro-service-Rajahmundry" element={<Aquagrandro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/aquagrand-ro-service-Patiala" element={<Aquagrandro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/aquagrand-ro-service-Agartala" element={<Aquagrandro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/aquagrand-ro-service-Tirupati" element={<Aquagrandro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/aquagrand-ro-service-Sambalpur" element={<Aquagrandro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/aquagrand-ro-service-Bilaspur" element={<Aquagrandro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/aquagrand-ro-service-Alwar" element={<Aquagrandro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/aquagrand-ro-service-Bardhaman" element={<Aquagrandro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/aquagrand-ro-service-Sonipat" element={<Aquagrandro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/aquagrand-ro-service-New-Delhi" element={<Aquagrandro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/aquagrand-ro-service-Puducherry" element={<Aquagrandro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/aquagrand-ro-service-Haridwar" element={<Aquagrandro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/aquagrand-ro-service-Vijayanagaram" element={<Aquagrandro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/aquagrand-ro-service-Sri-Ganganagar" element={<Aquagrandro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/aquagrand-ro-service-Secunderabad" element={<Aquagrandro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/aquagrand-ro-service-Yamunanagar" element={<Aquagrandro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/aquagrand-ro-service-Gandhinagar" element={<Aquagrandro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/aquagrand-ro-service-Ambala" element={<Aquagrandro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/aquagrand-ro-service-Vellore" element={<Aquagrandro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/aquagrand-ro-service-Shimla" element={<Aquagrandro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/aquagrand-ro-service-Amaravati" element={<Aquagrandro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />


        <Route path="/aosmith-ro-service-Araria" element={<BiharAOSmith title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/aosmith-ro-service-Arwal" element={<BiharAOSmith title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/aosmith-ro-service-Aurangabad" element={<BiharAOSmith title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/aosmith-ro-service-Banka" element={<BiharAOSmith title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/aosmith-ro-service-Begusarai" element={<BiharAOSmith title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/aosmith-ro-service-Bhabua" element={<BiharAOSmith title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/aosmith-ro-service-Bhagalpur" element={<BiharAOSmith title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/aosmith-ro-service-Bhojpur" element={<BiharAOSmith title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/aosmith-ro-service-Bihar-Sharif" element={<BiharAOSmith title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/aosmith-ro-service-Buxar" element={<BiharAOSmith title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/aosmith-ro-service-Chhapra" element={<BiharAOSmith title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/aosmith-ro-service-Darbhanga" element={<BiharAOSmith title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/aosmith-ro-service-East-Champaran" element={<BiharAOSmith title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/aosmith-ro-service-Gaya" element={<BiharAOSmith title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/aosmith-ro-service-Gopalganj" element={<BiharAOSmith title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/aosmith-ro-service-Jamui" element={<BiharAOSmith title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/aosmith-ro-service-Jehanabad" element={<BiharAOSmith title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/aosmith-ro-service-Khagaria" element={<BiharAOSmith title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/aosmith-ro-service-Kishanganj" element={<BiharAOSmith title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/aosmith-ro-service-Kaimur" element={<BiharAOSmith title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/aosmith-ro-service-Katihar" element={<BiharAOSmith title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/aosmith-ro-service-Lakhisarai" element={<BiharAOSmith title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/aosmith-ro-service-Madhubani" element={<BiharAOSmith title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/aosmith-ro-service-Munger" element={<BiharAOSmith title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/aosmith-ro-service-Madhepura" element={<BiharAOSmith title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/aosmith-ro-service-Muzaffarpur" element={<BiharAOSmith title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/aosmith-ro-service-Nalanda" element={<BiharAOSmith title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/aosmith-ro-service-Nawada" element={<BiharAOSmith title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/aosmith-ro-service-Patna" element={<BiharAOSmith title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/aosmith-ro-service-Purnia" element={<BiharAOSmith title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/aosmith-ro-service-Rohtas" element={<BiharAOSmith title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/aosmith-ro-service-Saharsa" element={<BiharAOSmith title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/aosmith-ro-service-Samastipur" element={<BiharAOSmith title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/aosmith-ro-service-Sasaram" element={<BiharAOSmith title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/aosmith-ro-service-Sheohar" element={<BiharAOSmith title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/aosmith-ro-service-Sheikhpura" element={<BiharAOSmith title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/aosmith-ro-service-Saran" element={<BiharAOSmith title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/aosmith-ro-service-Sitamarhi" element={<BiharAOSmith title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/aosmith-ro-service-Supaul" element={<BiharAOSmith title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/aosmith-ro-service-Siwan" element={<BiharAOSmith title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/aosmith-ro-service-Vaishali" element={<BiharAOSmith title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/aosmith-ro-service-West-Champaran" element={<BiharAOSmith title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/aosmith-ro-service-Garhwa" element={<JharkhandAOSmith title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/aosmith-ro-service-Palamu" element={<JharkhandAOSmith title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/aosmith-ro-service-Latehar" element={<JharkhandAOSmith title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/aosmith-ro-service-Chatra" element={<JharkhandAOSmith title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/aosmith-ro-service-Hazaribagh" element={<JharkhandAOSmith title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/aosmith-ro-service-Jamshedpur" element={<JharkhandAOSmith title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/aosmith-ro-service-Koderma" element={<JharkhandAOSmith title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/aosmith-ro-service-Giridih" element={<JharkhandAOSmith title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/aosmith-ro-service-Ramgarh" element={<JharkhandAOSmith title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/aosmith-ro-service-Bokaro" element={<JharkhandAOSmith title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/aosmith-ro-service-Dhanbad" element={<JharkhandAOSmith title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/aosmith-ro-service-Lohardaga" element={<JharkhandAOSmith title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/aosmith-ro-service-Gumla" element={<JharkhandAOSmith title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/aosmith-ro-service-Simdega" element={<JharkhandAOSmith title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/aosmith-ro-service-Ranchi" element={<JharkhandAOSmith title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/aosmith-ro-service-Khunti" element={<JharkhandAOSmith title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/aosmith-ro-service-Singhbhum" element={<JharkhandAOSmith title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/aosmith-ro-service-Saraikela-Kharsawan" element={<JharkhandAOSmith title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/aosmith-ro-service-Jamtara" element={<JharkhandAOSmith title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/aosmith-ro-service-Deoghar" element={<JharkhandAOSmith title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/aosmith-ro-service-Dumka" element={<JharkhandAOSmith title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/aosmith-ro-service-Pakur" element={<JharkhandAOSmith title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/aosmith-ro-service-Godda" element={<JharkhandAOSmith title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/aosmith-ro-service-Sahebganj" element={<JharkhandAOSmith title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />
        <Route path="/aosmith-ro-service-Alipurduar" element={<BengalAOSmith title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/aosmith-ro-service-Bankura" element={<BengalAOSmith title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/aosmith-ro-service-Birbhum" element={<BengalAOSmith title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/aosmith-ro-service-Cooch-Behar" element={<BengalAOSmith title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/aosmith-ro-service-Dakshin-Dinajpur" element={<BengalAOSmith title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/aosmith-ro-service-Darjeeling" element={<BengalAOSmith title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/aosmith-ro-service-Durgapur" element={<BengalAOSmith title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/aosmith-ro-service-Hooghly" element={<BengalAOSmith title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/aosmith-ro-service-Howrah" element={<BengalAOSmith title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/aosmith-ro-service-Jalpaiguri" element={<BengalAOSmith title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/aosmith-ro-service-Jhargram" element={<BengalAOSmith title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/aosmith-ro-service-Kalimpong" element={<BengalAOSmith title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/aosmith-ro-service-Kolkata" element={<BengalAOSmith title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/aosmith-ro-service-Malda" element={<BengalAOSmith title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/aosmith-ro-service-Murshidabad" element={<BengalAOSmith title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/aosmith-ro-service-Nadia" element={<BengalAOSmith title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/aosmith-ro-service-North-24-Parganas" element={<BengalAOSmith title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/aosmith-ro-service-Paschim-Bardhaman" element={<BengalAOSmith title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/aosmith-ro-service-Paschim-Medinipur" element={<BengalAOSmith title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/aosmith-ro-service-Purba-Bardhaman" element={<BengalAOSmith title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/aosmith-ro-service-Purba-Medinipur" element={<BengalAOSmith title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/aosmith-ro-service-South-24-Parganas" element={<BengalAOSmith title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/aosmith-ro-service-Uttar-Dinajpur" element={<BengalAOSmith title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />
        <Route path="/aosmith-ro-service-Agra" element={<UttarPradeshAOSmith title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/aosmith-ro-service-Aligarh" element={<UttarPradeshAOSmith title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/aosmith-ro-service-Ambedkar-Nagar" element={<UttarPradeshAOSmith title={locdata.AmbedkarNagar.title} number={locdata.AmbedkarNagar.number} />} />
        <Route path="/aosmith-ro-service-Ayodhya" element={<UttarPradeshAOSmith title={locdata.Ayodhya.title} number={locdata.Ayodhya.number} />} />
        <Route path="/aosmith-ro-service-Amethi" element={<UttarPradeshAOSmith title={locdata.Amethi.title} number={locdata.Amethi.number} />} />
        <Route path="/aosmith-ro-service-Amroha" element={<UttarPradeshAOSmith title={locdata.Amroha.title} number={locdata.Amroha.number} />} />
        <Route path="/aosmith-ro-service-Auraiya" element={<UttarPradeshAOSmith title={locdata.Auraiya.title} number={locdata.Auraiya.number} />} />
        <Route path="/aosmith-ro-service-Azamgarh" element={<UttarPradeshAOSmith title={locdata.Azamgarh.title} number={locdata.Azamgarh.number} />} />
        <Route path="/aosmith-ro-service-Budaun" element={<UttarPradeshAOSmith title={locdata.Budaun.title} number={locdata.Budaun.number} />} />
        <Route path="/aosmith-ro-service-Bagpat" element={<UttarPradeshAOSmith title={locdata.Bagpat.title} number={locdata.Bagpat.number} />} />
        <Route path="/aosmith-ro-service-Bahraich" element={<UttarPradeshAOSmith title={locdata.Bahraich.title} number={locdata.Bahraich.number} />} />
        <Route path="/aosmith-ro-service-Ballia" element={<UttarPradeshAOSmith title={locdata.Ballia.title} number={locdata.Ballia.number} />} />
        <Route path="/aosmith-ro-service-Balrampur" element={<UttarPradeshAOSmith title={locdata.Balrampur.title} number={locdata.Balrampur.number} />} />
        <Route path="/aosmith-ro-service-Banda" element={<UttarPradeshAOSmith title={locdata.Banda.title} number={locdata.Banda.number} />} />
        <Route path="/aosmith-ro-service-Barabanki" element={<UttarPradeshAOSmith title={locdata.Barabanki.title} number={locdata.Barabanki.number} />} />
        <Route path="/aosmith-ro-service-Bareilly" element={<UttarPradeshAOSmith title={locdata.Bareilly.title} number={locdata.Bareilly.number} />} />
        <Route path="/aosmith-ro-service-Basti" element={<UttarPradeshAOSmith title={locdata.Basti.title} number={locdata.Basti.number} />} />
        <Route path="/aosmith-ro-service-Bijnor" element={<UttarPradeshAOSmith title={locdata.Bijnor.title} number={locdata.Bijnor.number} />} />
        <Route path="/aosmith-ro-service-Bulandshahr" element={<UttarPradeshAOSmith title={locdata.Bulandshahr.title} number={locdata.Bulandshahr.number} />} />
        <Route path="/aosmith-ro-service-Chandauli" element={<UttarPradeshAOSmith title={locdata.Chandauli.title} number={locdata.Chandauli.number} />} />
        <Route path="/aosmith-ro-service-Chitrakoot" element={<UttarPradeshAOSmith title={locdata.Chitrakoot.title} number={locdata.Chitrakoot.number} />} />
        <Route path="/aosmith-ro-service-Deoria" element={<UttarPradeshAOSmith title={locdata.Deoria.title} number={locdata.Deoria.number} />} />
        <Route path="/aosmith-ro-service-Etah" element={<UttarPradeshAOSmith title={locdata.Etah.title} number={locdata.Etah.number} />} />
        <Route path="/aosmith-ro-service-Etawah" element={<UttarPradeshAOSmith title={locdata.Etawah.title} number={locdata.Etawah.number} />} />
        <Route path="/aosmith-ro-service-Farrukhabad" element={<UttarPradeshAOSmith title={locdata.Farrukhabad.title} number={locdata.Farrukhabad.number} />} />
        <Route path="/aosmith-ro-service-Fatehpur" element={<UttarPradeshAOSmith title={locdata.Fatehpur.title} number={locdata.Fatehpur.number} />} />
        <Route path="/aosmith-ro-service-Firozabad" element={<UttarPradeshAOSmith title={locdata.Firozabad.title} number={locdata.Firozabad.number} />} />
        <Route path="/aosmith-ro-service-Gautam-Buddha-Nagar" element={<UttarPradeshAOSmith title={locdata.GautamBuddhaNagar.title} number={locdata.GautamBuddhaNagar.number} />} />
        <Route path="/aosmith-ro-service-Ghaziabad" element={<UttarPradeshAOSmith title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/aosmith-ro-service-Ghazipur" element={<UttarPradeshAOSmith title={locdata.Ghazipur.title} number={locdata.Ghazipur.number} />} />
        <Route path="/aosmith-ro-service-Gonda" element={<UttarPradeshAOSmith title={locdata.Gonda.title} number={locdata.Gonda.number} />} />
        <Route path="/aosmith-ro-service-Gorakhpur" element={<UttarPradeshAOSmith title={locdata.Gorakhpur.title} number={locdata.Gorakhpur.number} />} />
        <Route path="/aosmith-ro-service-Hamirpur" element={<UttarPradeshAOSmith title={locdata.Hamirpur.title} number={locdata.Hamirpur.number} />} />
        <Route path="/aosmith-ro-service-Hapur" element={<UttarPradeshAOSmith title={locdata.Hapur.title} number={locdata.Hapur.number} />} />
        <Route path="/aosmith-ro-service-Hardoi" element={<UttarPradeshAOSmith title={locdata.Hardoi.title} number={locdata.Hardoi.number} />} />
        <Route path="/aosmith-ro-service-Hathras" element={<UttarPradeshAOSmith title={locdata.Hathras.title} number={locdata.Hathras.number} />} />
        <Route path="/aosmith-ro-service-Jalaun" element={<UttarPradeshAOSmith title={locdata.Jalaun.title} number={locdata.Jalaun.number} />} />
        <Route path="/aosmith-ro-service-Jaunpur" element={<UttarPradeshAOSmith title={locdata.Jaunpur.title} number={locdata.Jaunpur.number} />} />
        <Route path="/aosmith-ro-service-Jhansi" element={<UttarPradeshAOSmith title={locdata.Jhansi.title} number={locdata.Jhansi.number} />} />
        <Route path="/aosmith-ro-service-Kannauj" element={<UttarPradeshAOSmith title={locdata.Kannauj.title} number={locdata.Kannauj.number} />} />
        <Route path="/aosmith-ro-service-Kanpur-Dehat" element={<UttarPradeshAOSmith title={locdata.KanpurDehat.title} number={locdata.KanpurDehat.number} />} />
        <Route path="/aosmith-ro-service-Kanpur" element={<UttarPradeshAOSmith title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/aosmith-ro-service-Kasganj" element={<UttarPradeshAOSmith title={locdata.Kasganj.title} number={locdata.Kasganj.number} />} />
        <Route path="/aosmith-ro-service-Kaushambi" element={<UttarPradeshAOSmith title={locdata.Kaushambi.title} number={locdata.Kaushambi.number} />} />
        <Route path="/aosmith-ro-service-Kushinagar" element={<UttarPradeshAOSmith title={locdata.Kushinagar.title} number={locdata.Kushinagar.number} />} />
        <Route path="/aosmith-ro-service-Lakhimpur-Kheri" element={<UttarPradeshAOSmith title={locdata.LakhimpurKheri.title} number={locdata.LakhimpurKheri.number} />} />
        <Route path="/aosmith-ro-service-Lalitpur" element={<UttarPradeshAOSmith title={locdata.Lalitpur.title} number={locdata.Lalitpur.number} />} />
        <Route path="/aosmith-ro-service-Lucknow" element={<UttarPradeshAOSmith title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/aosmith-ro-service-Maharajganj" element={<UttarPradeshAOSmith title={locdata.Maharajganj.title} number={locdata.Maharajganj.number} />} />
        <Route path="/aosmith-ro-service-Mahoba" element={<UttarPradeshAOSmith title={locdata.Mahoba.title} number={locdata.Mahoba.number} />} />
        <Route path="/aosmith-ro-service-Mainpuri" element={<UttarPradeshAOSmith title={locdata.Mainpuri.title} number={locdata.Mainpuri.number} />} />
        <Route path="/aosmith-ro-service-Mathura" element={<UttarPradeshAOSmith title={locdata.Mathura.title} number={locdata.Mathura.number} />} />
        <Route path="/aosmith-ro-service-Mau" element={<UttarPradeshAOSmith title={locdata.Mau.title} number={locdata.Mau.number} />} />
        <Route path="/aosmith-ro-service-Meerut" element={<UttarPradeshAOSmith title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/aosmith-ro-service-Mirzapur" element={<UttarPradeshAOSmith title={locdata.Mirzapur.title} number={locdata.Mirzapur.number} />} />
        <Route path="/aosmith-ro-service-Moradabad" element={<UttarPradeshAOSmith title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/aosmith-ro-service-Muzaffarnagar" element={<UttarPradeshAOSmith title={locdata.Muzaffarnagar.title} number={locdata.Muzaffarnagar.number} />} />
        <Route path="/aosmith-ro-service-Pilibhit" element={<UttarPradeshAOSmith title={locdata.Pilibhit.title} number={locdata.Pilibhit.number} />} />
        <Route path="/aosmith-ro-service-Pratapgarh" element={<UttarPradeshAOSmith title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/aosmith-ro-service-Prayagraj" element={<UttarPradeshAOSmith title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/aosmith-ro-service-Rae-Bareli" element={<UttarPradeshAOSmith title={locdata.RaeBareli.title} number={locdata.RaeBareli.number} />} />
        <Route path="/aosmith-ro-service-Rampur" element={<UttarPradeshAOSmith title={locdata.Rampur.title} number={locdata.Rampur.number} />} />
        <Route path="/aosmith-ro-service-Saharanpur" element={<UttarPradeshAOSmith title={locdata.Saharanpur.title} number={locdata.Saharanpur.number} />} />
        <Route path="/aosmith-ro-service-Sant-Kabir-Nagar" element={<UttarPradeshAOSmith title={locdata.SantKabirNagar.title} number={locdata.SantKabirNagar.number} />} />
        <Route path="/aosmith-ro-service-Bhadohi" element={<UttarPradeshAOSmith title={locdata.Bhadohi.title} number={locdata.Bhadohi.number} />} />
        <Route path="/aosmith-ro-service-Sambhal" element={<UttarPradeshAOSmith title={locdata.Sambhal.title} number={locdata.Sambhal.number} />} />
        <Route path="/aosmith-ro-service-Shahjahanpur" element={<UttarPradeshAOSmith title={locdata.Shahjahanpur.title} number={locdata.Shahjahanpur.number} />} />
        <Route path="/aosmith-ro-service-Shamli" element={<UttarPradeshAOSmith title={locdata.Shamli.title} number={locdata.Shamli.number} />} />
        <Route path="/aosmith-ro-service-Shravasti" element={<UttarPradeshAOSmith title={locdata.Shravasti.title} number={locdata.Shravasti.number} />} />
        <Route path="/aosmith-ro-service-Siddharthnagar" element={<UttarPradeshAOSmith title={locdata.Siddharthnagar.title} number={locdata.Siddharthnagar.number} />} />
        <Route path="/aosmith-ro-service-Sitapur" element={<UttarPradeshAOSmith title={locdata.Sitapur.title} number={locdata.Sitapur.number} />} />
        <Route path="/aosmith-ro-service-Sonbhadra" element={<UttarPradeshAOSmith title={locdata.Sonbhadra.title} number={locdata.Sonbhadra.number} />} />
        <Route path="/aosmith-ro-service-Sultanpur" element={<UttarPradeshAOSmith title={locdata.Sultanpur.title} number={locdata.Sultanpur.number} />} />
        <Route path="/aosmith-ro-service-Unnao" element={<UttarPradeshAOSmith title={locdata.Unnao.title} number={locdata.Unnao.number} />} />
        <Route path="/aosmith-ro-service-Varanasi" element={<UttarPradeshAOSmith title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/aosmith-ro-service-Robertsganj" element={<UttarPradeshAOSmith title={locdata.Robertsganj.title} number={locdata.Robertsganj.number} />} />
        <Route path="/aosmith-ro-service-Bahjoi" element={<UttarPradeshAOSmith title={locdata.Bahjoi.title} number={locdata.Bahjoi.number} />} />
        <Route path="/aosmith-ro-service-Khalilabad" element={<UttarPradeshAOSmith title={locdata.Khalilabad.title} number={locdata.Khalilabad.number} />} />
        <Route path="/aosmith-ro-service-Akbarpur" element={<UttarPradeshAOSmith title={locdata.Akbarpur.title} number={locdata.Akbarpur.number} />} />
        <Route path="/aosmith-ro-service-Orai" element={<UttarPradeshAOSmith title={locdata.Orai.title} number={locdata.Orai.number} />} />
        <Route path="/aosmith-ro-service-Manjhanpur" element={<UttarPradeshAOSmith title={locdata.Manjhanpur.title} number={locdata.Manjhanpur.number} />} />
        <Route path="/aosmith-ro-service-Padarauna" element={<UttarPradeshAOSmith title={locdata.Padarauna.title} number={locdata.Padarauna.number} />} />
        <Route path="/aosmith-ro-service-Kheri" element={<UttarPradeshAOSmith title={locdata.Kheri.title} number={locdata.Kheri.number} />} />
        <Route path="/aosmith-ro-service-Fatehgarh" element={<UttarPradeshAOSmith title={locdata.Fatehgarh.title} number={locdata.Fatehgarh.number} />} />
        <Route path="/aosmith-ro-service-Noida" element={<UttarPradeshAOSmith title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/aosmith-ro-service-Greater-Noida" element={<UttarPradeshAOSmith title={locdata.GreaterNoida.title} number={locdata.GreaterNoida.number} />} />
        <Route path="/aosmith-ro-service-Indore" element={<MadhyaPradeshAOSmith title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/aosmith-ro-service-Bhopal" element={<MadhyaPradeshAOSmith title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/aosmith-ro-service-Jabalpur" element={<MadhyaPradeshAOSmith title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/aosmith-ro-service-Gwalior" element={<MadhyaPradeshAOSmith title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/aosmith-ro-service-Ujjain" element={<MadhyaPradeshAOSmith title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/aosmith-ro-service-Sagar" element={<MadhyaPradeshAOSmith title={locdata.Sagar.title} number={locdata.Sagar.number} />} />
        <Route path="/aosmith-ro-service-Dewas" element={<MadhyaPradeshAOSmith title={locdata.Dewas.title} number={locdata.Dewas.number} />} />
        <Route path="/aosmith-ro-service-Satna" element={<MadhyaPradeshAOSmith title={locdata.Satna.title} number={locdata.Satna.number} />} />
        <Route path="/aosmith-ro-service-Ratlam" element={<MadhyaPradeshAOSmith title={locdata.Ratlam.title} number={locdata.Ratlam.number} />} />
        <Route path="/aosmith-ro-service-Rewa" element={<MadhyaPradeshAOSmith title={locdata.Rewa.title} number={locdata.Rewa.number} />} />
        <Route path="/aosmith-ro-service-Katni" element={<MadhyaPradeshAOSmith title={locdata.Katni.title} number={locdata.Katni.number} />} />
        <Route path="/aosmith-ro-service-Singrauli" element={<MadhyaPradeshAOSmith title={locdata.Singrauli.title} number={locdata.Singrauli.number} />} />
        <Route path="/aosmith-ro-service-Burhanpur" element={<MadhyaPradeshAOSmith title={locdata.Burhanpur.title} number={locdata.Burhanpur.number} />} />
        <Route path="/aosmith-ro-service-Morena" element={<MadhyaPradeshAOSmith title={locdata.Morena.title} number={locdata.Morena.number} />} />
        <Route path="/aosmith-ro-service-Khandwa" element={<MadhyaPradeshAOSmith title={locdata.Khandwa.title} number={locdata.Khandwa.number} />} />
        <Route path="/aosmith-ro-service-Bhind" element={<MadhyaPradeshAOSmith title={locdata.Bhind.title} number={locdata.Bhind.number} />} />
        <Route path="/aosmith-ro-service-Chhindwara" element={<MadhyaPradeshAOSmith title={locdata.Chhindwara.title} number={locdata.Chhindwara.number} />} />
        <Route path="/aosmith-ro-service-Guna" element={<MadhyaPradeshAOSmith title={locdata.Guna.title} number={locdata.Guna.number} />} />
        <Route path="/aosmith-ro-service-Shivpuri" element={<MadhyaPradeshAOSmith title={locdata.Shivpuri.title} number={locdata.Shivpuri.number} />} />
        <Route path="/aosmith-ro-service-Vidisha" element={<MadhyaPradeshAOSmith title={locdata.Vidisha.title} number={locdata.Vidisha.number} />} />
        <Route path="/aosmith-ro-service-Chhatarpur" element={<MadhyaPradeshAOSmith title={locdata.Chhatarpur.title} number={locdata.Chhatarpur.number} />} />
        <Route path="/aosmith-ro-service-Damoh" element={<MadhyaPradeshAOSmith title={locdata.Damoh.title} number={locdata.Damoh.number} />} />
        <Route path="/aosmith-ro-service-Mandsaur" element={<MadhyaPradeshAOSmith title={locdata.Mandsaur.title} number={locdata.Mandsaur.number} />} />
        <Route path="/aosmith-ro-service-Khargone" element={<MadhyaPradeshAOSmith title={locdata.Khargone.title} number={locdata.Khargone.number} />} />
        <Route path="/aosmith-ro-service-Neemuch" element={<MadhyaPradeshAOSmith title={locdata.Neemuch.title} number={locdata.Neemuch.number} />} />
        <Route path="/aosmith-ro-service-Pithampur" element={<MadhyaPradeshAOSmith title={locdata.Pithampur.title} number={locdata.Pithampur.number} />} />
        <Route path="/aosmith-ro-service-Narmadapuram" element={<MadhyaPradeshAOSmith title={locdata.Narmadapuram.title} number={locdata.Narmadapuram.number} />} />
        <Route path="/aosmith-ro-service-Itarsi" element={<MadhyaPradeshAOSmith title={locdata.Itarsi.title} number={locdata.Itarsi.number} />} />
        <Route path="/aosmith-ro-service-Sehore" element={<MadhyaPradeshAOSmith title={locdata.Sehore.title} number={locdata.Sehore.number} />} />
        <Route path="/aosmith-ro-service-Betul" element={<MadhyaPradeshAOSmith title={locdata.Betul.title} number={locdata.Betul.number} />} />
        <Route path="/aosmith-ro-service-Seoni" element={<MadhyaPradeshAOSmith title={locdata.Seoni.title} number={locdata.Seoni.number} />} />
        <Route path="/aosmith-ro-service-Datia" element={<MadhyaPradeshAOSmith title={locdata.Datia.title} number={locdata.Datia.number} />} />
        <Route path="/aosmith-ro-service-Nagda" element={<MadhyaPradeshAOSmith title={locdata.Nagda.title} number={locdata.Nagda.number} />} />
        <Route path="/aosmith-ro-service-Shahdol" element={<MadhyaPradeshAOSmith title={locdata.Shahdol.title} number={locdata.Shahdol.number} />} />
        <Route path="/aosmith-ro-service-Dhar" element={<MadhyaPradeshAOSmith title={locdata.Dhar.title} number={locdata.Dhar.number} />} />

        <Route path="/aosmith-ro-service-Ajmer" element={<RajasthanAOSmith title={locdata.Ajmer.title} number={locdata.Ajmer.number} />} />
        <Route path="/aosmith-ro-service-Alwar" element={<RajasthanAOSmith title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/aosmith-ro-service-Balotra" element={<RajasthanAOSmith title={locdata.Balotra.title} number={locdata.Balotra.number} />} />
        <Route path="/aosmith-ro-service-Banswara" element={<RajasthanAOSmith title={locdata.Banswara.title} number={locdata.Banswara.number} />} />
        <Route path="/aosmith-ro-service-Baran" element={<RajasthanAOSmith title={locdata.Baran.title} number={locdata.Baran.number} />} />
        <Route path="/aosmith-ro-service-Barmer" element={<RajasthanAOSmith title={locdata.Barmer.title} number={locdata.Barmer.number} />} />
        <Route path="/aosmith-ro-service-Beawar" element={<RajasthanAOSmith title={locdata.Beawar.title} number={locdata.Beawar.number} />} />
        <Route path="/aosmith-ro-service-Bharatpur" element={<RajasthanAOSmith title={locdata.Bharatpur.title} number={locdata.Bharatpur.number} />} />
        <Route path="/aosmith-ro-service-Bhilwara" element={<RajasthanAOSmith title={locdata.Bhilwara.title} number={locdata.Bhilwara.number} />} />
        <Route path="/aosmith-ro-service-Bikaner" element={<RajasthanAOSmith title={locdata.Bikaner.title} number={locdata.Bikaner.number} />} />
        <Route path="/aosmith-ro-service-Bundi" element={<RajasthanAOSmith title={locdata.Bundi.title} number={locdata.Bundi.number} />} />
        <Route path="/aosmith-ro-service-Chittorgarh" element={<RajasthanAOSmith title={locdata.Chittorgarh.title} number={locdata.Chittorgarh.number} />} />
        <Route path="/aosmith-ro-service-Churu" element={<RajasthanAOSmith title={locdata.Churu.title} number={locdata.Churu.number} />} />
        <Route path="/aosmith-ro-service-Dausa" element={<RajasthanAOSmith title={locdata.Dausa.title} number={locdata.Dausa.number} />} />
        <Route path="/aosmith-ro-service-Deeg" element={<RajasthanAOSmith title={locdata.Deeg.title} number={locdata.Deeg.number} />} />
        <Route path="/aosmith-ro-service-Deedwana" element={<RajasthanAOSmith title={locdata.Deedwana.title} number={locdata.Deedwana.number} />} />
        <Route path="/aosmith-ro-service-Dholpur" element={<RajasthanAOSmith title={locdata.Dholpur.title} number={locdata.Dholpur.number} />} />
        <Route path="/aosmith-ro-service-Dungarpur" element={<RajasthanAOSmith title={locdata.Dungarpur.title} number={locdata.Dungarpur.number} />} />
        <Route path="/aosmith-ro-service-Hanumangarh" element={<RajasthanAOSmith title={locdata.Hanumangarh.title} number={locdata.Hanumangarh.number} />} />
        <Route path="/aosmith-ro-service-Jaipur" element={<RajasthanAOSmith title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/aosmith-ro-service-Jaisalmer" element={<RajasthanAOSmith title={locdata.Jaisalmer.title} number={locdata.Jaisalmer.number} />} />
        <Route path="/aosmith-ro-service-Jalore" element={<RajasthanAOSmith title={locdata.Jalore.title} number={locdata.Jalore.number} />} />
        <Route path="/aosmith-ro-service-Jhalawar" element={<RajasthanAOSmith title={locdata.Jhalawar.title} number={locdata.Jhalawar.number} />} />
        <Route path="/aosmith-ro-service-Jhunjhunu" element={<RajasthanAOSmith title={locdata.Jhunjhunu.title} number={locdata.Jhunjhunu.number} />} />
        <Route path="/aosmith-ro-service-Jodhpur" element={<RajasthanAOSmith title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/aosmith-ro-service-Karauli" element={<RajasthanAOSmith title={locdata.Karauli.title} number={locdata.Karauli.number} />} />
        <Route path="/aosmith-ro-service-Tijara" element={<RajasthanAOSmith title={locdata.Tijara.title} number={locdata.Tijara.number} />} />
        <Route path="/aosmith-ro-service-Kotputli" element={<RajasthanAOSmith title={locdata.Kotputli.title} number={locdata.Kotputli.number} />} />
        <Route path="/aosmith-ro-service-Kota" element={<RajasthanAOSmith title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/aosmith-ro-service-Nagaur" element={<RajasthanAOSmith title={locdata.Nagaur.title} number={locdata.Nagaur.number} />} />
        <Route path="/aosmith-ro-service-Pali" element={<RajasthanAOSmith title={locdata.Pali.title} number={locdata.Pali.number} />} />
        <Route path="/aosmith-ro-service-Phalodi" element={<RajasthanAOSmith title={locdata.Phalodi.title} number={locdata.Phalodi.number} />} />
        <Route path="/aosmith-ro-service-Pratapgarh" element={<RajasthanAOSmith title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/aosmith-ro-service-Rajsamand" element={<RajasthanAOSmith title={locdata.Rajsamand.title} number={locdata.Rajsamand.number} />} />
        <Route path="/aosmith-ro-service-Salumbar" element={<RajasthanAOSmith title={locdata.Salumbar.title} number={locdata.Salumbar.number} />} />
        <Route path="/aosmith-ro-service-Sawai-Madhopur" element={<RajasthanAOSmith title={locdata.SawaiMadhopur.title} number={locdata.SawaiMadhopur.number} />} />
        <Route path="/aosmith-ro-service-Sikar" element={<RajasthanAOSmith title={locdata.Sikar.title} number={locdata.Sikar.number} />} />
        <Route path="/aosmith-ro-service-Sirohi" element={<RajasthanAOSmith title={locdata.Sirohi.title} number={locdata.Sirohi.number} />} />
        <Route path="/aosmith-ro-service-Sri-Ganganagar" element={<RajasthanAOSmith title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/aosmith-ro-service-Tonk" element={<RajasthanAOSmith title={locdata.Tonk.title} number={locdata.Tonk.number} />} />
        <Route path="/aosmith-ro-service-Udaipur" element={<RajasthanAOSmith title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />

        <Route path="/aosmith-ro-service-Mumbai" element={<AOSmithro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/aosmith-ro-service-Delhi" element={<AOSmithro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/aosmith-ro-service-Bengaluru" element={<AOSmithro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/aosmith-ro-service-Hyderabad" element={<AOSmithro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/aosmith-ro-service-Ahmedabad" element={<AOSmithro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/aosmith-ro-service-Chennai" element={<AOSmithro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/aosmith-ro-service-Surat" element={<AOSmithro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/aosmith-ro-service-Pune" element={<AOSmithro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/aosmith-ro-service-Jaipur" element={<AOSmithro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/aosmith-ro-service-Lucknow" element={<AOSmithro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/aosmith-ro-service-Kanpur" element={<AOSmithro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/aosmith-ro-service-Nagpur" element={<AOSmithro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/aosmith-ro-service-Indore" element={<AOSmithro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/aosmith-ro-service-Thane" element={<AOSmithro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/aosmith-ro-service-Bhopal" element={<AOSmithro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/aosmith-ro-service-Visakhapatnam" element={<AOSmithro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/aosmith-ro-service-Vadodara" element={<AOSmithro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/aosmith-ro-service-Ghaziabad" element={<AOSmithro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/aosmith-ro-service-Ludhiana" element={<AOSmithro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/aosmith-ro-service-Agra" element={<AOSmithro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/aosmith-ro-service-Nashik" element={<AOSmithro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/aosmith-ro-service-Faridabad" element={<AOSmithro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/aosmith-ro-service-Meerut" element={<AOSmithro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/aosmith-ro-service-Rajkot" element={<AOSmithro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/aosmith-ro-service-Varanasi" element={<AOSmithro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/aosmith-ro-service-Srinagar" element={<AOSmithro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/aosmith-ro-service-Amritsar" element={<AOSmithro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/aosmith-ro-service-Navi-Mumbai" element={<AOSmithro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/aosmith-ro-service-Prayagraj" element={<AOSmithro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/aosmith-ro-service-Jabalpur" element={<AOSmithro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/aosmith-ro-service-Gwalior" element={<AOSmithro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/aosmith-ro-service-Coimbatore" element={<AOSmithro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/aosmith-ro-service-Vijayawada" element={<AOSmithro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/aosmith-ro-service-Jodhpur" element={<AOSmithro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/aosmith-ro-service-Madurai" element={<AOSmithro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/aosmith-ro-service-Raipur" element={<AOSmithro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/aosmith-ro-service-Kota" element={<AOSmithro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/aosmith-ro-service-Guwahati" element={<AOSmithro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/aosmith-ro-service-Chandigarh" element={<AOSmithro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/aosmith-ro-service-Thiruvananthapuram" element={<AOSmithro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/aosmith-ro-service-Solapur" element={<AOSmithro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/aosmith-ro-service-Tiruppur" element={<AOSmithro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/aosmith-ro-service-Moradabad" element={<AOSmithro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/aosmith-ro-service-Mysore" element={<AOSmithro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/aosmith-ro-service-Gurgaon" element={<AOSmithro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/aosmith-ro-service-Aligarh" element={<AOSmithro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/aosmith-ro-service-Jalandhar" element={<AOSmithro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/aosmith-ro-service-Bhubaneswar" element={<AOSmithro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/aosmith-ro-service-Noida" element={<AOSmithro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/aosmith-ro-service-Jamshedpur" element={<AOSmithro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/aosmith-ro-service-Cuttack" element={<AOSmithro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/aosmith-ro-service-Bhavnagar" element={<AOSmithro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/aosmith-ro-service-Dehradun" element={<AOSmithro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/aosmith-ro-service-Rourkela" element={<AOSmithro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/aosmith-ro-service-Jamnagar" element={<AOSmithro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/aosmith-ro-service-Ujjain" element={<AOSmithro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/aosmith-ro-service-Jammu" element={<AOSmithro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/aosmith-ro-service-Mangalore" element={<AOSmithro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/aosmith-ro-service-Erode" element={<AOSmithro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/aosmith-ro-service-Udaipur" element={<AOSmithro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/aosmith-ro-service-Rajahmundry" element={<AOSmithro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/aosmith-ro-service-Patiala" element={<AOSmithro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/aosmith-ro-service-Agartala" element={<AOSmithro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/aosmith-ro-service-Tirupati" element={<AOSmithro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/aosmith-ro-service-Sambalpur" element={<AOSmithro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/aosmith-ro-service-Bilaspur" element={<AOSmithro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/aosmith-ro-service-Alwar" element={<AOSmithro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/aosmith-ro-service-Bardhaman" element={<AOSmithro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/aosmith-ro-service-Sonipat" element={<AOSmithro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/aosmith-ro-service-New-Delhi" element={<AOSmithro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/aosmith-ro-service-Puducherry" element={<AOSmithro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/aosmith-ro-service-Haridwar" element={<AOSmithro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/aosmith-ro-service-Vijayanagaram" element={<AOSmithro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/aosmith-ro-service-Sri-Ganganagar" element={<AOSmithro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/aosmith-ro-service-Secunderabad" element={<AOSmithro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/aosmith-ro-service-Yamunanagar" element={<AOSmithro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/aosmith-ro-service-Gandhinagar" element={<AOSmithro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/aosmith-ro-service-Ambala" element={<AOSmithro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/aosmith-ro-service-Vellore" element={<AOSmithro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/aosmith-ro-service-Shimla" element={<AOSmithro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/aosmith-ro-service-Amaravati" element={<AOSmithro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />


        <Route path="/aquaguard-ro-service-Araria" element={<BiharAquaguard title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/aquaguard-ro-service-Arwal" element={<BiharAquaguard title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/aquaguard-ro-service-Aurangabad" element={<BiharAquaguard title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/aquaguard-ro-service-Banka" element={<BiharAquaguard title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/aquaguard-ro-service-Begusarai" element={<BiharAquaguard title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/aquaguard-ro-service-Bhabua" element={<BiharAquaguard title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/aquaguard-ro-service-Bhagalpur" element={<BiharAquaguard title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/aquaguard-ro-service-Bhojpur" element={<BiharAquaguard title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/aquaguard-ro-service-Bihar-Sharif" element={<BiharAquaguard title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/aquaguard-ro-service-Buxar" element={<BiharAquaguard title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/aquaguard-ro-service-Chhapra" element={<BiharAquaguard title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/aquaguard-ro-service-Darbhanga" element={<BiharAquaguard title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/aquaguard-ro-service-East-Champaran" element={<BiharAquaguard title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/aquaguard-ro-service-Gaya" element={<BiharAquaguard title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/aquaguard-ro-service-Gopalganj" element={<BiharAquaguard title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/aquaguard-ro-service-Jamui" element={<BiharAquaguard title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/aquaguard-ro-service-Jehanabad" element={<BiharAquaguard title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/aquaguard-ro-service-Khagaria" element={<BiharAquaguard title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/aquaguard-ro-service-Kishanganj" element={<BiharAquaguard title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/aquaguard-ro-service-Kaimur" element={<BiharAquaguard title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/aquaguard-ro-service-Katihar" element={<BiharAquaguard title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/aquaguard-ro-service-Lakhisarai" element={<BiharAquaguard title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/aquaguard-ro-service-Madhubani" element={<BiharAquaguard title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/aquaguard-ro-service-Munger" element={<BiharAquaguard title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/aquaguard-ro-service-Madhepura" element={<BiharAquaguard title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/aquaguard-ro-service-Muzaffarpur" element={<BiharAquaguard title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/aquaguard-ro-service-Nalanda" element={<BiharAquaguard title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/aquaguard-ro-service-Nawada" element={<BiharAquaguard title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/aquaguard-ro-service-Patna" element={<BiharAquaguard title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/aquaguard-ro-service-Purnia" element={<BiharAquaguard title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/aquaguard-ro-service-Rohtas" element={<BiharAquaguard title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/aquaguard-ro-service-Saharsa" element={<BiharAquaguard title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/aquaguard-ro-service-Samastipur" element={<BiharAquaguard title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/aquaguard-ro-service-Sasaram" element={<BiharAquaguard title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/aquaguard-ro-service-Sheohar" element={<BiharAquaguard title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/aquaguard-ro-service-Sheikhpura" element={<BiharAquaguard title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/aquaguard-ro-service-Saran" element={<BiharAquaguard title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/aquaguard-ro-service-Sitamarhi" element={<BiharAquaguard title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/aquaguard-ro-service-Supaul" element={<BiharAquaguard title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/aquaguard-ro-service-Siwan" element={<BiharAquaguard title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/aquaguard-ro-service-Vaishali" element={<BiharAquaguard title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/aquaguard-ro-service-West-Champaran" element={<BiharAquaguard title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/aquaguard-ro-service-Garhwa" element={<JharkhandAquaguard title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/aquaguard-ro-service-Palamu" element={<JharkhandAquaguard title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/aquaguard-ro-service-Latehar" element={<JharkhandAquaguard title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/aquaguard-ro-service-Chatra" element={<JharkhandAquaguard title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/aquaguard-ro-service-Hazaribagh" element={<JharkhandAquaguard title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/aquaguard-ro-service-Jamshedpur" element={<JharkhandAquaguard title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/aquaguard-ro-service-Koderma" element={<JharkhandAquaguard title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/aquaguard-ro-service-Giridih" element={<JharkhandAquaguard title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/aquaguard-ro-service-Ramgarh" element={<JharkhandAquaguard title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/aquaguard-ro-service-Bokaro" element={<JharkhandAquaguard title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/aquaguard-ro-service-Dhanbad" element={<JharkhandAquaguard title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/aquaguard-ro-service-Lohardaga" element={<JharkhandAquaguard title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/aquaguard-ro-service-Gumla" element={<JharkhandAquaguard title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/aquaguard-ro-service-Simdega" element={<JharkhandAquaguard title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/aquaguard-ro-service-Ranchi" element={<JharkhandAquaguard title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/aquaguard-ro-service-Khunti" element={<JharkhandAquaguard title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/aquaguard-ro-service-Singhbhum" element={<JharkhandAquaguard title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/aquaguard-ro-service-Saraikela-Kharsawan" element={<JharkhandAquaguard title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/aquaguard-ro-service-Jamtara" element={<JharkhandAquaguard title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/aquaguard-ro-service-Deoghar" element={<JharkhandAquaguard title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/aquaguard-ro-service-Dumka" element={<JharkhandAquaguard title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/aquaguard-ro-service-Pakur" element={<JharkhandAquaguard title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/aquaguard-ro-service-Godda" element={<JharkhandAquaguard title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/aquaguard-ro-service-Sahebganj" element={<JharkhandAquaguard title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />

        <Route path="/aquaguard-ro-service-Alipurduar" element={<BengalAquaguard title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/aquaguard-ro-service-Bankura" element={<BengalAquaguard title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/aquaguard-ro-service-Birbhum" element={<BengalAquaguard title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/aquaguard-ro-service-Cooch-Behar" element={<BengalAquaguard title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/aquaguard-ro-service-Dakshin-Dinajpur" element={<BengalAquaguard title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/aquaguard-ro-service-Darjeeling" element={<BengalAquaguard title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/aquaguard-ro-service-Durgapur" element={<BengalAquaguard title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/aquaguard-ro-service-Hooghly" element={<BengalAquaguard title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/aquaguard-ro-service-Howrah" element={<BengalAquaguard title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/aquaguard-ro-service-Jalpaiguri" element={<BengalAquaguard title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/aquaguard-ro-service-Jhargram" element={<BengalAquaguard title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/aquaguard-ro-service-Kalimpong" element={<BengalAquaguard title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/aquaguard-ro-service-Kolkata" element={<BengalAquaguard title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/aquaguard-ro-service-Malda" element={<BengalAquaguard title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/aquaguard-ro-service-Murshidabad" element={<BengalAquaguard title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/aquaguard-ro-service-Nadia" element={<BengalAquaguard title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/aquaguard-ro-service-North-24-Parganas" element={<BengalAquaguard title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/aquaguard-ro-service-Paschim-Bardhaman" element={<BengalAquaguard title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/aquaguard-ro-service-Paschim-Medinipur" element={<BengalAquaguard title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/aquaguard-ro-service-Purba-Bardhaman" element={<BengalAquaguard title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/aquaguard-ro-service-Purba-Medinipur" element={<BengalAquaguard title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/aquaguard-ro-service-South-24-Parganas" element={<BengalAquaguard title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/aquaguard-ro-service-Uttar-Dinajpur" element={<BengalAquaguard title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />

        <Route path="/aquaguard-ro-service-Agra" element={<UttarPradeshAquaguard title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/aquaguard-ro-service-Aligarh" element={<UttarPradeshAquaguard title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/aquaguard-ro-service-Ambedkar-Nagar" element={<UttarPradeshAquaguard title={locdata.AmbedkarNagar.title} number={locdata.AmbedkarNagar.number} />} />
        <Route path="/aquaguard-ro-service-Ayodhya" element={<UttarPradeshAquaguard title={locdata.Ayodhya.title} number={locdata.Ayodhya.number} />} />
        <Route path="/aquaguard-ro-service-Amethi" element={<UttarPradeshAquaguard title={locdata.Amethi.title} number={locdata.Amethi.number} />} />
        <Route path="/aquaguard-ro-service-Amroha" element={<UttarPradeshAquaguard title={locdata.Amroha.title} number={locdata.Amroha.number} />} />
        <Route path="/aquaguard-ro-service-Auraiya" element={<UttarPradeshAquaguard title={locdata.Auraiya.title} number={locdata.Auraiya.number} />} />
        <Route path="/aquaguard-ro-service-Azamgarh" element={<UttarPradeshAquaguard title={locdata.Azamgarh.title} number={locdata.Azamgarh.number} />} />
        <Route path="/aquaguard-ro-service-Budaun" element={<UttarPradeshAquaguard title={locdata.Budaun.title} number={locdata.Budaun.number} />} />
        <Route path="/aquaguard-ro-service-Bagpat" element={<UttarPradeshAquaguard title={locdata.Bagpat.title} number={locdata.Bagpat.number} />} />
        <Route path="/aquaguard-ro-service-Bahraich" element={<UttarPradeshAquaguard title={locdata.Bahraich.title} number={locdata.Bahraich.number} />} />
        <Route path="/aquaguard-ro-service-Ballia" element={<UttarPradeshAquaguard title={locdata.Ballia.title} number={locdata.Ballia.number} />} />
        <Route path="/aquaguard-ro-service-Balrampur" element={<UttarPradeshAquaguard title={locdata.Balrampur.title} number={locdata.Balrampur.number} />} />
        <Route path="/aquaguard-ro-service-Banda" element={<UttarPradeshAquaguard title={locdata.Banda.title} number={locdata.Banda.number} />} />
        <Route path="/aquaguard-ro-service-Barabanki" element={<UttarPradeshAquaguard title={locdata.Barabanki.title} number={locdata.Barabanki.number} />} />
        <Route path="/aquaguard-ro-service-Bareilly" element={<UttarPradeshAquaguard title={locdata.Bareilly.title} number={locdata.Bareilly.number} />} />
        <Route path="/aquaguard-ro-service-Basti" element={<UttarPradeshAquaguard title={locdata.Basti.title} number={locdata.Basti.number} />} />
        <Route path="/aquaguard-ro-service-Bijnor" element={<UttarPradeshAquaguard title={locdata.Bijnor.title} number={locdata.Bijnor.number} />} />
        <Route path="/aquaguard-ro-service-Bulandshahr" element={<UttarPradeshAquaguard title={locdata.Bulandshahr.title} number={locdata.Bulandshahr.number} />} />
        <Route path="/aquaguard-ro-service-Chandauli" element={<UttarPradeshAquaguard title={locdata.Chandauli.title} number={locdata.Chandauli.number} />} />
        <Route path="/aquaguard-ro-service-Chitrakoot" element={<UttarPradeshAquaguard title={locdata.Chitrakoot.title} number={locdata.Chitrakoot.number} />} />
        <Route path="/aquaguard-ro-service-Deoria" element={<UttarPradeshAquaguard title={locdata.Deoria.title} number={locdata.Deoria.number} />} />
        <Route path="/aquaguard-ro-service-Etah" element={<UttarPradeshAquaguard title={locdata.Etah.title} number={locdata.Etah.number} />} />
        <Route path="/aquaguard-ro-service-Etawah" element={<UttarPradeshAquaguard title={locdata.Etawah.title} number={locdata.Etawah.number} />} />
        <Route path="/aquaguard-ro-service-Farrukhabad" element={<UttarPradeshAquaguard title={locdata.Farrukhabad.title} number={locdata.Farrukhabad.number} />} />
        <Route path="/aquaguard-ro-service-Fatehpur" element={<UttarPradeshAquaguard title={locdata.Fatehpur.title} number={locdata.Fatehpur.number} />} />
        <Route path="/aquaguard-ro-service-Firozabad" element={<UttarPradeshAquaguard title={locdata.Firozabad.title} number={locdata.Firozabad.number} />} />
        <Route path="/aquaguard-ro-service-Gautam-Buddha-Nagar" element={<UttarPradeshAquaguard title={locdata.GautamBuddhaNagar.title} number={locdata.GautamBuddhaNagar.number} />} />
        <Route path="/aquaguard-ro-service-Ghaziabad" element={<UttarPradeshAquaguard title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/aquaguard-ro-service-Ghazipur" element={<UttarPradeshAquaguard title={locdata.Ghazipur.title} number={locdata.Ghazipur.number} />} />
        <Route path="/aquaguard-ro-service-Gonda" element={<UttarPradeshAquaguard title={locdata.Gonda.title} number={locdata.Gonda.number} />} />
        <Route path="/aquaguard-ro-service-Gorakhpur" element={<UttarPradeshAquaguard title={locdata.Gorakhpur.title} number={locdata.Gorakhpur.number} />} />
        <Route path="/aquaguard-ro-service-Hamirpur" element={<UttarPradeshAquaguard title={locdata.Hamirpur.title} number={locdata.Hamirpur.number} />} />
        <Route path="/aquaguard-ro-service-Hapur" element={<UttarPradeshAquaguard title={locdata.Hapur.title} number={locdata.Hapur.number} />} />
        <Route path="/aquaguard-ro-service-Hardoi" element={<UttarPradeshAquaguard title={locdata.Hardoi.title} number={locdata.Hardoi.number} />} />
        <Route path="/aquaguard-ro-service-Hathras" element={<UttarPradeshAquaguard title={locdata.Hathras.title} number={locdata.Hathras.number} />} />
        <Route path="/aquaguard-ro-service-Jalaun" element={<UttarPradeshAquaguard title={locdata.Jalaun.title} number={locdata.Jalaun.number} />} />
        <Route path="/aquaguard-ro-service-Jaunpur" element={<UttarPradeshAquaguard title={locdata.Jaunpur.title} number={locdata.Jaunpur.number} />} />
        <Route path="/aquaguard-ro-service-Jhansi" element={<UttarPradeshAquaguard title={locdata.Jhansi.title} number={locdata.Jhansi.number} />} />
        <Route path="/aquaguard-ro-service-Kannauj" element={<UttarPradeshAquaguard title={locdata.Kannauj.title} number={locdata.Kannauj.number} />} />
        <Route path="/aquaguard-ro-service-Kanpur-Dehat" element={<UttarPradeshAquaguard title={locdata.KanpurDehat.title} number={locdata.KanpurDehat.number} />} />
        <Route path="/aquaguard-ro-service-Kanpur" element={<UttarPradeshAquaguard title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/aquaguard-ro-service-Kasganj" element={<UttarPradeshAquaguard title={locdata.Kasganj.title} number={locdata.Kasganj.number} />} />
        <Route path="/aquaguard-ro-service-Kaushambi" element={<UttarPradeshAquaguard title={locdata.Kaushambi.title} number={locdata.Kaushambi.number} />} />
        <Route path="/aquaguard-ro-service-Kushinagar" element={<UttarPradeshAquaguard title={locdata.Kushinagar.title} number={locdata.Kushinagar.number} />} />
        <Route path="/aquaguard-ro-service-Lakhimpur-Kheri" element={<UttarPradeshAquaguard title={locdata.LakhimpurKheri.title} number={locdata.LakhimpurKheri.number} />} />
        <Route path="/aquaguard-ro-service-Lalitpur" element={<UttarPradeshAquaguard title={locdata.Lalitpur.title} number={locdata.Lalitpur.number} />} />
        <Route path="/aquaguard-ro-service-Lucknow" element={<UttarPradeshAquaguard title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/aquaguard-ro-service-Maharajganj" element={<UttarPradeshAquaguard title={locdata.Maharajganj.title} number={locdata.Maharajganj.number} />} />
        <Route path="/aquaguard-ro-service-Mahoba" element={<UttarPradeshAquaguard title={locdata.Mahoba.title} number={locdata.Mahoba.number} />} />
        <Route path="/aquaguard-ro-service-Mainpuri" element={<UttarPradeshAquaguard title={locdata.Mainpuri.title} number={locdata.Mainpuri.number} />} />
        <Route path="/aquaguard-ro-service-Mathura" element={<UttarPradeshAquaguard title={locdata.Mathura.title} number={locdata.Mathura.number} />} />
        <Route path="/aquaguard-ro-service-Mau" element={<UttarPradeshAquaguard title={locdata.Mau.title} number={locdata.Mau.number} />} />
        <Route path="/aquaguard-ro-service-Meerut" element={<UttarPradeshAquaguard title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/aquaguard-ro-service-Mirzapur" element={<UttarPradeshAquaguard title={locdata.Mirzapur.title} number={locdata.Mirzapur.number} />} />
        <Route path="/aquaguard-ro-service-Moradabad" element={<UttarPradeshAquaguard title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/aquaguard-ro-service-Muzaffarnagar" element={<UttarPradeshAquaguard title={locdata.Muzaffarnagar.title} number={locdata.Muzaffarnagar.number} />} />
        <Route path="/aquaguard-ro-service-Pilibhit" element={<UttarPradeshAquaguard title={locdata.Pilibhit.title} number={locdata.Pilibhit.number} />} />
        <Route path="/aquaguard-ro-service-Pratapgarh" element={<UttarPradeshAquaguard title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/aquaguard-ro-service-Prayagraj" element={<UttarPradeshAquaguard title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/aquaguard-ro-service-Rae-Bareli" element={<UttarPradeshAquaguard title={locdata.RaeBareli.title} number={locdata.RaeBareli.number} />} />
        <Route path="/aquaguard-ro-service-Rampur" element={<UttarPradeshAquaguard title={locdata.Rampur.title} number={locdata.Rampur.number} />} />
        <Route path="/aquaguard-ro-service-Saharanpur" element={<UttarPradeshAquaguard title={locdata.Saharanpur.title} number={locdata.Saharanpur.number} />} />
        <Route path="/aquaguard-ro-service-Sant-Kabir-Nagar" element={<UttarPradeshAquaguard title={locdata.SantKabirNagar.title} number={locdata.SantKabirNagar.number} />} />
        <Route path="/aquaguard-ro-service-Bhadohi" element={<UttarPradeshAquaguard title={locdata.Bhadohi.title} number={locdata.Bhadohi.number} />} />
        <Route path="/aquaguard-ro-service-Sambhal" element={<UttarPradeshAquaguard title={locdata.Sambhal.title} number={locdata.Sambhal.number} />} />
        <Route path="/aquaguard-ro-service-Shahjahanpur" element={<UttarPradeshAquaguard title={locdata.Shahjahanpur.title} number={locdata.Shahjahanpur.number} />} />
        <Route path="/aquaguard-ro-service-Shamli" element={<UttarPradeshAquaguard title={locdata.Shamli.title} number={locdata.Shamli.number} />} />
        <Route path="/aquaguard-ro-service-Shravasti" element={<UttarPradeshAquaguard title={locdata.Shravasti.title} number={locdata.Shravasti.number} />} />
        <Route path="/aquaguard-ro-service-Siddharthnagar" element={<UttarPradeshAquaguard title={locdata.Siddharthnagar.title} number={locdata.Siddharthnagar.number} />} />
        <Route path="/aquaguard-ro-service-Sitapur" element={<UttarPradeshAquaguard title={locdata.Sitapur.title} number={locdata.Sitapur.number} />} />
        <Route path="/aquaguard-ro-service-Sonbhadra" element={<UttarPradeshAquaguard title={locdata.Sonbhadra.title} number={locdata.Sonbhadra.number} />} />
        <Route path="/aquaguard-ro-service-Sultanpur" element={<UttarPradeshAquaguard title={locdata.Sultanpur.title} number={locdata.Sultanpur.number} />} />
        <Route path="/aquaguard-ro-service-Unnao" element={<UttarPradeshAquaguard title={locdata.Unnao.title} number={locdata.Unnao.number} />} />
        <Route path="/aquaguard-ro-service-Varanasi" element={<UttarPradeshAquaguard title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/aquaguard-ro-service-Robertsganj" element={<UttarPradeshAquaguard title={locdata.Robertsganj.title} number={locdata.Robertsganj.number} />} />
        <Route path="/aquaguard-ro-service-Bahjoi" element={<UttarPradeshAquaguard title={locdata.Bahjoi.title} number={locdata.Bahjoi.number} />} />
        <Route path="/aquaguard-ro-service-Khalilabad" element={<UttarPradeshAquaguard title={locdata.Khalilabad.title} number={locdata.Khalilabad.number} />} />
        <Route path="/aquaguard-ro-service-Akbarpur" element={<UttarPradeshAquaguard title={locdata.Akbarpur.title} number={locdata.Akbarpur.number} />} />
        <Route path="/aquaguard-ro-service-Orai" element={<UttarPradeshAquaguard title={locdata.Orai.title} number={locdata.Orai.number} />} />
        <Route path="/aquaguard-ro-service-Manjhanpur" element={<UttarPradeshAquaguard title={locdata.Manjhanpur.title} number={locdata.Manjhanpur.number} />} />
        <Route path="/aquaguard-ro-service-Padarauna" element={<UttarPradeshAquaguard title={locdata.Padarauna.title} number={locdata.Padarauna.number} />} />
        <Route path="/aquaguard-ro-service-Kheri" element={<UttarPradeshAquaguard title={locdata.Kheri.title} number={locdata.Kheri.number} />} />
        <Route path="/aquaguard-ro-service-Fatehgarh" element={<UttarPradeshAquaguard title={locdata.Fatehgarh.title} number={locdata.Fatehgarh.number} />} />
        <Route path="/aquaguard-ro-service-Noida" element={<UttarPradeshAquaguard title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/aquaguard-ro-service-Greater-Noida" element={<UttarPradeshAquaguard title={locdata.GreaterNoida.title} number={locdata.GreaterNoida.number} />} />

        <Route path="/aquaguard-ro-service-Indore" element={<MadhyaPradeshAquaguard title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/aquaguard-ro-service-Bhopal" element={<MadhyaPradeshAquaguard title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/aquaguard-ro-service-Jabalpur" element={<MadhyaPradeshAquaguard title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/aquaguard-ro-service-Gwalior" element={<MadhyaPradeshAquaguard title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/aquaguard-ro-service-Ujjain" element={<MadhyaPradeshAquaguard title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/aquaguard-ro-service-Sagar" element={<MadhyaPradeshAquaguard title={locdata.Sagar.title} number={locdata.Sagar.number} />} />
        <Route path="/aquaguard-ro-service-Dewas" element={<MadhyaPradeshAquaguard title={locdata.Dewas.title} number={locdata.Dewas.number} />} />
        <Route path="/aquaguard-ro-service-Satna" element={<MadhyaPradeshAquaguard title={locdata.Satna.title} number={locdata.Satna.number} />} />
        <Route path="/aquaguard-ro-service-Ratlam" element={<MadhyaPradeshAquaguard title={locdata.Ratlam.title} number={locdata.Ratlam.number} />} />
        <Route path="/aquaguard-ro-service-Rewa" element={<MadhyaPradeshAquaguard title={locdata.Rewa.title} number={locdata.Rewa.number} />} />
        <Route path="/aquaguard-ro-service-Katni" element={<MadhyaPradeshAquaguard title={locdata.Katni.title} number={locdata.Katni.number} />} />
        <Route path="/aquaguard-ro-service-Singrauli" element={<MadhyaPradeshAquaguard title={locdata.Singrauli.title} number={locdata.Singrauli.number} />} />
        <Route path="/aquaguard-ro-service-Burhanpur" element={<MadhyaPradeshAquaguard title={locdata.Burhanpur.title} number={locdata.Burhanpur.number} />} />
        <Route path="/aquaguard-ro-service-Morena" element={<MadhyaPradeshAquaguard title={locdata.Morena.title} number={locdata.Morena.number} />} />
        <Route path="/aquaguard-ro-service-Khandwa" element={<MadhyaPradeshAquaguard title={locdata.Khandwa.title} number={locdata.Khandwa.number} />} />
        <Route path="/aquaguard-ro-service-Bhind" element={<MadhyaPradeshAquaguard title={locdata.Bhind.title} number={locdata.Bhind.number} />} />
        <Route path="/aquaguard-ro-service-Chhindwara" element={<MadhyaPradeshAquaguard title={locdata.Chhindwara.title} number={locdata.Chhindwara.number} />} />
        <Route path="/aquaguard-ro-service-Guna" element={<MadhyaPradeshAquaguard title={locdata.Guna.title} number={locdata.Guna.number} />} />
        <Route path="/aquaguard-ro-service-Shivpuri" element={<MadhyaPradeshAquaguard title={locdata.Shivpuri.title} number={locdata.Shivpuri.number} />} />
        <Route path="/aquaguard-ro-service-Vidisha" element={<MadhyaPradeshAquaguard title={locdata.Vidisha.title} number={locdata.Vidisha.number} />} />
        <Route path="/aquaguard-ro-service-Chhatarpur" element={<MadhyaPradeshAquaguard title={locdata.Chhatarpur.title} number={locdata.Chhatarpur.number} />} />
        <Route path="/aquaguard-ro-service-Damoh" element={<MadhyaPradeshAquaguard title={locdata.Damoh.title} number={locdata.Damoh.number} />} />
        <Route path="/aquaguard-ro-service-Mandsaur" element={<MadhyaPradeshAquaguard title={locdata.Mandsaur.title} number={locdata.Mandsaur.number} />} />
        <Route path="/aquaguard-ro-service-Khargone" element={<MadhyaPradeshAquaguard title={locdata.Khargone.title} number={locdata.Khargone.number} />} />
        <Route path="/aquaguard-ro-service-Neemuch" element={<MadhyaPradeshAquaguard title={locdata.Neemuch.title} number={locdata.Neemuch.number} />} />
        <Route path="/aquaguard-ro-service-Pithampur" element={<MadhyaPradeshAquaguard title={locdata.Pithampur.title} number={locdata.Pithampur.number} />} />
        <Route path="/aquaguard-ro-service-Narmadapuram" element={<MadhyaPradeshAquaguard title={locdata.Narmadapuram.title} number={locdata.Narmadapuram.number} />} />
        <Route path="/aquaguard-ro-service-Itarsi" element={<MadhyaPradeshAquaguard title={locdata.Itarsi.title} number={locdata.Itarsi.number} />} />
        <Route path="/aquaguard-ro-service-Sehore" element={<MadhyaPradeshAquaguard title={locdata.Sehore.title} number={locdata.Sehore.number} />} />
        <Route path="/aquaguard-ro-service-Betul" element={<MadhyaPradeshAquaguard title={locdata.Betul.title} number={locdata.Betul.number} />} />
        <Route path="/aquaguard-ro-service-Seoni" element={<MadhyaPradeshAquaguard title={locdata.Seoni.title} number={locdata.Seoni.number} />} />
        <Route path="/aquaguard-ro-service-Datia" element={<MadhyaPradeshAquaguard title={locdata.Datia.title} number={locdata.Datia.number} />} />
        <Route path="/aquaguard-ro-service-Nagda" element={<MadhyaPradeshAquaguard title={locdata.Nagda.title} number={locdata.Nagda.number} />} />
        <Route path="/aquaguard-ro-service-Shahdol" element={<MadhyaPradeshAquaguard title={locdata.Shahdol.title} number={locdata.Shahdol.number} />} />
        <Route path="/aquaguard-ro-service-Dhar" element={<MadhyaPradeshAquaguard title={locdata.Dhar.title} number={locdata.Dhar.number} />} />

        <Route path="/aquaguard-ro-service-Ajmer" element={<RajasthanAquaguard title={locdata.Ajmer.title} number={locdata.Ajmer.number} />} />
        <Route path="/aquaguard-ro-service-Alwar" element={<RajasthanAquaguard title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/aquaguard-ro-service-Balotra" element={<RajasthanAquaguard title={locdata.Balotra.title} number={locdata.Balotra.number} />} />
        <Route path="/aquaguard-ro-service-Banswara" element={<RajasthanAquaguard title={locdata.Banswara.title} number={locdata.Banswara.number} />} />
        <Route path="/aquaguard-ro-service-Baran" element={<RajasthanAquaguard title={locdata.Baran.title} number={locdata.Baran.number} />} />
        <Route path="/aquaguard-ro-service-Barmer" element={<RajasthanAquaguard title={locdata.Barmer.title} number={locdata.Barmer.number} />} />
        <Route path="/aquaguard-ro-service-Beawar" element={<RajasthanAquaguard title={locdata.Beawar.title} number={locdata.Beawar.number} />} />
        <Route path="/aquaguard-ro-service-Bharatpur" element={<RajasthanAquaguard title={locdata.Bharatpur.title} number={locdata.Bharatpur.number} />} />
        <Route path="/aquaguard-ro-service-Bhilwara" element={<RajasthanAquaguard title={locdata.Bhilwara.title} number={locdata.Bhilwara.number} />} />
        <Route path="/aquaguard-ro-service-Bikaner" element={<RajasthanAquaguard title={locdata.Bikaner.title} number={locdata.Bikaner.number} />} />
        <Route path="/aquaguard-ro-service-Bundi" element={<RajasthanAquaguard title={locdata.Bundi.title} number={locdata.Bundi.number} />} />
        <Route path="/aquaguard-ro-service-Chittorgarh" element={<RajasthanAquaguard title={locdata.Chittorgarh.title} number={locdata.Chittorgarh.number} />} />
        <Route path="/aquaguard-ro-service-Churu" element={<RajasthanAquaguard title={locdata.Churu.title} number={locdata.Churu.number} />} />
        <Route path="/aquaguard-ro-service-Dausa" element={<RajasthanAquaguard title={locdata.Dausa.title} number={locdata.Dausa.number} />} />
        <Route path="/aquaguard-ro-service-Deeg" element={<RajasthanAquaguard title={locdata.Deeg.title} number={locdata.Deeg.number} />} />
        <Route path="/aquaguard-ro-service-Deedwana" element={<RajasthanAquaguard title={locdata.Deedwana.title} number={locdata.Deedwana.number} />} />
        <Route path="/aquaguard-ro-service-Dholpur" element={<RajasthanAquaguard title={locdata.Dholpur.title} number={locdata.Dholpur.number} />} />
        <Route path="/aquaguard-ro-service-Dungarpur" element={<RajasthanAquaguard title={locdata.Dungarpur.title} number={locdata.Dungarpur.number} />} />
        <Route path="/aquaguard-ro-service-Hanumangarh" element={<RajasthanAquaguard title={locdata.Hanumangarh.title} number={locdata.Hanumangarh.number} />} />
        <Route path="/aquaguard-ro-service-Jaipur" element={<RajasthanAquaguard title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/aquaguard-ro-service-Jaisalmer" element={<RajasthanAquaguard title={locdata.Jaisalmer.title} number={locdata.Jaisalmer.number} />} />
        <Route path="/aquaguard-ro-service-Jalore" element={<RajasthanAquaguard title={locdata.Jalore.title} number={locdata.Jalore.number} />} />
        <Route path="/aquaguard-ro-service-Jhalawar" element={<RajasthanAquaguard title={locdata.Jhalawar.title} number={locdata.Jhalawar.number} />} />
        <Route path="/aquaguard-ro-service-Jhunjhunu" element={<RajasthanAquaguard title={locdata.Jhunjhunu.title} number={locdata.Jhunjhunu.number} />} />
        <Route path="/aquaguard-ro-service-Jodhpur" element={<RajasthanAquaguard title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/aquaguard-ro-service-Karauli" element={<RajasthanAquaguard title={locdata.Karauli.title} number={locdata.Karauli.number} />} />
        <Route path="/aquaguard-ro-service-Tijara" element={<RajasthanAquaguard title={locdata.Tijara.title} number={locdata.Tijara.number} />} />
        <Route path="/aquaguard-ro-service-Kotputli" element={<RajasthanAquaguard title={locdata.Kotputli.title} number={locdata.Kotputli.number} />} />
        <Route path="/aquaguard-ro-service-Kota" element={<RajasthanAquaguard title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/aquaguard-ro-service-Nagaur" element={<RajasthanAquaguard title={locdata.Nagaur.title} number={locdata.Nagaur.number} />} />
        <Route path="/aquaguard-ro-service-Pali" element={<RajasthanAquaguard title={locdata.Pali.title} number={locdata.Pali.number} />} />
        <Route path="/aquaguard-ro-service-Phalodi" element={<RajasthanAquaguard title={locdata.Phalodi.title} number={locdata.Phalodi.number} />} />
        <Route path="/aquaguard-ro-service-Pratapgarh" element={<RajasthanAquaguard title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/aquaguard-ro-service-Rajsamand" element={<RajasthanAquaguard title={locdata.Rajsamand.title} number={locdata.Rajsamand.number} />} />
        <Route path="/aquaguard-ro-service-Salumbar" element={<RajasthanAquaguard title={locdata.Salumbar.title} number={locdata.Salumbar.number} />} />
        <Route path="/aquaguard-ro-service-Sawai-Madhopur" element={<RajasthanAquaguard title={locdata.SawaiMadhopur.title} number={locdata.SawaiMadhopur.number} />} />
        <Route path="/aquaguard-ro-service-Sikar" element={<RajasthanAquaguard title={locdata.Sikar.title} number={locdata.Sikar.number} />} />
        <Route path="/aquaguard-ro-service-Sirohi" element={<RajasthanAquaguard title={locdata.Sirohi.title} number={locdata.Sirohi.number} />} />
        <Route path="/aquaguard-ro-service-Sri-Ganganagar" element={<RajasthanAquaguard title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/aquaguard-ro-service-Tonk" element={<RajasthanAquaguard title={locdata.Tonk.title} number={locdata.Tonk.number} />} />
        <Route path="/aquaguard-ro-service-Udaipur" element={<RajasthanAquaguard title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />

        <Route path="/aquaguard-ro-service-Mumbai" element={<Aquaguardro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/aquaguard-ro-service-Delhi" element={<Aquaguardro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/aquaguard-ro-service-Bengaluru" element={<Aquaguardro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/aquaguard-ro-service-Hyderabad" element={<Aquaguardro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/aquaguard-ro-service-Ahmedabad" element={<Aquaguardro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/aquaguard-ro-service-Chennai" element={<Aquaguardro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/aquaguard-ro-service-Surat" element={<Aquaguardro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/aquaguard-ro-service-Pune" element={<Aquaguardro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/aquaguard-ro-service-Jaipur" element={<Aquaguardro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/aquaguard-ro-service-Lucknow" element={<Aquaguardro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/aquaguard-ro-service-Kanpur" element={<Aquaguardro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/aquaguard-ro-service-Nagpur" element={<Aquaguardro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/aquaguard-ro-service-Indore" element={<Aquaguardro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/aquaguard-ro-service-Thane" element={<Aquaguardro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/aquaguard-ro-service-Bhopal" element={<Aquaguardro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/aquaguard-ro-service-Visakhapatnam" element={<Aquaguardro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/aquaguard-ro-service-Vadodara" element={<Aquaguardro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/aquaguard-ro-service-Ghaziabad" element={<Aquaguardro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/aquaguard-ro-service-Ludhiana" element={<Aquaguardro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/aquaguard-ro-service-Agra" element={<Aquaguardro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/aquaguard-ro-service-Nashik" element={<Aquaguardro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/aquaguard-ro-service-Faridabad" element={<Aquaguardro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/aquaguard-ro-service-Meerut" element={<Aquaguardro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/aquaguard-ro-service-Rajkot" element={<Aquaguardro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/aquaguard-ro-service-Varanasi" element={<Aquaguardro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/aquaguard-ro-service-Srinagar" element={<Aquaguardro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/aquaguard-ro-service-Amritsar" element={<Aquaguardro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/aquaguard-ro-service-Navi-Mumbai" element={<Aquaguardro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/aquaguard-ro-service-Prayagraj" element={<Aquaguardro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/aquaguard-ro-service-Jabalpur" element={<Aquaguardro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/aquaguard-ro-service-Gwalior" element={<Aquaguardro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/aquaguard-ro-service-Coimbatore" element={<Aquaguardro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/aquaguard-ro-service-Vijayawada" element={<Aquaguardro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/aquaguard-ro-service-Jodhpur" element={<Aquaguardro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/aquaguard-ro-service-Madurai" element={<Aquaguardro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/aquaguard-ro-service-Raipur" element={<Aquaguardro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/aquaguard-ro-service-Kota" element={<Aquaguardro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/aquaguard-ro-service-Guwahati" element={<Aquaguardro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/aquaguard-ro-service-Chandigarh" element={<Aquaguardro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/aquaguard-ro-service-Thiruvananthapuram" element={<Aquaguardro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/aquaguard-ro-service-Solapur" element={<Aquaguardro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/aquaguard-ro-service-Tiruppur" element={<Aquaguardro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/aquaguard-ro-service-Moradabad" element={<Aquaguardro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/aquaguard-ro-service-Mysore" element={<Aquaguardro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/aquaguard-ro-service-Gurgaon" element={<Aquaguardro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/aquaguard-ro-service-Aligarh" element={<Aquaguardro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/aquaguard-ro-service-Jalandhar" element={<Aquaguardro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/aquaguard-ro-service-Bhubaneswar" element={<Aquaguardro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/aquaguard-ro-service-Noida" element={<Aquaguardro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/aquaguard-ro-service-Jamshedpur" element={<Aquaguardro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/aquaguard-ro-service-Cuttack" element={<Aquaguardro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/aquaguard-ro-service-Bhavnagar" element={<Aquaguardro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/aquaguard-ro-service-Dehradun" element={<Aquaguardro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/aquaguard-ro-service-Rourkela" element={<Aquaguardro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/aquaguard-ro-service-Jamnagar" element={<Aquaguardro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/aquaguard-ro-service-Ujjain" element={<Aquaguardro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/aquaguard-ro-service-Jammu" element={<Aquaguardro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/aquaguard-ro-service-Mangalore" element={<Aquaguardro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/aquaguard-ro-service-Erode" element={<Aquaguardro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/aquaguard-ro-service-Udaipur" element={<Aquaguardro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/aquaguard-ro-service-Rajahmundry" element={<Aquaguardro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/aquaguard-ro-service-Patiala" element={<Aquaguardro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/aquaguard-ro-service-Agartala" element={<Aquaguardro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/aquaguard-ro-service-Tirupati" element={<Aquaguardro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/aquaguard-ro-service-Sambalpur" element={<Aquaguardro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/aquaguard-ro-service-Bilaspur" element={<Aquaguardro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/aquaguard-ro-service-Alwar" element={<Aquaguardro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/aquaguard-ro-service-Bardhaman" element={<Aquaguardro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/aquaguard-ro-service-Sonipat" element={<Aquaguardro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/aquaguard-ro-service-New-Delhi" element={<Aquaguardro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/aquaguard-ro-service-Puducherry" element={<Aquaguardro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/aquaguard-ro-service-Haridwar" element={<Aquaguardro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/aquaguard-ro-service-Vijayanagaram" element={<Aquaguardro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/aquaguard-ro-service-Sri-Ganganagar" element={<Aquaguardro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/aquaguard-ro-service-Secunderabad" element={<Aquaguardro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/aquaguard-ro-service-Yamunanagar" element={<Aquaguardro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/aquaguard-ro-service-Gandhinagar" element={<Aquaguardro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/aquaguard-ro-service-Ambala" element={<Aquaguardro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/aquaguard-ro-service-Vellore" element={<Aquaguardro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/aquaguard-ro-service-Shimla" element={<Aquaguardro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/aquaguard-ro-service-Amaravati" element={<Aquaguardro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />


        <Route path="/bluestar-ro-service-Araria" element={<BiharBluestar title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/bluestar-ro-service-Arwal" element={<BiharBluestar title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/bluestar-ro-service-Aurangabad" element={<BiharBluestar title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/bluestar-ro-service-Banka" element={<BiharBluestar title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/bluestar-ro-service-Begusarai" element={<BiharBluestar title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/bluestar-ro-service-Bhabua" element={<BiharBluestar title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/bluestar-ro-service-Bhagalpur" element={<BiharBluestar title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/bluestar-ro-service-Bhojpur" element={<BiharBluestar title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/bluestar-ro-service-Bihar-Sharif" element={<BiharBluestar title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/bluestar-ro-service-Buxar" element={<BiharBluestar title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/bluestar-ro-service-Chhapra" element={<BiharBluestar title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/bluestar-ro-service-Darbhanga" element={<BiharBluestar title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/bluestar-ro-service-East-Champaran" element={<BiharBluestar title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/bluestar-ro-service-Gaya" element={<BiharBluestar title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/bluestar-ro-service-Gopalganj" element={<BiharBluestar title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/bluestar-ro-service-Jamui" element={<BiharBluestar title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/bluestar-ro-service-Jehanabad" element={<BiharBluestar title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/bluestar-ro-service-Khagaria" element={<BiharBluestar title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/bluestar-ro-service-Kishanganj" element={<BiharBluestar title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/bluestar-ro-service-Kaimur" element={<BiharBluestar title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/bluestar-ro-service-Katihar" element={<BiharBluestar title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/bluestar-ro-service-Lakhisarai" element={<BiharBluestar title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/bluestar-ro-service-Madhubani" element={<BiharBluestar title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/bluestar-ro-service-Munger" element={<BiharBluestar title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/bluestar-ro-service-Madhepura" element={<BiharBluestar title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/bluestar-ro-service-Muzaffarpur" element={<BiharBluestar title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/bluestar-ro-service-Nalanda" element={<BiharBluestar title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/bluestar-ro-service-Nawada" element={<BiharBluestar title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/bluestar-ro-service-Patna" element={<BiharBluestar title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/bluestar-ro-service-Purnia" element={<BiharBluestar title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/bluestar-ro-service-Rohtas" element={<BiharBluestar title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/bluestar-ro-service-Saharsa" element={<BiharBluestar title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/bluestar-ro-service-Samastipur" element={<BiharBluestar title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/bluestar-ro-service-Sasaram" element={<BiharBluestar title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/bluestar-ro-service-Sheohar" element={<BiharBluestar title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/bluestar-ro-service-Sheikhpura" element={<BiharBluestar title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/bluestar-ro-service-Saran" element={<BiharBluestar title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/bluestar-ro-service-Sitamarhi" element={<BiharBluestar title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/bluestar-ro-service-Supaul" element={<BiharBluestar title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/bluestar-ro-service-Siwan" element={<BiharBluestar title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/bluestar-ro-service-Vaishali" element={<BiharBluestar title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/bluestar-ro-service-West-Champaran" element={<BiharBluestar title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/bluestar-ro-service-Garhwa" element={<JharkhandBluestar title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/bluestar-ro-service-Palamu" element={<JharkhandBluestar title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/bluestar-ro-service-Latehar" element={<JharkhandBluestar title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/bluestar-ro-service-Chatra" element={<JharkhandBluestar title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/bluestar-ro-service-Hazaribagh" element={<JharkhandBluestar title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/bluestar-ro-service-Jamshedpur" element={<JharkhandBluestar title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/bluestar-ro-service-Koderma" element={<JharkhandBluestar title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/bluestar-ro-service-Giridih" element={<JharkhandBluestar title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/bluestar-ro-service-Ramgarh" element={<JharkhandBluestar title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/bluestar-ro-service-Bokaro" element={<JharkhandBluestar title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/bluestar-ro-service-Dhanbad" element={<JharkhandBluestar title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/bluestar-ro-service-Lohardaga" element={<JharkhandBluestar title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/bluestar-ro-service-Gumla" element={<JharkhandBluestar title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/bluestar-ro-service-Simdega" element={<JharkhandBluestar title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/bluestar-ro-service-Ranchi" element={<JharkhandBluestar title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/bluestar-ro-service-Khunti" element={<JharkhandBluestar title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/bluestar-ro-service-Singhbhum" element={<JharkhandBluestar title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/bluestar-ro-service-Saraikela-Kharsawan" element={<JharkhandBluestar title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/bluestar-ro-service-Jamtara" element={<JharkhandBluestar title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/bluestar-ro-service-Deoghar" element={<JharkhandBluestar title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/bluestar-ro-service-Dumka" element={<JharkhandBluestar title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/bluestar-ro-service-Pakur" element={<JharkhandBluestar title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/bluestar-ro-service-Godda" element={<JharkhandBluestar title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/bluestar-ro-service-Sahebganj" element={<JharkhandBluestar title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />

        <Route path="/bluestar-ro-service-Alipurduar" element={<BengalBluestar title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/bluestar-ro-service-Bankura" element={<BengalBluestar title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/bluestar-ro-service-Birbhum" element={<BengalBluestar title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/bluestar-ro-service-Cooch-Behar" element={<BengalBluestar title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/bluestar-ro-service-Dakshin-Dinajpur" element={<BengalBluestar title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/bluestar-ro-service-Darjeeling" element={<BengalBluestar title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/bluestar-ro-service-Durgapur" element={<BengalBluestar title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/bluestar-ro-service-Hooghly" element={<BengalBluestar title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/bluestar-ro-service-Howrah" element={<BengalBluestar title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/bluestar-ro-service-Jalpaiguri" element={<BengalBluestar title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/bluestar-ro-service-Jhargram" element={<BengalBluestar title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/bluestar-ro-service-Kalimpong" element={<BengalBluestar title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/bluestar-ro-service-Kolkata" element={<BengalBluestar title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/bluestar-ro-service-Malda" element={<BengalBluestar title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/bluestar-ro-service-Murshidabad" element={<BengalBluestar title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/bluestar-ro-service-Nadia" element={<BengalBluestar title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/bluestar-ro-service-North-24-Parganas" element={<BengalBluestar title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/bluestar-ro-service-Paschim-Bardhaman" element={<BengalBluestar title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/bluestar-ro-service-Paschim-Medinipur" element={<BengalBluestar title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/bluestar-ro-service-Purba-Bardhaman" element={<BengalBluestar title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/bluestar-ro-service-Purba-Medinipur" element={<BengalBluestar title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/bluestar-ro-service-South-24-Parganas" element={<BengalBluestar title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/bluestar-ro-service-Uttar-Dinajpur" element={<BengalBluestar title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />
        <Route path="/bluestar-ro-service-Agra" element={<UttarPradeshBluestar title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/bluestar-ro-service-Aligarh" element={<UttarPradeshBluestar title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/bluestar-ro-service-Ambedkar-Nagar" element={<UttarPradeshBluestar title={locdata.AmbedkarNagar.title} number={locdata.AmbedkarNagar.number} />} />
        <Route path="/bluestar-ro-service-Ayodhya" element={<UttarPradeshBluestar title={locdata.Ayodhya.title} number={locdata.Ayodhya.number} />} />
        <Route path="/bluestar-ro-service-Amethi" element={<UttarPradeshBluestar title={locdata.Amethi.title} number={locdata.Amethi.number} />} />
        <Route path="/bluestar-ro-service-Amroha" element={<UttarPradeshBluestar title={locdata.Amroha.title} number={locdata.Amroha.number} />} />
        <Route path="/bluestar-ro-service-Auraiya" element={<UttarPradeshBluestar title={locdata.Auraiya.title} number={locdata.Auraiya.number} />} />
        <Route path="/bluestar-ro-service-Azamgarh" element={<UttarPradeshBluestar title={locdata.Azamgarh.title} number={locdata.Azamgarh.number} />} />
        <Route path="/bluestar-ro-service-Budaun" element={<UttarPradeshBluestar title={locdata.Budaun.title} number={locdata.Budaun.number} />} />
        <Route path="/bluestar-ro-service-Bagpat" element={<UttarPradeshBluestar title={locdata.Bagpat.title} number={locdata.Bagpat.number} />} />
        <Route path="/bluestar-ro-service-Bahraich" element={<UttarPradeshBluestar title={locdata.Bahraich.title} number={locdata.Bahraich.number} />} />
        <Route path="/bluestar-ro-service-Ballia" element={<UttarPradeshBluestar title={locdata.Ballia.title} number={locdata.Ballia.number} />} />
        <Route path="/bluestar-ro-service-Balrampur" element={<UttarPradeshBluestar title={locdata.Balrampur.title} number={locdata.Balrampur.number} />} />
        <Route path="/bluestar-ro-service-Banda" element={<UttarPradeshBluestar title={locdata.Banda.title} number={locdata.Banda.number} />} />
        <Route path="/bluestar-ro-service-Barabanki" element={<UttarPradeshBluestar title={locdata.Barabanki.title} number={locdata.Barabanki.number} />} />
        <Route path="/bluestar-ro-service-Bareilly" element={<UttarPradeshBluestar title={locdata.Bareilly.title} number={locdata.Bareilly.number} />} />
        <Route path="/bluestar-ro-service-Basti" element={<UttarPradeshBluestar title={locdata.Basti.title} number={locdata.Basti.number} />} />
        <Route path="/bluestar-ro-service-Bijnor" element={<UttarPradeshBluestar title={locdata.Bijnor.title} number={locdata.Bijnor.number} />} />
        <Route path="/bluestar-ro-service-Bulandshahr" element={<UttarPradeshBluestar title={locdata.Bulandshahr.title} number={locdata.Bulandshahr.number} />} />
        <Route path="/bluestar-ro-service-Chandauli" element={<UttarPradeshBluestar title={locdata.Chandauli.title} number={locdata.Chandauli.number} />} />
        <Route path="/bluestar-ro-service-Chitrakoot" element={<UttarPradeshBluestar title={locdata.Chitrakoot.title} number={locdata.Chitrakoot.number} />} />
        <Route path="/bluestar-ro-service-Deoria" element={<UttarPradeshBluestar title={locdata.Deoria.title} number={locdata.Deoria.number} />} />
        <Route path="/bluestar-ro-service-Etah" element={<UttarPradeshBluestar title={locdata.Etah.title} number={locdata.Etah.number} />} />
        <Route path="/bluestar-ro-service-Etawah" element={<UttarPradeshBluestar title={locdata.Etawah.title} number={locdata.Etawah.number} />} />
        <Route path="/bluestar-ro-service-Farrukhabad" element={<UttarPradeshBluestar title={locdata.Farrukhabad.title} number={locdata.Farrukhabad.number} />} />
        <Route path="/bluestar-ro-service-Fatehpur" element={<UttarPradeshBluestar title={locdata.Fatehpur.title} number={locdata.Fatehpur.number} />} />
        <Route path="/bluestar-ro-service-Firozabad" element={<UttarPradeshBluestar title={locdata.Firozabad.title} number={locdata.Firozabad.number} />} />
        <Route path="/bluestar-ro-service-Gautam-Buddha-Nagar" element={<UttarPradeshBluestar title={locdata.GautamBuddhaNagar.title} number={locdata.GautamBuddhaNagar.number} />} />
        <Route path="/bluestar-ro-service-Ghaziabad" element={<UttarPradeshBluestar title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/bluestar-ro-service-Ghazipur" element={<UttarPradeshBluestar title={locdata.Ghazipur.title} number={locdata.Ghazipur.number} />} />
        <Route path="/bluestar-ro-service-Gonda" element={<UttarPradeshBluestar title={locdata.Gonda.title} number={locdata.Gonda.number} />} />
        <Route path="/bluestar-ro-service-Gorakhpur" element={<UttarPradeshBluestar title={locdata.Gorakhpur.title} number={locdata.Gorakhpur.number} />} />
        <Route path="/bluestar-ro-service-Hamirpur" element={<UttarPradeshBluestar title={locdata.Hamirpur.title} number={locdata.Hamirpur.number} />} />
        <Route path="/bluestar-ro-service-Hapur" element={<UttarPradeshBluestar title={locdata.Hapur.title} number={locdata.Hapur.number} />} />
        <Route path="/bluestar-ro-service-Hardoi" element={<UttarPradeshBluestar title={locdata.Hardoi.title} number={locdata.Hardoi.number} />} />
        <Route path="/bluestar-ro-service-Hathras" element={<UttarPradeshBluestar title={locdata.Hathras.title} number={locdata.Hathras.number} />} />
        <Route path="/bluestar-ro-service-Jalaun" element={<UttarPradeshBluestar title={locdata.Jalaun.title} number={locdata.Jalaun.number} />} />
        <Route path="/bluestar-ro-service-Jaunpur" element={<UttarPradeshBluestar title={locdata.Jaunpur.title} number={locdata.Jaunpur.number} />} />
        <Route path="/bluestar-ro-service-Jhansi" element={<UttarPradeshBluestar title={locdata.Jhansi.title} number={locdata.Jhansi.number} />} />
        <Route path="/bluestar-ro-service-Kannauj" element={<UttarPradeshBluestar title={locdata.Kannauj.title} number={locdata.Kannauj.number} />} />
        <Route path="/bluestar-ro-service-Kanpur-Dehat" element={<UttarPradeshBluestar title={locdata.KanpurDehat.title} number={locdata.KanpurDehat.number} />} />
        <Route path="/bluestar-ro-service-Kanpur" element={<UttarPradeshBluestar title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/bluestar-ro-service-Kasganj" element={<UttarPradeshBluestar title={locdata.Kasganj.title} number={locdata.Kasganj.number} />} />
        <Route path="/bluestar-ro-service-Kaushambi" element={<UttarPradeshBluestar title={locdata.Kaushambi.title} number={locdata.Kaushambi.number} />} />
        <Route path="/bluestar-ro-service-Kushinagar" element={<UttarPradeshBluestar title={locdata.Kushinagar.title} number={locdata.Kushinagar.number} />} />
        <Route path="/bluestar-ro-service-Lakhimpur-Kheri" element={<UttarPradeshBluestar title={locdata.LakhimpurKheri.title} number={locdata.LakhimpurKheri.number} />} />
        <Route path="/bluestar-ro-service-Lalitpur" element={<UttarPradeshBluestar title={locdata.Lalitpur.title} number={locdata.Lalitpur.number} />} />
        <Route path="/bluestar-ro-service-Lucknow" element={<UttarPradeshBluestar title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/bluestar-ro-service-Maharajganj" element={<UttarPradeshBluestar title={locdata.Maharajganj.title} number={locdata.Maharajganj.number} />} />
        <Route path="/bluestar-ro-service-Mahoba" element={<UttarPradeshBluestar title={locdata.Mahoba.title} number={locdata.Mahoba.number} />} />
        <Route path="/bluestar-ro-service-Mainpuri" element={<UttarPradeshBluestar title={locdata.Mainpuri.title} number={locdata.Mainpuri.number} />} />
        <Route path="/bluestar-ro-service-Mathura" element={<UttarPradeshBluestar title={locdata.Mathura.title} number={locdata.Mathura.number} />} />
        <Route path="/bluestar-ro-service-Mau" element={<UttarPradeshBluestar title={locdata.Mau.title} number={locdata.Mau.number} />} />
        <Route path="/bluestar-ro-service-Meerut" element={<UttarPradeshBluestar title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/bluestar-ro-service-Mirzapur" element={<UttarPradeshBluestar title={locdata.Mirzapur.title} number={locdata.Mirzapur.number} />} />
        <Route path="/bluestar-ro-service-Moradabad" element={<UttarPradeshBluestar title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/bluestar-ro-service-Muzaffarnagar" element={<UttarPradeshBluestar title={locdata.Muzaffarnagar.title} number={locdata.Muzaffarnagar.number} />} />
        <Route path="/bluestar-ro-service-Pilibhit" element={<UttarPradeshBluestar title={locdata.Pilibhit.title} number={locdata.Pilibhit.number} />} />
        <Route path="/bluestar-ro-service-Pratapgarh" element={<UttarPradeshBluestar title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/bluestar-ro-service-Prayagraj" element={<UttarPradeshBluestar title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/bluestar-ro-service-Rae-Bareli" element={<UttarPradeshBluestar title={locdata.RaeBareli.title} number={locdata.RaeBareli.number} />} />
        <Route path="/bluestar-ro-service-Rampur" element={<UttarPradeshBluestar title={locdata.Rampur.title} number={locdata.Rampur.number} />} />
        <Route path="/bluestar-ro-service-Saharanpur" element={<UttarPradeshBluestar title={locdata.Saharanpur.title} number={locdata.Saharanpur.number} />} />
        <Route path="/bluestar-ro-service-Sant-Kabir-Nagar" element={<UttarPradeshBluestar title={locdata.SantKabirNagar.title} number={locdata.SantKabirNagar.number} />} />
        <Route path="/bluestar-ro-service-Bhadohi" element={<UttarPradeshBluestar title={locdata.Bhadohi.title} number={locdata.Bhadohi.number} />} />
        <Route path="/bluestar-ro-service-Sambhal" element={<UttarPradeshBluestar title={locdata.Sambhal.title} number={locdata.Sambhal.number} />} />
        <Route path="/bluestar-ro-service-Shahjahanpur" element={<UttarPradeshBluestar title={locdata.Shahjahanpur.title} number={locdata.Shahjahanpur.number} />} />
        <Route path="/bluestar-ro-service-Shamli" element={<UttarPradeshBluestar title={locdata.Shamli.title} number={locdata.Shamli.number} />} />
        <Route path="/bluestar-ro-service-Shravasti" element={<UttarPradeshBluestar title={locdata.Shravasti.title} number={locdata.Shravasti.number} />} />
        <Route path="/bluestar-ro-service-Siddharthnagar" element={<UttarPradeshBluestar title={locdata.Siddharthnagar.title} number={locdata.Siddharthnagar.number} />} />
        <Route path="/bluestar-ro-service-Sitapur" element={<UttarPradeshBluestar title={locdata.Sitapur.title} number={locdata.Sitapur.number} />} />
        <Route path="/bluestar-ro-service-Sonbhadra" element={<UttarPradeshBluestar title={locdata.Sonbhadra.title} number={locdata.Sonbhadra.number} />} />
        <Route path="/bluestar-ro-service-Sultanpur" element={<UttarPradeshBluestar title={locdata.Sultanpur.title} number={locdata.Sultanpur.number} />} />
        <Route path="/bluestar-ro-service-Unnao" element={<UttarPradeshBluestar title={locdata.Unnao.title} number={locdata.Unnao.number} />} />
        <Route path="/bluestar-ro-service-Varanasi" element={<UttarPradeshBluestar title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/bluestar-ro-service-Robertsganj" element={<UttarPradeshBluestar title={locdata.Robertsganj.title} number={locdata.Robertsganj.number} />} />
        <Route path="/bluestar-ro-service-Bahjoi" element={<UttarPradeshBluestar title={locdata.Bahjoi.title} number={locdata.Bahjoi.number} />} />
        <Route path="/bluestar-ro-service-Khalilabad" element={<UttarPradeshBluestar title={locdata.Khalilabad.title} number={locdata.Khalilabad.number} />} />
        <Route path="/bluestar-ro-service-Akbarpur" element={<UttarPradeshBluestar title={locdata.Akbarpur.title} number={locdata.Akbarpur.number} />} />
        <Route path="/bluestar-ro-service-Orai" element={<UttarPradeshBluestar title={locdata.Orai.title} number={locdata.Orai.number} />} />
        <Route path="/bluestar-ro-service-Manjhanpur" element={<UttarPradeshBluestar title={locdata.Manjhanpur.title} number={locdata.Manjhanpur.number} />} />
        <Route path="/bluestar-ro-service-Padarauna" element={<UttarPradeshBluestar title={locdata.Padarauna.title} number={locdata.Padarauna.number} />} />
        <Route path="/bluestar-ro-service-Kheri" element={<UttarPradeshBluestar title={locdata.Kheri.title} number={locdata.Kheri.number} />} />
        <Route path="/bluestar-ro-service-Fatehgarh" element={<UttarPradeshBluestar title={locdata.Fatehgarh.title} number={locdata.Fatehgarh.number} />} />
        <Route path="/bluestar-ro-service-Noida" element={<UttarPradeshBluestar title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/bluestar-ro-service-Greater-Noida" element={<UttarPradeshBluestar title={locdata.GreaterNoida.title} number={locdata.GreaterNoida.number} />} />

        <Route path="/bluestar-ro-service-Indore" element={<MadhyaPradeshBlueStar title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/bluestar-ro-service-Bhopal" element={<MadhyaPradeshBlueStar title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/bluestar-ro-service-Jabalpur" element={<MadhyaPradeshBlueStar title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/bluestar-ro-service-Gwalior" element={<MadhyaPradeshBlueStar title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/bluestar-ro-service-Ujjain" element={<MadhyaPradeshBlueStar title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/bluestar-ro-service-Sagar" element={<MadhyaPradeshBlueStar title={locdata.Sagar.title} number={locdata.Sagar.number} />} />
        <Route path="/bluestar-ro-service-Dewas" element={<MadhyaPradeshBlueStar title={locdata.Dewas.title} number={locdata.Dewas.number} />} />
        <Route path="/bluestar-ro-service-Satna" element={<MadhyaPradeshBlueStar title={locdata.Satna.title} number={locdata.Satna.number} />} />
        <Route path="/bluestar-ro-service-Ratlam" element={<MadhyaPradeshBlueStar title={locdata.Ratlam.title} number={locdata.Ratlam.number} />} />
        <Route path="/bluestar-ro-service-Rewa" element={<MadhyaPradeshBlueStar title={locdata.Rewa.title} number={locdata.Rewa.number} />} />
        <Route path="/bluestar-ro-service-Katni" element={<MadhyaPradeshBlueStar title={locdata.Katni.title} number={locdata.Katni.number} />} />
        <Route path="/bluestar-ro-service-Singrauli" element={<MadhyaPradeshBlueStar title={locdata.Singrauli.title} number={locdata.Singrauli.number} />} />
        <Route path="/bluestar-ro-service-Burhanpur" element={<MadhyaPradeshBlueStar title={locdata.Burhanpur.title} number={locdata.Burhanpur.number} />} />
        <Route path="/bluestar-ro-service-Morena" element={<MadhyaPradeshBlueStar title={locdata.Morena.title} number={locdata.Morena.number} />} />
        <Route path="/bluestar-ro-service-Khandwa" element={<MadhyaPradeshBlueStar title={locdata.Khandwa.title} number={locdata.Khandwa.number} />} />
        <Route path="/bluestar-ro-service-Bhind" element={<MadhyaPradeshBlueStar title={locdata.Bhind.title} number={locdata.Bhind.number} />} />
        <Route path="/bluestar-ro-service-Chhindwara" element={<MadhyaPradeshBlueStar title={locdata.Chhindwara.title} number={locdata.Chhindwara.number} />} />
        <Route path="/bluestar-ro-service-Guna" element={<MadhyaPradeshBlueStar title={locdata.Guna.title} number={locdata.Guna.number} />} />
        <Route path="/bluestar-ro-service-Shivpuri" element={<MadhyaPradeshBlueStar title={locdata.Shivpuri.title} number={locdata.Shivpuri.number} />} />
        <Route path="/bluestar-ro-service-Vidisha" element={<MadhyaPradeshBlueStar title={locdata.Vidisha.title} number={locdata.Vidisha.number} />} />
        <Route path="/bluestar-ro-service-Chhatarpur" element={<MadhyaPradeshBlueStar title={locdata.Chhatarpur.title} number={locdata.Chhatarpur.number} />} />
        <Route path="/bluestar-ro-service-Damoh" element={<MadhyaPradeshBlueStar title={locdata.Damoh.title} number={locdata.Damoh.number} />} />
        <Route path="/bluestar-ro-service-Mandsaur" element={<MadhyaPradeshBlueStar title={locdata.Mandsaur.title} number={locdata.Mandsaur.number} />} />
        <Route path="/bluestar-ro-service-Khargone" element={<MadhyaPradeshBlueStar title={locdata.Khargone.title} number={locdata.Khargone.number} />} />
        <Route path="/bluestar-ro-service-Neemuch" element={<MadhyaPradeshBlueStar title={locdata.Neemuch.title} number={locdata.Neemuch.number} />} />
        <Route path="/bluestar-ro-service-Pithampur" element={<MadhyaPradeshBlueStar title={locdata.Pithampur.title} number={locdata.Pithampur.number} />} />
        <Route path="/bluestar-ro-service-Narmadapuram" element={<MadhyaPradeshBlueStar title={locdata.Narmadapuram.title} number={locdata.Narmadapuram.number} />} />
        <Route path="/bluestar-ro-service-Itarsi" element={<MadhyaPradeshBlueStar title={locdata.Itarsi.title} number={locdata.Itarsi.number} />} />
        <Route path="/bluestar-ro-service-Sehore" element={<MadhyaPradeshBlueStar title={locdata.Sehore.title} number={locdata.Sehore.number} />} />
        <Route path="/bluestar-ro-service-Betul" element={<MadhyaPradeshBlueStar title={locdata.Betul.title} number={locdata.Betul.number} />} />
        <Route path="/bluestar-ro-service-Seoni" element={<MadhyaPradeshBlueStar title={locdata.Seoni.title} number={locdata.Seoni.number} />} />
        <Route path="/bluestar-ro-service-Datia" element={<MadhyaPradeshBlueStar title={locdata.Datia.title} number={locdata.Datia.number} />} />
        <Route path="/bluestar-ro-service-Nagda" element={<MadhyaPradeshBlueStar title={locdata.Nagda.title} number={locdata.Nagda.number} />} />
        <Route path="/bluestar-ro-service-Shahdol" element={<MadhyaPradeshBlueStar title={locdata.Shahdol.title} number={locdata.Shahdol.number} />} />
        <Route path="/bluestar-ro-service-Dhar" element={<MadhyaPradeshBlueStar title={locdata.Dhar.title} number={locdata.Dhar.number} />} />

        <Route path="/bluestar-ro-service-Ajmer" element={<RajasthanBlueStar title={locdata.Ajmer.title} number={locdata.Ajmer.number} />} />
        <Route path="/bluestar-ro-service-Alwar" element={<RajasthanBlueStar title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/bluestar-ro-service-Balotra" element={<RajasthanBlueStar title={locdata.Balotra.title} number={locdata.Balotra.number} />} />
        <Route path="/bluestar-ro-service-Banswara" element={<RajasthanBlueStar title={locdata.Banswara.title} number={locdata.Banswara.number} />} />
        <Route path="/bluestar-ro-service-Baran" element={<RajasthanBlueStar title={locdata.Baran.title} number={locdata.Baran.number} />} />
        <Route path="/bluestar-ro-service-Barmer" element={<RajasthanBlueStar title={locdata.Barmer.title} number={locdata.Barmer.number} />} />
        <Route path="/bluestar-ro-service-Beawar" element={<RajasthanBlueStar title={locdata.Beawar.title} number={locdata.Beawar.number} />} />
        <Route path="/bluestar-ro-service-Bharatpur" element={<RajasthanBlueStar title={locdata.Bharatpur.title} number={locdata.Bharatpur.number} />} />
        <Route path="/bluestar-ro-service-Bhilwara" element={<RajasthanBlueStar title={locdata.Bhilwara.title} number={locdata.Bhilwara.number} />} />
        <Route path="/bluestar-ro-service-Bikaner" element={<RajasthanBlueStar title={locdata.Bikaner.title} number={locdata.Bikaner.number} />} />
        <Route path="/bluestar-ro-service-Bundi" element={<RajasthanBlueStar title={locdata.Bundi.title} number={locdata.Bundi.number} />} />
        <Route path="/bluestar-ro-service-Chittorgarh" element={<RajasthanBlueStar title={locdata.Chittorgarh.title} number={locdata.Chittorgarh.number} />} />
        <Route path="/bluestar-ro-service-Churu" element={<RajasthanBlueStar title={locdata.Churu.title} number={locdata.Churu.number} />} />
        <Route path="/bluestar-ro-service-Dausa" element={<RajasthanBlueStar title={locdata.Dausa.title} number={locdata.Dausa.number} />} />
        <Route path="/bluestar-ro-service-Deeg" element={<RajasthanBlueStar title={locdata.Deeg.title} number={locdata.Deeg.number} />} />
        <Route path="/bluestar-ro-service-Deedwana" element={<RajasthanBlueStar title={locdata.Deedwana.title} number={locdata.Deedwana.number} />} />
        <Route path="/bluestar-ro-service-Dholpur" element={<RajasthanBlueStar title={locdata.Dholpur.title} number={locdata.Dholpur.number} />} />
        <Route path="/bluestar-ro-service-Dungarpur" element={<RajasthanBlueStar title={locdata.Dungarpur.title} number={locdata.Dungarpur.number} />} />
        <Route path="/bluestar-ro-service-Hanumangarh" element={<RajasthanBlueStar title={locdata.Hanumangarh.title} number={locdata.Hanumangarh.number} />} />
        <Route path="/bluestar-ro-service-Jaipur" element={<RajasthanBlueStar title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/bluestar-ro-service-Jaisalmer" element={<RajasthanBlueStar title={locdata.Jaisalmer.title} number={locdata.Jaisalmer.number} />} />
        <Route path="/bluestar-ro-service-Jalore" element={<RajasthanBlueStar title={locdata.Jalore.title} number={locdata.Jalore.number} />} />
        <Route path="/bluestar-ro-service-Jhalawar" element={<RajasthanBlueStar title={locdata.Jhalawar.title} number={locdata.Jhalawar.number} />} />
        <Route path="/bluestar-ro-service-Jhunjhunu" element={<RajasthanBlueStar title={locdata.Jhunjhunu.title} number={locdata.Jhunjhunu.number} />} />
        <Route path="/bluestar-ro-service-Jodhpur" element={<RajasthanBlueStar title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/bluestar-ro-service-Karauli" element={<RajasthanBlueStar title={locdata.Karauli.title} number={locdata.Karauli.number} />} />
        <Route path="/bluestar-ro-service-Tijara" element={<RajasthanBlueStar title={locdata.Tijara.title} number={locdata.Tijara.number} />} />
        <Route path="/bluestar-ro-service-Kotputli" element={<RajasthanBlueStar title={locdata.Kotputli.title} number={locdata.Kotputli.number} />} />
        <Route path="/bluestar-ro-service-Kota" element={<RajasthanBlueStar title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/bluestar-ro-service-Nagaur" element={<RajasthanBlueStar title={locdata.Nagaur.title} number={locdata.Nagaur.number} />} />
        <Route path="/bluestar-ro-service-Pali" element={<RajasthanBlueStar title={locdata.Pali.title} number={locdata.Pali.number} />} />
        <Route path="/bluestar-ro-service-Phalodi" element={<RajasthanBlueStar title={locdata.Phalodi.title} number={locdata.Phalodi.number} />} />
        <Route path="/bluestar-ro-service-Pratapgarh" element={<RajasthanBlueStar title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/bluestar-ro-service-Rajsamand" element={<RajasthanBlueStar title={locdata.Rajsamand.title} number={locdata.Rajsamand.number} />} />
        <Route path="/bluestar-ro-service-Salumbar" element={<RajasthanBlueStar title={locdata.Salumbar.title} number={locdata.Salumbar.number} />} />
        <Route path="/bluestar-ro-service-Sawai-Madhopur" element={<RajasthanBlueStar title={locdata.SawaiMadhopur.title} number={locdata.SawaiMadhopur.number} />} />
        <Route path="/bluestar-ro-service-Sikar" element={<RajasthanBlueStar title={locdata.Sikar.title} number={locdata.Sikar.number} />} />
        <Route path="/bluestar-ro-service-Sirohi" element={<RajasthanBlueStar title={locdata.Sirohi.title} number={locdata.Sirohi.number} />} />
        <Route path="/bluestar-ro-service-Sri-Ganganagar" element={<RajasthanBlueStar title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/bluestar-ro-service-Tonk" element={<RajasthanBlueStar title={locdata.Tonk.title} number={locdata.Tonk.number} />} />
        <Route path="/bluestar-ro-service-Udaipur" element={<RajasthanBlueStar title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />

        <Route path="/bluestar-ro-service-Mumbai" element={<BlueStarro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/bluestar-ro-service-Delhi" element={<BlueStarro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/bluestar-ro-service-Bengaluru" element={<BlueStarro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/bluestar-ro-service-Hyderabad" element={<BlueStarro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/bluestar-ro-service-Ahmedabad" element={<BlueStarro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/bluestar-ro-service-Chennai" element={<BlueStarro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/bluestar-ro-service-Surat" element={<BlueStarro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/bluestar-ro-service-Pune" element={<BlueStarro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/bluestar-ro-service-Jaipur" element={<BlueStarro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/bluestar-ro-service-Lucknow" element={<BlueStarro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/bluestar-ro-service-Kanpur" element={<BlueStarro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/bluestar-ro-service-Nagpur" element={<BlueStarro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/bluestar-ro-service-Indore" element={<BlueStarro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/bluestar-ro-service-Thane" element={<BlueStarro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/bluestar-ro-service-Bhopal" element={<BlueStarro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/bluestar-ro-service-Visakhapatnam" element={<BlueStarro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/bluestar-ro-service-Vadodara" element={<BlueStarro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/bluestar-ro-service-Ghaziabad" element={<BlueStarro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/bluestar-ro-service-Ludhiana" element={<BlueStarro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/bluestar-ro-service-Agra" element={<BlueStarro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/bluestar-ro-service-Nashik" element={<BlueStarro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/bluestar-ro-service-Faridabad" element={<BlueStarro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/bluestar-ro-service-Meerut" element={<BlueStarro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/bluestar-ro-service-Rajkot" element={<BlueStarro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/bluestar-ro-service-Varanasi" element={<BlueStarro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/bluestar-ro-service-Srinagar" element={<BlueStarro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/bluestar-ro-service-Amritsar" element={<BlueStarro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/bluestar-ro-service-Navi-Mumbai" element={<BlueStarro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/bluestar-ro-service-Prayagraj" element={<BlueStarro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/bluestar-ro-service-Jabalpur" element={<BlueStarro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/bluestar-ro-service-Gwalior" element={<BlueStarro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/bluestar-ro-service-Coimbatore" element={<BlueStarro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/bluestar-ro-service-Vijayawada" element={<BlueStarro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/bluestar-ro-service-Jodhpur" element={<BlueStarro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/bluestar-ro-service-Madurai" element={<BlueStarro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/bluestar-ro-service-Raipur" element={<BlueStarro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/bluestar-ro-service-Kota" element={<BlueStarro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/bluestar-ro-service-Guwahati" element={<BlueStarro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/bluestar-ro-service-Chandigarh" element={<BlueStarro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/bluestar-ro-service-Thiruvananthapuram" element={<BlueStarro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/bluestar-ro-service-Solapur" element={<BlueStarro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/bluestar-ro-service-Tiruppur" element={<BlueStarro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/bluestar-ro-service-Moradabad" element={<BlueStarro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/bluestar-ro-service-Mysore" element={<BlueStarro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/bluestar-ro-service-Gurgaon" element={<BlueStarro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/bluestar-ro-service-Aligarh" element={<BlueStarro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/bluestar-ro-service-Jalandhar" element={<BlueStarro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/bluestar-ro-service-Bhubaneswar" element={<BlueStarro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/bluestar-ro-service-Noida" element={<BlueStarro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/bluestar-ro-service-Jamshedpur" element={<BlueStarro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/bluestar-ro-service-Cuttack" element={<BlueStarro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/bluestar-ro-service-Bhavnagar" element={<BlueStarro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/bluestar-ro-service-Dehradun" element={<BlueStarro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/bluestar-ro-service-Rourkela" element={<BlueStarro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/bluestar-ro-service-Jamnagar" element={<BlueStarro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/bluestar-ro-service-Ujjain" element={<BlueStarro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/bluestar-ro-service-Jammu" element={<BlueStarro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/bluestar-ro-service-Mangalore" element={<BlueStarro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/bluestar-ro-service-Erode" element={<BlueStarro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/bluestar-ro-service-Udaipur" element={<BlueStarro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/bluestar-ro-service-Rajahmundry" element={<BlueStarro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/bluestar-ro-service-Patiala" element={<BlueStarro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/bluestar-ro-service-Agartala" element={<BlueStarro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/bluestar-ro-service-Tirupati" element={<BlueStarro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/bluestar-ro-service-Sambalpur" element={<BlueStarro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/bluestar-ro-service-Bilaspur" element={<BlueStarro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/bluestar-ro-service-Alwar" element={<BlueStarro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/bluestar-ro-service-Bardhaman" element={<BlueStarro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/bluestar-ro-service-Sonipat" element={<BlueStarro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/bluestar-ro-service-New-Delhi" element={<BlueStarro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/bluestar-ro-service-Puducherry" element={<BlueStarro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/bluestar-ro-service-Haridwar" element={<BlueStarro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/bluestar-ro-service-Vijayanagaram" element={<BlueStarro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/bluestar-ro-service-Sri-Ganganagar" element={<BlueStarro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/bluestar-ro-service-Secunderabad" element={<BlueStarro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/bluestar-ro-service-Yamunanagar" element={<BlueStarro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/bluestar-ro-service-Gandhinagar" element={<BlueStarro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/bluestar-ro-service-Ambala" element={<BlueStarro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/bluestar-ro-service-Vellore" element={<BlueStarro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/bluestar-ro-service-Shimla" element={<BlueStarro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/bluestar-ro-service-Amaravati" element={<BlueStarro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />



        <Route path="/zerob-ro-service-Araria" element={<BiharZeroB title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/zerob-ro-service-Arwal" element={<BiharZeroB title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/zerob-ro-service-Aurangabad" element={<BiharZeroB title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/zerob-ro-service-Banka" element={<BiharZeroB title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/zerob-ro-service-Begusarai" element={<BiharZeroB title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/zerob-ro-service-Bhabua" element={<BiharZeroB title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/zerob-ro-service-Bhagalpur" element={<BiharZeroB title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/zerob-ro-service-Bhojpur" element={<BiharZeroB title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/zerob-ro-service-Bihar-Sharif" element={<BiharZeroB title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/zerob-ro-service-Buxar" element={<BiharZeroB title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/zerob-ro-service-Chhapra" element={<BiharZeroB title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/zerob-ro-service-Darbhanga" element={<BiharZeroB title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/zerob-ro-service-East-Champaran" element={<BiharZeroB title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/zerob-ro-service-Gaya" element={<BiharZeroB title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/zerob-ro-service-Gopalganj" element={<BiharZeroB title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/zerob-ro-service-Jamui" element={<BiharZeroB title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/zerob-ro-service-Jehanabad" element={<BiharZeroB title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/zerob-ro-service-Khagaria" element={<BiharZeroB title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/zerob-ro-service-Kishanganj" element={<BiharZeroB title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/zerob-ro-service-Kaimur" element={<BiharZeroB title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/zerob-ro-service-Katihar" element={<BiharZeroB title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/zerob-ro-service-Lakhisarai" element={<BiharZeroB title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/zerob-ro-service-Madhubani" element={<BiharZeroB title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/zerob-ro-service-Munger" element={<BiharZeroB title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/zerob-ro-service-Madhepura" element={<BiharZeroB title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/zerob-ro-service-Muzaffarpur" element={<BiharZeroB title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/zerob-ro-service-Nalanda" element={<BiharZeroB title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/zerob-ro-service-Nawada" element={<BiharZeroB title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/zerob-ro-service-Patna" element={<BiharZeroB title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/zerob-ro-service-Purnia" element={<BiharZeroB title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/zerob-ro-service-Rohtas" element={<BiharZeroB title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/zerob-ro-service-Saharsa" element={<BiharZeroB title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/zerob-ro-service-Samastipur" element={<BiharZeroB title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/zerob-ro-service-Sasaram" element={<BiharZeroB title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/zerob-ro-service-Sheohar" element={<BiharZeroB title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/zerob-ro-service-Sheikhpura" element={<BiharZeroB title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/zerob-ro-service-Saran" element={<BiharZeroB title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/zerob-ro-service-Sitamarhi" element={<BiharZeroB title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/zerob-ro-service-Supaul" element={<BiharZeroB title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/zerob-ro-service-Siwan" element={<BiharZeroB title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/zerob-ro-service-Vaishali" element={<BiharZeroB title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/zerob-ro-service-West-Champaran" element={<BiharZeroB title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/zerob-ro-service-Garhwa" element={<JharkhandZeroB title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/zerob-ro-service-Palamu" element={<JharkhandZeroB title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/zerob-ro-service-Latehar" element={<JharkhandZeroB title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/zerob-ro-service-Chatra" element={<JharkhandZeroB title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/zerob-ro-service-Hazaribagh" element={<JharkhandZeroB title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/zerob-ro-service-Jamshedpur" element={<JharkhandZeroB title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/zerob-ro-service-Koderma" element={<JharkhandZeroB title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/zerob-ro-service-Giridih" element={<JharkhandZeroB title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/zerob-ro-service-Ramgarh" element={<JharkhandZeroB title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/zerob-ro-service-Bokaro" element={<JharkhandZeroB title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/zerob-ro-service-Dhanbad" element={<JharkhandZeroB title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/zerob-ro-service-Lohardaga" element={<JharkhandZeroB title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/zerob-ro-service-Gumla" element={<JharkhandZeroB title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/zerob-ro-service-Simdega" element={<JharkhandZeroB title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/zerob-ro-service-Ranchi" element={<JharkhandZeroB title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/zerob-ro-service-Khunti" element={<JharkhandZeroB title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/zerob-ro-service-Singhbhum" element={<JharkhandZeroB title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/zerob-ro-service-Saraikela-Kharsawan" element={<JharkhandZeroB title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/zerob-ro-service-Jamtara" element={<JharkhandZeroB title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/zerob-ro-service-Deoghar" element={<JharkhandZeroB title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/zerob-ro-service-Dumka" element={<JharkhandZeroB title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/zerob-ro-service-Pakur" element={<JharkhandZeroB title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/zerob-ro-service-Godda" element={<JharkhandZeroB title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/zerob-ro-service-Sahebganj" element={<JharkhandZeroB title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />

        <Route path="/zerob-ro-service-Alipurduar" element={<BengalZeroB title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/zerob-ro-service-Bankura" element={<BengalZeroB title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/zerob-ro-service-Birbhum" element={<BengalZeroB title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/zerob-ro-service-Cooch-Behar" element={<BengalZeroB title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/zerob-ro-service-Dakshin-Dinajpur" element={<BengalZeroB title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/zerob-ro-service-Darjeeling" element={<BengalZeroB title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/zerob-ro-service-Durgapur" element={<BengalZeroB title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/zerob-ro-service-Hooghly" element={<BengalZeroB title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/zerob-ro-service-Howrah" element={<BengalZeroB title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/zerob-ro-service-Jalpaiguri" element={<BengalZeroB title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/zerob-ro-service-Jhargram" element={<BengalZeroB title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/zerob-ro-service-Kalimpong" element={<BengalZeroB title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/zerob-ro-service-Kolkata" element={<BengalZeroB title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/zerob-ro-service-Malda" element={<BengalZeroB title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/zerob-ro-service-Murshidabad" element={<BengalZeroB title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/zerob-ro-service-Nadia" element={<BengalZeroB title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/zerob-ro-service-North-24-Parganas" element={<BengalZeroB title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/zerob-ro-service-Paschim-Bardhaman" element={<BengalZeroB title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/zerob-ro-service-Paschim-Medinipur" element={<BengalZeroB title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/zerob-ro-service-Purba-Bardhaman" element={<BengalZeroB title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/zerob-ro-service-Purba-Medinipur" element={<BengalZeroB title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/zerob-ro-service-South-24-Parganas" element={<BengalZeroB title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/zerob-ro-service-Uttar-Dinajpur" element={<BengalZeroB title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />

        <Route path="/zerob-ro-service-Indore" element={<MadhyaPradeshZeroB title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/zerob-ro-service-Bhopal" element={<MadhyaPradeshZeroB title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/zerob-ro-service-Jabalpur" element={<MadhyaPradeshZeroB title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/zerob-ro-service-Gwalior" element={<MadhyaPradeshZeroB title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/zerob-ro-service-Ujjain" element={<MadhyaPradeshZeroB title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/zerob-ro-service-Sagar" element={<MadhyaPradeshZeroB title={locdata.Sagar.title} number={locdata.Sagar.number} />} />
        <Route path="/zerob-ro-service-Dewas" element={<MadhyaPradeshZeroB title={locdata.Dewas.title} number={locdata.Dewas.number} />} />
        <Route path="/zerob-ro-service-Satna" element={<MadhyaPradeshZeroB title={locdata.Satna.title} number={locdata.Satna.number} />} />
        <Route path="/zerob-ro-service-Ratlam" element={<MadhyaPradeshZeroB title={locdata.Ratlam.title} number={locdata.Ratlam.number} />} />
        <Route path="/zerob-ro-service-Rewa" element={<MadhyaPradeshZeroB title={locdata.Rewa.title} number={locdata.Rewa.number} />} />
        <Route path="/zerob-ro-service-Katni" element={<MadhyaPradeshZeroB title={locdata.Katni.title} number={locdata.Katni.number} />} />
        <Route path="/zerob-ro-service-Singrauli" element={<MadhyaPradeshZeroB title={locdata.Singrauli.title} number={locdata.Singrauli.number} />} />
        <Route path="/zerob-ro-service-Burhanpur" element={<MadhyaPradeshZeroB title={locdata.Burhanpur.title} number={locdata.Burhanpur.number} />} />
        <Route path="/zerob-ro-service-Morena" element={<MadhyaPradeshZeroB title={locdata.Morena.title} number={locdata.Morena.number} />} />
        <Route path="/zerob-ro-service-Khandwa" element={<MadhyaPradeshZeroB title={locdata.Khandwa.title} number={locdata.Khandwa.number} />} />
        <Route path="/zerob-ro-service-Bhind" element={<MadhyaPradeshZeroB title={locdata.Bhind.title} number={locdata.Bhind.number} />} />
        <Route path="/zerob-ro-service-Chhindwara" element={<MadhyaPradeshZeroB title={locdata.Chhindwara.title} number={locdata.Chhindwara.number} />} />
        <Route path="/zerob-ro-service-Guna" element={<MadhyaPradeshZeroB title={locdata.Guna.title} number={locdata.Guna.number} />} />
        <Route path="/zerob-ro-service-Shivpuri" element={<MadhyaPradeshZeroB title={locdata.Shivpuri.title} number={locdata.Shivpuri.number} />} />
        <Route path="/zerob-ro-service-Vidisha" element={<MadhyaPradeshZeroB title={locdata.Vidisha.title} number={locdata.Vidisha.number} />} />
        <Route path="/zerob-ro-service-Chhatarpur" element={<MadhyaPradeshZeroB title={locdata.Chhatarpur.title} number={locdata.Chhatarpur.number} />} />
        <Route path="/zerob-ro-service-Damoh" element={<MadhyaPradeshZeroB title={locdata.Damoh.title} number={locdata.Damoh.number} />} />
        <Route path="/zerob-ro-service-Mandsaur" element={<MadhyaPradeshZeroB title={locdata.Mandsaur.title} number={locdata.Mandsaur.number} />} />
        <Route path="/zerob-ro-service-Khargone" element={<MadhyaPradeshZeroB title={locdata.Khargone.title} number={locdata.Khargone.number} />} />
        <Route path="/zerob-ro-service-Neemuch" element={<MadhyaPradeshZeroB title={locdata.Neemuch.title} number={locdata.Neemuch.number} />} />
        <Route path="/zerob-ro-service-Pithampur" element={<MadhyaPradeshZeroB title={locdata.Pithampur.title} number={locdata.Pithampur.number} />} />
        <Route path="/zerob-ro-service-Narmadapuram" element={<MadhyaPradeshZeroB title={locdata.Narmadapuram.title} number={locdata.Narmadapuram.number} />} />
        <Route path="/zerob-ro-service-Itarsi" element={<MadhyaPradeshZeroB title={locdata.Itarsi.title} number={locdata.Itarsi.number} />} />
        <Route path="/zerob-ro-service-Sehore" element={<MadhyaPradeshZeroB title={locdata.Sehore.title} number={locdata.Sehore.number} />} />
        <Route path="/zerob-ro-service-Betul" element={<MadhyaPradeshZeroB title={locdata.Betul.title} number={locdata.Betul.number} />} />
        <Route path="/zerob-ro-service-Seoni" element={<MadhyaPradeshZeroB title={locdata.Seoni.title} number={locdata.Seoni.number} />} />
        <Route path="/zerob-ro-service-Datia" element={<MadhyaPradeshZeroB title={locdata.Datia.title} number={locdata.Datia.number} />} />
        <Route path="/zerob-ro-service-Nagda" element={<MadhyaPradeshZeroB title={locdata.Nagda.title} number={locdata.Nagda.number} />} />
        <Route path="/zerob-ro-service-Shahdol" element={<MadhyaPradeshZeroB title={locdata.Shahdol.title} number={locdata.Shahdol.number} />} />
        <Route path="/zerob-ro-service-Dhar" element={<MadhyaPradeshZeroB title={locdata.Dhar.title} number={locdata.Dhar.number} />} />

        <Route path="/zerob-ro-service-Ajmer" element={<RajasthanZeroB title={locdata.Ajmer.title} number={locdata.Ajmer.number} />} />
        <Route path="/zerob-ro-service-Alwar" element={<RajasthanZeroB title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/zerob-ro-service-Balotra" element={<RajasthanZeroB title={locdata.Balotra.title} number={locdata.Balotra.number} />} />
        <Route path="/zerob-ro-service-Banswara" element={<RajasthanZeroB title={locdata.Banswara.title} number={locdata.Banswara.number} />} />
        <Route path="/zerob-ro-service-Baran" element={<RajasthanZeroB title={locdata.Baran.title} number={locdata.Baran.number} />} />
        <Route path="/zerob-ro-service-Barmer" element={<RajasthanZeroB title={locdata.Barmer.title} number={locdata.Barmer.number} />} />
        <Route path="/zerob-ro-service-Beawar" element={<RajasthanZeroB title={locdata.Beawar.title} number={locdata.Beawar.number} />} />
        <Route path="/zerob-ro-service-Bharatpur" element={<RajasthanZeroB title={locdata.Bharatpur.title} number={locdata.Bharatpur.number} />} />
        <Route path="/zerob-ro-service-Bhilwara" element={<RajasthanZeroB title={locdata.Bhilwara.title} number={locdata.Bhilwara.number} />} />
        <Route path="/zerob-ro-service-Bikaner" element={<RajasthanZeroB title={locdata.Bikaner.title} number={locdata.Bikaner.number} />} />
        <Route path="/zerob-ro-service-Bundi" element={<RajasthanZeroB title={locdata.Bundi.title} number={locdata.Bundi.number} />} />
        <Route path="/zerob-ro-service-Chittorgarh" element={<RajasthanZeroB title={locdata.Chittorgarh.title} number={locdata.Chittorgarh.number} />} />
        <Route path="/zerob-ro-service-Churu" element={<RajasthanZeroB title={locdata.Churu.title} number={locdata.Churu.number} />} />
        <Route path="/zerob-ro-service-Dausa" element={<RajasthanZeroB title={locdata.Dausa.title} number={locdata.Dausa.number} />} />
        <Route path="/zerob-ro-service-Deeg" element={<RajasthanZeroB title={locdata.Deeg.title} number={locdata.Deeg.number} />} />
        <Route path="/zerob-ro-service-Deedwana" element={<RajasthanZeroB title={locdata.Deedwana.title} number={locdata.Deedwana.number} />} />
        <Route path="/zerob-ro-service-Dholpur" element={<RajasthanZeroB title={locdata.Dholpur.title} number={locdata.Dholpur.number} />} />
        <Route path="/zerob-ro-service-Dungarpur" element={<RajasthanZeroB title={locdata.Dungarpur.title} number={locdata.Dungarpur.number} />} />
        <Route path="/zerob-ro-service-Hanumangarh" element={<RajasthanZeroB title={locdata.Hanumangarh.title} number={locdata.Hanumangarh.number} />} />
        <Route path="/zerob-ro-service-Jaipur" element={<RajasthanZeroB title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/zerob-ro-service-Jaisalmer" element={<RajasthanZeroB title={locdata.Jaisalmer.title} number={locdata.Jaisalmer.number} />} />
        <Route path="/zerob-ro-service-Jalore" element={<RajasthanZeroB title={locdata.Jalore.title} number={locdata.Jalore.number} />} />
        <Route path="/zerob-ro-service-Jhalawar" element={<RajasthanZeroB title={locdata.Jhalawar.title} number={locdata.Jhalawar.number} />} />
        <Route path="/zerob-ro-service-Jhunjhunu" element={<RajasthanZeroB title={locdata.Jhunjhunu.title} number={locdata.Jhunjhunu.number} />} />
        <Route path="/zerob-ro-service-Jodhpur" element={<RajasthanZeroB title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/zerob-ro-service-Karauli" element={<RajasthanZeroB title={locdata.Karauli.title} number={locdata.Karauli.number} />} />
        <Route path="/zerob-ro-service-Tijara" element={<RajasthanZeroB title={locdata.Tijara.title} number={locdata.Tijara.number} />} />
        <Route path="/zerob-ro-service-Kotputli" element={<RajasthanZeroB title={locdata.Kotputli.title} number={locdata.Kotputli.number} />} />
        <Route path="/zerob-ro-service-Kota" element={<RajasthanZeroB title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/zerob-ro-service-Nagaur" element={<RajasthanZeroB title={locdata.Nagaur.title} number={locdata.Nagaur.number} />} />
        <Route path="/zerob-ro-service-Pali" element={<RajasthanZeroB title={locdata.Pali.title} number={locdata.Pali.number} />} />
        <Route path="/zerob-ro-service-Phalodi" element={<RajasthanZeroB title={locdata.Phalodi.title} number={locdata.Phalodi.number} />} />
        <Route path="/zerob-ro-service-Pratapgarh" element={<RajasthanZeroB title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/zerob-ro-service-Rajsamand" element={<RajasthanZeroB title={locdata.Rajsamand.title} number={locdata.Rajsamand.number} />} />
        <Route path="/zerob-ro-service-Salumbar" element={<RajasthanZeroB title={locdata.Salumbar.title} number={locdata.Salumbar.number} />} />
        <Route path="/zerob-ro-service-Sawai-Madhopur" element={<RajasthanZeroB title={locdata.SawaiMadhopur.title} number={locdata.SawaiMadhopur.number} />} />
        <Route path="/zerob-ro-service-Sikar" element={<RajasthanZeroB title={locdata.Sikar.title} number={locdata.Sikar.number} />} />
        <Route path="/zerob-ro-service-Sirohi" element={<RajasthanZeroB title={locdata.Sirohi.title} number={locdata.Sirohi.number} />} />
        <Route path="/zerob-ro-service-Sri-Ganganagar" element={<RajasthanZeroB title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/zerob-ro-service-Tonk" element={<RajasthanZeroB title={locdata.Tonk.title} number={locdata.Tonk.number} />} />
        <Route path="/zerob-ro-service-Udaipur" element={<RajasthanZeroB title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />

        <Route path="/zerob-ro-service-Mumbai" element={<ZeroBro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/zerob-ro-service-Delhi" element={<ZeroBro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/zerob-ro-service-Bengaluru" element={<ZeroBro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/zerob-ro-service-Hyderabad" element={<ZeroBro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/zerob-ro-service-Ahmedabad" element={<ZeroBro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/zerob-ro-service-Chennai" element={<ZeroBro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/zerob-ro-service-Surat" element={<ZeroBro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/zerob-ro-service-Pune" element={<ZeroBro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/zerob-ro-service-Jaipur" element={<ZeroBro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/zerob-ro-service-Lucknow" element={<ZeroBro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/zerob-ro-service-Kanpur" element={<ZeroBro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/zerob-ro-service-Nagpur" element={<ZeroBro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/zerob-ro-service-Indore" element={<ZeroBro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/zerob-ro-service-Thane" element={<ZeroBro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/zerob-ro-service-Bhopal" element={<ZeroBro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/zerob-ro-service-Visakhapatnam" element={<ZeroBro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/zerob-ro-service-Vadodara" element={<ZeroBro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/zerob-ro-service-Ghaziabad" element={<ZeroBro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/zerob-ro-service-Ludhiana" element={<ZeroBro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/zerob-ro-service-Agra" element={<ZeroBro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/zerob-ro-service-Nashik" element={<ZeroBro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/zerob-ro-service-Faridabad" element={<ZeroBro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/zerob-ro-service-Meerut" element={<ZeroBro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/zerob-ro-service-Rajkot" element={<ZeroBro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/zerob-ro-service-Varanasi" element={<ZeroBro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/zerob-ro-service-Srinagar" element={<ZeroBro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/zerob-ro-service-Amritsar" element={<ZeroBro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/zerob-ro-service-Navi-Mumbai" element={<ZeroBro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/zerob-ro-service-Prayagraj" element={<ZeroBro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/zerob-ro-service-Jabalpur" element={<ZeroBro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/zerob-ro-service-Gwalior" element={<ZeroBro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/zerob-ro-service-Coimbatore" element={<ZeroBro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/zerob-ro-service-Vijayawada" element={<ZeroBro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/zerob-ro-service-Jodhpur" element={<ZeroBro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/zerob-ro-service-Madurai" element={<ZeroBro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/zerob-ro-service-Raipur" element={<ZeroBro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/zerob-ro-service-Kota" element={<ZeroBro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/zerob-ro-service-Guwahati" element={<ZeroBro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/zerob-ro-service-Chandigarh" element={<ZeroBro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/zerob-ro-service-Thiruvananthapuram" element={<ZeroBro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/zerob-ro-service-Solapur" element={<ZeroBro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/zerob-ro-service-Tiruppur" element={<ZeroBro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/zerob-ro-service-Moradabad" element={<ZeroBro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/zerob-ro-service-Mysore" element={<ZeroBro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/zerob-ro-service-Gurgaon" element={<ZeroBro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/zerob-ro-service-Aligarh" element={<ZeroBro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/zerob-ro-service-Jalandhar" element={<ZeroBro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/zerob-ro-service-Bhubaneswar" element={<ZeroBro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/zerob-ro-service-Noida" element={<ZeroBro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/zerob-ro-service-Jamshedpur" element={<ZeroBro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/zerob-ro-service-Cuttack" element={<ZeroBro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/zerob-ro-service-Bhavnagar" element={<ZeroBro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/zerob-ro-service-Dehradun" element={<ZeroBro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/zerob-ro-service-Rourkela" element={<ZeroBro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/zerob-ro-service-Jamnagar" element={<ZeroBro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/zerob-ro-service-Ujjain" element={<ZeroBro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/zerob-ro-service-Jammu" element={<ZeroBro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/zerob-ro-service-Mangalore" element={<ZeroBro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/zerob-ro-service-Erode" element={<ZeroBro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/zerob-ro-service-Udaipur" element={<ZeroBro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/zerob-ro-service-Rajahmundry" element={<ZeroBro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/zerob-ro-service-Patiala" element={<ZeroBro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/zerob-ro-service-Agartala" element={<ZeroBro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/zerob-ro-service-Tirupati" element={<ZeroBro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/zerob-ro-service-Sambalpur" element={<ZeroBro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/zerob-ro-service-Bilaspur" element={<ZeroBro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/zerob-ro-service-Alwar" element={<ZeroBro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/zerob-ro-service-Bardhaman" element={<ZeroBro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/zerob-ro-service-Sonipat" element={<ZeroBro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/zerob-ro-service-New-Delhi" element={<ZeroBro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/zerob-ro-service-Puducherry" element={<ZeroBro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/zerob-ro-service-Haridwar" element={<ZeroBro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/zerob-ro-service-Vijayanagaram" element={<ZeroBro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/zerob-ro-service-Sri-Ganganagar" element={<ZeroBro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/zerob-ro-service-Secunderabad" element={<ZeroBro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/zerob-ro-service-Yamunanagar" element={<ZeroBro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/zerob-ro-service-Gandhinagar" element={<ZeroBro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/zerob-ro-service-Ambala" element={<ZeroBro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/zerob-ro-service-Vellore" element={<ZeroBro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/zerob-ro-service-Shimla" element={<ZeroBro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/zerob-ro-service-Amaravati" element={<ZeroBro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />


        <Route path="/havells-ro-service-Araria" element={<BiharHavells title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/havells-ro-service-Arwal" element={<BiharHavells title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/havells-ro-service-Aurangabad" element={<BiharHavells title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/havells-ro-service-Banka" element={<BiharHavells title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/havells-ro-service-Begusarai" element={<BiharHavells title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/havells-ro-service-Bhabua" element={<BiharHavells title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/havells-ro-service-Bhagalpur" element={<BiharHavells title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/havells-ro-service-Bhojpur" element={<BiharHavells title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/havells-ro-service-Bihar-Sharif" element={<BiharHavells title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/havells-ro-service-Buxar" element={<BiharHavells title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/havells-ro-service-Chhapra" element={<BiharHavells title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/havells-ro-service-Darbhanga" element={<BiharHavells title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/havells-ro-service-East-Champaran" element={<BiharHavells title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/havells-ro-service-Gaya" element={<BiharHavells title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/havells-ro-service-Gopalganj" element={<BiharHavells title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/havells-ro-service-Jamui" element={<BiharHavells title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/havells-ro-service-Jehanabad" element={<BiharHavells title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/havells-ro-service-Khagaria" element={<BiharHavells title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/havells-ro-service-Kishanganj" element={<BiharHavells title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/havells-ro-service-Kaimur" element={<BiharHavells title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/havells-ro-service-Katihar" element={<BiharHavells title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/havells-ro-service-Lakhisarai" element={<BiharHavells title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/havells-ro-service-Madhubani" element={<BiharHavells title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/havells-ro-service-Munger" element={<BiharHavells title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/havells-ro-service-Madhepura" element={<BiharHavells title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/havells-ro-service-Muzaffarpur" element={<BiharHavells title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/havells-ro-service-Nalanda" element={<BiharHavells title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/havells-ro-service-Nawada" element={<BiharHavells title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/havells-ro-service-Patna" element={<BiharHavells title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/havells-ro-service-Purnia" element={<BiharHavells title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/havells-ro-service-Rohtas" element={<BiharHavells title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/havells-ro-service-Saharsa" element={<BiharHavells title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/havells-ro-service-Samastipur" element={<BiharHavells title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/havells-ro-service-Sasaram" element={<BiharHavells title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/havells-ro-service-Sheohar" element={<BiharHavells title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/havells-ro-service-Sheikhpura" element={<BiharHavells title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/havells-ro-service-Saran" element={<BiharHavells title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/havells-ro-service-Sitamarhi" element={<BiharHavells title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/havells-ro-service-Supaul" element={<BiharHavells title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/havells-ro-service-Siwan" element={<BiharHavells title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/havells-ro-service-Vaishali" element={<BiharHavells title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/havells-ro-service-West-Champaran" element={<BiharHavells title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/havells-ro-service-Garhwa" element={<JharkhandHavells title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/havells-ro-service-Palamu" element={<JharkhandHavells title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/havells-ro-service-Latehar" element={<JharkhandHavells title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/havells-ro-service-Chatra" element={<JharkhandHavells title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/havells-ro-service-Hazaribagh" element={<JharkhandHavells title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/havells-ro-service-Jamshedpur" element={<JharkhandHavells title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/havells-ro-service-Koderma" element={<JharkhandHavells title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/havells-ro-service-Giridih" element={<JharkhandHavells title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/havells-ro-service-Ramgarh" element={<JharkhandHavells title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/havells-ro-service-Bokaro" element={<JharkhandHavells title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/havells-ro-service-Dhanbad" element={<JharkhandHavells title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/havells-ro-service-Lohardaga" element={<JharkhandHavells title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/havells-ro-service-Gumla" element={<JharkhandHavells title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/havells-ro-service-Simdega" element={<JharkhandHavells title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/havells-ro-service-Ranchi" element={<JharkhandHavells title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/havells-ro-service-Khunti" element={<JharkhandHavells title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/havells-ro-service-Singhbhum" element={<JharkhandHavells title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/havells-ro-service-Saraikela-Kharsawan" element={<JharkhandHavells title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/havells-ro-service-Jamtara" element={<JharkhandHavells title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/havells-ro-service-Deoghar" element={<JharkhandHavells title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/havells-ro-service-Dumka" element={<JharkhandHavells title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/havells-ro-service-Pakur" element={<JharkhandHavells title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/havells-ro-service-Godda" element={<JharkhandHavells title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/havells-ro-service-Sahebganj" element={<Jharkhand title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />

        <Route path="/havells-ro-service-Alipurduar" element={<BengalHavells title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/havells-ro-service-Bankura" element={<BengalHavells title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/havells-ro-service-Birbhum" element={<BengalHavells title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/havells-ro-service-Cooch-Behar" element={<BengalHavells title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/havells-ro-service-Dakshin-Dinajpur" element={<BengalHavells title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/havells-ro-service-Darjeeling" element={<BengalHavells title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/havells-ro-service-Durgapur" element={<BengalHavells title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/havells-ro-service-Hooghly" element={<BengalHavells title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/havells-ro-service-Howrah" element={<BengalHavells title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/havells-ro-service-Jalpaiguri" element={<BengalHavells title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/havells-ro-service-Jhargram" element={<BengalHavells title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/havells-ro-service-Kalimpong" element={<BengalHavells title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/havells-ro-service-Kolkata" element={<BengalHavells title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/havells-ro-service-Malda" element={<BengalHavells title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/havells-ro-service-Murshidabad" element={<BengalHavells title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/havells-ro-service-Nadia" element={<BengalHavells title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/havells-ro-service-North-24-Parganas" element={<BengalHavells title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/havells-ro-service-Paschim-Bardhaman" element={<BengalHavells title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/havells-ro-service-Paschim-Medinipur" element={<BengalHavells title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/havells-ro-service-Purba-Bardhaman" element={<BengalHavells title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/havells-ro-service-Purba-Medinipur" element={<BengalHavells title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/havells-ro-service-South-24-Parganas" element={<BengalHavells title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/havells-ro-service-Uttar-Dinajpur" element={<BengalHavells title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />
        <Route path="/havells-ro-service-Agra" element={<UttarPradeshHavells title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/havells-ro-service-Aligarh" element={<UttarPradeshHavells title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/havells-ro-service-Ambedkar-Nagar" element={<UttarPradeshHavells title={locdata.AmbedkarNagar.title} number={locdata.AmbedkarNagar.number} />} />
        <Route path="/havells-ro-service-Ayodhya" element={<UttarPradeshHavells title={locdata.Ayodhya.title} number={locdata.Ayodhya.number} />} />
        <Route path="/havells-ro-service-Amethi" element={<UttarPradeshHavells title={locdata.Amethi.title} number={locdata.Amethi.number} />} />
        <Route path="/havells-ro-service-Amroha" element={<UttarPradeshHavells title={locdata.Amroha.title} number={locdata.Amroha.number} />} />
        <Route path="/havells-ro-service-Auraiya" element={<UttarPradeshHavells title={locdata.Auraiya.title} number={locdata.Auraiya.number} />} />
        <Route path="/havells-ro-service-Azamgarh" element={<UttarPradeshHavells title={locdata.Azamgarh.title} number={locdata.Azamgarh.number} />} />
        <Route path="/havells-ro-service-Budaun" element={<UttarPradeshHavells title={locdata.Budaun.title} number={locdata.Budaun.number} />} />
        <Route path="/havells-ro-service-Bagpat" element={<UttarPradeshHavells title={locdata.Bagpat.title} number={locdata.Bagpat.number} />} />
        <Route path="/havells-ro-service-Bahraich" element={<UttarPradeshHavells title={locdata.Bahraich.title} number={locdata.Bahraich.number} />} />
        <Route path="/havells-ro-service-Ballia" element={<UttarPradeshHavells title={locdata.Ballia.title} number={locdata.Ballia.number} />} />
        <Route path="/havells-ro-service-Balrampur" element={<UttarPradeshHavells title={locdata.Balrampur.title} number={locdata.Balrampur.number} />} />
        <Route path="/havells-ro-service-Banda" element={<UttarPradeshHavells title={locdata.Banda.title} number={locdata.Banda.number} />} />
        <Route path="/havells-ro-service-Barabanki" element={<UttarPradeshHavells title={locdata.Barabanki.title} number={locdata.Barabanki.number} />} />
        <Route path="/havells-ro-service-Bareilly" element={<UttarPradeshHavells title={locdata.Bareilly.title} number={locdata.Bareilly.number} />} />
        <Route path="/havells-ro-service-Basti" element={<UttarPradeshHavells title={locdata.Basti.title} number={locdata.Basti.number} />} />
        <Route path="/havells-ro-service-Bijnor" element={<UttarPradeshHavells title={locdata.Bijnor.title} number={locdata.Bijnor.number} />} />
        <Route path="/havells-ro-service-Bulandshahr" element={<UttarPradeshHavells title={locdata.Bulandshahr.title} number={locdata.Bulandshahr.number} />} />
        <Route path="/havells-ro-service-Chandauli" element={<UttarPradeshHavells title={locdata.Chandauli.title} number={locdata.Chandauli.number} />} />
        <Route path="/havells-ro-service-Chitrakoot" element={<UttarPradeshHavells title={locdata.Chitrakoot.title} number={locdata.Chitrakoot.number} />} />
        <Route path="/havells-ro-service-Deoria" element={<UttarPradeshHavells title={locdata.Deoria.title} number={locdata.Deoria.number} />} />
        <Route path="/havells-ro-service-Etah" element={<UttarPradeshHavells title={locdata.Etah.title} number={locdata.Etah.number} />} />
        <Route path="/havells-ro-service-Etawah" element={<UttarPradeshHavells title={locdata.Etawah.title} number={locdata.Etawah.number} />} />
        <Route path="/havells-ro-service-Farrukhabad" element={<UttarPradeshHavells title={locdata.Farrukhabad.title} number={locdata.Farrukhabad.number} />} />
        <Route path="/havells-ro-service-Fatehpur" element={<UttarPradeshHavells title={locdata.Fatehpur.title} number={locdata.Fatehpur.number} />} />
        <Route path="/havells-ro-service-Firozabad" element={<UttarPradeshHavells title={locdata.Firozabad.title} number={locdata.Firozabad.number} />} />
        <Route path="/havells-ro-service-Gautam-Buddha-Nagar" element={<UttarPradeshHavells title={locdata.GautamBuddhaNagar.title} number={locdata.GautamBuddhaNagar.number} />} />
        <Route path="/havells-ro-service-Ghaziabad" element={<UttarPradeshHavells title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/havells-ro-service-Ghazipur" element={<UttarPradeshHavells title={locdata.Ghazipur.title} number={locdata.Ghazipur.number} />} />
        <Route path="/havells-ro-service-Gonda" element={<UttarPradeshHavells title={locdata.Gonda.title} number={locdata.Gonda.number} />} />
        <Route path="/havells-ro-service-Gorakhpur" element={<UttarPradeshHavells title={locdata.Gorakhpur.title} number={locdata.Gorakhpur.number} />} />
        <Route path="/havells-ro-service-Hamirpur" element={<UttarPradeshHavells title={locdata.Hamirpur.title} number={locdata.Hamirpur.number} />} />
        <Route path="/havells-ro-service-Hapur" element={<UttarPradeshHavells title={locdata.Hapur.title} number={locdata.Hapur.number} />} />
        <Route path="/havells-ro-service-Hardoi" element={<UttarPradeshHavells title={locdata.Hardoi.title} number={locdata.Hardoi.number} />} />
        <Route path="/havells-ro-service-Hathras" element={<UttarPradeshHavells title={locdata.Hathras.title} number={locdata.Hathras.number} />} />
        <Route path="/havells-ro-service-Jalaun" element={<UttarPradeshHavells title={locdata.Jalaun.title} number={locdata.Jalaun.number} />} />
        <Route path="/havells-ro-service-Jaunpur" element={<UttarPradeshHavells title={locdata.Jaunpur.title} number={locdata.Jaunpur.number} />} />
        <Route path="/havells-ro-service-Jhansi" element={<UttarPradeshHavells title={locdata.Jhansi.title} number={locdata.Jhansi.number} />} />
        <Route path="/havells-ro-service-Kannauj" element={<UttarPradeshHavells title={locdata.Kannauj.title} number={locdata.Kannauj.number} />} />
        <Route path="/havells-ro-service-Kanpur-Dehat" element={<UttarPradeshHavells title={locdata.KanpurDehat.title} number={locdata.KanpurDehat.number} />} />
        <Route path="/havells-ro-service-Kanpur" element={<UttarPradeshHavells title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/havells-ro-service-Kasganj" element={<UttarPradeshHavells title={locdata.Kasganj.title} number={locdata.Kasganj.number} />} />
        <Route path="/havells-ro-service-Kaushambi" element={<UttarPradeshHavells title={locdata.Kaushambi.title} number={locdata.Kaushambi.number} />} />
        <Route path="/havells-ro-service-Kushinagar" element={<UttarPradeshHavells title={locdata.Kushinagar.title} number={locdata.Kushinagar.number} />} />
        <Route path="/havells-ro-service-Lakhimpur-Kheri" element={<UttarPradeshHavells title={locdata.LakhimpurKheri.title} number={locdata.LakhimpurKheri.number} />} />
        <Route path="/havells-ro-service-Lalitpur" element={<UttarPradeshHavells title={locdata.Lalitpur.title} number={locdata.Lalitpur.number} />} />
        <Route path="/havells-ro-service-Lucknow" element={<UttarPradeshHavells title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/havells-ro-service-Maharajganj" element={<UttarPradeshHavells title={locdata.Maharajganj.title} number={locdata.Maharajganj.number} />} />
        <Route path="/havells-ro-service-Mahoba" element={<UttarPradeshHavells title={locdata.Mahoba.title} number={locdata.Mahoba.number} />} />
        <Route path="/havells-ro-service-Mainpuri" element={<UttarPradeshHavells title={locdata.Mainpuri.title} number={locdata.Mainpuri.number} />} />
        <Route path="/havells-ro-service-Mathura" element={<UttarPradeshHavells title={locdata.Mathura.title} number={locdata.Mathura.number} />} />
        <Route path="/havells-ro-service-Mau" element={<UttarPradeshHavells title={locdata.Mau.title} number={locdata.Mau.number} />} />
        <Route path="/havells-ro-service-Meerut" element={<UttarPradeshHavells title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/havells-ro-service-Mirzapur" element={<UttarPradeshHavells title={locdata.Mirzapur.title} number={locdata.Mirzapur.number} />} />
        <Route path="/havells-ro-service-Moradabad" element={<UttarPradeshHavells title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/havells-ro-service-Muzaffarnagar" element={<UttarPradeshHavells title={locdata.Muzaffarnagar.title} number={locdata.Muzaffarnagar.number} />} />
        <Route path="/havells-ro-service-Pilibhit" element={<UttarPradeshHavells title={locdata.Pilibhit.title} number={locdata.Pilibhit.number} />} />
        <Route path="/havells-ro-service-Pratapgarh" element={<UttarPradeshHavells title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/havells-ro-service-Prayagraj" element={<UttarPradeshHavells title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/havells-ro-service-Rae-Bareli" element={<UttarPradeshHavells title={locdata.RaeBareli.title} number={locdata.RaeBareli.number} />} />
        <Route path="/havells-ro-service-Rampur" element={<UttarPradeshHavells title={locdata.Rampur.title} number={locdata.Rampur.number} />} />
        <Route path="/havells-ro-service-Saharanpur" element={<UttarPradeshHavells title={locdata.Saharanpur.title} number={locdata.Saharanpur.number} />} />
        <Route path="/havells-ro-service-Sant-Kabir-Nagar" element={<UttarPradeshHavells title={locdata.SantKabirNagar.title} number={locdata.SantKabirNagar.number} />} />
        <Route path="/havells-ro-service-Bhadohi" element={<UttarPradeshHavells title={locdata.Bhadohi.title} number={locdata.Bhadohi.number} />} />
        <Route path="/havells-ro-service-Sambhal" element={<UttarPradeshHavells title={locdata.Sambhal.title} number={locdata.Sambhal.number} />} />
        <Route path="/havells-ro-service-Shahjahanpur" element={<UttarPradeshHavells title={locdata.Shahjahanpur.title} number={locdata.Shahjahanpur.number} />} />
        <Route path="/havells-ro-service-Shamli" element={<UttarPradeshHavells title={locdata.Shamli.title} number={locdata.Shamli.number} />} />
        <Route path="/havells-ro-service-Shravasti" element={<UttarPradeshHavells title={locdata.Shravasti.title} number={locdata.Shravasti.number} />} />
        <Route path="/havells-ro-service-Siddharthnagar" element={<UttarPradeshHavells title={locdata.Siddharthnagar.title} number={locdata.Siddharthnagar.number} />} />
        <Route path="/havells-ro-service-Sitapur" element={<UttarPradeshHavells title={locdata.Sitapur.title} number={locdata.Sitapur.number} />} />
        <Route path="/havells-ro-service-Sonbhadra" element={<UttarPradeshHavells title={locdata.Sonbhadra.title} number={locdata.Sonbhadra.number} />} />
        <Route path="/havells-ro-service-Sultanpur" element={<UttarPradeshHavells title={locdata.Sultanpur.title} number={locdata.Sultanpur.number} />} />
        <Route path="/havells-ro-service-Unnao" element={<UttarPradeshHavells title={locdata.Unnao.title} number={locdata.Unnao.number} />} />
        <Route path="/havells-ro-service-Varanasi" element={<UttarPradeshHavells title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/havells-ro-service-Robertsganj" element={<UttarPradeshHavells title={locdata.Robertsganj.title} number={locdata.Robertsganj.number} />} />
        <Route path="/havells-ro-service-Bahjoi" element={<UttarPradeshHavells title={locdata.Bahjoi.title} number={locdata.Bahjoi.number} />} />
        <Route path="/havells-ro-service-Khalilabad" element={<UttarPradeshHavells title={locdata.Khalilabad.title} number={locdata.Khalilabad.number} />} />
        <Route path="/havells-ro-service-Akbarpur" element={<UttarPradeshHavells title={locdata.Akbarpur.title} number={locdata.Akbarpur.number} />} />
        <Route path="/havells-ro-service-Orai" element={<UttarPradeshHavells title={locdata.Orai.title} number={locdata.Orai.number} />} />
        <Route path="/havells-ro-service-Manjhanpur" element={<UttarPradeshHavells title={locdata.Manjhanpur.title} number={locdata.Manjhanpur.number} />} />
        <Route path="/havells-ro-service-Padarauna" element={<UttarPradeshHavells title={locdata.Padarauna.title} number={locdata.Padarauna.number} />} />
        <Route path="/havells-ro-service-Kheri" element={<UttarPradeshHavells title={locdata.Kheri.title} number={locdata.Kheri.number} />} />
        <Route path="/havells-ro-service-Fatehgarh" element={<UttarPradeshHavells title={locdata.Fatehgarh.title} number={locdata.Fatehgarh.number} />} />
        <Route path="/havells-ro-service-Noida" element={<UttarPradeshHavells title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/havells-ro-service-Greater-Noida" element={<UttarPradeshHavells title={locdata.GreaterNoida.title} number={locdata.GreaterNoida.number} />} />

        <Route path="/havells-ro-service-Indore" element={<MadhyaPradeshHavells title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/havells-ro-service-Bhopal" element={<MadhyaPradeshHavells title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/havells-ro-service-Jabalpur" element={<MadhyaPradeshHavells title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/havells-ro-service-Gwalior" element={<MadhyaPradeshHavells title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/havells-ro-service-Ujjain" element={<MadhyaPradeshHavells title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/havells-ro-service-Sagar" element={<MadhyaPradeshHavells title={locdata.Sagar.title} number={locdata.Sagar.number} />} />
        <Route path="/havells-ro-service-Dewas" element={<MadhyaPradeshHavells title={locdata.Dewas.title} number={locdata.Dewas.number} />} />
        <Route path="/havells-ro-service-Satna" element={<MadhyaPradeshHavells title={locdata.Satna.title} number={locdata.Satna.number} />} />
        <Route path="/havells-ro-service-Ratlam" element={<MadhyaPradeshHavells title={locdata.Ratlam.title} number={locdata.Ratlam.number} />} />
        <Route path="/havells-ro-service-Rewa" element={<MadhyaPradeshHavells title={locdata.Rewa.title} number={locdata.Rewa.number} />} />
        <Route path="/havells-ro-service-Katni" element={<MadhyaPradeshHavells title={locdata.Katni.title} number={locdata.Katni.number} />} />
        <Route path="/havells-ro-service-Singrauli" element={<MadhyaPradeshHavells title={locdata.Singrauli.title} number={locdata.Singrauli.number} />} />
        <Route path="/havells-ro-service-Burhanpur" element={<MadhyaPradeshHavells title={locdata.Burhanpur.title} number={locdata.Burhanpur.number} />} />
        <Route path="/havells-ro-service-Morena" element={<MadhyaPradeshHavells title={locdata.Morena.title} number={locdata.Morena.number} />} />
        <Route path="/havells-ro-service-Khandwa" element={<MadhyaPradeshHavells title={locdata.Khandwa.title} number={locdata.Khandwa.number} />} />
        <Route path="/havells-ro-service-Bhind" element={<MadhyaPradeshHavells title={locdata.Bhind.title} number={locdata.Bhind.number} />} />
        <Route path="/havells-ro-service-Chhindwara" element={<MadhyaPradeshHavells title={locdata.Chhindwara.title} number={locdata.Chhindwara.number} />} />
        <Route path="/havells-ro-service-Guna" element={<MadhyaPradeshHavells title={locdata.Guna.title} number={locdata.Guna.number} />} />
        <Route path="/havells-ro-service-Shivpuri" element={<MadhyaPradeshHavells title={locdata.Shivpuri.title} number={locdata.Shivpuri.number} />} />
        <Route path="/havells-ro-service-Vidisha" element={<MadhyaPradeshHavells title={locdata.Vidisha.title} number={locdata.Vidisha.number} />} />
        <Route path="/havells-ro-service-Chhatarpur" element={<MadhyaPradeshHavells title={locdata.Chhatarpur.title} number={locdata.Chhatarpur.number} />} />
        <Route path="/havells-ro-service-Damoh" element={<MadhyaPradeshHavells title={locdata.Damoh.title} number={locdata.Damoh.number} />} />
        <Route path="/havells-ro-service-Mandsaur" element={<MadhyaPradeshHavells title={locdata.Mandsaur.title} number={locdata.Mandsaur.number} />} />
        <Route path="/havells-ro-service-Khargone" element={<MadhyaPradeshHavells title={locdata.Khargone.title} number={locdata.Khargone.number} />} />
        <Route path="/havells-ro-service-Neemuch" element={<MadhyaPradeshHavells title={locdata.Neemuch.title} number={locdata.Neemuch.number} />} />
        <Route path="/havells-ro-service-Pithampur" element={<MadhyaPradeshHavells title={locdata.Pithampur.title} number={locdata.Pithampur.number} />} />
        <Route path="/havells-ro-service-Narmadapuram" element={<MadhyaPradeshHavells title={locdata.Narmadapuram.title} number={locdata.Narmadapuram.number} />} />
        <Route path="/havells-ro-service-Itarsi" element={<MadhyaPradeshHavells title={locdata.Itarsi.title} number={locdata.Itarsi.number} />} />
        <Route path="/havells-ro-service-Sehore" element={<MadhyaPradeshHavells title={locdata.Sehore.title} number={locdata.Sehore.number} />} />
        <Route path="/havells-ro-service-Betul" element={<MadhyaPradeshHavells title={locdata.Betul.title} number={locdata.Betul.number} />} />
        <Route path="/havells-ro-service-Seoni" element={<MadhyaPradeshHavells title={locdata.Seoni.title} number={locdata.Seoni.number} />} />
        <Route path="/havells-ro-service-Datia" element={<MadhyaPradeshHavells title={locdata.Datia.title} number={locdata.Datia.number} />} />
        <Route path="/havells-ro-service-Nagda" element={<MadhyaPradeshHavells title={locdata.Nagda.title} number={locdata.Nagda.number} />} />
        <Route path="/havells-ro-service-Shahdol" element={<MadhyaPradeshHavells title={locdata.Shahdol.title} number={locdata.Shahdol.number} />} />
        <Route path="/havells-ro-service-Dhar" element={<MadhyaPradeshHavells title={locdata.Dhar.title} number={locdata.Dhar.number} />} />

        <Route path="havells-ro-service-Ajmer" element={<RajasthanHavells title={locdata.Ajmer.title} number={locdata.Ajmer.number} />} />
        <Route path="havells-ro-service-Alwar" element={<RajasthanHavells title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="havells-ro-service-Balotra" element={<RajasthanHavells title={locdata.Balotra.title} number={locdata.Balotra.number} />} />
        <Route path="havells-ro-service-Banswara" element={<RajasthanHavells title={locdata.Banswara.title} number={locdata.Banswara.number} />} />
        <Route path="havells-ro-service-Baran" element={<RajasthanHavells title={locdata.Baran.title} number={locdata.Baran.number} />} />
        <Route path="havells-ro-service-Barmer" element={<RajasthanHavells title={locdata.Barmer.title} number={locdata.Barmer.number} />} />
        <Route path="havells-ro-service-Beawar" element={<RajasthanHavells title={locdata.Beawar.title} number={locdata.Beawar.number} />} />
        <Route path="havells-ro-service-Bharatpur" element={<RajasthanHavells title={locdata.Bharatpur.title} number={locdata.Bharatpur.number} />} />
        <Route path="havells-ro-service-Bhilwara" element={<RajasthanHavells title={locdata.Bhilwara.title} number={locdata.Bhilwara.number} />} />
        <Route path="havells-ro-service-Bikaner" element={<RajasthanHavells title={locdata.Bikaner.title} number={locdata.Bikaner.number} />} />
        <Route path="havells-ro-service-Bundi" element={<RajasthanHavells title={locdata.Bundi.title} number={locdata.Bundi.number} />} />
        <Route path="havells-ro-service-Chittorgarh" element={<RajasthanHavells title={locdata.Chittorgarh.title} number={locdata.Chittorgarh.number} />} />
        <Route path="havells-ro-service-Churu" element={<RajasthanHavells title={locdata.Churu.title} number={locdata.Churu.number} />} />
        <Route path="havells-ro-service-Dausa" element={<RajasthanHavells title={locdata.Dausa.title} number={locdata.Dausa.number} />} />
        <Route path="havells-ro-service-Deeg" element={<RajasthanHavells title={locdata.Deeg.title} number={locdata.Deeg.number} />} />
        <Route path="havells-ro-service-Deedwana" element={<RajasthanHavells title={locdata.Deedwana.title} number={locdata.Deedwana.number} />} />
        <Route path="havells-ro-service-Dholpur" element={<RajasthanHavells title={locdata.Dholpur.title} number={locdata.Dholpur.number} />} />
        <Route path="havells-ro-service-Dungarpur" element={<RajasthanHavells title={locdata.Dungarpur.title} number={locdata.Dungarpur.number} />} />
        <Route path="havells-ro-service-Hanumangarh" element={<RajasthanHavells title={locdata.Hanumangarh.title} number={locdata.Hanumangarh.number} />} />
        <Route path="havells-ro-service-Jaipur" element={<RajasthanHavells title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="havells-ro-service-Jaisalmer" element={<RajasthanHavells title={locdata.Jaisalmer.title} number={locdata.Jaisalmer.number} />} />
        <Route path="havells-ro-service-Jalore" element={<RajasthanHavells title={locdata.Jalore.title} number={locdata.Jalore.number} />} />
        <Route path="havells-ro-service-Jhalawar" element={<RajasthanHavells title={locdata.Jhalawar.title} number={locdata.Jhalawar.number} />} />
        <Route path="havells-ro-service-Jhunjhunu" element={<RajasthanHavells title={locdata.Jhunjhunu.title} number={locdata.Jhunjhunu.number} />} />
        <Route path="havells-ro-service-Jodhpur" element={<RajasthanHavells title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="havells-ro-service-Karauli" element={<RajasthanHavells title={locdata.Karauli.title} number={locdata.Karauli.number} />} />
        <Route path="havells-ro-service-Tijara" element={<RajasthanHavells title={locdata.Tijara.title} number={locdata.Tijara.number} />} />
        <Route path="havells-ro-service-Kotputli" element={<RajasthanHavells title={locdata.Kotputli.title} number={locdata.Kotputli.number} />} />
        <Route path="havells-ro-service-Kota" element={<RajasthanHavells title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="havells-ro-service-Nagaur" element={<RajasthanHavells title={locdata.Nagaur.title} number={locdata.Nagaur.number} />} />
        <Route path="havells-ro-service-Pali" element={<RajasthanHavells title={locdata.Pali.title} number={locdata.Pali.number} />} />
        <Route path="havells-ro-service-Phalodi" element={<RajasthanHavells title={locdata.Phalodi.title} number={locdata.Phalodi.number} />} />
        <Route path="havells-ro-service-Pratapgarh" element={<RajasthanHavells title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="havells-ro-service-Rajsamand" element={<RajasthanHavells title={locdata.Rajsamand.title} number={locdata.Rajsamand.number} />} />
        <Route path="havells-ro-service-Salumbar" element={<RajasthanHavells title={locdata.Salumbar.title} number={locdata.Salumbar.number} />} />
        <Route path="havells-ro-service-Sawai-Madhopur" element={<RajasthanHavells title={locdata.SawaiMadhopur.title} number={locdata.SawaiMadhopur.number} />} />
        <Route path="havells-ro-service-Sikar" element={<RajasthanHavells title={locdata.Sikar.title} number={locdata.Sikar.number} />} />
        <Route path="havells-ro-service-Sirohi" element={<RajasthanHavells title={locdata.Sirohi.title} number={locdata.Sirohi.number} />} />
        <Route path="havells-ro-service-Sri-Ganganagar" element={<RajasthanHavells title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="havells-ro-service-Tonk" element={<RajasthanHavells title={locdata.Tonk.title} number={locdata.Tonk.number} />} />
        <Route path="havells-ro-service-Udaipur" element={<RajasthanHavells title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />

        <Route path="/havells-ro-service-Mumbai" element={<Havellsro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/havells-ro-service-Delhi" element={<Havellsro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/havells-ro-service-Bengaluru" element={<Havellsro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/havells-ro-service-Hyderabad" element={<Havellsro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/havells-ro-service-Ahmedabad" element={<Havellsro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/havells-ro-service-Chennai" element={<Havellsro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/havells-ro-service-Surat" element={<Havellsro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/havells-ro-service-Pune" element={<Havellsro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/havells-ro-service-Jaipur" element={<Havellsro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/havells-ro-service-Lucknow" element={<Havellsro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/havells-ro-service-Kanpur" element={<Havellsro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/havells-ro-service-Nagpur" element={<Havellsro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/havells-ro-service-Indore" element={<Havellsro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/havells-ro-service-Thane" element={<Havellsro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/havells-ro-service-Bhopal" element={<Havellsro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/havells-ro-service-Visakhapatnam" element={<Havellsro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/havells-ro-service-Vadodara" element={<Havellsro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/havells-ro-service-Ghaziabad" element={<Havellsro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/havells-ro-service-Ludhiana" element={<Havellsro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/havells-ro-service-Agra" element={<Havellsro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/havells-ro-service-Nashik" element={<Havellsro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/havells-ro-service-Faridabad" element={<Havellsro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/havells-ro-service-Meerut" element={<Havellsro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/havells-ro-service-Rajkot" element={<Havellsro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/havells-ro-service-Varanasi" element={<Havellsro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/havells-ro-service-Srinagar" element={<Havellsro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/havells-ro-service-Amritsar" element={<Havellsro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/havells-ro-service-Navi-Mumbai" element={<Havellsro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/havells-ro-service-Prayagraj" element={<Havellsro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/havells-ro-service-Jabalpur" element={<Havellsro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/havells-ro-service-Gwalior" element={<Havellsro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/havells-ro-service-Coimbatore" element={<Havellsro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/havells-ro-service-Vijayawada" element={<Havellsro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/havells-ro-service-Jodhpur" element={<Havellsro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/havells-ro-service-Madurai" element={<Havellsro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/havells-ro-service-Raipur" element={<Havellsro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/havells-ro-service-Kota" element={<Havellsro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/havells-ro-service-Guwahati" element={<Havellsro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/havells-ro-service-Chandigarh" element={<Havellsro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/havells-ro-service-Thiruvananthapuram" element={<Havellsro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/havells-ro-service-Solapur" element={<Havellsro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/havells-ro-service-Tiruppur" element={<Havellsro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/havells-ro-service-Moradabad" element={<Havellsro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/havells-ro-service-Mysore" element={<Havellsro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/havells-ro-service-Gurgaon" element={<Havellsro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/havells-ro-service-Aligarh" element={<Havellsro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/havells-ro-service-Jalandhar" element={<Havellsro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/havells-ro-service-Bhubaneswar" element={<Havellsro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/havells-ro-service-Noida" element={<Havellsro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/havells-ro-service-Jamshedpur" element={<Havellsro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/havells-ro-service-Cuttack" element={<Havellsro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/havells-ro-service-Bhavnagar" element={<Havellsro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/havells-ro-service-Dehradun" element={<Havellsro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/havells-ro-service-Rourkela" element={<Havellsro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/havells-ro-service-Jamnagar" element={<Havellsro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/havells-ro-service-Ujjain" element={<Havellsro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/havells-ro-service-Jammu" element={<Havellsro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/havells-ro-service-Mangalore" element={<Havellsro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/havells-ro-service-Erode" element={<Havellsro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/havells-ro-service-Udaipur" element={<Havellsro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/havells-ro-service-Rajahmundry" element={<Havellsro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/havells-ro-service-Patiala" element={<Havellsro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/havells-ro-service-Agartala" element={<Havellsro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/havells-ro-service-Tirupati" element={<Havellsro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/havells-ro-service-Sambalpur" element={<Havellsro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/havells-ro-service-Bilaspur" element={<Havellsro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/havells-ro-service-Alwar" element={<Havellsro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/havells-ro-service-Bardhaman" element={<Havellsro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/havells-ro-service-Sonipat" element={<Havellsro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/havells-ro-service-New-Delhi" element={<Havellsro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/havells-ro-service-Puducherry" element={<Havellsro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/havells-ro-service-Haridwar" element={<Havellsro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/havells-ro-service-Vijayanagaram" element={<Havellsro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/havells-ro-service-Sri-Ganganagar" element={<Havellsro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/havells-ro-service-Secunderabad" element={<Havellsro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/havells-ro-service-Yamunanagar" element={<Havellsro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/havells-ro-service-Gandhinagar" element={<Havellsro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/havells-ro-service-Ambala" element={<Havellsro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/havells-ro-service-Vellore" element={<Havellsro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/havells-ro-service-Shimla" element={<Havellsro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/havells-ro-service-Amaravati" element={<Havellsro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />

        <Route path="/kent-ro-service-Araria" element={<BiharKent title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/kent-ro-service-Arwal" element={<BiharKent title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/kent-ro-service-Aurangabad" element={<BiharKent title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/kent-ro-service-Banka" element={<BiharKent title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/kent-ro-service-Begusarai" element={<BiharKent title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/kent-ro-service-Bhabua" element={<BiharKent title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/kent-ro-service-Bhagalpur" element={<BiharKent title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/kent-ro-service-Bhojpur" element={<BiharKent title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/kent-ro-service-Bihar-Sharif" element={<BiharKent title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/kent-ro-service-Buxar" element={<BiharKent title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/kent-ro-service-Chhapra" element={<BiharKent title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/kent-ro-service-Darbhanga" element={<BiharKent title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/kent-ro-service-East-Champaran" element={<BiharKent title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/kent-ro-service-Gaya" element={<BiharKent title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/kent-ro-service-Gopalganj" element={<BiharKent title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/kent-ro-service-Jamui" element={<BiharKent title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/kent-ro-service-Jehanabad" element={<BiharKent title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/kent-ro-service-Khagaria" element={<BiharKent title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/kent-ro-service-Kishanganj" element={<BiharKent title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/kent-ro-service-Kaimur" element={<BiharKent title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/kent-ro-service-Katihar" element={<BiharKent title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/kent-ro-service-Lakhisarai" element={<BiharKent title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/kent-ro-service-Madhubani" element={<BiharKent title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/kent-ro-service-Munger" element={<BiharKent title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/kent-ro-service-Madhepura" element={<BiharKent title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/kent-ro-service-Muzaffarpur" element={<BiharKent title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/kent-ro-service-Nalanda" element={<BiharKent title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/kent-ro-service-Nawada" element={<BiharKent title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/kent-ro-service-Patna" element={<BiharKent title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/kent-ro-service-Purnia" element={<BiharKent title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/kent-ro-service-Rohtas" element={<BiharKent title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/kent-ro-service-Saharsa" element={<BiharKent title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/kent-ro-service-Samastipur" element={<BiharKent title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/kent-ro-service-Sasaram" element={<BiharKent title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/kent-ro-service-Sheohar" element={<BiharKent title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/kent-ro-service-Sheikhpura" element={<BiharKent title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/kent-ro-service-Saran" element={<BiharKent title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/kent-ro-service-Sitamarhi" element={<BiharKent title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/kent-ro-service-Supaul" element={<BiharKent title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/kent-ro-service-Siwan" element={<BiharKent title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/kent-ro-service-Vaishali" element={<BiharKent title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/kent-ro-service-West-Champaran" element={<BiharKent title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/kent-ro-service-Garhwa" element={<JharkhandKent title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/kent-ro-service-Palamu" element={<JharkhandKent title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/kent-ro-service-Latehar" element={<JharkhandKent title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/kent-ro-service-Chatra" element={<JharkhandKent title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/kent-ro-service-Hazaribagh" element={<JharkhandKent title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/kent-ro-service-Jamshedpur" element={<JharkhandKent title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/kent-ro-service-Koderma" element={<JharkhandKent title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/kent-ro-service-Giridih" element={<JharkhandKent title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/kent-ro-service-Ramgarh" element={<JharkhandKent title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/kent-ro-service-Bokaro" element={<JharkhandKent title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/kent-ro-service-Dhanbad" element={<JharkhandKent title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/kent-ro-service-Lohardaga" element={<JharkhandKent title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/kent-ro-service-Gumla" element={<JharkhandKent title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/kent-ro-service-Simdega" element={<JharkhandKent title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/kent-ro-service-Ranchi" element={<JharkhandKent title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/kent-ro-service-Khunti" element={<JharkhandKent title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/kent-ro-service-Singhbhum" element={<JharkhandKent title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/kent-ro-service-Saraikela-Kharsawan" element={<JharkhandKent title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/kent-ro-service-Jamtara" element={<JharkhandKent title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/kent-ro-service-Deoghar" element={<JharkhandKent title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/kent-ro-service-Dumka" element={<JharkhandKent title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/kent-ro-service-Pakur" element={<JharkhandKent title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/kent-ro-service-Godda" element={<JharkhandKent title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/kent-ro-service-Sahebganj" element={<JharkhandKent title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />

        <Route path="/kent-ro-service-Alipurduar" element={<BengalKent title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/kent-ro-service-Bankura" element={<BengalKent title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/kent-ro-service-Birbhum" element={<BengalKent title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/kent-ro-service-Cooch-Behar" element={<BengalKent title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/kent-ro-service-Dakshin-Dinajpur" element={<BengalKent title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/kent-ro-service-Darjeeling" element={<BengalKent title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/kent-ro-service-Durgapur" element={<BengalKent title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/kent-ro-service-Hooghly" element={<BengalKent title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/kent-ro-service-Howrah" element={<BengalKent title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/kent-ro-service-Jalpaiguri" element={<BengalKent title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/kent-ro-service-Jhargram" element={<BengalKent title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/kent-ro-service-Kalimpong" element={<BengalKent title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/kent-ro-service-Kolkata" element={<BengalKent title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/kent-ro-service-Malda" element={<BengalKent title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/kent-ro-service-Murshidabad" element={<BengalKent title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/kent-ro-service-Nadia" element={<BengalKent title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/kent-ro-service-North-24-Parganas" element={<BengalKent title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/kent-ro-service-Paschim-Bardhaman" element={<BengalKent title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/kent-ro-service-Paschim-Medinipur" element={<BengalKent title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/kent-ro-service-Purba-Bardhaman" element={<BengalKent title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/kent-ro-service-Purba-Medinipur" element={<BengalKent title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/kent-ro-service-South-24-Parganas" element={<BengalKent title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/kent-ro-service-Uttar-Dinajpur" element={<BengalKent title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />
        <Route path="/kent-ro-service-Agra" element={<UttarPradeshKent title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/kent-ro-service-Aligarh" element={<UttarPradeshKent title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/kent-ro-service-Ambedkar-Nagar" element={<UttarPradeshKent title={locdata.AmbedkarNagar.title} number={locdata.AmbedkarNagar.number} />} />
        <Route path="/kent-ro-service-Ayodhya" element={<UttarPradeshKent title={locdata.Ayodhya.title} number={locdata.Ayodhya.number} />} />
        <Route path="/kent-ro-service-Amethi" element={<UttarPradeshKent title={locdata.Amethi.title} number={locdata.Amethi.number} />} />
        <Route path="/kent-ro-service-Amroha" element={<UttarPradeshKent title={locdata.Amroha.title} number={locdata.Amroha.number} />} />
        <Route path="/kent-ro-service-Auraiya" element={<UttarPradeshKent title={locdata.Auraiya.title} number={locdata.Auraiya.number} />} />
        <Route path="/kent-ro-service-Azamgarh" element={<UttarPradeshKent title={locdata.Azamgarh.title} number={locdata.Azamgarh.number} />} />
        <Route path="/kent-ro-service-Budaun" element={<UttarPradeshKent title={locdata.Budaun.title} number={locdata.Budaun.number} />} />
        <Route path="/kent-ro-service-Bagpat" element={<UttarPradeshKent title={locdata.Bagpat.title} number={locdata.Bagpat.number} />} />
        <Route path="/kent-ro-service-Bahraich" element={<UttarPradeshKent title={locdata.Bahraich.title} number={locdata.Bahraich.number} />} />
        <Route path="/kent-ro-service-Ballia" element={<UttarPradeshKent title={locdata.Ballia.title} number={locdata.Ballia.number} />} />
        <Route path="/kent-ro-service-Balrampur" element={<UttarPradeshKent title={locdata.Balrampur.title} number={locdata.Balrampur.number} />} />
        <Route path="/kent-ro-service-Banda" element={<UttarPradeshKent title={locdata.Banda.title} number={locdata.Banda.number} />} />
        <Route path="/kent-ro-service-Barabanki" element={<UttarPradeshKent title={locdata.Barabanki.title} number={locdata.Barabanki.number} />} />
        <Route path="/kent-ro-service-Bareilly" element={<UttarPradeshKent title={locdata.Bareilly.title} number={locdata.Bareilly.number} />} />
        <Route path="/kent-ro-service-Basti" element={<UttarPradeshKent title={locdata.Basti.title} number={locdata.Basti.number} />} />
        <Route path="/kent-ro-service-Bijnor" element={<UttarPradeshKent title={locdata.Bijnor.title} number={locdata.Bijnor.number} />} />
        <Route path="/kent-ro-service-Bulandshahr" element={<UttarPradeshKent title={locdata.Bulandshahr.title} number={locdata.Bulandshahr.number} />} />
        <Route path="/kent-ro-service-Chandauli" element={<UttarPradeshKent title={locdata.Chandauli.title} number={locdata.Chandauli.number} />} />
        <Route path="/kent-ro-service-Chitrakoot" element={<UttarPradeshKent title={locdata.Chitrakoot.title} number={locdata.Chitrakoot.number} />} />
        <Route path="/kent-ro-service-Deoria" element={<UttarPradeshKent title={locdata.Deoria.title} number={locdata.Deoria.number} />} />
        <Route path="/kent-ro-service-Etah" element={<UttarPradeshKent title={locdata.Etah.title} number={locdata.Etah.number} />} />
        <Route path="/kent-ro-service-Etawah" element={<UttarPradeshKent title={locdata.Etawah.title} number={locdata.Etawah.number} />} />
        <Route path="/kent-ro-service-Farrukhabad" element={<UttarPradeshKent title={locdata.Farrukhabad.title} number={locdata.Farrukhabad.number} />} />
        <Route path="/kent-ro-service-Fatehpur" element={<UttarPradeshKent title={locdata.Fatehpur.title} number={locdata.Fatehpur.number} />} />
        <Route path="/kent-ro-service-Firozabad" element={<UttarPradeshKent title={locdata.Firozabad.title} number={locdata.Firozabad.number} />} />
        <Route path="/kent-ro-service-Gautam-Buddha-Nagar" element={<UttarPradeshKent title={locdata.GautamBuddhaNagar.title} number={locdata.GautamBuddhaNagar.number} />} />
        <Route path="/kent-ro-service-Ghaziabad" element={<UttarPradeshKent title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/kent-ro-service-Ghazipur" element={<UttarPradeshKent title={locdata.Ghazipur.title} number={locdata.Ghazipur.number} />} />
        <Route path="/kent-ro-service-Gonda" element={<UttarPradeshKent title={locdata.Gonda.title} number={locdata.Gonda.number} />} />
        <Route path="/kent-ro-service-Gorakhpur" element={<UttarPradeshKent title={locdata.Gorakhpur.title} number={locdata.Gorakhpur.number} />} />
        <Route path="/kent-ro-service-Hamirpur" element={<UttarPradeshKent title={locdata.Hamirpur.title} number={locdata.Hamirpur.number} />} />
        <Route path="/kent-ro-service-Hapur" element={<UttarPradeshKent title={locdata.Hapur.title} number={locdata.Hapur.number} />} />
        <Route path="/kent-ro-service-Hardoi" element={<UttarPradeshKent title={locdata.Hardoi.title} number={locdata.Hardoi.number} />} />
        <Route path="/kent-ro-service-Hathras" element={<UttarPradeshKent title={locdata.Hathras.title} number={locdata.Hathras.number} />} />
        <Route path="/kent-ro-service-Jalaun" element={<UttarPradeshKent title={locdata.Jalaun.title} number={locdata.Jalaun.number} />} />
        <Route path="/kent-ro-service-Jaunpur" element={<UttarPradeshKent title={locdata.Jaunpur.title} number={locdata.Jaunpur.number} />} />
        <Route path="/kent-ro-service-Jhansi" element={<UttarPradeshKent title={locdata.Jhansi.title} number={locdata.Jhansi.number} />} />
        <Route path="/kent-ro-service-Kannauj" element={<UttarPradeshKent title={locdata.Kannauj.title} number={locdata.Kannauj.number} />} />
        <Route path="/kent-ro-service-Kanpur-Dehat" element={<UttarPradeshKent title={locdata.KanpurDehat.title} number={locdata.KanpurDehat.number} />} />
        <Route path="/kent-ro-service-Kanpur" element={<UttarPradeshKent title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/kent-ro-service-Kasganj" element={<UttarPradeshKent title={locdata.Kasganj.title} number={locdata.Kasganj.number} />} />
        <Route path="/kent-ro-service-Kaushambi" element={<UttarPradeshKent title={locdata.Kaushambi.title} number={locdata.Kaushambi.number} />} />
        <Route path="/kent-ro-service-Kushinagar" element={<UttarPradeshKent title={locdata.Kushinagar.title} number={locdata.Kushinagar.number} />} />
        <Route path="/kent-ro-service-Lakhimpur-Kheri" element={<UttarPradeshKent title={locdata.LakhimpurKheri.title} number={locdata.LakhimpurKheri.number} />} />
        <Route path="/kent-ro-service-Lalitpur" element={<UttarPradeshKent title={locdata.Lalitpur.title} number={locdata.Lalitpur.number} />} />
        <Route path="/kent-ro-service-Lucknow" element={<UttarPradeshKent title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/kent-ro-service-Maharajganj" element={<UttarPradeshKent title={locdata.Maharajganj.title} number={locdata.Maharajganj.number} />} />
        <Route path="/kent-ro-service-Mahoba" element={<UttarPradeshKent title={locdata.Mahoba.title} number={locdata.Mahoba.number} />} />
        <Route path="/kent-ro-service-Mainpuri" element={<UttarPradeshKent title={locdata.Mainpuri.title} number={locdata.Mainpuri.number} />} />
        <Route path="/kent-ro-service-Mathura" element={<UttarPradeshKent title={locdata.Mathura.title} number={locdata.Mathura.number} />} />
        <Route path="/kent-ro-service-Mau" element={<UttarPradeshKent title={locdata.Mau.title} number={locdata.Mau.number} />} />
        <Route path="/kent-ro-service-Meerut" element={<UttarPradeshKent title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/kent-ro-service-Mirzapur" element={<UttarPradeshKent title={locdata.Mirzapur.title} number={locdata.Mirzapur.number} />} />
        <Route path="/kent-ro-service-Moradabad" element={<UttarPradeshKent title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/kent-ro-service-Muzaffarnagar" element={<UttarPradeshKent title={locdata.Muzaffarnagar.title} number={locdata.Muzaffarnagar.number} />} />
        <Route path="/kent-ro-service-Pilibhit" element={<UttarPradeshKent title={locdata.Pilibhit.title} number={locdata.Pilibhit.number} />} />
        <Route path="/kent-ro-service-Pratapgarh" element={<UttarPradeshKent title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/kent-ro-service-Prayagraj" element={<UttarPradeshKent title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/kent-ro-service-Rae-Bareli" element={<UttarPradeshKent title={locdata.RaeBareli.title} number={locdata.RaeBareli.number} />} />
        <Route path="/kent-ro-service-Rampur" element={<UttarPradeshKent title={locdata.Rampur.title} number={locdata.Rampur.number} />} />
        <Route path="/kent-ro-service-Saharanpur" element={<UttarPradeshKent title={locdata.Saharanpur.title} number={locdata.Saharanpur.number} />} />
        <Route path="/kent-ro-service-Sant-Kabir-Nagar" element={<UttarPradeshKent title={locdata.SantKabirNagar.title} number={locdata.SantKabirNagar.number} />} />
        <Route path="/kent-ro-service-Bhadohi" element={<UttarPradeshKent title={locdata.Bhadohi.title} number={locdata.Bhadohi.number} />} />
        <Route path="/kent-ro-service-Sambhal" element={<UttarPradeshKent title={locdata.Sambhal.title} number={locdata.Sambhal.number} />} />
        <Route path="/kent-ro-service-Shahjahanpur" element={<UttarPradeshKent title={locdata.Shahjahanpur.title} number={locdata.Shahjahanpur.number} />} />
        <Route path="/kent-ro-service-Shamli" element={<UttarPradeshKent title={locdata.Shamli.title} number={locdata.Shamli.number} />} />
        <Route path="/kent-ro-service-Shravasti" element={<UttarPradeshKent title={locdata.Shravasti.title} number={locdata.Shravasti.number} />} />
        <Route path="/kent-ro-service-Siddharthnagar" element={<UttarPradeshKent title={locdata.Siddharthnagar.title} number={locdata.Siddharthnagar.number} />} />
        <Route path="/kent-ro-service-Sitapur" element={<UttarPradeshKent title={locdata.Sitapur.title} number={locdata.Sitapur.number} />} />
        <Route path="/kent-ro-service-Sonbhadra" element={<UttarPradeshKent title={locdata.Sonbhadra.title} number={locdata.Sonbhadra.number} />} />
        <Route path="/kent-ro-service-Sultanpur" element={<UttarPradeshKent title={locdata.Sultanpur.title} number={locdata.Sultanpur.number} />} />
        <Route path="/kent-ro-service-Unnao" element={<UttarPradeshKent title={locdata.Unnao.title} number={locdata.Unnao.number} />} />
        <Route path="/kent-ro-service-Varanasi" element={<UttarPradeshKent title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/kent-ro-service-Robertsganj" element={<UttarPradeshKent title={locdata.Robertsganj.title} number={locdata.Robertsganj.number} />} />
        <Route path="/kent-ro-service-Bahjoi" element={<UttarPradeshKent title={locdata.Bahjoi.title} number={locdata.Bahjoi.number} />} />
        <Route path="/kent-ro-service-Khalilabad" element={<UttarPradeshKent title={locdata.Khalilabad.title} number={locdata.Khalilabad.number} />} />
        <Route path="/kent-ro-service-Akbarpur" element={<UttarPradeshKent title={locdata.Akbarpur.title} number={locdata.Akbarpur.number} />} />
        <Route path="/kent-ro-service-Orai" element={<UttarPradeshKent title={locdata.Orai.title} number={locdata.Orai.number} />} />
        <Route path="/kent-ro-service-Manjhanpur" element={<UttarPradeshKent title={locdata.Manjhanpur.title} number={locdata.Manjhanpur.number} />} />
        <Route path="/kent-ro-service-Padarauna" element={<UttarPradeshKent title={locdata.Padarauna.title} number={locdata.Padarauna.number} />} />
        <Route path="/kent-ro-service-Kheri" element={<UttarPradeshKent title={locdata.Kheri.title} number={locdata.Kheri.number} />} />
        <Route path="/kent-ro-service-Fatehgarh" element={<UttarPradeshKent title={locdata.Fatehgarh.title} number={locdata.Fatehgarh.number} />} />
        <Route path="/kent-ro-service-Noida" element={<UttarPradeshKent title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/kent-ro-service-Greater-Noida" element={<UttarPradeshKent title={locdata.GreaterNoida.title} number={locdata.GreaterNoida.number} />} />

        <Route path="/kent-ro-service-Indore" element={<MadhyaPradeshKent title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/kent-ro-service-Bhopal" element={<MadhyaPradeshKent title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/kent-ro-service-Jabalpur" element={<MadhyaPradeshKent title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/kent-ro-service-Gwalior" element={<MadhyaPradeshKent title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/kent-ro-service-Ujjain" element={<MadhyaPradeshKent title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/kent-ro-service-Sagar" element={<MadhyaPradeshKent title={locdata.Sagar.title} number={locdata.Sagar.number} />} />
        <Route path="/kent-ro-service-Dewas" element={<MadhyaPradeshKent title={locdata.Dewas.title} number={locdata.Dewas.number} />} />
        <Route path="/kent-ro-service-Satna" element={<MadhyaPradeshKent title={locdata.Satna.title} number={locdata.Satna.number} />} />
        <Route path="/kent-ro-service-Ratlam" element={<MadhyaPradeshKent title={locdata.Ratlam.title} number={locdata.Ratlam.number} />} />
        <Route path="/kent-ro-service-Rewa" element={<MadhyaPradeshKent title={locdata.Rewa.title} number={locdata.Rewa.number} />} />
        <Route path="/kent-ro-service-Katni" element={<MadhyaPradeshKent title={locdata.Katni.title} number={locdata.Katni.number} />} />
        <Route path="/kent-ro-service-Singrauli" element={<MadhyaPradeshKent title={locdata.Singrauli.title} number={locdata.Singrauli.number} />} />
        <Route path="/kent-ro-service-Burhanpur" element={<MadhyaPradeshKent title={locdata.Burhanpur.title} number={locdata.Burhanpur.number} />} />
        <Route path="/kent-ro-service-Morena" element={<MadhyaPradeshKent title={locdata.Morena.title} number={locdata.Morena.number} />} />
        <Route path="/kent-ro-service-Khandwa" element={<MadhyaPradeshKent title={locdata.Khandwa.title} number={locdata.Khandwa.number} />} />
        <Route path="/kent-ro-service-Bhind" element={<MadhyaPradeshKent title={locdata.Bhind.title} number={locdata.Bhind.number} />} />
        <Route path="/kent-ro-service-Chhindwara" element={<MadhyaPradeshKent title={locdata.Chhindwara.title} number={locdata.Chhindwara.number} />} />
        <Route path="/kent-ro-service-Guna" element={<MadhyaPradeshKent title={locdata.Guna.title} number={locdata.Guna.number} />} />
        <Route path="/kent-ro-service-Shivpuri" element={<MadhyaPradeshKent title={locdata.Shivpuri.title} number={locdata.Shivpuri.number} />} />
        <Route path="/kent-ro-service-Vidisha" element={<MadhyaPradeshKent title={locdata.Vidisha.title} number={locdata.Vidisha.number} />} />
        <Route path="/kent-ro-service-Chhatarpur" element={<MadhyaPradeshKent title={locdata.Chhatarpur.title} number={locdata.Chhatarpur.number} />} />
        <Route path="/kent-ro-service-Damoh" element={<MadhyaPradeshKent title={locdata.Damoh.title} number={locdata.Damoh.number} />} />
        <Route path="/kent-ro-service-Mandsaur" element={<MadhyaPradeshKent title={locdata.Mandsaur.title} number={locdata.Mandsaur.number} />} />
        <Route path="/kent-ro-service-Khargone" element={<MadhyaPradeshKent title={locdata.Khargone.title} number={locdata.Khargone.number} />} />
        <Route path="/kent-ro-service-Neemuch" element={<MadhyaPradeshKent title={locdata.Neemuch.title} number={locdata.Neemuch.number} />} />
        <Route path="/kent-ro-service-Pithampur" element={<MadhyaPradeshKent title={locdata.Pithampur.title} number={locdata.Pithampur.number} />} />
        <Route path="/kent-ro-service-Narmadapuram" element={<MadhyaPradeshKent title={locdata.Narmadapuram.title} number={locdata.Narmadapuram.number} />} />
        <Route path="/kent-ro-service-Itarsi" element={<MadhyaPradeshKent title={locdata.Itarsi.title} number={locdata.Itarsi.number} />} />
        <Route path="/kent-ro-service-Sehore" element={<MadhyaPradeshKent title={locdata.Sehore.title} number={locdata.Sehore.number} />} />
        <Route path="/kent-ro-service-Betul" element={<MadhyaPradeshKent title={locdata.Betul.title} number={locdata.Betul.number} />} />
        <Route path="/kent-ro-service-Seoni" element={<MadhyaPradeshKent title={locdata.Seoni.title} number={locdata.Seoni.number} />} />
        <Route path="/kent-ro-service-Datia" element={<MadhyaPradeshKent title={locdata.Datia.title} number={locdata.Datia.number} />} />
        <Route path="/kent-ro-service-Nagda" element={<MadhyaPradeshKent title={locdata.Nagda.title} number={locdata.Nagda.number} />} />
        <Route path="/kent-ro-service-Shahdol" element={<MadhyaPradeshKent title={locdata.Shahdol.title} number={locdata.Shahdol.number} />} />
        <Route path="/kent-ro-service-Dhar" element={<MadhyaPradeshKent title={locdata.Dhar.title} number={locdata.Dhar.number} />} />

        <Route path="/kent-ro-service-Ajmer" element={<RajasthanKent title={locdata.Ajmer.title} number={locdata.Ajmer.number} />} />
        <Route path="/kent-ro-service-Alwar" element={<RajasthanKent title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/kent-ro-service-Balotra" element={<RajasthanKent title={locdata.Balotra.title} number={locdata.Balotra.number} />} />
        <Route path="/kent-ro-service-Banswara" element={<RajasthanKent title={locdata.Banswara.title} number={locdata.Banswara.number} />} />
        <Route path="/kent-ro-service-Baran" element={<RajasthanKent title={locdata.Baran.title} number={locdata.Baran.number} />} />
        <Route path="/kent-ro-service-Barmer" element={<RajasthanKent title={locdata.Barmer.title} number={locdata.Barmer.number} />} />
        <Route path="/kent-ro-service-Beawar" element={<RajasthanKent title={locdata.Beawar.title} number={locdata.Beawar.number} />} />
        <Route path="/kent-ro-service-Bharatpur" element={<RajasthanKent title={locdata.Bharatpur.title} number={locdata.Bharatpur.number} />} />
        <Route path="/kent-ro-service-Bhilwara" element={<RajasthanKent title={locdata.Bhilwara.title} number={locdata.Bhilwara.number} />} />
        <Route path="/kent-ro-service-Bikaner" element={<RajasthanKent title={locdata.Bikaner.title} number={locdata.Bikaner.number} />} />
        <Route path="/kent-ro-service-Bundi" element={<RajasthanKent title={locdata.Bundi.title} number={locdata.Bundi.number} />} />
        <Route path="/kent-ro-service-Chittorgarh" element={<RajasthanKent title={locdata.Chittorgarh.title} number={locdata.Chittorgarh.number} />} />
        <Route path="/kent-ro-service-Churu" element={<RajasthanKent title={locdata.Churu.title} number={locdata.Churu.number} />} />
        <Route path="/kent-ro-service-Dausa" element={<RajasthanKent title={locdata.Dausa.title} number={locdata.Dausa.number} />} />
        <Route path="/kent-ro-service-Deeg" element={<RajasthanKent title={locdata.Deeg.title} number={locdata.Deeg.number} />} />
        <Route path="/kent-ro-service-Deedwana" element={<RajasthanKent title={locdata.Deedwana.title} number={locdata.Deedwana.number} />} />
        <Route path="/kent-ro-service-Dholpur" element={<RajasthanKent title={locdata.Dholpur.title} number={locdata.Dholpur.number} />} />
        <Route path="/kent-ro-service-Dungarpur" element={<RajasthanKent title={locdata.Dungarpur.title} number={locdata.Dungarpur.number} />} />
        <Route path="/kent-ro-service-Hanumangarh" element={<RajasthanKent title={locdata.Hanumangarh.title} number={locdata.Hanumangarh.number} />} />
        <Route path="/kent-ro-service-Jaipur" element={<RajasthanKent title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/kent-ro-service-Jaisalmer" element={<RajasthanKent title={locdata.Jaisalmer.title} number={locdata.Jaisalmer.number} />} />
        <Route path="/kent-ro-service-Jalore" element={<RajasthanKent title={locdata.Jalore.title} number={locdata.Jalore.number} />} />
        <Route path="/kent-ro-service-Jhalawar" element={<RajasthanKent title={locdata.Jhalawar.title} number={locdata.Jhalawar.number} />} />
        <Route path="/kent-ro-service-Jhunjhunu" element={<RajasthanKent title={locdata.Jhunjhunu.title} number={locdata.Jhunjhunu.number} />} />
        <Route path="/kent-ro-service-Jodhpur" element={<RajasthanKent title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/kent-ro-service-Karauli" element={<RajasthanKent title={locdata.Karauli.title} number={locdata.Karauli.number} />} />
        <Route path="/kent-ro-service-Tijara" element={<RajasthanKent title={locdata.Tijara.title} number={locdata.Tijara.number} />} />
        <Route path="/kent-ro-service-Kotputli" element={<RajasthanKent title={locdata.Kotputli.title} number={locdata.Kotputli.number} />} />
        <Route path="/kent-ro-service-Kota" element={<RajasthanKent title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/kent-ro-service-Nagaur" element={<RajasthanKent title={locdata.Nagaur.title} number={locdata.Nagaur.number} />} />
        <Route path="/kent-ro-service-Pali" element={<RajasthanKent title={locdata.Pali.title} number={locdata.Pali.number} />} />
        <Route path="/kent-ro-service-Phalodi" element={<RajasthanKent title={locdata.Phalodi.title} number={locdata.Phalodi.number} />} />
        <Route path="/kent-ro-service-Pratapgarh" element={<RajasthanKent title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/kent-ro-service-Rajsamand" element={<RajasthanKent title={locdata.Rajsamand.title} number={locdata.Rajsamand.number} />} />
        <Route path="/kent-ro-service-Salumbar" element={<RajasthanKent title={locdata.Salumbar.title} number={locdata.Salumbar.number} />} />
        <Route path="/kent-ro-service-Sawai-Madhopur" element={<RajasthanKent title={locdata.SawaiMadhopur.title} number={locdata.SawaiMadhopur.number} />} />
        <Route path="/kent-ro-service-Sikar" element={<RajasthanKent title={locdata.Sikar.title} number={locdata.Sikar.number} />} />
        <Route path="/kent-ro-service-Sirohi" element={<RajasthanKent title={locdata.Sirohi.title} number={locdata.Sirohi.number} />} />
        <Route path="/kent-ro-service-Sri-Ganganagar" element={<RajasthanKent title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/kent-ro-service-Tonk" element={<RajasthanKent title={locdata.Tonk.title} number={locdata.Tonk.number} />} />
        <Route path="/kent-ro-service-Udaipur" element={<RajasthanKent title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />

        <Route path="/kent-ro-service-Mumbai" element={<Kentro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/kent-ro-service-Delhi" element={<Kentro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/kent-ro-service-Bengaluru" element={<Kentro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/kent-ro-service-Hyderabad" element={<Kentro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/kent-ro-service-Ahmedabad" element={<Kentro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/kent-ro-service-Chennai" element={<Kentro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/kent-ro-service-Surat" element={<Kentro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/kent-ro-service-Pune" element={<Kentro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/kent-ro-service-Jaipur" element={<Kentro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/kent-ro-service-Lucknow" element={<Kentro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/kent-ro-service-Kanpur" element={<Kentro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/kent-ro-service-Nagpur" element={<Kentro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/kent-ro-service-Indore" element={<Kentro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/kent-ro-service-Thane" element={<Kentro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/kent-ro-service-Bhopal" element={<Kentro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/kent-ro-service-Visakhapatnam" element={<Kentro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/kent-ro-service-Vadodara" element={<Kentro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/kent-ro-service-Ghaziabad" element={<Kentro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/kent-ro-service-Ludhiana" element={<Kentro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/kent-ro-service-Agra" element={<Kentro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/kent-ro-service-Nashik" element={<Kentro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/kent-ro-service-Faridabad" element={<Kentro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/kent-ro-service-Meerut" element={<Kentro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/kent-ro-service-Rajkot" element={<Kentro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/kent-ro-service-Varanasi" element={<Kentro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/kent-ro-service-Srinagar" element={<Kentro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/kent-ro-service-Amritsar" element={<Kentro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/kent-ro-service-Navi-Mumbai" element={<Kentro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/kent-ro-service-Prayagraj" element={<Kentro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/kent-ro-service-Jabalpur" element={<Kentro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/kent-ro-service-Gwalior" element={<Kentro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/kent-ro-service-Coimbatore" element={<Kentro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/kent-ro-service-Vijayawada" element={<Kentro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/kent-ro-service-Jodhpur" element={<Kentro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/kent-ro-service-Madurai" element={<Kentro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/kent-ro-service-Raipur" element={<Kentro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/kent-ro-service-Kota" element={<Kentro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/kent-ro-service-Guwahati" element={<Kentro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/kent-ro-service-Chandigarh" element={<Kentro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/kent-ro-service-Thiruvananthapuram" element={<Kentro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/kent-ro-service-Solapur" element={<Kentro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/kent-ro-service-Tiruppur" element={<Kentro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/kent-ro-service-Moradabad" element={<Kentro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/kent-ro-service-Mysore" element={<Kentro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/kent-ro-service-Gurgaon" element={<Kentro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/kent-ro-service-Aligarh" element={<Kentro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/kent-ro-service-Jalandhar" element={<Kentro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/kent-ro-service-Bhubaneswar" element={<Kentro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/kent-ro-service-Noida" element={<Kentro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/kent-ro-service-Jamshedpur" element={<Kentro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/kent-ro-service-Cuttack" element={<Kentro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/kent-ro-service-Bhavnagar" element={<Kentro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/kent-ro-service-Dehradun" element={<Kentro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/kent-ro-service-Rourkela" element={<Kentro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/kent-ro-service-Jamnagar" element={<Kentro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/kent-ro-service-Ujjain" element={<Kentro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/kent-ro-service-Jammu" element={<Kentro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/kent-ro-service-Mangalore" element={<Kentro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/kent-ro-service-Erode" element={<Kentro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/kent-ro-service-Udaipur" element={<Kentro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/kent-ro-service-Rajahmundry" element={<Kentro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/kent-ro-service-Patiala" element={<Kentro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/kent-ro-service-Agartala" element={<Kentro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/kent-ro-service-Tirupati" element={<Kentro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/kent-ro-service-Sambalpur" element={<Kentro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/kent-ro-service-Bilaspur" element={<Kentro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/kent-ro-service-Alwar" element={<Kentro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/kent-ro-service-Bardhaman" element={<Kentro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/kent-ro-service-Sonipat" element={<Kentro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/kent-ro-service-New-Delhi" element={<Kentro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/kent-ro-service-Puducherry" element={<Kentro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/kent-ro-service-Haridwar" element={<Kentro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/kent-ro-service-Vijayanagaram" element={<Kentro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/kent-ro-service-Sri-Ganganagar" element={<Kentro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/kent-ro-service-Secunderabad" element={<Kentro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/kent-ro-service-Yamunanagar" element={<Kentro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/kent-ro-service-Gandhinagar" element={<Kentro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/kent-ro-service-Ambala" element={<Kentro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/kent-ro-service-Vellore" element={<Kentro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/kent-ro-service-Shimla" element={<Kentro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/kent-ro-service-Amaravati" element={<Kentro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />


        <Route path="/kuchina-ro-service-Araria" element={<BiharKuchina title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/kuchina-ro-service-Arwal" element={<BiharKuchina title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/kuchina-ro-service-Aurangabad" element={<BiharKuchina title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/kuchina-ro-service-Banka" element={<BiharKuchina title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/kuchina-ro-service-Begusarai" element={<BiharKuchina title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/kuchina-ro-service-Bhabua" element={<BiharKuchina title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/kuchina-ro-service-Bhagalpur" element={<BiharKuchina title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/kuchina-ro-service-Bhojpur" element={<BiharKuchina title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/kuchina-ro-service-Bihar-Sharif" element={<BiharKuchina title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/kuchina-ro-service-Buxar" element={<BiharKuchina title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/kuchina-ro-service-Chhapra" element={<BiharKuchina title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/kuchina-ro-service-Darbhanga" element={<BiharKuchina title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/kuchina-ro-service-East-Champaran" element={<BiharKuchina title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/kuchina-ro-service-Gaya" element={<BiharKuchina title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/kuchina-ro-service-Gopalganj" element={<BiharKuchina title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/kuchina-ro-service-Jamui" element={<BiharKuchina title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/kuchina-ro-service-Jehanabad" element={<BiharKuchina title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/kuchina-ro-service-Khagaria" element={<BiharKuchina title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/kuchina-ro-service-Kishanganj" element={<BiharKuchina title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/kuchina-ro-service-Kaimur" element={<BiharKuchina title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/kuchina-ro-service-Katihar" element={<BiharKuchina title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/kuchina-ro-service-Lakhisarai" element={<BiharKuchina title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/kuchina-ro-service-Madhubani" element={<BiharKuchina title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/kuchina-ro-service-Munger" element={<BiharKuchina title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/kuchina-ro-service-Madhepura" element={<BiharKuchina title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/kuchina-ro-service-Muzaffarpur" element={<BiharKuchina title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/kuchina-ro-service-Nalanda" element={<BiharKuchina title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/kuchina-ro-service-Nawada" element={<BiharKuchina title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/kuchina-ro-service-Patna" element={<BiharKuchina title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/kuchina-ro-service-Purnia" element={<BiharKuchina title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/kuchina-ro-service-Rohtas" element={<BiharKuchina title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/kuchina-ro-service-Saharsa" element={<BiharKuchina title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/kuchina-ro-service-Samastipur" element={<BiharKuchina title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/kuchina-ro-service-Sasaram" element={<BiharKuchina title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/kuchina-ro-service-Sheohar" element={<BiharKuchina title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/kuchina-ro-service-Sheikhpura" element={<BiharKuchina title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/kuchina-ro-service-Saran" element={<BiharKuchina title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/kuchina-ro-service-Sitamarhi" element={<BiharKuchina title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/kuchina-ro-service-Supaul" element={<BiharKuchina title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/kuchina-ro-service-Siwan" element={<BiharKuchina title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/kuchina-ro-service-Vaishali" element={<BiharKuchina title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/kuchina-ro-service-West-Champaran" element={<BiharKuchina title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/kuchina-ro-service-Garhwa" element={<JharkhandKuchina title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/kuchina-ro-service-Palamu" element={<JharkhandKuchina title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/kuchina-ro-service-Latehar" element={<JharkhandKuchina title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/kuchina-ro-service-Chatra" element={<JharkhandKuchina title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/kuchina-ro-service-Hazaribagh" element={<JharkhandKuchina title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/kuchina-ro-service-Jamshedpur" element={<JharkhandKuchina title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/kuchina-ro-service-Koderma" element={<JharkhandKuchina title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/kuchina-ro-service-Giridih" element={<JharkhandKuchina title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/kuchina-ro-service-Ramgarh" element={<JharkhandKuchina title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/kuchina-ro-service-Bokaro" element={<JharkhandKuchina title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/kuchina-ro-service-Dhanbad" element={<JharkhandKuchina title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/kuchina-ro-service-Lohardaga" element={<JharkhandKuchina title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/kuchina-ro-service-Gumla" element={<JharkhandKuchina title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/kuchina-ro-service-Simdega" element={<JharkhandKuchina title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/kuchina-ro-service-Ranchi" element={<JharkhandKuchina title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/kuchina-ro-service-Khunti" element={<JharkhandKuchina title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/kuchina-ro-service-Singhbhum" element={<JharkhandKuchina title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/kuchina-ro-service-Saraikela-Kharsawan" element={<JharkhandKuchina title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/kuchina-ro-service-Jamtara" element={<JharkhandKuchina title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/kuchina-ro-service-Deoghar" element={<JharkhandKuchina title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/kuchina-ro-service-Dumka" element={<JharkhandKuchina title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/kuchina-ro-service-Pakur" element={<JharkhandKuchina title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/kuchina-ro-service-Godda" element={<JharkhandKuchina title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/kuchina-ro-service-Sahebganj" element={<JharkhandKuchina title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />

        <Route path="/kuchina-ro-service-Alipurduar" element={<BengalKuchina title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/kuchina-ro-service-Bankura" element={<BengalKuchina title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/kuchina-ro-service-Birbhum" element={<BengalKuchina title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/kuchina-ro-service-Cooch-Behar" element={<BengalKuchina title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/kuchina-ro-service-Dakshin-Dinajpur" element={<BengalKuchina title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/kuchina-ro-service-Darjeeling" element={<BengalKuchina title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/kuchina-ro-service-Durgapur" element={<BengalKuchina title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/kuchina-ro-service-Hooghly" element={<BengalKuchina title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/kuchina-ro-service-Howrah" element={<BengalKuchina title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/kuchina-ro-service-Jalpaiguri" element={<BengalKuchina title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/kuchina-ro-service-Jhargram" element={<BengalKuchina title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/kuchina-ro-service-Kalimpong" element={<BengalKuchina title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/kuchina-ro-service-Kolkata" element={<BengalKuchina title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/kuchina-ro-service-Malda" element={<BengalKuchina title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/kuchina-ro-service-Murshidabad" element={<BengalKuchina title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/kuchina-ro-service-Nadia" element={<BengalKuchina title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/kuchina-ro-service-North-24-Parganas" element={<BengalKuchina title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/kuchina-ro-service-Paschim-Bardhaman" element={<BengalKuchina title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/kuchina-ro-service-Paschim-Medinipur" element={<BengalKuchina title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/kuchina-ro-service-Purba-Bardhaman" element={<BengalKuchina title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/kuchina-ro-service-Purba-Medinipur" element={<BengalKuchina title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/kuchina-ro-service-South-24-Parganas" element={<BengalKuchina title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/kuchina-ro-service-Uttar-Dinajpur" element={<BengalKuchina title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />
        <Route path="/kuchina-ro-service-Agra" element={<UttarPradeshKuchina title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/kuchina-ro-service-Aligarh" element={<UttarPradeshKuchina title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/kuchina-ro-service-Ambedkar-Nagar" element={<UttarPradeshKuchina title={locdata.AmbedkarNagar.title} number={locdata.AmbedkarNagar.number} />} />
        <Route path="/kuchina-ro-service-Ayodhya" element={<UttarPradeshKuchina title={locdata.Ayodhya.title} number={locdata.Ayodhya.number} />} />
        <Route path="/kuchina-ro-service-Amethi" element={<UttarPradeshKuchina title={locdata.Amethi.title} number={locdata.Amethi.number} />} />
        <Route path="/kuchina-ro-service-Amroha" element={<UttarPradeshKuchina title={locdata.Amroha.title} number={locdata.Amroha.number} />} />
        <Route path="/kuchina-ro-service-Auraiya" element={<UttarPradeshKuchina title={locdata.Auraiya.title} number={locdata.Auraiya.number} />} />
        <Route path="/kuchina-ro-service-Azamgarh" element={<UttarPradeshKuchina title={locdata.Azamgarh.title} number={locdata.Azamgarh.number} />} />
        <Route path="/kuchina-ro-service-Budaun" element={<UttarPradeshKuchina title={locdata.Budaun.title} number={locdata.Budaun.number} />} />
        <Route path="/kuchina-ro-service-Bagpat" element={<UttarPradeshKuchina title={locdata.Bagpat.title} number={locdata.Bagpat.number} />} />
        <Route path="/kuchina-ro-service-Bahraich" element={<UttarPradeshKuchina title={locdata.Bahraich.title} number={locdata.Bahraich.number} />} />
        <Route path="/kuchina-ro-service-Ballia" element={<UttarPradeshKuchina title={locdata.Ballia.title} number={locdata.Ballia.number} />} />
        <Route path="/kuchina-ro-service-Balrampur" element={<UttarPradeshKuchina title={locdata.Balrampur.title} number={locdata.Balrampur.number} />} />
        <Route path="/kuchina-ro-service-Banda" element={<UttarPradeshKuchina title={locdata.Banda.title} number={locdata.Banda.number} />} />
        <Route path="/kuchina-ro-service-Barabanki" element={<UttarPradeshKuchina title={locdata.Barabanki.title} number={locdata.Barabanki.number} />} />
        <Route path="/kuchina-ro-service-Bareilly" element={<UttarPradeshKuchina title={locdata.Bareilly.title} number={locdata.Bareilly.number} />} />
        <Route path="/kuchina-ro-service-Basti" element={<UttarPradeshKuchina title={locdata.Basti.title} number={locdata.Basti.number} />} />
        <Route path="/kuchina-ro-service-Bijnor" element={<UttarPradeshKuchina title={locdata.Bijnor.title} number={locdata.Bijnor.number} />} />
        <Route path="/kuchina-ro-service-Bulandshahr" element={<UttarPradeshKuchina title={locdata.Bulandshahr.title} number={locdata.Bulandshahr.number} />} />
        <Route path="/kuchina-ro-service-Chandauli" element={<UttarPradeshKuchina title={locdata.Chandauli.title} number={locdata.Chandauli.number} />} />
        <Route path="/kuchina-ro-service-Chitrakoot" element={<UttarPradeshKuchina title={locdata.Chitrakoot.title} number={locdata.Chitrakoot.number} />} />
        <Route path="/kuchina-ro-service-Deoria" element={<UttarPradeshKuchina title={locdata.Deoria.title} number={locdata.Deoria.number} />} />
        <Route path="/kuchina-ro-service-Etah" element={<UttarPradeshKuchina title={locdata.Etah.title} number={locdata.Etah.number} />} />
        <Route path="/kuchina-ro-service-Etawah" element={<UttarPradeshKuchina title={locdata.Etawah.title} number={locdata.Etawah.number} />} />
        <Route path="/kuchina-ro-service-Farrukhabad" element={<UttarPradeshKuchina title={locdata.Farrukhabad.title} number={locdata.Farrukhabad.number} />} />
        <Route path="/kuchina-ro-service-Fatehpur" element={<UttarPradeshKuchina title={locdata.Fatehpur.title} number={locdata.Fatehpur.number} />} />
        <Route path="/kuchina-ro-service-Firozabad" element={<UttarPradeshKuchina title={locdata.Firozabad.title} number={locdata.Firozabad.number} />} />
        <Route path="/kuchina-ro-service-Gautam-Buddha-Nagar" element={<UttarPradeshKuchina title={locdata.GautamBuddhaNagar.title} number={locdata.GautamBuddhaNagar.number} />} />
        <Route path="/kuchina-ro-service-Ghaziabad" element={<UttarPradeshKuchina title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/kuchina-ro-service-Ghazipur" element={<UttarPradeshKuchina title={locdata.Ghazipur.title} number={locdata.Ghazipur.number} />} />
        <Route path="/kuchina-ro-service-Gonda" element={<UttarPradeshKuchina title={locdata.Gonda.title} number={locdata.Gonda.number} />} />
        <Route path="/kuchina-ro-service-Gorakhpur" element={<UttarPradeshKuchina title={locdata.Gorakhpur.title} number={locdata.Gorakhpur.number} />} />
        <Route path="/kuchina-ro-service-Hamirpur" element={<UttarPradeshKuchina title={locdata.Hamirpur.title} number={locdata.Hamirpur.number} />} />
        <Route path="/kuchina-ro-service-Hapur" element={<UttarPradeshKuchina title={locdata.Hapur.title} number={locdata.Hapur.number} />} />
        <Route path="/kuchina-ro-service-Hardoi" element={<UttarPradeshKuchina title={locdata.Hardoi.title} number={locdata.Hardoi.number} />} />
        <Route path="/kuchina-ro-service-Hathras" element={<UttarPradeshKuchina title={locdata.Hathras.title} number={locdata.Hathras.number} />} />
        <Route path="/kuchina-ro-service-Jalaun" element={<UttarPradeshKuchina title={locdata.Jalaun.title} number={locdata.Jalaun.number} />} />
        <Route path="/kuchina-ro-service-Jaunpur" element={<UttarPradeshKuchina title={locdata.Jaunpur.title} number={locdata.Jaunpur.number} />} />
        <Route path="/kuchina-ro-service-Jhansi" element={<UttarPradeshKuchina title={locdata.Jhansi.title} number={locdata.Jhansi.number} />} />
        <Route path="/kuchina-ro-service-Kannauj" element={<UttarPradeshKuchina title={locdata.Kannauj.title} number={locdata.Kannauj.number} />} />
        <Route path="/kuchina-ro-service-Kanpur-Dehat" element={<UttarPradeshKuchina title={locdata.KanpurDehat.title} number={locdata.KanpurDehat.number} />} />
        <Route path="/kuchina-ro-service-Kanpur" element={<UttarPradeshKuchina title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/kuchina-ro-service-Kasganj" element={<UttarPradeshKuchina title={locdata.Kasganj.title} number={locdata.Kasganj.number} />} />
        <Route path="/kuchina-ro-service-Kaushambi" element={<UttarPradeshKuchina title={locdata.Kaushambi.title} number={locdata.Kaushambi.number} />} />
        <Route path="/kuchina-ro-service-Kushinagar" element={<UttarPradeshKuchina title={locdata.Kushinagar.title} number={locdata.Kushinagar.number} />} />
        <Route path="/kuchina-ro-service-Lakhimpur-Kheri" element={<UttarPradeshKuchina title={locdata.LakhimpurKheri.title} number={locdata.LakhimpurKheri.number} />} />
        <Route path="/kuchina-ro-service-Lalitpur" element={<UttarPradeshKuchina title={locdata.Lalitpur.title} number={locdata.Lalitpur.number} />} />
        <Route path="/kuchina-ro-service-Lucknow" element={<UttarPradeshKuchina title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/kuchina-ro-service-Maharajganj" element={<UttarPradeshKuchina title={locdata.Maharajganj.title} number={locdata.Maharajganj.number} />} />
        <Route path="/kuchina-ro-service-Mahoba" element={<UttarPradeshKuchina title={locdata.Mahoba.title} number={locdata.Mahoba.number} />} />
        <Route path="/kuchina-ro-service-Mainpuri" element={<UttarPradeshKuchina title={locdata.Mainpuri.title} number={locdata.Mainpuri.number} />} />
        <Route path="/kuchina-ro-service-Mathura" element={<UttarPradeshKuchina title={locdata.Mathura.title} number={locdata.Mathura.number} />} />
        <Route path="/kuchina-ro-service-Mau" element={<UttarPradeshKuchina title={locdata.Mau.title} number={locdata.Mau.number} />} />
        <Route path="/kuchina-ro-service-Meerut" element={<UttarPradeshKuchina title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/kuchina-ro-service-Mirzapur" element={<UttarPradeshKuchina title={locdata.Mirzapur.title} number={locdata.Mirzapur.number} />} />
        <Route path="/kuchina-ro-service-Moradabad" element={<UttarPradeshKuchina title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/kuchina-ro-service-Muzaffarnagar" element={<UttarPradeshKuchina title={locdata.Muzaffarnagar.title} number={locdata.Muzaffarnagar.number} />} />
        <Route path="/kuchina-ro-service-Pilibhit" element={<UttarPradeshKuchina title={locdata.Pilibhit.title} number={locdata.Pilibhit.number} />} />
        <Route path="/kuchina-ro-service-Pratapgarh" element={<UttarPradeshKuchina title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/kuchina-ro-service-Prayagraj" element={<UttarPradeshKuchina title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/kuchina-ro-service-Rae-Bareli" element={<UttarPradeshKuchina title={locdata.RaeBareli.title} number={locdata.RaeBareli.number} />} />
        <Route path="/kuchina-ro-service-Rampur" element={<UttarPradeshKuchina title={locdata.Rampur.title} number={locdata.Rampur.number} />} />
        <Route path="/kuchina-ro-service-Saharanpur" element={<UttarPradeshKuchina title={locdata.Saharanpur.title} number={locdata.Saharanpur.number} />} />
        <Route path="/kuchina-ro-service-Sant-Kabir-Nagar" element={<UttarPradeshKuchina title={locdata.SantKabirNagar.title} number={locdata.SantKabirNagar.number} />} />
        <Route path="/kuchina-ro-service-Bhadohi" element={<UttarPradeshKuchina title={locdata.Bhadohi.title} number={locdata.Bhadohi.number} />} />
        <Route path="/kuchina-ro-service-Sambhal" element={<UttarPradeshKuchina title={locdata.Sambhal.title} number={locdata.Sambhal.number} />} />
        <Route path="/kuchina-ro-service-Shahjahanpur" element={<UttarPradeshKuchina title={locdata.Shahjahanpur.title} number={locdata.Shahjahanpur.number} />} />
        <Route path="/kuchina-ro-service-Shamli" element={<UttarPradeshKuchina title={locdata.Shamli.title} number={locdata.Shamli.number} />} />
        <Route path="/kuchina-ro-service-Shravasti" element={<UttarPradeshKuchina title={locdata.Shravasti.title} number={locdata.Shravasti.number} />} />
        <Route path="/kuchina-ro-service-Siddharthnagar" element={<UttarPradeshKuchina title={locdata.Siddharthnagar.title} number={locdata.Siddharthnagar.number} />} />
        <Route path="/kuchina-ro-service-Sitapur" element={<UttarPradeshKuchina title={locdata.Sitapur.title} number={locdata.Sitapur.number} />} />
        <Route path="/kuchina-ro-service-Sonbhadra" element={<UttarPradeshKuchina title={locdata.Sonbhadra.title} number={locdata.Sonbhadra.number} />} />
        <Route path="/kuchina-ro-service-Sultanpur" element={<UttarPradeshKuchina title={locdata.Sultanpur.title} number={locdata.Sultanpur.number} />} />
        <Route path="/kuchina-ro-service-Unnao" element={<UttarPradeshKuchina title={locdata.Unnao.title} number={locdata.Unnao.number} />} />
        <Route path="/kuchina-ro-service-Varanasi" element={<UttarPradeshKuchina title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/kuchina-ro-service-Robertsganj" element={<UttarPradeshKuchina title={locdata.Robertsganj.title} number={locdata.Robertsganj.number} />} />
        <Route path="/kuchina-ro-service-Bahjoi" element={<UttarPradeshKuchina title={locdata.Bahjoi.title} number={locdata.Bahjoi.number} />} />
        <Route path="/kuchina-ro-service-Khalilabad" element={<UttarPradeshKuchina title={locdata.Khalilabad.title} number={locdata.Khalilabad.number} />} />
        <Route path="/kuchina-ro-service-Akbarpur" element={<UttarPradeshKuchina title={locdata.Akbarpur.title} number={locdata.Akbarpur.number} />} />
        <Route path="/kuchina-ro-service-Orai" element={<UttarPradeshKuchina title={locdata.Orai.title} number={locdata.Orai.number} />} />
        <Route path="/kuchina-ro-service-Manjhanpur" element={<UttarPradeshKuchina title={locdata.Manjhanpur.title} number={locdata.Manjhanpur.number} />} />
        <Route path="/kuchina-ro-service-Padarauna" element={<UttarPradeshKuchina title={locdata.Padarauna.title} number={locdata.Padarauna.number} />} />
        <Route path="/kuchina-ro-service-Kheri" element={<UttarPradeshKuchina title={locdata.Kheri.title} number={locdata.Kheri.number} />} />
        <Route path="/kuchina-ro-service-Fatehgarh" element={<UttarPradeshKuchina title={locdata.Fatehgarh.title} number={locdata.Fatehgarh.number} />} />
        <Route path="/kuchina-ro-service-Noida" element={<UttarPradeshKuchina title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/kuchina-ro-service-Greater-Noida" element={<UttarPradeshKuchina title={locdata.GreaterNoida.title} number={locdata.GreaterNoida.number} />} />

        <Route path="/kuchina-ro-service-Indore" element={<MadhyaPradeshKutchina title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/kuchina-ro-service-Bhopal" element={<MadhyaPradeshKutchina title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/kuchina-ro-service-Jabalpur" element={<MadhyaPradeshKutchina title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/kuchina-ro-service-Gwalior" element={<MadhyaPradeshKutchina title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/kuchina-ro-service-Ujjain" element={<MadhyaPradeshKutchina title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/kuchina-ro-service-Sagar" element={<MadhyaPradeshKutchina title={locdata.Sagar.title} number={locdata.Sagar.number} />} />
        <Route path="/kuchina-ro-service-Dewas" element={<MadhyaPradeshKutchina title={locdata.Dewas.title} number={locdata.Dewas.number} />} />
        <Route path="/kuchina-ro-service-Satna" element={<MadhyaPradeshKutchina title={locdata.Satna.title} number={locdata.Satna.number} />} />
        <Route path="/kuchina-ro-service-Ratlam" element={<MadhyaPradeshKutchina title={locdata.Ratlam.title} number={locdata.Ratlam.number} />} />
        <Route path="/kuchina-ro-service-Rewa" element={<MadhyaPradeshKutchina title={locdata.Rewa.title} number={locdata.Rewa.number} />} />
        <Route path="/kuchina-ro-service-Katni" element={<MadhyaPradeshKutchina title={locdata.Katni.title} number={locdata.Katni.number} />} />
        <Route path="/kuchina-ro-service-Singrauli" element={<MadhyaPradeshKutchina title={locdata.Singrauli.title} number={locdata.Singrauli.number} />} />
        <Route path="/kuchina-ro-service-Burhanpur" element={<MadhyaPradeshKutchina title={locdata.Burhanpur.title} number={locdata.Burhanpur.number} />} />
        <Route path="/kuchina-ro-service-Morena" element={<MadhyaPradeshKutchina title={locdata.Morena.title} number={locdata.Morena.number} />} />
        <Route path="/kuchina-ro-service-Khandwa" element={<MadhyaPradeshKutchina title={locdata.Khandwa.title} number={locdata.Khandwa.number} />} />
        <Route path="/kuchina-ro-service-Bhind" element={<MadhyaPradeshKutchina title={locdata.Bhind.title} number={locdata.Bhind.number} />} />
        <Route path="/kuchina-ro-service-Chhindwara" element={<MadhyaPradeshKutchina title={locdata.Chhindwara.title} number={locdata.Chhindwara.number} />} />
        <Route path="/kuchina-ro-service-Guna" element={<MadhyaPradeshKutchina title={locdata.Guna.title} number={locdata.Guna.number} />} />
        <Route path="/kuchina-ro-service-Shivpuri" element={<MadhyaPradeshKutchina title={locdata.Shivpuri.title} number={locdata.Shivpuri.number} />} />
        <Route path="/kuchina-ro-service-Vidisha" element={<MadhyaPradeshKutchina title={locdata.Vidisha.title} number={locdata.Vidisha.number} />} />
        <Route path="/kuchina-ro-service-Chhatarpur" element={<MadhyaPradeshKutchina title={locdata.Chhatarpur.title} number={locdata.Chhatarpur.number} />} />
        <Route path="/kuchina-ro-service-Damoh" element={<MadhyaPradeshKutchina title={locdata.Damoh.title} number={locdata.Damoh.number} />} />
        <Route path="/kuchina-ro-service-Mandsaur" element={<MadhyaPradeshKutchina title={locdata.Mandsaur.title} number={locdata.Mandsaur.number} />} />
        <Route path="/kuchina-ro-service-Khargone" element={<MadhyaPradeshKutchina title={locdata.Khargone.title} number={locdata.Khargone.number} />} />
        <Route path="/kuchina-ro-service-Neemuch" element={<MadhyaPradeshKutchina title={locdata.Neemuch.title} number={locdata.Neemuch.number} />} />
        <Route path="/kuchina-ro-service-Pithampur" element={<MadhyaPradeshKutchina title={locdata.Pithampur.title} number={locdata.Pithampur.number} />} />
        <Route path="/kuchina-ro-service-Narmadapuram" element={<MadhyaPradeshKutchina title={locdata.Narmadapuram.title} number={locdata.Narmadapuram.number} />} />
        <Route path="/kuchina-ro-service-Itarsi" element={<MadhyaPradeshKutchina title={locdata.Itarsi.title} number={locdata.Itarsi.number} />} />
        <Route path="/kuchina-ro-service-Sehore" element={<MadhyaPradeshKutchina title={locdata.Sehore.title} number={locdata.Sehore.number} />} />
        <Route path="/kuchina-ro-service-Betul" element={<MadhyaPradeshKutchina title={locdata.Betul.title} number={locdata.Betul.number} />} />
        <Route path="/kuchina-ro-service-Seoni" element={<MadhyaPradeshKutchina title={locdata.Seoni.title} number={locdata.Seoni.number} />} />
        <Route path="/kuchina-ro-service-Datia" element={<MadhyaPradeshKutchina title={locdata.Datia.title} number={locdata.Datia.number} />} />
        <Route path="/kuchina-ro-service-Nagda" element={<MadhyaPradeshKutchina title={locdata.Nagda.title} number={locdata.Nagda.number} />} />
        <Route path="/kuchina-ro-service-Shahdol" element={<MadhyaPradeshKutchina title={locdata.Shahdol.title} number={locdata.Shahdol.number} />} />
        <Route path="/kuchina-ro-service-Dhar" element={<MadhyaPradeshKutchina title={locdata.Dhar.title} number={locdata.Dhar.number} />} />

        <Route path="/kuchina-ro-service-Ajmer" element={<RajasthanKutchina title={locdata.Ajmer.title} number={locdata.Ajmer.number} />} />
        <Route path="/kuchina-ro-service-Alwar" element={<RajasthanKutchina title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/kuchina-ro-service-Balotra" element={<RajasthanKutchina title={locdata.Balotra.title} number={locdata.Balotra.number} />} />
        <Route path="/kuchina-ro-service-Banswara" element={<RajasthanKutchina title={locdata.Banswara.title} number={locdata.Banswara.number} />} />
        <Route path="/kuchina-ro-service-Baran" element={<RajasthanKutchina title={locdata.Baran.title} number={locdata.Baran.number} />} />
        <Route path="/kuchina-ro-service-Barmer" element={<RajasthanKutchina title={locdata.Barmer.title} number={locdata.Barmer.number} />} />
        <Route path="/kuchina-ro-service-Beawar" element={<RajasthanKutchina title={locdata.Beawar.title} number={locdata.Beawar.number} />} />
        <Route path="/kuchina-ro-service-Bharatpur" element={<RajasthanKutchina title={locdata.Bharatpur.title} number={locdata.Bharatpur.number} />} />
        <Route path="/kuchina-ro-service-Bhilwara" element={<RajasthanKutchina title={locdata.Bhilwara.title} number={locdata.Bhilwara.number} />} />
        <Route path="/kuchina-ro-service-Bikaner" element={<RajasthanKutchina title={locdata.Bikaner.title} number={locdata.Bikaner.number} />} />
        <Route path="/kuchina-ro-service-Bundi" element={<RajasthanKutchina title={locdata.Bundi.title} number={locdata.Bundi.number} />} />
        <Route path="/kuchina-ro-service-Chittorgarh" element={<RajasthanKutchina title={locdata.Chittorgarh.title} number={locdata.Chittorgarh.number} />} />
        <Route path="/kuchina-ro-service-Churu" element={<RajasthanKutchina title={locdata.Churu.title} number={locdata.Churu.number} />} />
        <Route path="/kuchina-ro-service-Dausa" element={<RajasthanKutchina title={locdata.Dausa.title} number={locdata.Dausa.number} />} />
        <Route path="/kuchina-ro-service-Deeg" element={<RajasthanKutchina title={locdata.Deeg.title} number={locdata.Deeg.number} />} />
        <Route path="/kuchina-ro-service-Deedwana" element={<RajasthanKutchina title={locdata.Deedwana.title} number={locdata.Deedwana.number} />} />
        <Route path="/kuchina-ro-service-Dholpur" element={<RajasthanKutchina title={locdata.Dholpur.title} number={locdata.Dholpur.number} />} />
        <Route path="/kuchina-ro-service-Dungarpur" element={<RajasthanKutchina title={locdata.Dungarpur.title} number={locdata.Dungarpur.number} />} />
        <Route path="/kuchina-ro-service-Hanumangarh" element={<RajasthanKutchina title={locdata.Hanumangarh.title} number={locdata.Hanumangarh.number} />} />
        <Route path="/kuchina-ro-service-Jaipur" element={<RajasthanKutchina title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/kuchina-ro-service-Jaisalmer" element={<RajasthanKutchina title={locdata.Jaisalmer.title} number={locdata.Jaisalmer.number} />} />
        <Route path="/kuchina-ro-service-Jalore" element={<RajasthanKutchina title={locdata.Jalore.title} number={locdata.Jalore.number} />} />
        <Route path="/kuchina-ro-service-Jhalawar" element={<RajasthanKutchina title={locdata.Jhalawar.title} number={locdata.Jhalawar.number} />} />
        <Route path="/kuchina-ro-service-Jhunjhunu" element={<RajasthanKutchina title={locdata.Jhunjhunu.title} number={locdata.Jhunjhunu.number} />} />
        <Route path="/kuchina-ro-service-Jodhpur" element={<RajasthanKutchina title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/kuchina-ro-service-Karauli" element={<RajasthanKutchina title={locdata.Karauli.title} number={locdata.Karauli.number} />} />
        <Route path="/kuchina-ro-service-Tijara" element={<RajasthanKutchina title={locdata.Tijara.title} number={locdata.Tijara.number} />} />
        <Route path="/kuchina-ro-service-Kotputli" element={<RajasthanKutchina title={locdata.Kotputli.title} number={locdata.Kotputli.number} />} />
        <Route path="/kuchina-ro-service-Kota" element={<RajasthanKutchina title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/kuchina-ro-service-Nagaur" element={<RajasthanKutchina title={locdata.Nagaur.title} number={locdata.Nagaur.number} />} />
        <Route path="/kuchina-ro-service-Pali" element={<RajasthanKutchina title={locdata.Pali.title} number={locdata.Pali.number} />} />
        <Route path="/kuchina-ro-service-Phalodi" element={<RajasthanKutchina title={locdata.Phalodi.title} number={locdata.Phalodi.number} />} />
        <Route path="/kuchina-ro-service-Pratapgarh" element={<RajasthanKutchina title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/kuchina-ro-service-Rajsamand" element={<RajasthanKutchina title={locdata.Rajsamand.title} number={locdata.Rajsamand.number} />} />
        <Route path="/kuchina-ro-service-Salumbar" element={<RajasthanKutchina title={locdata.Salumbar.title} number={locdata.Salumbar.number} />} />
        <Route path="/kuchina-ro-service-Sawai-Madhopur" element={<RajasthanKutchina title={locdata.SawaiMadhopur.title} number={locdata.SawaiMadhopur.number} />} />
        <Route path="/kuchina-ro-service-Sikar" element={<RajasthanKutchina title={locdata.Sikar.title} number={locdata.Sikar.number} />} />
        <Route path="/kuchina-ro-service-Sirohi" element={<RajasthanKutchina title={locdata.Sirohi.title} number={locdata.Sirohi.number} />} />
        <Route path="/kuchina-ro-service-Sri-Ganganagar" element={<RajasthanKutchina title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/kuchina-ro-service-Tonk" element={<RajasthanKutchina title={locdata.Tonk.title} number={locdata.Tonk.number} />} />
        <Route path="/kuchina-ro-service-Udaipur" element={<RajasthanKutchina title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />

        <Route path="/kuchina-ro-service-Mumbai" element={<Kuchinaro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/kuchina-ro-service-Delhi" element={<Kuchinaro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/kuchina-ro-service-Bengaluru" element={<Kuchinaro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/kuchina-ro-service-Hyderabad" element={<Kuchinaro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/kuchina-ro-service-Ahmedabad" element={<Kuchinaro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/kuchina-ro-service-Chennai" element={<Kuchinaro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/kuchina-ro-service-Surat" element={<Kuchinaro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/kuchina-ro-service-Pune" element={<Kuchinaro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/kuchina-ro-service-Jaipur" element={<Kuchinaro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/kuchina-ro-service-Lucknow" element={<Kuchinaro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/kuchina-ro-service-Kanpur" element={<Kuchinaro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/kuchina-ro-service-Nagpur" element={<Kuchinaro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/kuchina-ro-service-Indore" element={<Kuchinaro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/kuchina-ro-service-Thane" element={<Kuchinaro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/kuchina-ro-service-Bhopal" element={<Kuchinaro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/kuchina-ro-service-Visakhapatnam" element={<Kuchinaro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/kuchina-ro-service-Vadodara" element={<Kuchinaro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/kuchina-ro-service-Ghaziabad" element={<Kuchinaro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/kuchina-ro-service-Ludhiana" element={<Kuchinaro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/kuchina-ro-service-Agra" element={<Kuchinaro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/kuchina-ro-service-Nashik" element={<Kuchinaro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/kuchina-ro-service-Faridabad" element={<Kuchinaro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/kuchina-ro-service-Meerut" element={<Kuchinaro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/kuchina-ro-service-Rajkot" element={<Kuchinaro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/kuchina-ro-service-Varanasi" element={<Kuchinaro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/kuchina-ro-service-Srinagar" element={<Kuchinaro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/kuchina-ro-service-Amritsar" element={<Kuchinaro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/kuchina-ro-service-Navi-Mumbai" element={<Kuchinaro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/kuchina-ro-service-Prayagraj" element={<Kuchinaro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/kuchina-ro-service-Jabalpur" element={<Kuchinaro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/kuchina-ro-service-Gwalior" element={<Kuchinaro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/kuchina-ro-service-Coimbatore" element={<Kuchinaro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/kuchina-ro-service-Vijayawada" element={<Kuchinaro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/kuchina-ro-service-Jodhpur" element={<Kuchinaro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/kuchina-ro-service-Madurai" element={<Kuchinaro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/kuchina-ro-service-Raipur" element={<Kuchinaro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/kuchina-ro-service-Kota" element={<Kuchinaro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/kuchina-ro-service-Guwahati" element={<Kuchinaro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/kuchina-ro-service-Chandigarh" element={<Kuchinaro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/kuchina-ro-service-Thiruvananthapuram" element={<Kuchinaro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/kuchina-ro-service-Solapur" element={<Kuchinaro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/kuchina-ro-service-Tiruppur" element={<Kuchinaro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/kuchina-ro-service-Moradabad" element={<Kuchinaro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/kuchina-ro-service-Mysore" element={<Kuchinaro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/kuchina-ro-service-Gurgaon" element={<Kuchinaro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/kuchina-ro-service-Aligarh" element={<Kuchinaro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/kuchina-ro-service-Jalandhar" element={<Kuchinaro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/kuchina-ro-service-Bhubaneswar" element={<Kuchinaro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/kuchina-ro-service-Noida" element={<Kuchinaro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/kuchina-ro-service-Jamshedpur" element={<Kuchinaro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/kuchina-ro-service-Cuttack" element={<Kuchinaro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/kuchina-ro-service-Bhavnagar" element={<Kuchinaro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/kuchina-ro-service-Dehradun" element={<Kuchinaro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/kuchina-ro-service-Rourkela" element={<Kuchinaro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/kuchina-ro-service-Jamnagar" element={<Kuchinaro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/kuchina-ro-service-Ujjain" element={<Kuchinaro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/kuchina-ro-service-Jammu" element={<Kuchinaro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/kuchina-ro-service-Mangalore" element={<Kuchinaro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/kuchina-ro-service-Erode" element={<Kuchinaro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/kuchina-ro-service-Udaipur" element={<Kuchinaro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/kuchina-ro-service-Rajahmundry" element={<Kuchinaro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/kuchina-ro-service-Patiala" element={<Kuchinaro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/kuchina-ro-service-Agartala" element={<Kuchinaro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/kuchina-ro-service-Tirupati" element={<Kuchinaro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/kuchina-ro-service-Sambalpur" element={<Kuchinaro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/kuchina-ro-service-Bilaspur" element={<Kuchinaro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/kuchina-ro-service-Alwar" element={<Kuchinaro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/kuchina-ro-service-Bardhaman" element={<Kuchinaro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/kuchina-ro-service-Sonipat" element={<Kuchinaro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/kuchina-ro-service-New-Delhi" element={<Kuchinaro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/kuchina-ro-service-Puducherry" element={<Kuchinaro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/kuchina-ro-service-Haridwar" element={<Kuchinaro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/kuchina-ro-service-Vijayanagaram" element={<Kuchinaro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/kuchina-ro-service-Sri-Ganganagar" element={<Kuchinaro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/kuchina-ro-service-Secunderabad" element={<Kuchinaro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/kuchina-ro-service-Yamunanagar" element={<Kuchinaro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/kuchina-ro-service-Gandhinagar" element={<Kuchinaro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/kuchina-ro-service-Ambala" element={<Kuchinaro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/kuchina-ro-service-Vellore" element={<Kuchinaro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/kuchina-ro-service-Shimla" element={<Kuchinaro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/kuchina-ro-service-Amaravati" element={<Kuchinaro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />


        <Route path="/lg-ro-service-Araria" element={<BiharLG title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/lg-ro-service-Arwal" element={<BiharLG title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/lg-ro-service-Aurangabad" element={<BiharLG title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/lg-ro-service-Banka" element={<BiharLG title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/lg-ro-service-Begusarai" element={<BiharLG title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/lg-ro-service-Bhabua" element={<BiharLG title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/lg-ro-service-Bhagalpur" element={<BiharLG title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/lg-ro-service-Bhojpur" element={<BiharLG title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/lg-ro-service-Bihar-Sharif" element={<BiharLG title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/lg-ro-service-Buxar" element={<BiharLG title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/lg-ro-service-Chhapra" element={<BiharLG title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/lg-ro-service-Darbhanga" element={<BiharLG title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/lg-ro-service-East-Champaran" element={<BiharLG title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/lg-ro-service-Gaya" element={<BiharLG title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/lg-ro-service-Gopalganj" element={<BiharLG title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/lg-ro-service-Jamui" element={<BiharLG title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/lg-ro-service-Jehanabad" element={<BiharLG title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/lg-ro-service-Khagaria" element={<BiharLG title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/lg-ro-service-Kishanganj" element={<BiharLG title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/lg-ro-service-Kaimur" element={<BiharLG title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/lg-ro-service-Katihar" element={<BiharLG title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/lg-ro-service-Lakhisarai" element={<BiharLG title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/lg-ro-service-Madhubani" element={<BiharLG title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/lg-ro-service-Munger" element={<BiharLG title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/lg-ro-service-Madhepura" element={<BiharLG title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/lg-ro-service-Muzaffarpur" element={<BiharLG title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/lg-ro-service-Nalanda" element={<BiharLG title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/lg-ro-service-Nawada" element={<BiharLG title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/lg-ro-service-Patna" element={<BiharLG title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/lg-ro-service-Purnia" element={<BiharLG title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/lg-ro-service-Rohtas" element={<BiharLG title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/lg-ro-service-Saharsa" element={<BiharLG title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/lg-ro-service-Samastipur" element={<BiharLG title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/lg-ro-service-Sasaram" element={<BiharLG title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/lg-ro-service-Sheohar" element={<BiharLG title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/lg-ro-service-Sheikhpura" element={<BiharLG title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/lg-ro-service-Saran" element={<BiharLG title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/lg-ro-service-Sitamarhi" element={<BiharLG title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/lg-ro-service-Supaul" element={<BiharLG title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/lg-ro-service-Siwan" element={<BiharLG title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/lg-ro-service-Vaishali" element={<BiharLG title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/lg-ro-service-West-Champaran" element={<BiharLG title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/lg-ro-service-Garhwa" element={<JharkhandLG title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/lg-ro-service-Palamu" element={<JharkhandLG title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/lg-ro-service-Latehar" element={<JharkhandLG title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/lg-ro-service-Chatra" element={<JharkhandLG title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/lg-ro-service-Hazaribagh" element={<JharkhandLG title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/lg-ro-service-Jamshedpur" element={<JharkhandLG title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/lg-ro-service-Koderma" element={<JharkhandLG title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/lg-ro-service-Giridih" element={<JharkhandLG title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/lg-ro-service-Ramgarh" element={<JharkhandLG title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/lg-ro-service-Bokaro" element={<JharkhandLG title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/lg-ro-service-Dhanbad" element={<JharkhandLG title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/lg-ro-service-Lohardaga" element={<JharkhandLG title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/lg-ro-service-Gumla" element={<JharkhandLG title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/lg-ro-service-Simdega" element={<JharkhandLG title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/lg-ro-service-Ranchi" element={<JharkhandLG title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/lg-ro-service-Khunti" element={<JharkhandLG title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/lg-ro-service-Singhbhum" element={<JharkhandLG title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/lg-ro-service-Saraikela-Kharsawan" element={<JharkhandLG title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/lg-ro-service-Jamtara" element={<JharkhandLG title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/lg-ro-service-Deoghar" element={<JharkhandLG title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/lg-ro-service-Dumka" element={<JharkhandLG title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/lg-ro-service-Pakur" element={<JharkhandLG title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/lg-ro-service-Godda" element={<JharkhandLG title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/lg-ro-service-Sahebganj" element={<JharkhandLG title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />

        <Route path="/lg-ro-service-Alipurduar" element={<BengalLG title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/lg-ro-service-Bankura" element={<BengalLG title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/lg-ro-service-Birbhum" element={<BengalLG title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/lg-ro-service-Cooch-Behar" element={<BengalLG title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/lg-ro-service-Dakshin-Dinajpur" element={<BengalLG title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/lg-ro-service-Darjeeling" element={<BengalLG title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/lg-ro-service-Durgapur" element={<BengalLG title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/lg-ro-service-Hooghly" element={<BengalLG title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/lg-ro-service-Howrah" element={<BengalLG title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/lg-ro-service-Jalpaiguri" element={<BengalLG title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/lg-ro-service-Jhargram" element={<BengalLG title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/lg-ro-service-Kalimpong" element={<BengalLG title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/lg-ro-service-Kolkata" element={<BengalLG title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/lg-ro-service-Malda" element={<BengalLG title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/lg-ro-service-Murshidabad" element={<BengalLG title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/lg-ro-service-Nadia" element={<BengalLG title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/lg-ro-service-North-24-Parganas" element={<BengalLG title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/lg-ro-service-Paschim-Bardhaman" element={<BengalLG title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/lg-ro-service-Paschim-Medinipur" element={<BengalLG title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/lg-ro-service-Purba-Bardhaman" element={<BengalLG title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/lg-ro-service-Purba-Medinipur" element={<BengalLG title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/lg-ro-service-South-24-Parganas" element={<BengalLG title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/lg-ro-service-Uttar-Dinajpur" element={<BengalLG title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />
        <Route path="/lg-ro-service-Agra" element={<UttarPradeshLG title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/lg-ro-service-Aligarh" element={<UttarPradeshLG title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/lg-ro-service-Ambedkar-Nagar" element={<UttarPradeshLG title={locdata.AmbedkarNagar.title} number={locdata.AmbedkarNagar.number} />} />
        <Route path="/lg-ro-service-Ayodhya" element={<UttarPradeshLG title={locdata.Ayodhya.title} number={locdata.Ayodhya.number} />} />
        <Route path="/lg-ro-service-Amethi" element={<UttarPradeshLG title={locdata.Amethi.title} number={locdata.Amethi.number} />} />
        <Route path="/lg-ro-service-Amroha" element={<UttarPradeshLG title={locdata.Amroha.title} number={locdata.Amroha.number} />} />
        <Route path="/lg-ro-service-Auraiya" element={<UttarPradeshLG title={locdata.Auraiya.title} number={locdata.Auraiya.number} />} />
        <Route path="/lg-ro-service-Azamgarh" element={<UttarPradeshLG title={locdata.Azamgarh.title} number={locdata.Azamgarh.number} />} />
        <Route path="/lg-ro-service-Budaun" element={<UttarPradeshLG title={locdata.Budaun.title} number={locdata.Budaun.number} />} />
        <Route path="/lg-ro-service-Bagpat" element={<UttarPradeshLG title={locdata.Bagpat.title} number={locdata.Bagpat.number} />} />
        <Route path="/lg-ro-service-Bahraich" element={<UttarPradeshLG title={locdata.Bahraich.title} number={locdata.Bahraich.number} />} />
        <Route path="/lg-ro-service-Ballia" element={<UttarPradeshLG title={locdata.Ballia.title} number={locdata.Ballia.number} />} />
        <Route path="/lg-ro-service-Balrampur" element={<UttarPradeshLG title={locdata.Balrampur.title} number={locdata.Balrampur.number} />} />
        <Route path="/lg-ro-service-Banda" element={<UttarPradeshLG title={locdata.Banda.title} number={locdata.Banda.number} />} />
        <Route path="/lg-ro-service-Barabanki" element={<UttarPradeshLG title={locdata.Barabanki.title} number={locdata.Barabanki.number} />} />
        <Route path="/lg-ro-service-Bareilly" element={<UttarPradeshLG title={locdata.Bareilly.title} number={locdata.Bareilly.number} />} />
        <Route path="/lg-ro-service-Basti" element={<UttarPradeshLG title={locdata.Basti.title} number={locdata.Basti.number} />} />
        <Route path="/lg-ro-service-Bijnor" element={<UttarPradeshLG title={locdata.Bijnor.title} number={locdata.Bijnor.number} />} />
        <Route path="/lg-ro-service-Bulandshahr" element={<UttarPradeshLG title={locdata.Bulandshahr.title} number={locdata.Bulandshahr.number} />} />
        <Route path="/lg-ro-service-Chandauli" element={<UttarPradeshLG title={locdata.Chandauli.title} number={locdata.Chandauli.number} />} />
        <Route path="/lg-ro-service-Chitrakoot" element={<UttarPradeshLG title={locdata.Chitrakoot.title} number={locdata.Chitrakoot.number} />} />
        <Route path="/lg-ro-service-Deoria" element={<UttarPradeshLG title={locdata.Deoria.title} number={locdata.Deoria.number} />} />
        <Route path="/lg-ro-service-Etah" element={<UttarPradeshLG title={locdata.Etah.title} number={locdata.Etah.number} />} />
        <Route path="/lg-ro-service-Etawah" element={<UttarPradeshLG title={locdata.Etawah.title} number={locdata.Etawah.number} />} />
        <Route path="/lg-ro-service-Farrukhabad" element={<UttarPradeshLG title={locdata.Farrukhabad.title} number={locdata.Farrukhabad.number} />} />
        <Route path="/lg-ro-service-Fatehpur" element={<UttarPradeshLG title={locdata.Fatehpur.title} number={locdata.Fatehpur.number} />} />
        <Route path="/lg-ro-service-Firozabad" element={<UttarPradeshLG title={locdata.Firozabad.title} number={locdata.Firozabad.number} />} />
        <Route path="/lg-ro-service-Gautam-Buddha-Nagar" element={<UttarPradeshLG title={locdata.GautamBuddhaNagar.title} number={locdata.GautamBuddhaNagar.number} />} />
        <Route path="/lg-ro-service-Ghaziabad" element={<UttarPradeshLG title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/lg-ro-service-Ghazipur" element={<UttarPradeshLG title={locdata.Ghazipur.title} number={locdata.Ghazipur.number} />} />
        <Route path="/lg-ro-service-Gonda" element={<UttarPradeshLG title={locdata.Gonda.title} number={locdata.Gonda.number} />} />
        <Route path="/lg-ro-service-Gorakhpur" element={<UttarPradeshLG title={locdata.Gorakhpur.title} number={locdata.Gorakhpur.number} />} />
        <Route path="/lg-ro-service-Hamirpur" element={<UttarPradeshLG title={locdata.Hamirpur.title} number={locdata.Hamirpur.number} />} />
        <Route path="/lg-ro-service-Hapur" element={<UttarPradeshLG title={locdata.Hapur.title} number={locdata.Hapur.number} />} />
        <Route path="/lg-ro-service-Hardoi" element={<UttarPradeshLG title={locdata.Hardoi.title} number={locdata.Hardoi.number} />} />
        <Route path="/lg-ro-service-Hathras" element={<UttarPradeshLG title={locdata.Hathras.title} number={locdata.Hathras.number} />} />
        <Route path="/lg-ro-service-Jalaun" element={<UttarPradeshLG title={locdata.Jalaun.title} number={locdata.Jalaun.number} />} />
        <Route path="/lg-ro-service-Jaunpur" element={<UttarPradeshLG title={locdata.Jaunpur.title} number={locdata.Jaunpur.number} />} />
        <Route path="/lg-ro-service-Jhansi" element={<UttarPradeshLG title={locdata.Jhansi.title} number={locdata.Jhansi.number} />} />
        <Route path="/lg-ro-service-Kannauj" element={<UttarPradeshLG title={locdata.Kannauj.title} number={locdata.Kannauj.number} />} />
        <Route path="/lg-ro-service-Kanpur-Dehat" element={<UttarPradeshLG title={locdata.KanpurDehat.title} number={locdata.KanpurDehat.number} />} />
        <Route path="/lg-ro-service-Kanpur" element={<UttarPradeshLG title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/lg-ro-service-Kasganj" element={<UttarPradeshLG title={locdata.Kasganj.title} number={locdata.Kasganj.number} />} />
        <Route path="/lg-ro-service-Kaushambi" element={<UttarPradeshLG title={locdata.Kaushambi.title} number={locdata.Kaushambi.number} />} />
        <Route path="/lg-ro-service-Kushinagar" element={<UttarPradeshLG title={locdata.Kushinagar.title} number={locdata.Kushinagar.number} />} />
        <Route path="/lg-ro-service-Lakhimpur-Kheri" element={<UttarPradeshLG title={locdata.LakhimpurKheri.title} number={locdata.LakhimpurKheri.number} />} />
        <Route path="/lg-ro-service-Lalitpur" element={<UttarPradeshLG title={locdata.Lalitpur.title} number={locdata.Lalitpur.number} />} />
        <Route path="/lg-ro-service-Lucknow" element={<UttarPradeshLG title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/lg-ro-service-Maharajganj" element={<UttarPradeshLG title={locdata.Maharajganj.title} number={locdata.Maharajganj.number} />} />
        <Route path="/lg-ro-service-Mahoba" element={<UttarPradeshLG title={locdata.Mahoba.title} number={locdata.Mahoba.number} />} />
        <Route path="/lg-ro-service-Mainpuri" element={<UttarPradeshLG title={locdata.Mainpuri.title} number={locdata.Mainpuri.number} />} />
        <Route path="/lg-ro-service-Mathura" element={<UttarPradeshLG title={locdata.Mathura.title} number={locdata.Mathura.number} />} />
        <Route path="/lg-ro-service-Mau" element={<UttarPradeshLG title={locdata.Mau.title} number={locdata.Mau.number} />} />
        <Route path="/lg-ro-service-Meerut" element={<UttarPradeshLG title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/lg-ro-service-Mirzapur" element={<UttarPradeshLG title={locdata.Mirzapur.title} number={locdata.Mirzapur.number} />} />
        <Route path="/lg-ro-service-Moradabad" element={<UttarPradeshLG title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/lg-ro-service-Muzaffarnagar" element={<UttarPradeshLG title={locdata.Muzaffarnagar.title} number={locdata.Muzaffarnagar.number} />} />
        <Route path="/lg-ro-service-Pilibhit" element={<UttarPradeshLG title={locdata.Pilibhit.title} number={locdata.Pilibhit.number} />} />
        <Route path="/lg-ro-service-Pratapgarh" element={<UttarPradeshLG title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/lg-ro-service-Prayagraj" element={<UttarPradeshLG title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/lg-ro-service-Rae-Bareli" element={<UttarPradeshLG title={locdata.RaeBareli.title} number={locdata.RaeBareli.number} />} />
        <Route path="/lg-ro-service-Rampur" element={<UttarPradeshLG title={locdata.Rampur.title} number={locdata.Rampur.number} />} />
        <Route path="/lg-ro-service-Saharanpur" element={<UttarPradeshLG title={locdata.Saharanpur.title} number={locdata.Saharanpur.number} />} />
        <Route path="/lg-ro-service-Sant-Kabir-Nagar" element={<UttarPradeshLG title={locdata.SantKabirNagar.title} number={locdata.SantKabirNagar.number} />} />
        <Route path="/lg-ro-service-Bhadohi" element={<UttarPradeshLG title={locdata.Bhadohi.title} number={locdata.Bhadohi.number} />} />
        <Route path="/lg-ro-service-Sambhal" element={<UttarPradeshLG title={locdata.Sambhal.title} number={locdata.Sambhal.number} />} />
        <Route path="/lg-ro-service-Shahjahanpur" element={<UttarPradeshLG title={locdata.Shahjahanpur.title} number={locdata.Shahjahanpur.number} />} />
        <Route path="/lg-ro-service-Shamli" element={<UttarPradeshLG title={locdata.Shamli.title} number={locdata.Shamli.number} />} />
        <Route path="/lg-ro-service-Shravasti" element={<UttarPradeshLG title={locdata.Shravasti.title} number={locdata.Shravasti.number} />} />
        <Route path="/lg-ro-service-Siddharthnagar" element={<UttarPradeshLG title={locdata.Siddharthnagar.title} number={locdata.Siddharthnagar.number} />} />
        <Route path="/lg-ro-service-Sitapur" element={<UttarPradeshLG title={locdata.Sitapur.title} number={locdata.Sitapur.number} />} />
        <Route path="/lg-ro-service-Sonbhadra" element={<UttarPradeshLG title={locdata.Sonbhadra.title} number={locdata.Sonbhadra.number} />} />
        <Route path="/lg-ro-service-Sultanpur" element={<UttarPradeshLG title={locdata.Sultanpur.title} number={locdata.Sultanpur.number} />} />
        <Route path="/lg-ro-service-Unnao" element={<UttarPradeshLG title={locdata.Unnao.title} number={locdata.Unnao.number} />} />
        <Route path="/lg-ro-service-Varanasi" element={<UttarPradeshLG title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/lg-ro-service-Robertsganj" element={<UttarPradeshLG title={locdata.Robertsganj.title} number={locdata.Robertsganj.number} />} />
        <Route path="/lg-ro-service-Bahjoi" element={<UttarPradeshLG title={locdata.Bahjoi.title} number={locdata.Bahjoi.number} />} />
        <Route path="/lg-ro-service-Khalilabad" element={<UttarPradeshLG title={locdata.Khalilabad.title} number={locdata.Khalilabad.number} />} />
        <Route path="/lg-ro-service-Akbarpur" element={<UttarPradeshLG title={locdata.Akbarpur.title} number={locdata.Akbarpur.number} />} />
        <Route path="/lg-ro-service-Orai" element={<UttarPradeshLG title={locdata.Orai.title} number={locdata.Orai.number} />} />
        <Route path="/lg-ro-service-Manjhanpur" element={<UttarPradeshLG title={locdata.Manjhanpur.title} number={locdata.Manjhanpur.number} />} />
        <Route path="/lg-ro-service-Padarauna" element={<UttarPradeshLG title={locdata.Padarauna.title} number={locdata.Padarauna.number} />} />
        <Route path="/lg-ro-service-Kheri" element={<UttarPradeshLG title={locdata.Kheri.title} number={locdata.Kheri.number} />} />
        <Route path="/lg-ro-service-Fatehgarh" element={<UttarPradeshLG title={locdata.Fatehgarh.title} number={locdata.Fatehgarh.number} />} />
        <Route path="/lg-ro-service-Noida" element={<UttarPradeshLG title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/lg-ro-service-Greater-Noida" element={<UttarPradeshLG title={locdata.GreaterNoida.title} number={locdata.GreaterNoida.number} />} />

        <Route path="/lg-ro-service-Indore" element={<MadhyaPradeshLG title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/lg-ro-service-Bhopal" element={<MadhyaPradeshLG title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/lg-ro-service-Jabalpur" element={<MadhyaPradeshLG title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/lg-ro-service-Gwalior" element={<MadhyaPradeshLG title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/lg-ro-service-Ujjain" element={<MadhyaPradeshLG title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/lg-ro-service-Sagar" element={<MadhyaPradeshLG title={locdata.Sagar.title} number={locdata.Sagar.number} />} />
        <Route path="/lg-ro-service-Dewas" element={<MadhyaPradeshLG title={locdata.Dewas.title} number={locdata.Dewas.number} />} />
        <Route path="/lg-ro-service-Satna" element={<MadhyaPradeshLG title={locdata.Satna.title} number={locdata.Satna.number} />} />
        <Route path="/lg-ro-service-Ratlam" element={<MadhyaPradeshLG title={locdata.Ratlam.title} number={locdata.Ratlam.number} />} />
        <Route path="/lg-ro-service-Rewa" element={<MadhyaPradeshLG title={locdata.Rewa.title} number={locdata.Rewa.number} />} />
        <Route path="/lg-ro-service-Katni" element={<MadhyaPradeshLG title={locdata.Katni.title} number={locdata.Katni.number} />} />
        <Route path="/lg-ro-service-Singrauli" element={<MadhyaPradeshLG title={locdata.Singrauli.title} number={locdata.Singrauli.number} />} />
        <Route path="/lg-ro-service-Burhanpur" element={<MadhyaPradeshLG title={locdata.Burhanpur.title} number={locdata.Burhanpur.number} />} />
        <Route path="/lg-ro-service-Morena" element={<MadhyaPradeshLG title={locdata.Morena.title} number={locdata.Morena.number} />} />
        <Route path="/lg-ro-service-Khandwa" element={<MadhyaPradeshLG title={locdata.Khandwa.title} number={locdata.Khandwa.number} />} />
        <Route path="/lg-ro-service-Bhind" element={<MadhyaPradeshLG title={locdata.Bhind.title} number={locdata.Bhind.number} />} />
        <Route path="/lg-ro-service-Chhindwara" element={<MadhyaPradeshLG title={locdata.Chhindwara.title} number={locdata.Chhindwara.number} />} />
        <Route path="/lg-ro-service-Guna" element={<MadhyaPradeshLG title={locdata.Guna.title} number={locdata.Guna.number} />} />
        <Route path="/lg-ro-service-Shivpuri" element={<MadhyaPradeshLG title={locdata.Shivpuri.title} number={locdata.Shivpuri.number} />} />
        <Route path="/lg-ro-service-Vidisha" element={<MadhyaPradeshLG title={locdata.Vidisha.title} number={locdata.Vidisha.number} />} />
        <Route path="/lg-ro-service-Chhatarpur" element={<MadhyaPradeshLG title={locdata.Chhatarpur.title} number={locdata.Chhatarpur.number} />} />
        <Route path="/lg-ro-service-Damoh" element={<MadhyaPradeshLG title={locdata.Damoh.title} number={locdata.Damoh.number} />} />
        <Route path="/lg-ro-service-Mandsaur" element={<MadhyaPradeshLG title={locdata.Mandsaur.title} number={locdata.Mandsaur.number} />} />
        <Route path="/lg-ro-service-Khargone" element={<MadhyaPradeshLG title={locdata.Khargone.title} number={locdata.Khargone.number} />} />
        <Route path="/lg-ro-service-Neemuch" element={<MadhyaPradeshLG title={locdata.Neemuch.title} number={locdata.Neemuch.number} />} />
        <Route path="/lg-ro-service-Pithampur" element={<MadhyaPradeshLG title={locdata.Pithampur.title} number={locdata.Pithampur.number} />} />
        <Route path="/lg-ro-service-Narmadapuram" element={<MadhyaPradeshLG title={locdata.Narmadapuram.title} number={locdata.Narmadapuram.number} />} />
        <Route path="/lg-ro-service-Itarsi" element={<MadhyaPradeshLG title={locdata.Itarsi.title} number={locdata.Itarsi.number} />} />
        <Route path="/lg-ro-service-Sehore" element={<MadhyaPradeshLG title={locdata.Sehore.title} number={locdata.Sehore.number} />} />
        <Route path="/lg-ro-service-Betul" element={<MadhyaPradeshLG title={locdata.Betul.title} number={locdata.Betul.number} />} />
        <Route path="/lg-ro-service-Seoni" element={<MadhyaPradeshLG title={locdata.Seoni.title} number={locdata.Seoni.number} />} />
        <Route path="/lg-ro-service-Datia" element={<MadhyaPradeshLG title={locdata.Datia.title} number={locdata.Datia.number} />} />
        <Route path="/lg-ro-service-Nagda" element={<MadhyaPradeshLG title={locdata.Nagda.title} number={locdata.Nagda.number} />} />
        <Route path="/lg-ro-service-Shahdol" element={<MadhyaPradeshLG title={locdata.Shahdol.title} number={locdata.Shahdol.number} />} />
        <Route path="/lg-ro-service-Dhar" element={<MadhyaPradeshLG title={locdata.Dhar.title} number={locdata.Dhar.number} />} />

        <Route path="/lg-ro-service-Ajmer" element={<RajasthanLG title={locdata.Ajmer.title} number={locdata.Ajmer.number} />} />
        <Route path="/lg-ro-service-Alwar" element={<RajasthanLG title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/lg-ro-service-Balotra" element={<RajasthanLG title={locdata.Balotra.title} number={locdata.Balotra.number} />} />
        <Route path="/lg-ro-service-Banswara" element={<RajasthanLG title={locdata.Banswara.title} number={locdata.Banswara.number} />} />
        <Route path="/lg-ro-service-Baran" element={<RajasthanLG title={locdata.Baran.title} number={locdata.Baran.number} />} />
        <Route path="/lg-ro-service-Barmer" element={<RajasthanLG title={locdata.Barmer.title} number={locdata.Barmer.number} />} />
        <Route path="/lg-ro-service-Beawar" element={<RajasthanLG title={locdata.Beawar.title} number={locdata.Beawar.number} />} />
        <Route path="/lg-ro-service-Bharatpur" element={<RajasthanLG title={locdata.Bharatpur.title} number={locdata.Bharatpur.number} />} />
        <Route path="/lg-ro-service-Bhilwara" element={<RajasthanLG title={locdata.Bhilwara.title} number={locdata.Bhilwara.number} />} />
        <Route path="/lg-ro-service-Bikaner" element={<RajasthanLG title={locdata.Bikaner.title} number={locdata.Bikaner.number} />} />
        <Route path="/lg-ro-service-Bundi" element={<RajasthanLG title={locdata.Bundi.title} number={locdata.Bundi.number} />} />
        <Route path="/lg-ro-service-Chittorgarh" element={<RajasthanLG title={locdata.Chittorgarh.title} number={locdata.Chittorgarh.number} />} />
        <Route path="/lg-ro-service-Churu" element={<RajasthanLG title={locdata.Churu.title} number={locdata.Churu.number} />} />
        <Route path="/lg-ro-service-Dausa" element={<RajasthanLG title={locdata.Dausa.title} number={locdata.Dausa.number} />} />
        <Route path="/lg-ro-service-Deeg" element={<RajasthanLG title={locdata.Deeg.title} number={locdata.Deeg.number} />} />
        <Route path="/lg-ro-service-Deedwana" element={<RajasthanLG title={locdata.Deedwana.title} number={locdata.Deedwana.number} />} />
        <Route path="/lg-ro-service-Dholpur" element={<RajasthanLG title={locdata.Dholpur.title} number={locdata.Dholpur.number} />} />
        <Route path="/lg-ro-service-Dungarpur" element={<RajasthanLG title={locdata.Dungarpur.title} number={locdata.Dungarpur.number} />} />
        <Route path="/lg-ro-service-Hanumangarh" element={<RajasthanLG title={locdata.Hanumangarh.title} number={locdata.Hanumangarh.number} />} />
        <Route path="/lg-ro-service-Jaipur" element={<RajasthanLG title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/lg-ro-service-Jaisalmer" element={<RajasthanLG title={locdata.Jaisalmer.title} number={locdata.Jaisalmer.number} />} />
        <Route path="/lg-ro-service-Jalore" element={<RajasthanLG title={locdata.Jalore.title} number={locdata.Jalore.number} />} />
        <Route path="/lg-ro-service-Jhalawar" element={<RajasthanLG title={locdata.Jhalawar.title} number={locdata.Jhalawar.number} />} />
        <Route path="/lg-ro-service-Jhunjhunu" element={<RajasthanLG title={locdata.Jhunjhunu.title} number={locdata.Jhunjhunu.number} />} />
        <Route path="/lg-ro-service-Jodhpur" element={<RajasthanLG title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/lg-ro-service-Karauli" element={<RajasthanLG title={locdata.Karauli.title} number={locdata.Karauli.number} />} />
        <Route path="/lg-ro-service-Tijara" element={<RajasthanLG title={locdata.Tijara.title} number={locdata.Tijara.number} />} />
        <Route path="/lg-ro-service-Kotputli" element={<RajasthanLG title={locdata.Kotputli.title} number={locdata.Kotputli.number} />} />
        <Route path="/lg-ro-service-Kota" element={<RajasthanLG title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/lg-ro-service-Nagaur" element={<RajasthanLG title={locdata.Nagaur.title} number={locdata.Nagaur.number} />} />
        <Route path="/lg-ro-service-Pali" element={<RajasthanLG title={locdata.Pali.title} number={locdata.Pali.number} />} />
        <Route path="/lg-ro-service-Phalodi" element={<RajasthanLG title={locdata.Phalodi.title} number={locdata.Phalodi.number} />} />
        <Route path="/lg-ro-service-Pratapgarh" element={<RajasthanLG title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/lg-ro-service-Rajsamand" element={<RajasthanLG title={locdata.Rajsamand.title} number={locdata.Rajsamand.number} />} />
        <Route path="/lg-ro-service-Salumbar" element={<RajasthanLG title={locdata.Salumbar.title} number={locdata.Salumbar.number} />} />
        <Route path="/lg-ro-service-Sawai-Madhopur" element={<RajasthanLG title={locdata.SawaiMadhopur.title} number={locdata.SawaiMadhopur.number} />} />
        <Route path="/lg-ro-service-Sikar" element={<RajasthanLG title={locdata.Sikar.title} number={locdata.Sikar.number} />} />
        <Route path="/lg-ro-service-Sirohi" element={<RajasthanLG title={locdata.Sirohi.title} number={locdata.Sirohi.number} />} />
        <Route path="/lg-ro-service-Sri-Ganganagar" element={<RajasthanLG title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/lg-ro-service-Tonk" element={<RajasthanLG title={locdata.Tonk.title} number={locdata.Tonk.number} />} />
        <Route path="/lg-ro-service-Udaipur" element={<RajasthanLG title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />

        <Route path="/lg-ro-service-Mumbai" element={<LGro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/lg-ro-service-Delhi" element={<LGro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/lg-ro-service-Bengaluru" element={<LGro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/lg-ro-service-Hyderabad" element={<LGro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/lg-ro-service-Ahmedabad" element={<LGro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/lg-ro-service-Chennai" element={<LGro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/lg-ro-service-Surat" element={<LGro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/lg-ro-service-Pune" element={<LGro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/lg-ro-service-Jaipur" element={<LGro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/lg-ro-service-Lucknow" element={<LGro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/lg-ro-service-Kanpur" element={<LGro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/lg-ro-service-Nagpur" element={<LGro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/lg-ro-service-Indore" element={<LGro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/lg-ro-service-Thane" element={<LGro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/lg-ro-service-Bhopal" element={<LGro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/lg-ro-service-Visakhapatnam" element={<LGro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/lg-ro-service-Vadodara" element={<LGro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/lg-ro-service-Ghaziabad" element={<LGro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/lg-ro-service-Ludhiana" element={<LGro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/lg-ro-service-Agra" element={<LGro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/lg-ro-service-Nashik" element={<LGro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/lg-ro-service-Faridabad" element={<LGro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/lg-ro-service-Meerut" element={<LGro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/lg-ro-service-Rajkot" element={<LGro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/lg-ro-service-Varanasi" element={<LGro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/lg-ro-service-Srinagar" element={<LGro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/lg-ro-service-Amritsar" element={<LGro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/lg-ro-service-Navi-Mumbai" element={<LGro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/lg-ro-service-Prayagraj" element={<LGro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/lg-ro-service-Jabalpur" element={<LGro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/lg-ro-service-Gwalior" element={<LGro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/lg-ro-service-Coimbatore" element={<LGro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/lg-ro-service-Vijayawada" element={<LGro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/lg-ro-service-Jodhpur" element={<LGro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/lg-ro-service-Madurai" element={<LGro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/lg-ro-service-Raipur" element={<LGro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/lg-ro-service-Kota" element={<LGro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/lg-ro-service-Guwahati" element={<LGro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/lg-ro-service-Chandigarh" element={<LGro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/lg-ro-service-Thiruvananthapuram" element={<LGro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/lg-ro-service-Solapur" element={<LGro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/lg-ro-service-Tiruppur" element={<LGro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/lg-ro-service-Moradabad" element={<LGro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/lg-ro-service-Mysore" element={<LGro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/lg-ro-service-Gurgaon" element={<LGro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/lg-ro-service-Aligarh" element={<LGro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/lg-ro-service-Jalandhar" element={<LGro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/lg-ro-service-Bhubaneswar" element={<LGro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/lg-ro-service-Noida" element={<LGro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/lg-ro-service-Jamshedpur" element={<LGro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/lg-ro-service-Cuttack" element={<LGro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/lg-ro-service-Bhavnagar" element={<LGro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/lg-ro-service-Dehradun" element={<LGro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/lg-ro-service-Rourkela" element={<LGro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/lg-ro-service-Jamnagar" element={<LGro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/lg-ro-service-Ujjain" element={<LGro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/lg-ro-service-Jammu" element={<LGro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/lg-ro-service-Mangalore" element={<LGro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/lg-ro-service-Erode" element={<LGro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/lg-ro-service-Udaipur" element={<LGro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/lg-ro-service-Rajahmundry" element={<LGro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/lg-ro-service-Patiala" element={<LGro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/lg-ro-service-Agartala" element={<LGro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/lg-ro-service-Tirupati" element={<LGro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/lg-ro-service-Sambalpur" element={<LGro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/lg-ro-service-Bilaspur" element={<LGro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/lg-ro-service-Alwar" element={<LGro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/lg-ro-service-Bardhaman" element={<LGro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/lg-ro-service-Sonipat" element={<LGro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/lg-ro-service-New-Delhi" element={<LGro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/lg-ro-service-Puducherry" element={<LGro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/lg-ro-service-Haridwar" element={<LGro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/lg-ro-service-Vijayanagaram" element={<LGro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/lg-ro-service-Sri-Ganganagar" element={<LGro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/lg-ro-service-Secunderabad" element={<LGro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/lg-ro-service-Yamunanagar" element={<LGro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/lg-ro-service-Gandhinagar" element={<LGro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/lg-ro-service-Ambala" element={<LGro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/lg-ro-service-Vellore" element={<LGro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/lg-ro-service-Shimla" element={<LGro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/lg-ro-service-Amaravati" element={<LGro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />


        <Route path="/livepure-ro-service-Araria" element={<BiharLivepure title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/livepure-ro-service-Arwal" element={<BiharLivepure title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/livepure-ro-service-Aurangabad" element={<BiharLivepure title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/livepure-ro-service-Banka" element={<BiharLivepure title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/livepure-ro-service-Begusarai" element={<BiharLivepure title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/livepure-ro-service-Bhabua" element={<BiharLivepure title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/livepure-ro-service-Bhagalpur" element={<BiharLivepure title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/livepure-ro-service-Bhojpur" element={<BiharLivepure title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/livepure-ro-service-Bihar-Sharif" element={<BiharLivepure title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/livepure-ro-service-Buxar" element={<BiharLivepure title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/livepure-ro-service-Chhapra" element={<BiharLivepure title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/livepure-ro-service-Darbhanga" element={<BiharLivepure title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/livepure-ro-service-East-Champaran" element={<BiharLivepure title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/livepure-ro-service-Gaya" element={<BiharLivepure title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/livepure-ro-service-Gopalganj" element={<BiharLivepure title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/livepure-ro-service-Jamui" element={<BiharLivepure title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/livepure-ro-service-Jehanabad" element={<BiharLivepure title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/livepure-ro-service-Khagaria" element={<BiharLivepure title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/livepure-ro-service-Kishanganj" element={<BiharLivepure title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/livepure-ro-service-Kaimur" element={<BiharLivepure title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/livepure-ro-service-Katihar" element={<BiharLivepure title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/livepure-ro-service-Lakhisarai" element={<BiharLivepure title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/livepure-ro-service-Madhubani" element={<BiharLivepure title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/livepure-ro-service-Munger" element={<BiharLivepure title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/livepure-ro-service-Madhepura" element={<BiharLivepure title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/livepure-ro-service-Muzaffarpur" element={<BiharLivepure title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/livepure-ro-service-Nalanda" element={<BiharLivepure title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/livepure-ro-service-Nawada" element={<BiharLivepure title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/livepure-ro-service-Patna" element={<BiharLivepure title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/livepure-ro-service-Purnia" element={<BiharLivepure title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/livepure-ro-service-Rohtas" element={<BiharLivepure title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/livepure-ro-service-Saharsa" element={<BiharLivepure title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/livepure-ro-service-Samastipur" element={<BiharLivepure title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/livepure-ro-service-Sasaram" element={<BiharLivepure title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/livepure-ro-service-Sheohar" element={<BiharLivepure title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/livepure-ro-service-Sheikhpura" element={<BiharLivepure title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/livepure-ro-service-Saran" element={<BiharLivepure title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/livepure-ro-service-Sitamarhi" element={<BiharLivepure title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/livepure-ro-service-Supaul" element={<BiharLivepure title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/livepure-ro-service-Siwan" element={<BiharLivepure title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/livepure-ro-service-Vaishali" element={<BiharLivepure title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/livepure-ro-service-West-Champaran" element={<BiharLivepure title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/livepure-ro-service-Garhwa" element={<JharkhandLivepure title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/livepure-ro-service-Palamu" element={<JharkhandLivepure title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/livepure-ro-service-Latehar" element={<JharkhandLivepure title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/livepure-ro-service-Chatra" element={<JharkhandLivepure title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/livepure-ro-service-Hazaribagh" element={<JharkhandLivepure title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/livepure-ro-service-Jamshedpur" element={<JharkhandLivepure title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/livepure-ro-service-Koderma" element={<JharkhandLivepure title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/livepure-ro-service-Giridih" element={<JharkhandLivepure title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/livepure-ro-service-Ramgarh" element={<JharkhandLivepure title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/livepure-ro-service-Bokaro" element={<JharkhandLivepure title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/livepure-ro-service-Dhanbad" element={<JharkhandLivepure title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/livepure-ro-service-Lohardaga" element={<JharkhandLivepure title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/livepure-ro-service-Gumla" element={<JharkhandLivepure title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/livepure-ro-service-Simdega" element={<JharkhandLivepure title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/livepure-ro-service-Ranchi" element={<JharkhandLivepure title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/livepure-ro-service-Khunti" element={<JharkhandLivepure title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/livepure-ro-service-Singhbhum" element={<JharkhandLivepure title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/livepure-ro-service-Saraikela-Kharsawan" element={<JharkhandLivepure title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/livepure-ro-service-Jamtara" element={<JharkhandLivepure title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/livepure-ro-service-Deoghar" element={<JharkhandLivepure title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/livepure-ro-service-Dumka" element={<JharkhandLivepure title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/livepure-ro-service-Pakur" element={<JharkhandLivepure title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/livepure-ro-service-Godda" element={<JharkhandLivepure title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/livepure-ro-service-Sahebganj" element={<JharkhandLivepure title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />

        <Route path="/livepure-ro-service-Alipurduar" element={<BengalLivepure title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/livepure-ro-service-Bankura" element={<BengalLivepure title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/livepure-ro-service-Birbhum" element={<BengalLivepure title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/livepure-ro-service-Cooch-Behar" element={<BengalLivepure title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/livepure-ro-service-Dakshin-Dinajpur" element={<BengalLivepure title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/livepure-ro-service-Darjeeling" element={<BengalLivepure title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/livepure-ro-service-Durgapur" element={<BengalLivepure title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/livepure-ro-service-Hooghly" element={<BengalLivepure title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/livepure-ro-service-Howrah" element={<BengalLivepure title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/livepure-ro-service-Jalpaiguri" element={<BengalLivepure title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/livepure-ro-service-Jhargram" element={<BengalLivepure title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/livepure-ro-service-Kalimpong" element={<BengalLivepure title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/livepure-ro-service-Kolkata" element={<BengalLivepure title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/livepure-ro-service-Malda" element={<BengalLivepure title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/livepure-ro-service-Murshidabad" element={<BengalLivepure title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/livepure-ro-service-Nadia" element={<BengalLivepure title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/livepure-ro-service-North-24-Parganas" element={<BengalLivepure title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/livepure-ro-service-Paschim-Bardhaman" element={<BengalLivepure title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/livepure-ro-service-Paschim-Medinipur" element={<BengalLivepure title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/livepure-ro-service-Purba-Bardhaman" element={<BengalLivepure title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/livepure-ro-service-Purba-Medinipur" element={<BengalLivepure title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/livepure-ro-service-South-24-Parganas" element={<BengalLivepure title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/livepure-ro-service-Uttar-Dinajpur" element={<BengalLivepure title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />
        <Route path="/livepure-ro-service-Agra" element={<UttarPradeshLivepure title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/livepure-ro-service-Aligarh" element={<UttarPradeshLivepure title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/livepure-ro-service-Ambedkar-Nagar" element={<UttarPradeshLivepure title={locdata.AmbedkarNagar.title} number={locdata.AmbedkarNagar.number} />} />
        <Route path="/livepure-ro-service-Ayodhya" element={<UttarPradeshLivepure title={locdata.Ayodhya.title} number={locdata.Ayodhya.number} />} />
        <Route path="/livepure-ro-service-Amethi" element={<UttarPradeshLivepure title={locdata.Amethi.title} number={locdata.Amethi.number} />} />
        <Route path="/livepure-ro-service-Amroha" element={<UttarPradeshLivepure title={locdata.Amroha.title} number={locdata.Amroha.number} />} />
        <Route path="/livepure-ro-service-Auraiya" element={<UttarPradeshLivepure title={locdata.Auraiya.title} number={locdata.Auraiya.number} />} />
        <Route path="/livepure-ro-service-Azamgarh" element={<UttarPradeshLivepure title={locdata.Azamgarh.title} number={locdata.Azamgarh.number} />} />
        <Route path="/livepure-ro-service-Budaun" element={<UttarPradeshLivepure title={locdata.Budaun.title} number={locdata.Budaun.number} />} />
        <Route path="/livepure-ro-service-Bagpat" element={<UttarPradeshLivepure title={locdata.Bagpat.title} number={locdata.Bagpat.number} />} />
        <Route path="/livepure-ro-service-Bahraich" element={<UttarPradeshLivepure title={locdata.Bahraich.title} number={locdata.Bahraich.number} />} />
        <Route path="/livepure-ro-service-Ballia" element={<UttarPradeshLivepure title={locdata.Ballia.title} number={locdata.Ballia.number} />} />
        <Route path="/livepure-ro-service-Balrampur" element={<UttarPradeshLivepure title={locdata.Balrampur.title} number={locdata.Balrampur.number} />} />
        <Route path="/livepure-ro-service-Banda" element={<UttarPradeshLivepure title={locdata.Banda.title} number={locdata.Banda.number} />} />
        <Route path="/livepure-ro-service-Barabanki" element={<UttarPradeshLivepure title={locdata.Barabanki.title} number={locdata.Barabanki.number} />} />
        <Route path="/livepure-ro-service-Bareilly" element={<UttarPradeshLivepure title={locdata.Bareilly.title} number={locdata.Bareilly.number} />} />
        <Route path="/livepure-ro-service-Basti" element={<UttarPradeshLivepure title={locdata.Basti.title} number={locdata.Basti.number} />} />
        <Route path="/livepure-ro-service-Bijnor" element={<UttarPradeshLivepure title={locdata.Bijnor.title} number={locdata.Bijnor.number} />} />
        <Route path="/livepure-ro-service-Bulandshahr" element={<UttarPradeshLivepure title={locdata.Bulandshahr.title} number={locdata.Bulandshahr.number} />} />
        <Route path="/livepure-ro-service-Chandauli" element={<UttarPradeshLivepure title={locdata.Chandauli.title} number={locdata.Chandauli.number} />} />
        <Route path="/livepure-ro-service-Chitrakoot" element={<UttarPradeshLivepure title={locdata.Chitrakoot.title} number={locdata.Chitrakoot.number} />} />
        <Route path="/livepure-ro-service-Deoria" element={<UttarPradeshLivepure title={locdata.Deoria.title} number={locdata.Deoria.number} />} />
        <Route path="/livepure-ro-service-Etah" element={<UttarPradeshLivepure title={locdata.Etah.title} number={locdata.Etah.number} />} />
        <Route path="/livepure-ro-service-Etawah" element={<UttarPradeshLivepure title={locdata.Etawah.title} number={locdata.Etawah.number} />} />
        <Route path="/livepure-ro-service-Farrukhabad" element={<UttarPradeshLivepure title={locdata.Farrukhabad.title} number={locdata.Farrukhabad.number} />} />
        <Route path="/livepure-ro-service-Fatehpur" element={<UttarPradeshLivepure title={locdata.Fatehpur.title} number={locdata.Fatehpur.number} />} />
        <Route path="/livepure-ro-service-Firozabad" element={<UttarPradeshLivepure title={locdata.Firozabad.title} number={locdata.Firozabad.number} />} />
        <Route path="/livepure-ro-service-Gautam-Buddha-Nagar" element={<UttarPradeshLivepure title={locdata.GautamBuddhaNagar.title} number={locdata.GautamBuddhaNagar.number} />} />
        <Route path="/livepure-ro-service-Ghaziabad" element={<UttarPradeshLivepure title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/livepure-ro-service-Ghazipur" element={<UttarPradeshLivepure title={locdata.Ghazipur.title} number={locdata.Ghazipur.number} />} />
        <Route path="/livepure-ro-service-Gonda" element={<UttarPradeshLivepure title={locdata.Gonda.title} number={locdata.Gonda.number} />} />
        <Route path="/livepure-ro-service-Gorakhpur" element={<UttarPradeshLivepure title={locdata.Gorakhpur.title} number={locdata.Gorakhpur.number} />} />
        <Route path="/livepure-ro-service-Hamirpur" element={<UttarPradeshLivepure title={locdata.Hamirpur.title} number={locdata.Hamirpur.number} />} />
        <Route path="/livepure-ro-service-Hapur" element={<UttarPradeshLivepure title={locdata.Hapur.title} number={locdata.Hapur.number} />} />
        <Route path="/livepure-ro-service-Hardoi" element={<UttarPradeshLivepure title={locdata.Hardoi.title} number={locdata.Hardoi.number} />} />
        <Route path="/livepure-ro-service-Hathras" element={<UttarPradeshLivepure title={locdata.Hathras.title} number={locdata.Hathras.number} />} />
        <Route path="/livepure-ro-service-Jalaun" element={<UttarPradeshLivepure title={locdata.Jalaun.title} number={locdata.Jalaun.number} />} />
        <Route path="/livepure-ro-service-Jaunpur" element={<UttarPradeshLivepure title={locdata.Jaunpur.title} number={locdata.Jaunpur.number} />} />
        <Route path="/livepure-ro-service-Jhansi" element={<UttarPradeshLivepure title={locdata.Jhansi.title} number={locdata.Jhansi.number} />} />
        <Route path="/livepure-ro-service-Kannauj" element={<UttarPradeshLivepure title={locdata.Kannauj.title} number={locdata.Kannauj.number} />} />
        <Route path="/livepure-ro-service-Kanpur-Dehat" element={<UttarPradeshLivepure title={locdata.KanpurDehat.title} number={locdata.KanpurDehat.number} />} />
        <Route path="/livepure-ro-service-Kanpur" element={<UttarPradeshLivepure title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/livepure-ro-service-Kasganj" element={<UttarPradeshLivepure title={locdata.Kasganj.title} number={locdata.Kasganj.number} />} />
        <Route path="/livepure-ro-service-Kaushambi" element={<UttarPradeshLivepure title={locdata.Kaushambi.title} number={locdata.Kaushambi.number} />} />
        <Route path="/livepure-ro-service-Kushinagar" element={<UttarPradeshLivepure title={locdata.Kushinagar.title} number={locdata.Kushinagar.number} />} />
        <Route path="/livepure-ro-service-Lakhimpur-Kheri" element={<UttarPradeshLivepure title={locdata.LakhimpurKheri.title} number={locdata.LakhimpurKheri.number} />} />
        <Route path="/livepure-ro-service-Lalitpur" element={<UttarPradeshLivepure title={locdata.Lalitpur.title} number={locdata.Lalitpur.number} />} />
        <Route path="/livepure-ro-service-Lucknow" element={<UttarPradeshLivepure title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/livepure-ro-service-Maharajganj" element={<UttarPradeshLivepure title={locdata.Maharajganj.title} number={locdata.Maharajganj.number} />} />
        <Route path="/livepure-ro-service-Mahoba" element={<UttarPradeshLivepure title={locdata.Mahoba.title} number={locdata.Mahoba.number} />} />
        <Route path="/livepure-ro-service-Mainpuri" element={<UttarPradeshLivepure title={locdata.Mainpuri.title} number={locdata.Mainpuri.number} />} />
        <Route path="/livepure-ro-service-Mathura" element={<UttarPradeshLivepure title={locdata.Mathura.title} number={locdata.Mathura.number} />} />
        <Route path="/livepure-ro-service-Mau" element={<UttarPradeshLivepure title={locdata.Mau.title} number={locdata.Mau.number} />} />
        <Route path="/livepure-ro-service-Meerut" element={<UttarPradeshLivepure title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/livepure-ro-service-Mirzapur" element={<UttarPradeshLivepure title={locdata.Mirzapur.title} number={locdata.Mirzapur.number} />} />
        <Route path="/livepure-ro-service-Moradabad" element={<UttarPradeshLivepure title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/livepure-ro-service-Muzaffarnagar" element={<UttarPradeshLivepure title={locdata.Muzaffarnagar.title} number={locdata.Muzaffarnagar.number} />} />
        <Route path="/livepure-ro-service-Pilibhit" element={<UttarPradeshLivepure title={locdata.Pilibhit.title} number={locdata.Pilibhit.number} />} />
        <Route path="/livepure-ro-service-Pratapgarh" element={<UttarPradeshLivepure title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/livepure-ro-service-Prayagraj" element={<UttarPradeshLivepure title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/livepure-ro-service-Rae-Bareli" element={<UttarPradeshLivepure title={locdata.RaeBareli.title} number={locdata.RaeBareli.number} />} />
        <Route path="/livepure-ro-service-Rampur" element={<UttarPradeshLivepure title={locdata.Rampur.title} number={locdata.Rampur.number} />} />
        <Route path="/livepure-ro-service-Saharanpur" element={<UttarPradeshLivepure title={locdata.Saharanpur.title} number={locdata.Saharanpur.number} />} />
        <Route path="/livepure-ro-service-Sant-Kabir-Nagar" element={<UttarPradeshLivepure title={locdata.SantKabirNagar.title} number={locdata.SantKabirNagar.number} />} />
        <Route path="/livepure-ro-service-Bhadohi" element={<UttarPradeshLivepure title={locdata.Bhadohi.title} number={locdata.Bhadohi.number} />} />
        <Route path="/livepure-ro-service-Sambhal" element={<UttarPradeshLivepure title={locdata.Sambhal.title} number={locdata.Sambhal.number} />} />
        <Route path="/livepure-ro-service-Shahjahanpur" element={<UttarPradeshLivepure title={locdata.Shahjahanpur.title} number={locdata.Shahjahanpur.number} />} />
        <Route path="/livepure-ro-service-Shamli" element={<UttarPradeshLivepure title={locdata.Shamli.title} number={locdata.Shamli.number} />} />
        <Route path="/livepure-ro-service-Shravasti" element={<UttarPradeshLivepure title={locdata.Shravasti.title} number={locdata.Shravasti.number} />} />
        <Route path="/livepure-ro-service-Siddharthnagar" element={<UttarPradeshLivepure title={locdata.Siddharthnagar.title} number={locdata.Siddharthnagar.number} />} />
        <Route path="/livepure-ro-service-Sitapur" element={<UttarPradeshLivepure title={locdata.Sitapur.title} number={locdata.Sitapur.number} />} />
        <Route path="/livepure-ro-service-Sonbhadra" element={<UttarPradeshLivepure title={locdata.Sonbhadra.title} number={locdata.Sonbhadra.number} />} />
        <Route path="/livepure-ro-service-Sultanpur" element={<UttarPradeshLivepure title={locdata.Sultanpur.title} number={locdata.Sultanpur.number} />} />
        <Route path="/livepure-ro-service-Unnao" element={<UttarPradeshLivepure title={locdata.Unnao.title} number={locdata.Unnao.number} />} />
        <Route path="/livepure-ro-service-Varanasi" element={<UttarPradeshLivepure title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/livepure-ro-service-Robertsganj" element={<UttarPradeshLivepure title={locdata.Robertsganj.title} number={locdata.Robertsganj.number} />} />
        <Route path="/livepure-ro-service-Bahjoi" element={<UttarPradeshLivepure title={locdata.Bahjoi.title} number={locdata.Bahjoi.number} />} />
        <Route path="/livepure-ro-service-Khalilabad" element={<UttarPradeshLivepure title={locdata.Khalilabad.title} number={locdata.Khalilabad.number} />} />
        <Route path="/livepure-ro-service-Akbarpur" element={<UttarPradeshLivepure title={locdata.Akbarpur.title} number={locdata.Akbarpur.number} />} />
        <Route path="/livepure-ro-service-Orai" element={<UttarPradeshLivepure title={locdata.Orai.title} number={locdata.Orai.number} />} />
        <Route path="/livepure-ro-service-Manjhanpur" element={<UttarPradeshLivepure title={locdata.Manjhanpur.title} number={locdata.Manjhanpur.number} />} />
        <Route path="/livepure-ro-service-Padarauna" element={<UttarPradeshLivepure title={locdata.Padarauna.title} number={locdata.Padarauna.number} />} />
        <Route path="/livepure-ro-service-Kheri" element={<UttarPradeshLivepure title={locdata.Kheri.title} number={locdata.Kheri.number} />} />
        <Route path="/livepure-ro-service-Fatehgarh" element={<UttarPradeshLivepure title={locdata.Fatehgarh.title} number={locdata.Fatehgarh.number} />} />
        <Route path="/livepure-ro-service-Noida" element={<UttarPradeshLivepure title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/livepure-ro-service-Greater-Noida" element={<UttarPradeshLivepure title={locdata.GreaterNoida.title} number={locdata.GreaterNoida.number} />} />

        <Route path="/livepure-ro-service-Indore" element={<MadhyaPradeshLivpure title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/livepure-ro-service-Bhopal" element={<MadhyaPradeshLivpure title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/livepure-ro-service-Jabalpur" element={<MadhyaPradeshLivpure title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/livepure-ro-service-Gwalior" element={<MadhyaPradeshLivpure title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/livepure-ro-service-Ujjain" element={<MadhyaPradeshLivpure title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/livepure-ro-service-Sagar" element={<MadhyaPradeshLivpure title={locdata.Sagar.title} number={locdata.Sagar.number} />} />
        <Route path="/livepure-ro-service-Dewas" element={<MadhyaPradeshLivpure title={locdata.Dewas.title} number={locdata.Dewas.number} />} />
        <Route path="/livepure-ro-service-Satna" element={<MadhyaPradeshLivpure title={locdata.Satna.title} number={locdata.Satna.number} />} />
        <Route path="/livepure-ro-service-Ratlam" element={<MadhyaPradeshLivpure title={locdata.Ratlam.title} number={locdata.Ratlam.number} />} />
        <Route path="/livepure-ro-service-Rewa" element={<MadhyaPradeshLivpure title={locdata.Rewa.title} number={locdata.Rewa.number} />} />
        <Route path="/livepure-ro-service-Katni" element={<MadhyaPradeshLivpure title={locdata.Katni.title} number={locdata.Katni.number} />} />
        <Route path="/livepure-ro-service-Singrauli" element={<MadhyaPradeshLivpure title={locdata.Singrauli.title} number={locdata.Singrauli.number} />} />
        <Route path="/livepure-ro-service-Burhanpur" element={<MadhyaPradeshLivpure title={locdata.Burhanpur.title} number={locdata.Burhanpur.number} />} />
        <Route path="/livepure-ro-service-Morena" element={<MadhyaPradeshLivpure title={locdata.Morena.title} number={locdata.Morena.number} />} />
        <Route path="/livepure-ro-service-Khandwa" element={<MadhyaPradeshLivpure title={locdata.Khandwa.title} number={locdata.Khandwa.number} />} />
        <Route path="/livepure-ro-service-Bhind" element={<MadhyaPradeshLivpure title={locdata.Bhind.title} number={locdata.Bhind.number} />} />
        <Route path="/livepure-ro-service-Chhindwara" element={<MadhyaPradeshLivpure title={locdata.Chhindwara.title} number={locdata.Chhindwara.number} />} />
        <Route path="/livepure-ro-service-Guna" element={<MadhyaPradeshLivpure title={locdata.Guna.title} number={locdata.Guna.number} />} />
        <Route path="/livepure-ro-service-Shivpuri" element={<MadhyaPradeshLivpure title={locdata.Shivpuri.title} number={locdata.Shivpuri.number} />} />
        <Route path="/livepure-ro-service-Vidisha" element={<MadhyaPradeshLivpure title={locdata.Vidisha.title} number={locdata.Vidisha.number} />} />
        <Route path="/livepure-ro-service-Chhatarpur" element={<MadhyaPradeshLivpure title={locdata.Chhatarpur.title} number={locdata.Chhatarpur.number} />} />
        <Route path="/livepure-ro-service-Damoh" element={<MadhyaPradeshLivpure title={locdata.Damoh.title} number={locdata.Damoh.number} />} />
        <Route path="/livepure-ro-service-Mandsaur" element={<MadhyaPradeshLivpure title={locdata.Mandsaur.title} number={locdata.Mandsaur.number} />} />
        <Route path="/livepure-ro-service-Khargone" element={<MadhyaPradeshLivpure title={locdata.Khargone.title} number={locdata.Khargone.number} />} />
        <Route path="/livepure-ro-service-Neemuch" element={<MadhyaPradeshLivpure title={locdata.Neemuch.title} number={locdata.Neemuch.number} />} />
        <Route path="/livepure-ro-service-Pithampur" element={<MadhyaPradeshLivpure title={locdata.Pithampur.title} number={locdata.Pithampur.number} />} />
        <Route path="/livepure-ro-service-Narmadapuram" element={<MadhyaPradeshLivpure title={locdata.Narmadapuram.title} number={locdata.Narmadapuram.number} />} />
        <Route path="/livepure-ro-service-Itarsi" element={<MadhyaPradeshLivpure title={locdata.Itarsi.title} number={locdata.Itarsi.number} />} />
        <Route path="/livepure-ro-service-Sehore" element={<MadhyaPradeshLivpure title={locdata.Sehore.title} number={locdata.Sehore.number} />} />
        <Route path="/livepure-ro-service-Betul" element={<MadhyaPradeshLivpure title={locdata.Betul.title} number={locdata.Betul.number} />} />
        <Route path="/livepure-ro-service-Seoni" element={<MadhyaPradeshLivpure title={locdata.Seoni.title} number={locdata.Seoni.number} />} />
        <Route path="/livepure-ro-service-Datia" element={<MadhyaPradeshLivpure title={locdata.Datia.title} number={locdata.Datia.number} />} />
        <Route path="/livepure-ro-service-Nagda" element={<MadhyaPradeshLivpure title={locdata.Nagda.title} number={locdata.Nagda.number} />} />
        <Route path="/livepure-ro-service-Shahdol" element={<MadhyaPradeshLivpure title={locdata.Shahdol.title} number={locdata.Shahdol.number} />} />
        <Route path="/livepure-ro-service-Dhar" element={<MadhyaPradeshLivpure title={locdata.Dhar.title} number={locdata.Dhar.number} />} />

        <Route path="/livepure-ro-service-Ajmer" element={<RajasthanLivpure title={locdata.Ajmer.title} number={locdata.Ajmer.number} />} />
        <Route path="/livepure-ro-service-Alwar" element={<RajasthanLivpure title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/livepure-ro-service-Balotra" element={<RajasthanLivpure title={locdata.Balotra.title} number={locdata.Balotra.number} />} />
        <Route path="/livepure-ro-service-Banswara" element={<RajasthanLivpure title={locdata.Banswara.title} number={locdata.Banswara.number} />} />
        <Route path="/livepure-ro-service-Baran" element={<RajasthanLivpure title={locdata.Baran.title} number={locdata.Baran.number} />} />
        <Route path="/livepure-ro-service-Barmer" element={<RajasthanLivpure title={locdata.Barmer.title} number={locdata.Barmer.number} />} />
        <Route path="/livepure-ro-service-Beawar" element={<RajasthanLivpure title={locdata.Beawar.title} number={locdata.Beawar.number} />} />
        <Route path="/livepure-ro-service-Bharatpur" element={<RajasthanLivpure title={locdata.Bharatpur.title} number={locdata.Bharatpur.number} />} />
        <Route path="/livepure-ro-service-Bhilwara" element={<RajasthanLivpure title={locdata.Bhilwara.title} number={locdata.Bhilwara.number} />} />
        <Route path="/livepure-ro-service-Bikaner" element={<RajasthanLivpure title={locdata.Bikaner.title} number={locdata.Bikaner.number} />} />
        <Route path="/livepure-ro-service-Bundi" element={<RajasthanLivpure title={locdata.Bundi.title} number={locdata.Bundi.number} />} />
        <Route path="/livepure-ro-service-Chittorgarh" element={<RajasthanLivpure title={locdata.Chittorgarh.title} number={locdata.Chittorgarh.number} />} />
        <Route path="/livepure-ro-service-Churu" element={<RajasthanLivpure title={locdata.Churu.title} number={locdata.Churu.number} />} />
        <Route path="/livepure-ro-service-Dausa" element={<RajasthanLivpure title={locdata.Dausa.title} number={locdata.Dausa.number} />} />
        <Route path="/livepure-ro-service-Deeg" element={<RajasthanLivpure title={locdata.Deeg.title} number={locdata.Deeg.number} />} />
        <Route path="/livepure-ro-service-Deedwana" element={<RajasthanLivpure title={locdata.Deedwana.title} number={locdata.Deedwana.number} />} />
        <Route path="/livepure-ro-service-Dholpur" element={<RajasthanLivpure title={locdata.Dholpur.title} number={locdata.Dholpur.number} />} />
        <Route path="/livepure-ro-service-Dungarpur" element={<RajasthanLivpure title={locdata.Dungarpur.title} number={locdata.Dungarpur.number} />} />
        <Route path="/livepure-ro-service-Hanumangarh" element={<RajasthanLivpure title={locdata.Hanumangarh.title} number={locdata.Hanumangarh.number} />} />
        <Route path="/livepure-ro-service-Jaipur" element={<RajasthanLivpure title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/livepure-ro-service-Jaisalmer" element={<RajasthanLivpure title={locdata.Jaisalmer.title} number={locdata.Jaisalmer.number} />} />
        <Route path="/livepure-ro-service-Jalore" element={<RajasthanLivpure title={locdata.Jalore.title} number={locdata.Jalore.number} />} />
        <Route path="/livepure-ro-service-Jhalawar" element={<RajasthanLivpure title={locdata.Jhalawar.title} number={locdata.Jhalawar.number} />} />
        <Route path="/livepure-ro-service-Jhunjhunu" element={<RajasthanLivpure title={locdata.Jhunjhunu.title} number={locdata.Jhunjhunu.number} />} />
        <Route path="/livepure-ro-service-Jodhpur" element={<RajasthanLivpure title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/livepure-ro-service-Karauli" element={<RajasthanLivpure title={locdata.Karauli.title} number={locdata.Karauli.number} />} />
        <Route path="/livepure-ro-service-Tijara" element={<RajasthanLivpure title={locdata.Tijara.title} number={locdata.Tijara.number} />} />
        <Route path="/livepure-ro-service-Kotputli" element={<RajasthanLivpure title={locdata.Kotputli.title} number={locdata.Kotputli.number} />} />
        <Route path="/livepure-ro-service-Kota" element={<RajasthanLivpure title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/livepure-ro-service-Nagaur" element={<RajasthanLivpure title={locdata.Nagaur.title} number={locdata.Nagaur.number} />} />
        <Route path="/livepure-ro-service-Pali" element={<RajasthanLivpure title={locdata.Pali.title} number={locdata.Pali.number} />} />
        <Route path="/livepure-ro-service-Phalodi" element={<RajasthanLivpure title={locdata.Phalodi.title} number={locdata.Phalodi.number} />} />
        <Route path="/livepure-ro-service-Pratapgarh" element={<RajasthanLivpure title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/livepure-ro-service-Rajsamand" element={<RajasthanLivpure title={locdata.Rajsamand.title} number={locdata.Rajsamand.number} />} />
        <Route path="/livepure-ro-service-Salumbar" element={<RajasthanLivpure title={locdata.Salumbar.title} number={locdata.Salumbar.number} />} />
        <Route path="/livepure-ro-service-Sawai-Madhopur" element={<RajasthanLivpure title={locdata.SawaiMadhopur.title} number={locdata.SawaiMadhopur.number} />} />
        <Route path="/livepure-ro-service-Sikar" element={<RajasthanLivpure title={locdata.Sikar.title} number={locdata.Sikar.number} />} />
        <Route path="/livepure-ro-service-Sirohi" element={<RajasthanLivpure title={locdata.Sirohi.title} number={locdata.Sirohi.number} />} />
        <Route path="/livepure-ro-service-Sri-Ganganagar" element={<RajasthanLivpure title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/livepure-ro-service-Tonk" element={<RajasthanLivpure title={locdata.Tonk.title} number={locdata.Tonk.number} />} />
        <Route path="/livepure-ro-service-Udaipur" element={<RajasthanLivpure title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />

        <Route path="/livepure-ro-service-Mumbai" element={<Livepurero title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/livepure-ro-service-Delhi" element={<Livepurero title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/livepure-ro-service-Bengaluru" element={<Livepurero title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/livepure-ro-service-Hyderabad" element={<Livepurero title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/livepure-ro-service-Ahmedabad" element={<Livepurero title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/livepure-ro-service-Chennai" element={<Livepurero title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/livepure-ro-service-Surat" element={<Livepurero title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/livepure-ro-service-Pune" element={<Livepurero title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/livepure-ro-service-Jaipur" element={<Livepurero title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/livepure-ro-service-Lucknow" element={<Livepurero title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/livepure-ro-service-Kanpur" element={<Livepurero title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/livepure-ro-service-Nagpur" element={<Livepurero title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/livepure-ro-service-Indore" element={<Livepurero title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/livepure-ro-service-Thane" element={<Livepurero title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/livepure-ro-service-Bhopal" element={<Livepurero title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/livepure-ro-service-Visakhapatnam" element={<Livepurero title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/livepure-ro-service-Vadodara" element={<Livepurero title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/livepure-ro-service-Ghaziabad" element={<Livepurero title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/livepure-ro-service-Ludhiana" element={<Livepurero title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/livepure-ro-service-Agra" element={<Livepurero title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/livepure-ro-service-Nashik" element={<Livepurero title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/livepure-ro-service-Faridabad" element={<Livepurero title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/livepure-ro-service-Meerut" element={<Livepurero title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/livepure-ro-service-Rajkot" element={<Livepurero title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/livepure-ro-service-Varanasi" element={<Livepurero title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/livepure-ro-service-Srinagar" element={<Livepurero title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/livepure-ro-service-Amritsar" element={<Livepurero title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/livepure-ro-service-Navi-Mumbai" element={<Livepurero title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/livepure-ro-service-Prayagraj" element={<Livepurero title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/livepure-ro-service-Jabalpur" element={<Livepurero title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/livepure-ro-service-Gwalior" element={<Livepurero title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/livepure-ro-service-Coimbatore" element={<Livepurero title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/livepure-ro-service-Vijayawada" element={<Livepurero title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/livepure-ro-service-Jodhpur" element={<Livepurero title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/livepure-ro-service-Madurai" element={<Livepurero title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/livepure-ro-service-Raipur" element={<Livepurero title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/livepure-ro-service-Kota" element={<Livepurero title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/livepure-ro-service-Guwahati" element={<Livepurero title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/livepure-ro-service-Chandigarh" element={<Livepurero title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/livepure-ro-service-Thiruvananthapuram" element={<Livepurero title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/livepure-ro-service-Solapur" element={<Livepurero title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/livepure-ro-service-Tiruppur" element={<Livepurero title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/livepure-ro-service-Moradabad" element={<Livepurero title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/livepure-ro-service-Mysore" element={<Livepurero title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/livepure-ro-service-Gurgaon" element={<Livepurero title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/livepure-ro-service-Aligarh" element={<Livepurero title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/livepure-ro-service-Jalandhar" element={<Livepurero title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/livepure-ro-service-Bhubaneswar" element={<Livepurero title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/livepure-ro-service-Noida" element={<Livepurero title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/livepure-ro-service-Jamshedpur" element={<Livepurero title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/livepure-ro-service-Cuttack" element={<Livepurero title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/livepure-ro-service-Bhavnagar" element={<Livepurero title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/livepure-ro-service-Dehradun" element={<Livepurero title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/livepure-ro-service-Rourkela" element={<Livepurero title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/livepure-ro-service-Jamnagar" element={<Livepurero title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/livepure-ro-service-Ujjain" element={<Livepurero title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/livepure-ro-service-Jammu" element={<Livepurero title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/livepure-ro-service-Mangalore" element={<Livepurero title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/livepure-ro-service-Erode" element={<Livepurero title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/livepure-ro-service-Udaipur" element={<Livepurero title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/livepure-ro-service-Rajahmundry" element={<Livepurero title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/livepure-ro-service-Patiala" element={<Livepurero title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/livepure-ro-service-Agartala" element={<Livepurero title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/livepure-ro-service-Tirupati" element={<Livepurero title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/livepure-ro-service-Sambalpur" element={<Livepurero title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/livepure-ro-service-Bilaspur" element={<Livepurero title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/livepure-ro-service-Alwar" element={<Livepurero title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/livepure-ro-service-Bardhaman" element={<Livepurero title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/livepure-ro-service-Sonipat" element={<Livepurero title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/livepure-ro-service-New-Delhi" element={<Livepurero title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/livepure-ro-service-Puducherry" element={<Livepurero title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/livepure-ro-service-Haridwar" element={<Livepurero title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/livepure-ro-service-Vijayanagaram" element={<Livepurero title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/livepure-ro-service-Sri-Ganganagar" element={<Livepurero title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/livepure-ro-service-Secunderabad" element={<Livepurero title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/livepure-ro-service-Yamunanagar" element={<Livepurero title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/livepure-ro-service-Gandhinagar" element={<Livepurero title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/livepure-ro-service-Ambala" element={<Livepurero title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/livepure-ro-service-Vellore" element={<Livepurero title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/livepure-ro-service-Shimla" element={<Livepurero title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/livepure-ro-service-Amaravati" element={<Livepurero title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />


        <Route path="/marq-ro-service-Araria" element={<BiharMarq title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/marq-ro-service-Arwal" element={<BiharMarq title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/marq-ro-service-Aurangabad" element={<BiharMarq title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/marq-ro-service-Banka" element={<BiharMarq title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/marq-ro-service-Begusarai" element={<BiharMarq title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/marq-ro-service-Bhabua" element={<BiharMarq title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/marq-ro-service-Bhagalpur" element={<BiharMarq title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/marq-ro-service-Bhojpur" element={<BiharMarq title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/marq-ro-service-Bihar-Sharif" element={<BiharMarq title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/marq-ro-service-Buxar" element={<BiharMarq title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/marq-ro-service-Chhapra" element={<BiharMarq title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/marq-ro-service-Darbhanga" element={<BiharMarq title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/marq-ro-service-East-Champaran" element={<BiharMarq title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/marq-ro-service-Gaya" element={<BiharMarq title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/marq-ro-service-Gopalganj" element={<BiharMarq title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/marq-ro-service-Jamui" element={<BiharMarq title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/marq-ro-service-Jehanabad" element={<BiharMarq title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/marq-ro-service-Khagaria" element={<BiharMarq title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/marq-ro-service-Kishanganj" element={<BiharMarq title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/marq-ro-service-Kaimur" element={<BiharMarq title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/marq-ro-service-Katihar" element={<BiharMarq title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/marq-ro-service-Lakhisarai" element={<BiharMarq title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/marq-ro-service-Madhubani" element={<BiharMarq title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/marq-ro-service-Munger" element={<BiharMarq title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/marq-ro-service-Madhepura" element={<BiharMarq title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/marq-ro-service-Muzaffarpur" element={<BiharMarq title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/marq-ro-service-Nalanda" element={<BiharMarq title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/marq-ro-service-Nawada" element={<BiharMarq title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/marq-ro-service-Patna" element={<BiharMarq title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/marq-ro-service-Purnia" element={<BiharMarq title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/marq-ro-service-Rohtas" element={<BiharMarq title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/marq-ro-service-Saharsa" element={<BiharMarq title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/marq-ro-service-Samastipur" element={<BiharMarq title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/marq-ro-service-Sasaram" element={<BiharMarq title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/marq-ro-service-Sheohar" element={<BiharMarq title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/marq-ro-service-Sheikhpura" element={<BiharMarq title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/marq-ro-service-Saran" element={<BiharMarq title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/marq-ro-service-Sitamarhi" element={<BiharMarq title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/marq-ro-service-Supaul" element={<BiharMarq title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/marq-ro-service-Siwan" element={<BiharMarq title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/marq-ro-service-Vaishali" element={<BiharMarq title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/marq-ro-service-West-Champaran" element={<BiharMarq title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/marq-ro-service-Garhwa" element={<JharkhandMarq title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/marq-ro-service-Palamu" element={<JharkhandMarq title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/marq-ro-service-Latehar" element={<JharkhandMarq title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/marq-ro-service-Chatra" element={<JharkhandMarq title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/marq-ro-service-Hazaribagh" element={<JharkhandMarq title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/marq-ro-service-Jamshedpur" element={<JharkhandMarq title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/marq-ro-service-Koderma" element={<JharkhandMarq title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/marq-ro-service-Giridih" element={<JharkhandMarq title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/marq-ro-service-Ramgarh" element={<JharkhandMarq title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/marq-ro-service-Bokaro" element={<JharkhandMarq title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/marq-ro-service-Dhanbad" element={<JharkhandMarq title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/marq-ro-service-Lohardaga" element={<JharkhandMarq title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/marq-ro-service-Gumla" element={<JharkhandMarq title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/marq-ro-service-Simdega" element={<JharkhandMarq title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/marq-ro-service-Ranchi" element={<JharkhandMarq title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/marq-ro-service-Khunti" element={<JharkhandMarq title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/marq-ro-service-Singhbhum" element={<JharkhandMarq title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/marq-ro-service-Saraikela-Kharsawan" element={<JharkhandMarq title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/marq-ro-service-Jamtara" element={<JharkhandMarq title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/marq-ro-service-Deoghar" element={<JharkhandMarq title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/marq-ro-service-Dumka" element={<JharkhandMarq title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/marq-ro-service-Pakur" element={<JharkhandMarq title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/marq-ro-service-Godda" element={<JharkhandMarq title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/marq-ro-service-Sahebganj" element={<JharkhandMarq title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />

        <Route path="/marq-ro-service-Alipurduar" element={<BengalMarq title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/marq-ro-service-Bankura" element={<BengalMarq title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/marq-ro-service-Birbhum" element={<BengalMarq title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/marq-ro-service-Cooch-Behar" element={<BengalMarq title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/marq-ro-service-Dakshin-Dinajpur" element={<BengalMarq title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/marq-ro-service-Darjeeling" element={<BengalMarq title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/marq-ro-service-Durgapur" element={<BengalMarq title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/marq-ro-service-Hooghly" element={<BengalMarq title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/marq-ro-service-Howrah" element={<BengalMarq title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/marq-ro-service-Jalpaiguri" element={<BengalMarq title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/marq-ro-service-Jhargram" element={<BengalMarq title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/marq-ro-service-Kalimpong" element={<BengalMarq title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/marq-ro-service-Kolkata" element={<BengalMarq title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/marq-ro-service-Malda" element={<BengalMarq title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/marq-ro-service-Murshidabad" element={<BengalMarq title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/marq-ro-service-Nadia" element={<BengalMarq title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/marq-ro-service-North-24-Parganas" element={<BengalMarq title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/marq-ro-service-Paschim-Bardhaman" element={<BengalMarq title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/marq-ro-service-Paschim-Medinipur" element={<BengalMarq title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/marq-ro-service-Purba-Bardhaman" element={<BengalMarq title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/marq-ro-service-Purba-Medinipur" element={<BengalMarq title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/marq-ro-service-South-24-Parganas" element={<BengalMarq title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/marq-ro-service-Uttar-Dinajpur" element={<BengalMarq title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />
        <Route path="/marq-ro-service-Agra" element={<UttarPradeshMarq title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/marq-ro-service-Aligarh" element={<UttarPradeshMarq title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/marq-ro-service-Ambedkar-Nagar" element={<UttarPradeshMarq title={locdata.AmbedkarNagar.title} number={locdata.AmbedkarNagar.number} />} />
        <Route path="/marq-ro-service-Ayodhya" element={<UttarPradeshMarq title={locdata.Ayodhya.title} number={locdata.Ayodhya.number} />} />
        <Route path="/marq-ro-service-Amethi" element={<UttarPradeshMarq title={locdata.Amethi.title} number={locdata.Amethi.number} />} />
        <Route path="/marq-ro-service-Amroha" element={<UttarPradeshMarq title={locdata.Amroha.title} number={locdata.Amroha.number} />} />
        <Route path="/marq-ro-service-Auraiya" element={<UttarPradeshMarq title={locdata.Auraiya.title} number={locdata.Auraiya.number} />} />
        <Route path="/marq-ro-service-Azamgarh" element={<UttarPradeshMarq title={locdata.Azamgarh.title} number={locdata.Azamgarh.number} />} />
        <Route path="/marq-ro-service-Budaun" element={<UttarPradeshMarq title={locdata.Budaun.title} number={locdata.Budaun.number} />} />
        <Route path="/marq-ro-service-Bagpat" element={<UttarPradeshMarq title={locdata.Bagpat.title} number={locdata.Bagpat.number} />} />
        <Route path="/marq-ro-service-Bahraich" element={<UttarPradeshMarq title={locdata.Bahraich.title} number={locdata.Bahraich.number} />} />
        <Route path="/marq-ro-service-Ballia" element={<UttarPradeshMarq title={locdata.Ballia.title} number={locdata.Ballia.number} />} />
        <Route path="/marq-ro-service-Balrampur" element={<UttarPradeshMarq title={locdata.Balrampur.title} number={locdata.Balrampur.number} />} />
        <Route path="/marq-ro-service-Banda" element={<UttarPradeshMarq title={locdata.Banda.title} number={locdata.Banda.number} />} />
        <Route path="/marq-ro-service-Barabanki" element={<UttarPradeshMarq title={locdata.Barabanki.title} number={locdata.Barabanki.number} />} />
        <Route path="/marq-ro-service-Bareilly" element={<UttarPradeshMarq title={locdata.Bareilly.title} number={locdata.Bareilly.number} />} />
        <Route path="/marq-ro-service-Basti" element={<UttarPradeshMarq title={locdata.Basti.title} number={locdata.Basti.number} />} />
        <Route path="/marq-ro-service-Bijnor" element={<UttarPradeshMarq title={locdata.Bijnor.title} number={locdata.Bijnor.number} />} />
        <Route path="/marq-ro-service-Bulandshahr" element={<UttarPradeshMarq title={locdata.Bulandshahr.title} number={locdata.Bulandshahr.number} />} />
        <Route path="/marq-ro-service-Chandauli" element={<UttarPradeshMarq title={locdata.Chandauli.title} number={locdata.Chandauli.number} />} />
        <Route path="/marq-ro-service-Chitrakoot" element={<UttarPradeshMarq title={locdata.Chitrakoot.title} number={locdata.Chitrakoot.number} />} />
        <Route path="/marq-ro-service-Deoria" element={<UttarPradeshMarq title={locdata.Deoria.title} number={locdata.Deoria.number} />} />
        <Route path="/marq-ro-service-Etah" element={<UttarPradeshMarq title={locdata.Etah.title} number={locdata.Etah.number} />} />
        <Route path="/marq-ro-service-Etawah" element={<UttarPradeshMarq title={locdata.Etawah.title} number={locdata.Etawah.number} />} />
        <Route path="/marq-ro-service-Farrukhabad" element={<UttarPradeshMarq title={locdata.Farrukhabad.title} number={locdata.Farrukhabad.number} />} />
        <Route path="/marq-ro-service-Fatehpur" element={<UttarPradeshMarq title={locdata.Fatehpur.title} number={locdata.Fatehpur.number} />} />
        <Route path="/marq-ro-service-Firozabad" element={<UttarPradeshMarq title={locdata.Firozabad.title} number={locdata.Firozabad.number} />} />
        <Route path="/marq-ro-service-Gautam-Buddha-Nagar" element={<UttarPradeshMarq title={locdata.GautamBuddhaNagar.title} number={locdata.GautamBuddhaNagar.number} />} />
        <Route path="/marq-ro-service-Ghaziabad" element={<UttarPradeshMarq title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/marq-ro-service-Ghazipur" element={<UttarPradeshMarq title={locdata.Ghazipur.title} number={locdata.Ghazipur.number} />} />
        <Route path="/marq-ro-service-Gonda" element={<UttarPradeshMarq title={locdata.Gonda.title} number={locdata.Gonda.number} />} />
        <Route path="/marq-ro-service-Gorakhpur" element={<UttarPradeshMarq title={locdata.Gorakhpur.title} number={locdata.Gorakhpur.number} />} />
        <Route path="/marq-ro-service-Hamirpur" element={<UttarPradeshMarq title={locdata.Hamirpur.title} number={locdata.Hamirpur.number} />} />
        <Route path="/marq-ro-service-Hapur" element={<UttarPradeshMarq title={locdata.Hapur.title} number={locdata.Hapur.number} />} />
        <Route path="/marq-ro-service-Hardoi" element={<UttarPradeshMarq title={locdata.Hardoi.title} number={locdata.Hardoi.number} />} />
        <Route path="/marq-ro-service-Hathras" element={<UttarPradeshMarq title={locdata.Hathras.title} number={locdata.Hathras.number} />} />
        <Route path="/marq-ro-service-Jalaun" element={<UttarPradeshMarq title={locdata.Jalaun.title} number={locdata.Jalaun.number} />} />
        <Route path="/marq-ro-service-Jaunpur" element={<UttarPradeshMarq title={locdata.Jaunpur.title} number={locdata.Jaunpur.number} />} />
        <Route path="/marq-ro-service-Jhansi" element={<UttarPradeshMarq title={locdata.Jhansi.title} number={locdata.Jhansi.number} />} />
        <Route path="/marq-ro-service-Kannauj" element={<UttarPradeshMarq title={locdata.Kannauj.title} number={locdata.Kannauj.number} />} />
        <Route path="/marq-ro-service-Kanpur-Dehat" element={<UttarPradeshMarq title={locdata.KanpurDehat.title} number={locdata.KanpurDehat.number} />} />
        <Route path="/marq-ro-service-Kanpur" element={<UttarPradeshMarq title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/marq-ro-service-Kasganj" element={<UttarPradeshMarq title={locdata.Kasganj.title} number={locdata.Kasganj.number} />} />
        <Route path="/marq-ro-service-Kaushambi" element={<UttarPradeshMarq title={locdata.Kaushambi.title} number={locdata.Kaushambi.number} />} />
        <Route path="/marq-ro-service-Kushinagar" element={<UttarPradeshMarq title={locdata.Kushinagar.title} number={locdata.Kushinagar.number} />} />
        <Route path="/marq-ro-service-Lakhimpur-Kheri" element={<UttarPradeshMarq title={locdata.LakhimpurKheri.title} number={locdata.LakhimpurKheri.number} />} />
        <Route path="/marq-ro-service-Lalitpur" element={<UttarPradeshMarq title={locdata.Lalitpur.title} number={locdata.Lalitpur.number} />} />
        <Route path="/marq-ro-service-Lucknow" element={<UttarPradeshMarq title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/marq-ro-service-Maharajganj" element={<UttarPradeshMarq title={locdata.Maharajganj.title} number={locdata.Maharajganj.number} />} />
        <Route path="/marq-ro-service-Mahoba" element={<UttarPradeshMarq title={locdata.Mahoba.title} number={locdata.Mahoba.number} />} />
        <Route path="/marq-ro-service-Mainpuri" element={<UttarPradeshMarq title={locdata.Mainpuri.title} number={locdata.Mainpuri.number} />} />
        <Route path="/marq-ro-service-Mathura" element={<UttarPradeshMarq title={locdata.Mathura.title} number={locdata.Mathura.number} />} />
        <Route path="/marq-ro-service-Mau" element={<UttarPradeshMarq title={locdata.Mau.title} number={locdata.Mau.number} />} />
        <Route path="/marq-ro-service-Meerut" element={<UttarPradeshMarq title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/marq-ro-service-Mirzapur" element={<UttarPradeshMarq title={locdata.Mirzapur.title} number={locdata.Mirzapur.number} />} />
        <Route path="/marq-ro-service-Moradabad" element={<UttarPradeshMarq title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/marq-ro-service-Muzaffarnagar" element={<UttarPradeshMarq title={locdata.Muzaffarnagar.title} number={locdata.Muzaffarnagar.number} />} />
        <Route path="/marq-ro-service-Pilibhit" element={<UttarPradeshMarq title={locdata.Pilibhit.title} number={locdata.Pilibhit.number} />} />
        <Route path="/marq-ro-service-Pratapgarh" element={<UttarPradeshMarq title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/marq-ro-service-Prayagraj" element={<UttarPradeshMarq title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/marq-ro-service-Rae-Bareli" element={<UttarPradeshMarq title={locdata.RaeBareli.title} number={locdata.RaeBareli.number} />} />
        <Route path="/marq-ro-service-Rampur" element={<UttarPradeshMarq title={locdata.Rampur.title} number={locdata.Rampur.number} />} />
        <Route path="/marq-ro-service-Saharanpur" element={<UttarPradeshMarq title={locdata.Saharanpur.title} number={locdata.Saharanpur.number} />} />
        <Route path="/marq-ro-service-Sant-Kabir-Nagar" element={<UttarPradeshMarq title={locdata.SantKabirNagar.title} number={locdata.SantKabirNagar.number} />} />
        <Route path="/marq-ro-service-Bhadohi" element={<UttarPradeshMarq title={locdata.Bhadohi.title} number={locdata.Bhadohi.number} />} />
        <Route path="/marq-ro-service-Sambhal" element={<UttarPradeshMarq title={locdata.Sambhal.title} number={locdata.Sambhal.number} />} />
        <Route path="/marq-ro-service-Shahjahanpur" element={<UttarPradeshMarq title={locdata.Shahjahanpur.title} number={locdata.Shahjahanpur.number} />} />
        <Route path="/marq-ro-service-Shamli" element={<UttarPradeshMarq title={locdata.Shamli.title} number={locdata.Shamli.number} />} />
        <Route path="/marq-ro-service-Shravasti" element={<UttarPradeshMarq title={locdata.Shravasti.title} number={locdata.Shravasti.number} />} />
        <Route path="/marq-ro-service-Siddharthnagar" element={<UttarPradeshMarq title={locdata.Siddharthnagar.title} number={locdata.Siddharthnagar.number} />} />
        <Route path="/marq-ro-service-Sitapur" element={<UttarPradeshMarq title={locdata.Sitapur.title} number={locdata.Sitapur.number} />} />
        <Route path="/marq-ro-service-Sonbhadra" element={<UttarPradeshMarq title={locdata.Sonbhadra.title} number={locdata.Sonbhadra.number} />} />
        <Route path="/marq-ro-service-Sultanpur" element={<UttarPradeshMarq title={locdata.Sultanpur.title} number={locdata.Sultanpur.number} />} />
        <Route path="/marq-ro-service-Unnao" element={<UttarPradeshMarq title={locdata.Unnao.title} number={locdata.Unnao.number} />} />
        <Route path="/marq-ro-service-Varanasi" element={<UttarPradeshMarq title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/marq-ro-service-Robertsganj" element={<UttarPradeshMarq title={locdata.Robertsganj.title} number={locdata.Robertsganj.number} />} />
        <Route path="/marq-ro-service-Bahjoi" element={<UttarPradeshMarq title={locdata.Bahjoi.title} number={locdata.Bahjoi.number} />} />
        <Route path="/marq-ro-service-Khalilabad" element={<UttarPradeshMarq title={locdata.Khalilabad.title} number={locdata.Khalilabad.number} />} />
        <Route path="/marq-ro-service-Akbarpur" element={<UttarPradeshMarq title={locdata.Akbarpur.title} number={locdata.Akbarpur.number} />} />
        <Route path="/marq-ro-service-Orai" element={<UttarPradeshMarq title={locdata.Orai.title} number={locdata.Orai.number} />} />
        <Route path="/marq-ro-service-Manjhanpur" element={<UttarPradeshMarq title={locdata.Manjhanpur.title} number={locdata.Manjhanpur.number} />} />
        <Route path="/marq-ro-service-Padarauna" element={<UttarPradeshMarq title={locdata.Padarauna.title} number={locdata.Padarauna.number} />} />
        <Route path="/marq-ro-service-Kheri" element={<UttarPradeshMarq title={locdata.Kheri.title} number={locdata.Kheri.number} />} />
        <Route path="/marq-ro-service-Fatehgarh" element={<UttarPradeshMarq title={locdata.Fatehgarh.title} number={locdata.Fatehgarh.number} />} />
        <Route path="/marq-ro-service-Noida" element={<UttarPradeshMarq title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/marq-ro-service-Greater-Noida" element={<UttarPradeshMarq title={locdata.GreaterNoida.title} number={locdata.GreaterNoida.number} />} />

        <Route path="/marq-ro-service-Indore" element={<MadhyaPradeshMarQ title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/marq-ro-service-Bhopal" element={<MadhyaPradeshMarQ title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/marq-ro-service-Jabalpur" element={<MadhyaPradeshMarQ title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/marq-ro-service-Gwalior" element={<MadhyaPradeshMarQ title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/marq-ro-service-Ujjain" element={<MadhyaPradeshMarQ title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/marq-ro-service-Sagar" element={<MadhyaPradeshMarQ title={locdata.Sagar.title} number={locdata.Sagar.number} />} />
        <Route path="/marq-ro-service-Dewas" element={<MadhyaPradeshMarQ title={locdata.Dewas.title} number={locdata.Dewas.number} />} />
        <Route path="/marq-ro-service-Satna" element={<MadhyaPradeshMarQ title={locdata.Satna.title} number={locdata.Satna.number} />} />
        <Route path="/marq-ro-service-Ratlam" element={<MadhyaPradeshMarQ title={locdata.Ratlam.title} number={locdata.Ratlam.number} />} />
        <Route path="/marq-ro-service-Rewa" element={<MadhyaPradeshMarQ title={locdata.Rewa.title} number={locdata.Rewa.number} />} />
        <Route path="/marq-ro-service-Katni" element={<MadhyaPradeshMarQ title={locdata.Katni.title} number={locdata.Katni.number} />} />
        <Route path="/marq-ro-service-Singrauli" element={<MadhyaPradeshMarQ title={locdata.Singrauli.title} number={locdata.Singrauli.number} />} />
        <Route path="/marq-ro-service-Burhanpur" element={<MadhyaPradeshMarQ title={locdata.Burhanpur.title} number={locdata.Burhanpur.number} />} />
        <Route path="/marq-ro-service-Morena" element={<MadhyaPradeshMarQ title={locdata.Morena.title} number={locdata.Morena.number} />} />
        <Route path="/marq-ro-service-Khandwa" element={<MadhyaPradeshMarQ title={locdata.Khandwa.title} number={locdata.Khandwa.number} />} />
        <Route path="/marq-ro-service-Bhind" element={<MadhyaPradeshMarQ title={locdata.Bhind.title} number={locdata.Bhind.number} />} />
        <Route path="/marq-ro-service-Chhindwara" element={<MadhyaPradeshMarQ title={locdata.Chhindwara.title} number={locdata.Chhindwara.number} />} />
        <Route path="/marq-ro-service-Guna" element={<MadhyaPradeshMarQ title={locdata.Guna.title} number={locdata.Guna.number} />} />
        <Route path="/marq-ro-service-Shivpuri" element={<MadhyaPradeshMarQ title={locdata.Shivpuri.title} number={locdata.Shivpuri.number} />} />
        <Route path="/marq-ro-service-Vidisha" element={<MadhyaPradeshMarQ title={locdata.Vidisha.title} number={locdata.Vidisha.number} />} />
        <Route path="/marq-ro-service-Chhatarpur" element={<MadhyaPradeshMarQ title={locdata.Chhatarpur.title} number={locdata.Chhatarpur.number} />} />
        <Route path="/marq-ro-service-Damoh" element={<MadhyaPradeshMarQ title={locdata.Damoh.title} number={locdata.Damoh.number} />} />
        <Route path="/marq-ro-service-Mandsaur" element={<MadhyaPradeshMarQ title={locdata.Mandsaur.title} number={locdata.Mandsaur.number} />} />
        <Route path="/marq-ro-service-Khargone" element={<MadhyaPradeshMarQ title={locdata.Khargone.title} number={locdata.Khargone.number} />} />
        <Route path="/marq-ro-service-Neemuch" element={<MadhyaPradeshMarQ title={locdata.Neemuch.title} number={locdata.Neemuch.number} />} />
        <Route path="/marq-ro-service-Pithampur" element={<MadhyaPradeshMarQ title={locdata.Pithampur.title} number={locdata.Pithampur.number} />} />
        <Route path="/marq-ro-service-Narmadapuram" element={<MadhyaPradeshMarQ title={locdata.Narmadapuram.title} number={locdata.Narmadapuram.number} />} />
        <Route path="/marq-ro-service-Itarsi" element={<MadhyaPradeshMarQ title={locdata.Itarsi.title} number={locdata.Itarsi.number} />} />
        <Route path="/marq-ro-service-Sehore" element={<MadhyaPradeshMarQ title={locdata.Sehore.title} number={locdata.Sehore.number} />} />
        <Route path="/marq-ro-service-Betul" element={<MadhyaPradeshMarQ title={locdata.Betul.title} number={locdata.Betul.number} />} />
        <Route path="/marq-ro-service-Seoni" element={<MadhyaPradeshMarQ title={locdata.Seoni.title} number={locdata.Seoni.number} />} />
        <Route path="/marq-ro-service-Datia" element={<MadhyaPradeshMarQ title={locdata.Datia.title} number={locdata.Datia.number} />} />
        <Route path="/marq-ro-service-Nagda" element={<MadhyaPradeshMarQ title={locdata.Nagda.title} number={locdata.Nagda.number} />} />
        <Route path="/marq-ro-service-Shahdol" element={<MadhyaPradeshMarQ title={locdata.Shahdol.title} number={locdata.Shahdol.number} />} />
        <Route path="/marq-ro-service-Dhar" element={<MadhyaPradeshMarQ title={locdata.Dhar.title} number={locdata.Dhar.number} />} />

        <Route path="/marq-ro-service-Ajmer" element={<RajasthanMarQ title={locdata.Ajmer.title} number={locdata.Ajmer.number} />} />
        <Route path="/marq-ro-service-Alwar" element={<RajasthanMarQ title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/marq-ro-service-Balotra" element={<RajasthanMarQ title={locdata.Balotra.title} number={locdata.Balotra.number} />} />
        <Route path="/marq-ro-service-Banswara" element={<RajasthanMarQ title={locdata.Banswara.title} number={locdata.Banswara.number} />} />
        <Route path="/marq-ro-service-Baran" element={<RajasthanMarQ title={locdata.Baran.title} number={locdata.Baran.number} />} />
        <Route path="/marq-ro-service-Barmer" element={<RajasthanMarQ title={locdata.Barmer.title} number={locdata.Barmer.number} />} />
        <Route path="/marq-ro-service-Beawar" element={<RajasthanMarQ title={locdata.Beawar.title} number={locdata.Beawar.number} />} />
        <Route path="/marq-ro-service-Bharatpur" element={<RajasthanMarQ title={locdata.Bharatpur.title} number={locdata.Bharatpur.number} />} />
        <Route path="/marq-ro-service-Bhilwara" element={<RajasthanMarQ title={locdata.Bhilwara.title} number={locdata.Bhilwara.number} />} />
        <Route path="/marq-ro-service-Bikaner" element={<RajasthanMarQ title={locdata.Bikaner.title} number={locdata.Bikaner.number} />} />
        <Route path="/marq-ro-service-Bundi" element={<RajasthanMarQ title={locdata.Bundi.title} number={locdata.Bundi.number} />} />
        <Route path="/marq-ro-service-Chittorgarh" element={<RajasthanMarQ title={locdata.Chittorgarh.title} number={locdata.Chittorgarh.number} />} />
        <Route path="/marq-ro-service-Churu" element={<RajasthanMarQ title={locdata.Churu.title} number={locdata.Churu.number} />} />
        <Route path="/marq-ro-service-Dausa" element={<RajasthanMarQ title={locdata.Dausa.title} number={locdata.Dausa.number} />} />
        <Route path="/marq-ro-service-Deeg" element={<RajasthanMarQ title={locdata.Deeg.title} number={locdata.Deeg.number} />} />
        <Route path="/marq-ro-service-Deedwana" element={<RajasthanMarQ title={locdata.Deedwana.title} number={locdata.Deedwana.number} />} />
        <Route path="/marq-ro-service-Dholpur" element={<RajasthanMarQ title={locdata.Dholpur.title} number={locdata.Dholpur.number} />} />
        <Route path="/marq-ro-service-Dungarpur" element={<RajasthanMarQ title={locdata.Dungarpur.title} number={locdata.Dungarpur.number} />} />
        <Route path="/marq-ro-service-Hanumangarh" element={<RajasthanMarQ title={locdata.Hanumangarh.title} number={locdata.Hanumangarh.number} />} />
        <Route path="/marq-ro-service-Jaipur" element={<RajasthanMarQ title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/marq-ro-service-Jaisalmer" element={<RajasthanMarQ title={locdata.Jaisalmer.title} number={locdata.Jaisalmer.number} />} />
        <Route path="/marq-ro-service-Jalore" element={<RajasthanMarQ title={locdata.Jalore.title} number={locdata.Jalore.number} />} />
        <Route path="/marq-ro-service-Jhalawar" element={<RajasthanMarQ title={locdata.Jhalawar.title} number={locdata.Jhalawar.number} />} />
        <Route path="/marq-ro-service-Jhunjhunu" element={<RajasthanMarQ title={locdata.Jhunjhunu.title} number={locdata.Jhunjhunu.number} />} />
        <Route path="/marq-ro-service-Jodhpur" element={<RajasthanMarQ title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/marq-ro-service-Karauli" element={<RajasthanMarQ title={locdata.Karauli.title} number={locdata.Karauli.number} />} />
        <Route path="/marq-ro-service-Tijara" element={<RajasthanMarQ title={locdata.Tijara.title} number={locdata.Tijara.number} />} />
        <Route path="/marq-ro-service-Kotputli" element={<RajasthanMarQ title={locdata.Kotputli.title} number={locdata.Kotputli.number} />} />
        <Route path="/marq-ro-service-Kota" element={<RajasthanMarQ title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/marq-ro-service-Nagaur" element={<RajasthanMarQ title={locdata.Nagaur.title} number={locdata.Nagaur.number} />} />
        <Route path="/marq-ro-service-Pali" element={<RajasthanMarQ title={locdata.Pali.title} number={locdata.Pali.number} />} />
        <Route path="/marq-ro-service-Phalodi" element={<RajasthanMarQ title={locdata.Phalodi.title} number={locdata.Phalodi.number} />} />
        <Route path="/marq-ro-service-Pratapgarh" element={<RajasthanMarQ title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/marq-ro-service-Rajsamand" element={<RajasthanMarQ title={locdata.Rajsamand.title} number={locdata.Rajsamand.number} />} />
        <Route path="/marq-ro-service-Salumbar" element={<RajasthanMarQ title={locdata.Salumbar.title} number={locdata.Salumbar.number} />} />
        <Route path="/marq-ro-service-Sawai-Madhopur" element={<RajasthanMarQ title={locdata.SawaiMadhopur.title} number={locdata.SawaiMadhopur.number} />} />
        <Route path="/marq-ro-service-Sikar" element={<RajasthanMarQ title={locdata.Sikar.title} number={locdata.Sikar.number} />} />
        <Route path="/marq-ro-service-Sirohi" element={<RajasthanMarQ title={locdata.Sirohi.title} number={locdata.Sirohi.number} />} />
        <Route path="/marq-ro-service-Sri-Ganganagar" element={<RajasthanMarQ title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/marq-ro-service-Tonk" element={<RajasthanMarQ title={locdata.Tonk.title} number={locdata.Tonk.number} />} />
        <Route path="/marq-ro-service-Udaipur" element={<RajasthanMarQ title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />

        <Route path="/marq-ro-service-Mumbai" element={<MarQro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/marq-ro-service-Delhi" element={<MarQro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/marq-ro-service-Bengaluru" element={<MarQro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/marq-ro-service-Hyderabad" element={<MarQro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/marq-ro-service-Ahmedabad" element={<MarQro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/marq-ro-service-Chennai" element={<MarQro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/marq-ro-service-Surat" element={<MarQro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/marq-ro-service-Pune" element={<MarQro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/marq-ro-service-Jaipur" element={<MarQro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/marq-ro-service-Lucknow" element={<MarQro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/marq-ro-service-Kanpur" element={<MarQro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/marq-ro-service-Nagpur" element={<MarQro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/marq-ro-service-Indore" element={<MarQro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/marq-ro-service-Thane" element={<MarQro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/marq-ro-service-Bhopal" element={<MarQro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/marq-ro-service-Visakhapatnam" element={<MarQro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/marq-ro-service-Vadodara" element={<MarQro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/marq-ro-service-Ghaziabad" element={<MarQro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/marq-ro-service-Ludhiana" element={<MarQro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/marq-ro-service-Agra" element={<MarQro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/marq-ro-service-Nashik" element={<MarQro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/marq-ro-service-Faridabad" element={<MarQro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/marq-ro-service-Meerut" element={<MarQro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/marq-ro-service-Rajkot" element={<MarQro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/marq-ro-service-Varanasi" element={<MarQro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/marq-ro-service-Srinagar" element={<MarQro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/marq-ro-service-Amritsar" element={<MarQro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/marq-ro-service-Navi-Mumbai" element={<MarQro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/marq-ro-service-Prayagraj" element={<MarQro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/marq-ro-service-Jabalpur" element={<MarQro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/marq-ro-service-Gwalior" element={<MarQro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/marq-ro-service-Coimbatore" element={<MarQro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/marq-ro-service-Vijayawada" element={<MarQro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/marq-ro-service-Jodhpur" element={<MarQro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/marq-ro-service-Madurai" element={<MarQro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/marq-ro-service-Raipur" element={<MarQro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/marq-ro-service-Kota" element={<MarQro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/marq-ro-service-Guwahati" element={<MarQro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/marq-ro-service-Chandigarh" element={<MarQro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/marq-ro-service-Thiruvananthapuram" element={<MarQro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/marq-ro-service-Solapur" element={<MarQro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/marq-ro-service-Tiruppur" element={<MarQro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/marq-ro-service-Moradabad" element={<MarQro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/marq-ro-service-Mysore" element={<MarQro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/marq-ro-service-Gurgaon" element={<MarQro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/marq-ro-service-Aligarh" element={<MarQro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/marq-ro-service-Jalandhar" element={<MarQro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/marq-ro-service-Bhubaneswar" element={<MarQro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/marq-ro-service-Noida" element={<MarQro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/marq-ro-service-Jamshedpur" element={<MarQro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/marq-ro-service-Cuttack" element={<MarQro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/marq-ro-service-Bhavnagar" element={<MarQro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/marq-ro-service-Dehradun" element={<MarQro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/marq-ro-service-Rourkela" element={<MarQro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/marq-ro-service-Jamnagar" element={<MarQro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/marq-ro-service-Ujjain" element={<MarQro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/marq-ro-service-Jammu" element={<MarQro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/marq-ro-service-Mangalore" element={<MarQro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/marq-ro-service-Erode" element={<MarQro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/marq-ro-service-Udaipur" element={<MarQro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/marq-ro-service-Rajahmundry" element={<MarQro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/marq-ro-service-Patiala" element={<MarQro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/marq-ro-service-Agartala" element={<MarQro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/marq-ro-service-Tirupati" element={<MarQro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/marq-ro-service-Sambalpur" element={<MarQro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/marq-ro-service-Bilaspur" element={<MarQro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/marq-ro-service-Alwar" element={<MarQro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/marq-ro-service-Bardhaman" element={<MarQro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/marq-ro-service-Sonipat" element={<MarQro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/marq-ro-service-New-Delhi" element={<MarQro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/marq-ro-service-Puducherry" element={<MarQro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/marq-ro-service-Haridwar" element={<MarQro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/marq-ro-service-Vijayanagaram" element={<MarQro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/marq-ro-service-Sri-Ganganagar" element={<MarQro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/marq-ro-service-Secunderabad" element={<MarQro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/marq-ro-service-Yamunanagar" element={<MarQro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/marq-ro-service-Gandhinagar" element={<MarQro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/marq-ro-service-Ambala" element={<MarQro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/marq-ro-service-Vellore" element={<MarQro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/marq-ro-service-Shimla" element={<MarQro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/marq-ro-service-Amaravati" element={<MarQro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />


        <Route path="/pureit-ro-service-Araria" element={<BiharPureit title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/pureit-ro-service-Arwal" element={<BiharPureit title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/pureit-ro-service-Aurangabad" element={<BiharPureit title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/pureit-ro-service-Banka" element={<BiharPureit title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/pureit-ro-service-Begusarai" element={<BiharPureit title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/pureit-ro-service-Bhabua" element={<BiharPureit title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/pureit-ro-service-Bhagalpur" element={<BiharPureit title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/pureit-ro-service-Bhojpur" element={<BiharPureit title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/pureit-ro-service-Bihar-Sharif" element={<BiharPureit title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/pureit-ro-service-Buxar" element={<BiharPureit title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/pureit-ro-service-Chhapra" element={<BiharPureit title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/pureit-ro-service-Darbhanga" element={<BiharPureit title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/pureit-ro-service-East-Champaran" element={<BiharPureit title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/pureit-ro-service-Gaya" element={<BiharPureit title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/pureit-ro-service-Gopalganj" element={<BiharPureit title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/pureit-ro-service-Jamui" element={<BiharPureit title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/pureit-ro-service-Jehanabad" element={<BiharPureit title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/pureit-ro-service-Khagaria" element={<BiharPureit title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/pureit-ro-service-Kishanganj" element={<BiharPureit title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/pureit-ro-service-Kaimur" element={<BiharPureit title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/pureit-ro-service-Katihar" element={<BiharPureit title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/pureit-ro-service-Lakhisarai" element={<BiharPureit title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/pureit-ro-service-Madhubani" element={<BiharPureit title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/pureit-ro-service-Munger" element={<BiharPureit title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/pureit-ro-service-Madhepura" element={<BiharPureit title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/pureit-ro-service-Muzaffarpur" element={<BiharPureit title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/pureit-ro-service-Nalanda" element={<BiharPureit title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/pureit-ro-service-Nawada" element={<BiharPureit title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/pureit-ro-service-Patna" element={<BiharPureit title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/pureit-ro-service-Purnia" element={<BiharPureit title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/pureit-ro-service-Rohtas" element={<BiharPureit title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/pureit-ro-service-Saharsa" element={<BiharPureit title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/pureit-ro-service-Samastipur" element={<BiharPureit title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/pureit-ro-service-Sasaram" element={<BiharPureit title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/pureit-ro-service-Sheohar" element={<BiharPureit title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/pureit-ro-service-Sheikhpura" element={<BiharPureit title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/pureit-ro-service-Saran" element={<BiharPureit title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/pureit-ro-service-Sitamarhi" element={<BiharPureit title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/pureit-ro-service-Supaul" element={<BiharPureit title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/pureit-ro-service-Siwan" element={<BiharPureit title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/pureit-ro-service-Vaishali" element={<BiharPureit title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/pureit-ro-service-West-Champaran" element={<BiharPureit title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/pureit-ro-service-Garhwa" element={<JharkhandPureit title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/pureit-ro-service-Palamu" element={<JharkhandPureit title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/pureit-ro-service-Latehar" element={<JharkhandPureit title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/pureit-ro-service-Chatra" element={<JharkhandPureit title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/pureit-ro-service-Hazaribagh" element={<JharkhandPureit title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/pureit-ro-service-Jamshedpur" element={<JharkhandPureit title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/pureit-ro-service-Koderma" element={<JharkhandPureit title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/pureit-ro-service-Giridih" element={<JharkhandPureit title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/pureit-ro-service-Ramgarh" element={<JharkhandPureit title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/pureit-ro-service-Bokaro" element={<JharkhandPureit title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/pureit-ro-service-Dhanbad" element={<JharkhandPureit title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/pureit-ro-service-Lohardaga" element={<JharkhandPureit title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/pureit-ro-service-Gumla" element={<JharkhandPureit title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/pureit-ro-service-Simdega" element={<JharkhandPureit title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/pureit-ro-service-Ranchi" element={<JharkhandPureit title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/pureit-ro-service-Khunti" element={<JharkhandPureit title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/pureit-ro-service-Singhbhum" element={<JharkhandPureit title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/pureit-ro-service-Saraikela-Kharsawan" element={<JharkhandPureit title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/pureit-ro-service-Jamtara" element={<JharkhandPureit title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/pureit-ro-service-Deoghar" element={<JharkhandPureit title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/pureit-ro-service-Dumka" element={<JharkhandPureit title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/pureit-ro-service-Pakur" element={<JharkhandPureit title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/pureit-ro-service-Godda" element={<JharkhandPureit title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/pureit-ro-service-Sahebganj" element={<JharkhandPureit title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />

        <Route path="/pureit-ro-service-Alipurduar" element={<BengalPureit title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/pureit-ro-service-Bankura" element={<BengalPureit title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/pureit-ro-service-Birbhum" element={<BengalPureit title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/pureit-ro-service-Cooch-Behar" element={<BengalPureit title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/pureit-ro-service-Dakshin-Dinajpur" element={<BengalPureit title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/pureit-ro-service-Darjeeling" element={<BengalPureit title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/pureit-ro-service-Durgapur" element={<BengalPureit title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/pureit-ro-service-Hooghly" element={<BengalPureit title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/pureit-ro-service-Howrah" element={<BengalPureit title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/pureit-ro-service-Jalpaiguri" element={<BengalPureit title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/pureit-ro-service-Jhargram" element={<BengalPureit title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/pureit-ro-service-Kalimpong" element={<BengalPureit title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/pureit-ro-service-Kolkata" element={<BengalPureit title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/pureit-ro-service-Malda" element={<BengalPureit title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/pureit-ro-service-Murshidabad" element={<BengalPureit title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/pureit-ro-service-Nadia" element={<BengalPureit title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/pureit-ro-service-North-24-Parganas" element={<BengalPureit title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/pureit-ro-service-Paschim-Bardhaman" element={<BengalPureit title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/pureit-ro-service-Paschim-Medinipur" element={<BengalPureit title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/pureit-ro-service-Purba-Bardhaman" element={<BengalPureit title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/pureit-ro-service-Purba-Medinipur" element={<BengalPureit title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/pureit-ro-service-South-24-Parganas" element={<BengalPureit title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/pureit-ro-service-Uttar-Dinajpur" element={<BengalPureit title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />
        <Route path="/pureit-ro-service-Agra" element={<UttarPradeshPureit title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/pureit-ro-service-Aligarh" element={<UttarPradeshPureit title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/pureit-ro-service-Ambedkar-Nagar" element={<UttarPradeshPureit title={locdata.AmbedkarNagar.title} number={locdata.AmbedkarNagar.number} />} />
        <Route path="/pureit-ro-service-Ayodhya" element={<UttarPradeshPureit title={locdata.Ayodhya.title} number={locdata.Ayodhya.number} />} />
        <Route path="/pureit-ro-service-Amethi" element={<UttarPradeshPureit title={locdata.Amethi.title} number={locdata.Amethi.number} />} />
        <Route path="/pureit-ro-service-Amroha" element={<UttarPradeshPureit title={locdata.Amroha.title} number={locdata.Amroha.number} />} />
        <Route path="/pureit-ro-service-Auraiya" element={<UttarPradeshPureit title={locdata.Auraiya.title} number={locdata.Auraiya.number} />} />
        <Route path="/pureit-ro-service-Azamgarh" element={<UttarPradeshPureit title={locdata.Azamgarh.title} number={locdata.Azamgarh.number} />} />
        <Route path="/pureit-ro-service-Budaun" element={<UttarPradeshPureit title={locdata.Budaun.title} number={locdata.Budaun.number} />} />
        <Route path="/pureit-ro-service-Bagpat" element={<UttarPradeshPureit title={locdata.Bagpat.title} number={locdata.Bagpat.number} />} />
        <Route path="/pureit-ro-service-Bahraich" element={<UttarPradeshPureit title={locdata.Bahraich.title} number={locdata.Bahraich.number} />} />
        <Route path="/pureit-ro-service-Ballia" element={<UttarPradeshPureit title={locdata.Ballia.title} number={locdata.Ballia.number} />} />
        <Route path="/pureit-ro-service-Balrampur" element={<UttarPradeshPureit title={locdata.Balrampur.title} number={locdata.Balrampur.number} />} />
        <Route path="/pureit-ro-service-Banda" element={<UttarPradeshPureit title={locdata.Banda.title} number={locdata.Banda.number} />} />
        <Route path="/pureit-ro-service-Barabanki" element={<UttarPradeshPureit title={locdata.Barabanki.title} number={locdata.Barabanki.number} />} />
        <Route path="/pureit-ro-service-Bareilly" element={<UttarPradeshPureit title={locdata.Bareilly.title} number={locdata.Bareilly.number} />} />
        <Route path="/pureit-ro-service-Basti" element={<UttarPradeshPureit title={locdata.Basti.title} number={locdata.Basti.number} />} />
        <Route path="/pureit-ro-service-Bijnor" element={<UttarPradeshPureit title={locdata.Bijnor.title} number={locdata.Bijnor.number} />} />
        <Route path="/pureit-ro-service-Bulandshahr" element={<UttarPradeshPureit title={locdata.Bulandshahr.title} number={locdata.Bulandshahr.number} />} />
        <Route path="/pureit-ro-service-Chandauli" element={<UttarPradeshPureit title={locdata.Chandauli.title} number={locdata.Chandauli.number} />} />
        <Route path="/pureit-ro-service-Chitrakoot" element={<UttarPradeshPureit title={locdata.Chitrakoot.title} number={locdata.Chitrakoot.number} />} />
        <Route path="/pureit-ro-service-Deoria" element={<UttarPradeshPureit title={locdata.Deoria.title} number={locdata.Deoria.number} />} />
        <Route path="/pureit-ro-service-Etah" element={<UttarPradeshPureit title={locdata.Etah.title} number={locdata.Etah.number} />} />
        <Route path="/pureit-ro-service-Etawah" element={<UttarPradeshPureit title={locdata.Etawah.title} number={locdata.Etawah.number} />} />
        <Route path="/pureit-ro-service-Farrukhabad" element={<UttarPradeshPureit title={locdata.Farrukhabad.title} number={locdata.Farrukhabad.number} />} />
        <Route path="/pureit-ro-service-Fatehpur" element={<UttarPradeshPureit title={locdata.Fatehpur.title} number={locdata.Fatehpur.number} />} />
        <Route path="/pureit-ro-service-Firozabad" element={<UttarPradeshPureit title={locdata.Firozabad.title} number={locdata.Firozabad.number} />} />
        <Route path="/pureit-ro-service-Gautam-Buddha-Nagar" element={<UttarPradeshPureit title={locdata.GautamBuddhaNagar.title} number={locdata.GautamBuddhaNagar.number} />} />
        <Route path="/pureit-ro-service-Ghaziabad" element={<UttarPradeshPureit title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/pureit-ro-service-Ghazipur" element={<UttarPradeshPureit title={locdata.Ghazipur.title} number={locdata.Ghazipur.number} />} />
        <Route path="/pureit-ro-service-Gonda" element={<UttarPradeshPureit title={locdata.Gonda.title} number={locdata.Gonda.number} />} />
        <Route path="/pureit-ro-service-Gorakhpur" element={<UttarPradeshPureit title={locdata.Gorakhpur.title} number={locdata.Gorakhpur.number} />} />
        <Route path="/pureit-ro-service-Hamirpur" element={<UttarPradeshPureit title={locdata.Hamirpur.title} number={locdata.Hamirpur.number} />} />
        <Route path="/pureit-ro-service-Hapur" element={<UttarPradeshPureit title={locdata.Hapur.title} number={locdata.Hapur.number} />} />
        <Route path="/pureit-ro-service-Hardoi" element={<UttarPradeshPureit title={locdata.Hardoi.title} number={locdata.Hardoi.number} />} />
        <Route path="/pureit-ro-service-Hathras" element={<UttarPradeshPureit title={locdata.Hathras.title} number={locdata.Hathras.number} />} />
        <Route path="/pureit-ro-service-Jalaun" element={<UttarPradeshPureit title={locdata.Jalaun.title} number={locdata.Jalaun.number} />} />
        <Route path="/pureit-ro-service-Jaunpur" element={<UttarPradeshPureit title={locdata.Jaunpur.title} number={locdata.Jaunpur.number} />} />
        <Route path="/pureit-ro-service-Jhansi" element={<UttarPradeshPureit title={locdata.Jhansi.title} number={locdata.Jhansi.number} />} />
        <Route path="/pureit-ro-service-Kannauj" element={<UttarPradeshPureit title={locdata.Kannauj.title} number={locdata.Kannauj.number} />} />
        <Route path="/pureit-ro-service-Kanpur-Dehat" element={<UttarPradeshPureit title={locdata.KanpurDehat.title} number={locdata.KanpurDehat.number} />} />
        <Route path="/pureit-ro-service-Kanpur" element={<UttarPradeshPureit title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/pureit-ro-service-Kasganj" element={<UttarPradeshPureit title={locdata.Kasganj.title} number={locdata.Kasganj.number} />} />
        <Route path="/pureit-ro-service-Kaushambi" element={<UttarPradeshPureit title={locdata.Kaushambi.title} number={locdata.Kaushambi.number} />} />
        <Route path="/pureit-ro-service-Kushinagar" element={<UttarPradeshPureit title={locdata.Kushinagar.title} number={locdata.Kushinagar.number} />} />
        <Route path="/pureit-ro-service-Lakhimpur-Kheri" element={<UttarPradeshPureit title={locdata.LakhimpurKheri.title} number={locdata.LakhimpurKheri.number} />} />
        <Route path="/pureit-ro-service-Lalitpur" element={<UttarPradeshPureit title={locdata.Lalitpur.title} number={locdata.Lalitpur.number} />} />
        <Route path="/pureit-ro-service-Lucknow" element={<UttarPradeshPureit title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/pureit-ro-service-Maharajganj" element={<UttarPradeshPureit title={locdata.Maharajganj.title} number={locdata.Maharajganj.number} />} />
        <Route path="/pureit-ro-service-Mahoba" element={<UttarPradeshPureit title={locdata.Mahoba.title} number={locdata.Mahoba.number} />} />
        <Route path="/pureit-ro-service-Mainpuri" element={<UttarPradeshPureit title={locdata.Mainpuri.title} number={locdata.Mainpuri.number} />} />
        <Route path="/pureit-ro-service-Mathura" element={<UttarPradeshPureit title={locdata.Mathura.title} number={locdata.Mathura.number} />} />
        <Route path="/pureit-ro-service-Mau" element={<UttarPradeshPureit title={locdata.Mau.title} number={locdata.Mau.number} />} />
        <Route path="/pureit-ro-service-Meerut" element={<UttarPradeshPureit title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/pureit-ro-service-Mirzapur" element={<UttarPradeshPureit title={locdata.Mirzapur.title} number={locdata.Mirzapur.number} />} />
        <Route path="/pureit-ro-service-Moradabad" element={<UttarPradeshPureit title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/pureit-ro-service-Muzaffarnagar" element={<UttarPradeshPureit title={locdata.Muzaffarnagar.title} number={locdata.Muzaffarnagar.number} />} />
        <Route path="/pureit-ro-service-Pilibhit" element={<UttarPradeshPureit title={locdata.Pilibhit.title} number={locdata.Pilibhit.number} />} />
        <Route path="/pureit-ro-service-Pratapgarh" element={<UttarPradeshPureit title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/pureit-ro-service-Prayagraj" element={<UttarPradeshPureit title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/pureit-ro-service-Rae-Bareli" element={<UttarPradeshPureit title={locdata.RaeBareli.title} number={locdata.RaeBareli.number} />} />
        <Route path="/pureit-ro-service-Rampur" element={<UttarPradeshPureit title={locdata.Rampur.title} number={locdata.Rampur.number} />} />
        <Route path="/pureit-ro-service-Saharanpur" element={<UttarPradeshPureit title={locdata.Saharanpur.title} number={locdata.Saharanpur.number} />} />
        <Route path="/pureit-ro-service-Sant-Kabir-Nagar" element={<UttarPradeshPureit title={locdata.SantKabirNagar.title} number={locdata.SantKabirNagar.number} />} />
        <Route path="/pureit-ro-service-Bhadohi" element={<UttarPradeshPureit title={locdata.Bhadohi.title} number={locdata.Bhadohi.number} />} />
        <Route path="/pureit-ro-service-Sambhal" element={<UttarPradeshPureit title={locdata.Sambhal.title} number={locdata.Sambhal.number} />} />
        <Route path="/pureit-ro-service-Shahjahanpur" element={<UttarPradeshPureit title={locdata.Shahjahanpur.title} number={locdata.Shahjahanpur.number} />} />
        <Route path="/pureit-ro-service-Shamli" element={<UttarPradeshPureit title={locdata.Shamli.title} number={locdata.Shamli.number} />} />
        <Route path="/pureit-ro-service-Shravasti" element={<UttarPradeshPureit title={locdata.Shravasti.title} number={locdata.Shravasti.number} />} />
        <Route path="/pureit-ro-service-Siddharthnagar" element={<UttarPradeshPureit title={locdata.Siddharthnagar.title} number={locdata.Siddharthnagar.number} />} />
        <Route path="/pureit-ro-service-Sitapur" element={<UttarPradeshPureit title={locdata.Sitapur.title} number={locdata.Sitapur.number} />} />
        <Route path="/pureit-ro-service-Sonbhadra" element={<UttarPradeshPureit title={locdata.Sonbhadra.title} number={locdata.Sonbhadra.number} />} />
        <Route path="/pureit-ro-service-Sultanpur" element={<UttarPradeshPureit title={locdata.Sultanpur.title} number={locdata.Sultanpur.number} />} />
        <Route path="/pureit-ro-service-Unnao" element={<UttarPradeshPureit title={locdata.Unnao.title} number={locdata.Unnao.number} />} />
        <Route path="/pureit-ro-service-Varanasi" element={<UttarPradeshPureit title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/pureit-ro-service-Robertsganj" element={<UttarPradeshPureit title={locdata.Robertsganj.title} number={locdata.Robertsganj.number} />} />
        <Route path="/pureit-ro-service-Bahjoi" element={<UttarPradeshPureit title={locdata.Bahjoi.title} number={locdata.Bahjoi.number} />} />
        <Route path="/pureit-ro-service-Khalilabad" element={<UttarPradeshPureit title={locdata.Khalilabad.title} number={locdata.Khalilabad.number} />} />
        <Route path="/pureit-ro-service-Akbarpur" element={<UttarPradeshPureit title={locdata.Akbarpur.title} number={locdata.Akbarpur.number} />} />
        <Route path="/pureit-ro-service-Orai" element={<UttarPradeshPureit title={locdata.Orai.title} number={locdata.Orai.number} />} />
        <Route path="/pureit-ro-service-Manjhanpur" element={<UttarPradeshPureit title={locdata.Manjhanpur.title} number={locdata.Manjhanpur.number} />} />
        <Route path="/pureit-ro-service-Padarauna" element={<UttarPradeshPureit title={locdata.Padarauna.title} number={locdata.Padarauna.number} />} />
        <Route path="/pureit-ro-service-Kheri" element={<UttarPradeshPureit title={locdata.Kheri.title} number={locdata.Kheri.number} />} />
        <Route path="/pureit-ro-service-Fatehgarh" element={<UttarPradeshPureit title={locdata.Fatehgarh.title} number={locdata.Fatehgarh.number} />} />
        <Route path="/pureit-ro-service-Noida" element={<UttarPradeshPureit title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/pureit-ro-service-Greater-Noida" element={<UttarPradeshPureit title={locdata.GreaterNoida.title} number={locdata.GreaterNoida.number} />} />

        <Route path="/pureit-ro-service-Indore" element={<MadhyaPradeshPureit title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/pureit-ro-service-Bhopal" element={<MadhyaPradeshPureit title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/pureit-ro-service-Jabalpur" element={<MadhyaPradeshPureit title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/pureit-ro-service-Gwalior" element={<MadhyaPradeshPureit title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/pureit-ro-service-Ujjain" element={<MadhyaPradeshPureit title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/pureit-ro-service-Sagar" element={<MadhyaPradeshPureit title={locdata.Sagar.title} number={locdata.Sagar.number} />} />
        <Route path="/pureit-ro-service-Dewas" element={<MadhyaPradeshPureit title={locdata.Dewas.title} number={locdata.Dewas.number} />} />
        <Route path="/pureit-ro-service-Satna" element={<MadhyaPradeshPureit title={locdata.Satna.title} number={locdata.Satna.number} />} />
        <Route path="/pureit-ro-service-Ratlam" element={<MadhyaPradeshPureit title={locdata.Ratlam.title} number={locdata.Ratlam.number} />} />
        <Route path="/pureit-ro-service-Rewa" element={<MadhyaPradeshPureit title={locdata.Rewa.title} number={locdata.Rewa.number} />} />
        <Route path="/pureit-ro-service-Katni" element={<MadhyaPradeshPureit title={locdata.Katni.title} number={locdata.Katni.number} />} />
        <Route path="/pureit-ro-service-Singrauli" element={<MadhyaPradeshPureit title={locdata.Singrauli.title} number={locdata.Singrauli.number} />} />
        <Route path="/pureit-ro-service-Burhanpur" element={<MadhyaPradeshPureit title={locdata.Burhanpur.title} number={locdata.Burhanpur.number} />} />
        <Route path="/pureit-ro-service-Morena" element={<MadhyaPradeshPureit title={locdata.Morena.title} number={locdata.Morena.number} />} />
        <Route path="/pureit-ro-service-Khandwa" element={<MadhyaPradeshPureit title={locdata.Khandwa.title} number={locdata.Khandwa.number} />} />
        <Route path="/pureit-ro-service-Bhind" element={<MadhyaPradeshPureit title={locdata.Bhind.title} number={locdata.Bhind.number} />} />
        <Route path="/pureit-ro-service-Chhindwara" element={<MadhyaPradeshPureit title={locdata.Chhindwara.title} number={locdata.Chhindwara.number} />} />
        <Route path="/pureit-ro-service-Guna" element={<MadhyaPradeshPureit title={locdata.Guna.title} number={locdata.Guna.number} />} />
        <Route path="/pureit-ro-service-Shivpuri" element={<MadhyaPradeshPureit title={locdata.Shivpuri.title} number={locdata.Shivpuri.number} />} />
        <Route path="/pureit-ro-service-Vidisha" element={<MadhyaPradeshPureit title={locdata.Vidisha.title} number={locdata.Vidisha.number} />} />
        <Route path="/pureit-ro-service-Chhatarpur" element={<MadhyaPradeshPureit title={locdata.Chhatarpur.title} number={locdata.Chhatarpur.number} />} />
        <Route path="/pureit-ro-service-Damoh" element={<MadhyaPradeshPureit title={locdata.Damoh.title} number={locdata.Damoh.number} />} />
        <Route path="/pureit-ro-service-Mandsaur" element={<MadhyaPradeshPureit title={locdata.Mandsaur.title} number={locdata.Mandsaur.number} />} />
        <Route path="/pureit-ro-service-Khargone" element={<MadhyaPradeshPureit title={locdata.Khargone.title} number={locdata.Khargone.number} />} />
        <Route path="/pureit-ro-service-Neemuch" element={<MadhyaPradeshPureit title={locdata.Neemuch.title} number={locdata.Neemuch.number} />} />
        <Route path="/pureit-ro-service-Pithampur" element={<MadhyaPradeshPureit title={locdata.Pithampur.title} number={locdata.Pithampur.number} />} />
        <Route path="/pureit-ro-service-Narmadapuram" element={<MadhyaPradeshPureit title={locdata.Narmadapuram.title} number={locdata.Narmadapuram.number} />} />
        <Route path="/pureit-ro-service-Itarsi" element={<MadhyaPradeshPureit title={locdata.Itarsi.title} number={locdata.Itarsi.number} />} />
        <Route path="/pureit-ro-service-Sehore" element={<MadhyaPradeshPureit title={locdata.Sehore.title} number={locdata.Sehore.number} />} />
        <Route path="/pureit-ro-service-Betul" element={<MadhyaPradeshPureit title={locdata.Betul.title} number={locdata.Betul.number} />} />
        <Route path="/pureit-ro-service-Seoni" element={<MadhyaPradeshPureit title={locdata.Seoni.title} number={locdata.Seoni.number} />} />
        <Route path="/pureit-ro-service-Datia" element={<MadhyaPradeshPureit title={locdata.Datia.title} number={locdata.Datia.number} />} />
        <Route path="/pureit-ro-service-Nagda" element={<MadhyaPradeshPureit title={locdata.Nagda.title} number={locdata.Nagda.number} />} />
        <Route path="/pureit-ro-service-Shahdol" element={<MadhyaPradeshPureit title={locdata.Shahdol.title} number={locdata.Shahdol.number} />} />
        <Route path="/pureit-ro-service-Dhar" element={<MadhyaPradeshPureit title={locdata.Dhar.title} number={locdata.Dhar.number} />} />

        <Route path="/pureit-ro-service-Ajmer" element={<RajasthanPureit title={locdata.Ajmer.title} number={locdata.Ajmer.number} />} />
        <Route path="/pureit-ro-service-Alwar" element={<RajasthanPureit title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/pureit-ro-service-Balotra" element={<RajasthanPureit title={locdata.Balotra.title} number={locdata.Balotra.number} />} />
        <Route path="/pureit-ro-service-Banswara" element={<RajasthanPureit title={locdata.Banswara.title} number={locdata.Banswara.number} />} />
        <Route path="/pureit-ro-service-Baran" element={<RajasthanPureit title={locdata.Baran.title} number={locdata.Baran.number} />} />
        <Route path="/pureit-ro-service-Barmer" element={<RajasthanPureit title={locdata.Barmer.title} number={locdata.Barmer.number} />} />
        <Route path="/pureit-ro-service-Beawar" element={<RajasthanPureit title={locdata.Beawar.title} number={locdata.Beawar.number} />} />
        <Route path="/pureit-ro-service-Bharatpur" element={<RajasthanPureit title={locdata.Bharatpur.title} number={locdata.Bharatpur.number} />} />
        <Route path="/pureit-ro-service-Bhilwara" element={<RajasthanPureit title={locdata.Bhilwara.title} number={locdata.Bhilwara.number} />} />
        <Route path="/pureit-ro-service-Bikaner" element={<RajasthanPureit title={locdata.Bikaner.title} number={locdata.Bikaner.number} />} />
        <Route path="/pureit-ro-service-Bundi" element={<RajasthanPureit title={locdata.Bundi.title} number={locdata.Bundi.number} />} />
        <Route path="/pureit-ro-service-Chittorgarh" element={<RajasthanPureit title={locdata.Chittorgarh.title} number={locdata.Chittorgarh.number} />} />
        <Route path="/pureit-ro-service-Churu" element={<RajasthanPureit title={locdata.Churu.title} number={locdata.Churu.number} />} />
        <Route path="/pureit-ro-service-Dausa" element={<RajasthanPureit title={locdata.Dausa.title} number={locdata.Dausa.number} />} />
        <Route path="/pureit-ro-service-Deeg" element={<RajasthanPureit title={locdata.Deeg.title} number={locdata.Deeg.number} />} />
        <Route path="/pureit-ro-service-Deedwana" element={<RajasthanPureit title={locdata.Deedwana.title} number={locdata.Deedwana.number} />} />
        <Route path="/pureit-ro-service-Dholpur" element={<RajasthanPureit title={locdata.Dholpur.title} number={locdata.Dholpur.number} />} />
        <Route path="/pureit-ro-service-Dungarpur" element={<RajasthanPureit title={locdata.Dungarpur.title} number={locdata.Dungarpur.number} />} />
        <Route path="/pureit-ro-service-Hanumangarh" element={<RajasthanPureit title={locdata.Hanumangarh.title} number={locdata.Hanumangarh.number} />} />
        <Route path="/pureit-ro-service-Jaipur" element={<RajasthanPureit title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/pureit-ro-service-Jaisalmer" element={<RajasthanPureit title={locdata.Jaisalmer.title} number={locdata.Jaisalmer.number} />} />
        <Route path="/pureit-ro-service-Jalore" element={<RajasthanPureit title={locdata.Jalore.title} number={locdata.Jalore.number} />} />
        <Route path="/pureit-ro-service-Jhalawar" element={<RajasthanPureit title={locdata.Jhalawar.title} number={locdata.Jhalawar.number} />} />
        <Route path="/pureit-ro-service-Jhunjhunu" element={<RajasthanPureit title={locdata.Jhunjhunu.title} number={locdata.Jhunjhunu.number} />} />
        <Route path="/pureit-ro-service-Jodhpur" element={<RajasthanPureit title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/pureit-ro-service-Karauli" element={<RajasthanPureit title={locdata.Karauli.title} number={locdata.Karauli.number} />} />
        <Route path="/pureit-ro-service-Tijara" element={<RajasthanPureit title={locdata.Tijara.title} number={locdata.Tijara.number} />} />
        <Route path="/pureit-ro-service-Kotputli" element={<RajasthanPureit title={locdata.Kotputli.title} number={locdata.Kotputli.number} />} />
        <Route path="/pureit-ro-service-Kota" element={<RajasthanPureit title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/pureit-ro-service-Nagaur" element={<RajasthanPureit title={locdata.Nagaur.title} number={locdata.Nagaur.number} />} />
        <Route path="/pureit-ro-service-Pali" element={<RajasthanPureit title={locdata.Pali.title} number={locdata.Pali.number} />} />
        <Route path="/pureit-ro-service-Phalodi" element={<RajasthanPureit title={locdata.Phalodi.title} number={locdata.Phalodi.number} />} />
        <Route path="/pureit-ro-service-Pratapgarh" element={<RajasthanPureit title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/pureit-ro-service-Rajsamand" element={<RajasthanPureit title={locdata.Rajsamand.title} number={locdata.Rajsamand.number} />} />
        <Route path="/pureit-ro-service-Salumbar" element={<RajasthanPureit title={locdata.Salumbar.title} number={locdata.Salumbar.number} />} />
        <Route path="/pureit-ro-service-Sawai-Madhopur" element={<RajasthanPureit title={locdata.SawaiMadhopur.title} number={locdata.SawaiMadhopur.number} />} />
        <Route path="/pureit-ro-service-Sikar" element={<RajasthanPureit title={locdata.Sikar.title} number={locdata.Sikar.number} />} />
        <Route path="/pureit-ro-service-Sirohi" element={<RajasthanPureit title={locdata.Sirohi.title} number={locdata.Sirohi.number} />} />
        <Route path="/pureit-ro-service-Sri-Ganganagar" element={<RajasthanPureit title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/pureit-ro-service-Tonk" element={<RajasthanPureit title={locdata.Tonk.title} number={locdata.Tonk.number} />} />
        <Route path="/pureit-ro-service-Udaipur" element={<RajasthanPureit title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />

        <Route path="/pureit-ro-service-Mumbai" element={<PureItro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/pureit-ro-service-Delhi" element={<PureItro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/pureit-ro-service-Bengaluru" element={<PureItro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/pureit-ro-service-Hyderabad" element={<PureItro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/pureit-ro-service-Ahmedabad" element={<PureItro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/pureit-ro-service-Chennai" element={<PureItro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/pureit-ro-service-Surat" element={<PureItro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/pureit-ro-service-Pune" element={<PureItro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/pureit-ro-service-Jaipur" element={<PureItro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/pureit-ro-service-Lucknow" element={<PureItro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/pureit-ro-service-Kanpur" element={<PureItro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/pureit-ro-service-Nagpur" element={<PureItro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/pureit-ro-service-Indore" element={<PureItro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/pureit-ro-service-Thane" element={<PureItro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/pureit-ro-service-Bhopal" element={<PureItro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/pureit-ro-service-Visakhapatnam" element={<PureItro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/pureit-ro-service-Vadodara" element={<PureItro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/pureit-ro-service-Ghaziabad" element={<PureItro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/pureit-ro-service-Ludhiana" element={<PureItro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/pureit-ro-service-Agra" element={<PureItro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/pureit-ro-service-Nashik" element={<PureItro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/pureit-ro-service-Faridabad" element={<PureItro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/pureit-ro-service-Meerut" element={<PureItro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/pureit-ro-service-Rajkot" element={<PureItro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/pureit-ro-service-Varanasi" element={<PureItro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/pureit-ro-service-Srinagar" element={<PureItro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/pureit-ro-service-Amritsar" element={<PureItro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/pureit-ro-service-Navi-Mumbai" element={<PureItro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/pureit-ro-service-Prayagraj" element={<PureItro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/pureit-ro-service-Jabalpur" element={<PureItro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/pureit-ro-service-Gwalior" element={<PureItro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/pureit-ro-service-Coimbatore" element={<PureItro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/pureit-ro-service-Vijayawada" element={<PureItro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/pureit-ro-service-Jodhpur" element={<PureItro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/pureit-ro-service-Madurai" element={<PureItro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/pureit-ro-service-Raipur" element={<PureItro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/pureit-ro-service-Kota" element={<PureItro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/pureit-ro-service-Guwahati" element={<PureItro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/pureit-ro-service-Chandigarh" element={<PureItro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/pureit-ro-service-Thiruvananthapuram" element={<PureItro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/pureit-ro-service-Solapur" element={<PureItro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/pureit-ro-service-Tiruppur" element={<PureItro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/pureit-ro-service-Moradabad" element={<PureItro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/pureit-ro-service-Mysore" element={<PureItro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/pureit-ro-service-Gurgaon" element={<PureItro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/pureit-ro-service-Aligarh" element={<PureItro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/pureit-ro-service-Jalandhar" element={<PureItro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/pureit-ro-service-Bhubaneswar" element={<PureItro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/pureit-ro-service-Noida" element={<PureItro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/pureit-ro-service-Jamshedpur" element={<PureItro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/pureit-ro-service-Cuttack" element={<PureItro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/pureit-ro-service-Bhavnagar" element={<PureItro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/pureit-ro-service-Dehradun" element={<PureItro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/pureit-ro-service-Rourkela" element={<PureItro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/pureit-ro-service-Jamnagar" element={<PureItro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/pureit-ro-service-Ujjain" element={<PureItro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/pureit-ro-service-Jammu" element={<PureItro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/pureit-ro-service-Mangalore" element={<PureItro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/pureit-ro-service-Erode" element={<PureItro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/pureit-ro-service-Udaipur" element={<PureItro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/pureit-ro-service-Rajahmundry" element={<PureItro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/pureit-ro-service-Patiala" element={<PureItro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/pureit-ro-service-Agartala" element={<PureItro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/pureit-ro-service-Tirupati" element={<PureItro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/pureit-ro-service-Sambalpur" element={<PureItro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/pureit-ro-service-Bilaspur" element={<PureItro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/pureit-ro-service-Alwar" element={<PureItro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/pureit-ro-service-Bardhaman" element={<PureItro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/pureit-ro-service-Sonipat" element={<PureItro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/pureit-ro-service-New-Delhi" element={<PureItro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/pureit-ro-service-Puducherry" element={<PureItro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/pureit-ro-service-Haridwar" element={<PureItro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/pureit-ro-service-Vijayanagaram" element={<PureItro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/pureit-ro-service-Sri-Ganganagar" element={<PureItro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/pureit-ro-service-Secunderabad" element={<PureItro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/pureit-ro-service-Yamunanagar" element={<PureItro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/pureit-ro-service-Gandhinagar" element={<PureItro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/pureit-ro-service-Ambala" element={<PureItro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/pureit-ro-service-Vellore" element={<PureItro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/pureit-ro-service-Shimla" element={<PureItro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/pureit-ro-service-Amaravati" element={<PureItro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />


        <Route path="/whirlpool-ro-service-Araria" element={<BiharWhirlpool title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/whirlpool-ro-service-Arwal" element={<BiharWhirlpool title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/whirlpool-ro-service-Aurangabad" element={<BiharWhirlpool title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/whirlpool-ro-service-Banka" element={<BiharWhirlpool title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/whirlpool-ro-service-Begusarai" element={<BiharWhirlpool title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/whirlpool-ro-service-Bhabua" element={<BiharWhirlpool title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/whirlpool-ro-service-Bhagalpur" element={<BiharWhirlpool title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/whirlpool-ro-service-Bhojpur" element={<BiharWhirlpool title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/whirlpool-ro-service-Bihar-Sharif" element={<BiharWhirlpool title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/whirlpool-ro-service-Buxar" element={<BiharWhirlpool title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/whirlpool-ro-service-Chhapra" element={<BiharWhirlpool title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/whirlpool-ro-service-Darbhanga" element={<BiharWhirlpool title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/whirlpool-ro-service-East-Champaran" element={<BiharWhirlpool title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/whirlpool-ro-service-Gaya" element={<BiharWhirlpool title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/whirlpool-ro-service-Gopalganj" element={<BiharWhirlpool title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/whirlpool-ro-service-Jamui" element={<BiharWhirlpool title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/whirlpool-ro-service-Jehanabad" element={<BiharWhirlpool title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/whirlpool-ro-service-Khagaria" element={<BiharWhirlpool title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/whirlpool-ro-service-Kishanganj" element={<BiharWhirlpool title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/whirlpool-ro-service-Kaimur" element={<BiharWhirlpool title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/whirlpool-ro-service-Katihar" element={<BiharWhirlpool title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/whirlpool-ro-service-Lakhisarai" element={<BiharWhirlpool title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/whirlpool-ro-service-Madhubani" element={<BiharWhirlpool title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/whirlpool-ro-service-Munger" element={<BiharWhirlpool title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/whirlpool-ro-service-Madhepura" element={<BiharWhirlpool title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/whirlpool-ro-service-Muzaffarpur" element={<BiharWhirlpool title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/whirlpool-ro-service-Nalanda" element={<BiharWhirlpool title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/whirlpool-ro-service-Nawada" element={<BiharWhirlpool title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/whirlpool-ro-service-Patna" element={<BiharWhirlpool title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/whirlpool-ro-service-Purnia" element={<BiharWhirlpool title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/whirlpool-ro-service-Rohtas" element={<BiharWhirlpool title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/whirlpool-ro-service-Saharsa" element={<BiharWhirlpool title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/whirlpool-ro-service-Samastipur" element={<BiharWhirlpool title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/whirlpool-ro-service-Sasaram" element={<BiharWhirlpool title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/whirlpool-ro-service-Sheohar" element={<BiharWhirlpool title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/whirlpool-ro-service-Sheikhpura" element={<BiharWhirlpool title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/whirlpool-ro-service-Saran" element={<BiharWhirlpool title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/whirlpool-ro-service-Sitamarhi" element={<BiharWhirlpool title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/whirlpool-ro-service-Supaul" element={<BiharWhirlpool title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/whirlpool-ro-service-Siwan" element={<BiharWhirlpool title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/whirlpool-ro-service-Vaishali" element={<BiharWhirlpool title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/whirlpool-ro-service-West-Champaran" element={<BiharWhirlpool title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/whirlpool-ro-service-Garhwa" element={<JharkhandWhirlpool title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/whirlpool-ro-service-Palamu" element={<JharkhandWhirlpool title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/whirlpool-ro-service-Latehar" element={<JharkhandWhirlpool title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/whirlpool-ro-service-Chatra" element={<JharkhandWhirlpool title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/whirlpool-ro-service-Hazaribagh" element={<JharkhandWhirlpool title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/whirlpool-ro-service-Jamshedpur" element={<JharkhandWhirlpool title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/whirlpool-ro-service-Koderma" element={<JharkhandWhirlpool title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/whirlpool-ro-service-Giridih" element={<JharkhandWhirlpool title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/whirlpool-ro-service-Ramgarh" element={<JharkhandWhirlpool title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/whirlpool-ro-service-Bokaro" element={<JharkhandWhirlpool title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/whirlpool-ro-service-Dhanbad" element={<JharkhandWhirlpool title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/whirlpool-ro-service-Lohardaga" element={<JharkhandWhirlpool title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/whirlpool-ro-service-Gumla" element={<JharkhandWhirlpool title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/whirlpool-ro-service-Simdega" element={<JharkhandWhirlpool title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/whirlpool-ro-service-Ranchi" element={<JharkhandWhirlpool title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/whirlpool-ro-service-Khunti" element={<JharkhandWhirlpool title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/whirlpool-ro-service-Singhbhum" element={<JharkhandWhirlpool title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/whirlpool-ro-service-Saraikela-Kharsawan" element={<JharkhandWhirlpool title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/whirlpool-ro-service-Jamtara" element={<JharkhandWhirlpool title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/whirlpool-ro-service-Deoghar" element={<JharkhandWhirlpool title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/whirlpool-ro-service-Dumka" element={<JharkhandWhirlpool title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/whirlpool-ro-service-Pakur" element={<JharkhandWhirlpool title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/whirlpool-ro-service-Godda" element={<JharkhandWhirlpool title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/whirlpool-ro-service-Sahebganj" element={<JharkhandWhirlpool title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />

        <Route path="/whirlpool-ro-service-Alipurduar" element={<BengalWhirlpool title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/whirlpool-ro-service-Bankura" element={<BengalWhirlpool title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/whirlpool-ro-service-Birbhum" element={<BengalWhirlpool title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/whirlpool-ro-service-Cooch-Behar" element={<BengalWhirlpool title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/whirlpool-ro-service-Dakshin-Dinajpur" element={<BengalWhirlpool title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/whirlpool-ro-service-Darjeeling" element={<BengalWhirlpool title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/whirlpool-ro-service-Durgapur" element={<BengalWhirlpool title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/whirlpool-ro-service-Hooghly" element={<BengalWhirlpool title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/whirlpool-ro-service-Howrah" element={<BengalWhirlpool title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/whirlpool-ro-service-Jalpaiguri" element={<BengalWhirlpool title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/whirlpool-ro-service-Jhargram" element={<BengalWhirlpool title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/whirlpool-ro-service-Kalimpong" element={<BengalWhirlpool title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/whirlpool-ro-service-Kolkata" element={<BengalWhirlpool title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/whirlpool-ro-service-Malda" element={<BengalWhirlpool title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/whirlpool-ro-service-Murshidabad" element={<BengalWhirlpool title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/whirlpool-ro-service-Nadia" element={<BengalWhirlpool title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/whirlpool-ro-service-North-24-Parganas" element={<BengalWhirlpool title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/whirlpool-ro-service-Paschim-Bardhaman" element={<BengalWhirlpool title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/whirlpool-ro-service-Paschim-Medinipur" element={<BengalWhirlpool title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/whirlpool-ro-service-Purba-Bardhaman" element={<BengalWhirlpool title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/whirlpool-ro-service-Purba-Medinipur" element={<BengalWhirlpool title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/whirlpool-ro-service-South-24-Parganas" element={<BengalWhirlpool title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/whirlpool-ro-service-Uttar-Dinajpur" element={<BengalWhirlpool title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />
        <Route path="/whirlpool-ro-service-Agra" element={<UttarPradeshWhirlpool title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/whirlpool-ro-service-Aligarh" element={<UttarPradeshWhirlpool title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/whirlpool-ro-service-Ambedkar-Nagar" element={<UttarPradeshWhirlpool title={locdata.AmbedkarNagar.title} number={locdata.AmbedkarNagar.number} />} />
        <Route path="/whirlpool-ro-service-Ayodhya" element={<UttarPradeshWhirlpool title={locdata.Ayodhya.title} number={locdata.Ayodhya.number} />} />
        <Route path="/whirlpool-ro-service-Amethi" element={<UttarPradeshWhirlpool title={locdata.Amethi.title} number={locdata.Amethi.number} />} />
        <Route path="/whirlpool-ro-service-Amroha" element={<UttarPradeshWhirlpool title={locdata.Amroha.title} number={locdata.Amroha.number} />} />
        <Route path="/whirlpool-ro-service-Auraiya" element={<UttarPradeshWhirlpool title={locdata.Auraiya.title} number={locdata.Auraiya.number} />} />
        <Route path="/whirlpool-ro-service-Azamgarh" element={<UttarPradeshWhirlpool title={locdata.Azamgarh.title} number={locdata.Azamgarh.number} />} />
        <Route path="/whirlpool-ro-service-Budaun" element={<UttarPradeshWhirlpool title={locdata.Budaun.title} number={locdata.Budaun.number} />} />
        <Route path="/whirlpool-ro-service-Bagpat" element={<UttarPradeshWhirlpool title={locdata.Bagpat.title} number={locdata.Bagpat.number} />} />
        <Route path="/whirlpool-ro-service-Bahraich" element={<UttarPradeshWhirlpool title={locdata.Bahraich.title} number={locdata.Bahraich.number} />} />
        <Route path="/whirlpool-ro-service-Ballia" element={<UttarPradeshWhirlpool title={locdata.Ballia.title} number={locdata.Ballia.number} />} />
        <Route path="/whirlpool-ro-service-Balrampur" element={<UttarPradeshWhirlpool title={locdata.Balrampur.title} number={locdata.Balrampur.number} />} />
        <Route path="/whirlpool-ro-service-Banda" element={<UttarPradeshWhirlpool title={locdata.Banda.title} number={locdata.Banda.number} />} />
        <Route path="/whirlpool-ro-service-Barabanki" element={<UttarPradeshWhirlpool title={locdata.Barabanki.title} number={locdata.Barabanki.number} />} />
        <Route path="/whirlpool-ro-service-Bareilly" element={<UttarPradeshWhirlpool title={locdata.Bareilly.title} number={locdata.Bareilly.number} />} />
        <Route path="/whirlpool-ro-service-Basti" element={<UttarPradeshWhirlpool title={locdata.Basti.title} number={locdata.Basti.number} />} />
        <Route path="/whirlpool-ro-service-Bijnor" element={<UttarPradeshWhirlpool title={locdata.Bijnor.title} number={locdata.Bijnor.number} />} />
        <Route path="/whirlpool-ro-service-Bulandshahr" element={<UttarPradeshWhirlpool title={locdata.Bulandshahr.title} number={locdata.Bulandshahr.number} />} />
        <Route path="/whirlpool-ro-service-Chandauli" element={<UttarPradeshWhirlpool title={locdata.Chandauli.title} number={locdata.Chandauli.number} />} />
        <Route path="/whirlpool-ro-service-Chitrakoot" element={<UttarPradeshWhirlpool title={locdata.Chitrakoot.title} number={locdata.Chitrakoot.number} />} />
        <Route path="/whirlpool-ro-service-Deoria" element={<UttarPradeshWhirlpool title={locdata.Deoria.title} number={locdata.Deoria.number} />} />
        <Route path="/whirlpool-ro-service-Etah" element={<UttarPradeshWhirlpool title={locdata.Etah.title} number={locdata.Etah.number} />} />
        <Route path="/whirlpool-ro-service-Etawah" element={<UttarPradeshWhirlpool title={locdata.Etawah.title} number={locdata.Etawah.number} />} />
        <Route path="/whirlpool-ro-service-Farrukhabad" element={<UttarPradeshWhirlpool title={locdata.Farrukhabad.title} number={locdata.Farrukhabad.number} />} />
        <Route path="/whirlpool-ro-service-Fatehpur" element={<UttarPradeshWhirlpool title={locdata.Fatehpur.title} number={locdata.Fatehpur.number} />} />
        <Route path="/whirlpool-ro-service-Firozabad" element={<UttarPradeshWhirlpool title={locdata.Firozabad.title} number={locdata.Firozabad.number} />} />
        <Route path="/whirlpool-ro-service-Gautam-Buddha-Nagar" element={<UttarPradeshWhirlpool title={locdata.GautamBuddhaNagar.title} number={locdata.GautamBuddhaNagar.number} />} />
        <Route path="/whirlpool-ro-service-Ghaziabad" element={<UttarPradeshWhirlpool title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/whirlpool-ro-service-Ghazipur" element={<UttarPradeshWhirlpool title={locdata.Ghazipur.title} number={locdata.Ghazipur.number} />} />
        <Route path="/whirlpool-ro-service-Gonda" element={<UttarPradeshWhirlpool title={locdata.Gonda.title} number={locdata.Gonda.number} />} />
        <Route path="/whirlpool-ro-service-Gorakhpur" element={<UttarPradeshWhirlpool title={locdata.Gorakhpur.title} number={locdata.Gorakhpur.number} />} />
        <Route path="/whirlpool-ro-service-Hamirpur" element={<UttarPradeshWhirlpool title={locdata.Hamirpur.title} number={locdata.Hamirpur.number} />} />
        <Route path="/whirlpool-ro-service-Hapur" element={<UttarPradeshWhirlpool title={locdata.Hapur.title} number={locdata.Hapur.number} />} />
        <Route path="/whirlpool-ro-service-Hardoi" element={<UttarPradeshWhirlpool title={locdata.Hardoi.title} number={locdata.Hardoi.number} />} />
        <Route path="/whirlpool-ro-service-Hathras" element={<UttarPradeshWhirlpool title={locdata.Hathras.title} number={locdata.Hathras.number} />} />
        <Route path="/whirlpool-ro-service-Jalaun" element={<UttarPradeshWhirlpool title={locdata.Jalaun.title} number={locdata.Jalaun.number} />} />
        <Route path="/whirlpool-ro-service-Jaunpur" element={<UttarPradeshWhirlpool title={locdata.Jaunpur.title} number={locdata.Jaunpur.number} />} />
        <Route path="/whirlpool-ro-service-Jhansi" element={<UttarPradeshWhirlpool title={locdata.Jhansi.title} number={locdata.Jhansi.number} />} />
        <Route path="/whirlpool-ro-service-Kannauj" element={<UttarPradeshWhirlpool title={locdata.Kannauj.title} number={locdata.Kannauj.number} />} />
        <Route path="/whirlpool-ro-service-Kanpur-Dehat" element={<UttarPradeshWhirlpool title={locdata.KanpurDehat.title} number={locdata.KanpurDehat.number} />} />
        <Route path="/whirlpool-ro-service-Kanpur" element={<UttarPradeshWhirlpool title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/whirlpool-ro-service-Kasganj" element={<UttarPradeshWhirlpool title={locdata.Kasganj.title} number={locdata.Kasganj.number} />} />
        <Route path="/whirlpool-ro-service-Kaushambi" element={<UttarPradeshWhirlpool title={locdata.Kaushambi.title} number={locdata.Kaushambi.number} />} />
        <Route path="/whirlpool-ro-service-Kushinagar" element={<UttarPradeshWhirlpool title={locdata.Kushinagar.title} number={locdata.Kushinagar.number} />} />
        <Route path="/whirlpool-ro-service-Lakhimpur-Kheri" element={<UttarPradeshWhirlpool title={locdata.LakhimpurKheri.title} number={locdata.LakhimpurKheri.number} />} />
        <Route path="/whirlpool-ro-service-Lalitpur" element={<UttarPradeshWhirlpool title={locdata.Lalitpur.title} number={locdata.Lalitpur.number} />} />
        <Route path="/whirlpool-ro-service-Lucknow" element={<UttarPradeshWhirlpool title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/whirlpool-ro-service-Maharajganj" element={<UttarPradeshWhirlpool title={locdata.Maharajganj.title} number={locdata.Maharajganj.number} />} />
        <Route path="/whirlpool-ro-service-Mahoba" element={<UttarPradeshWhirlpool title={locdata.Mahoba.title} number={locdata.Mahoba.number} />} />
        <Route path="/whirlpool-ro-service-Mainpuri" element={<UttarPradeshWhirlpool title={locdata.Mainpuri.title} number={locdata.Mainpuri.number} />} />
        <Route path="/whirlpool-ro-service-Mathura" element={<UttarPradeshWhirlpool title={locdata.Mathura.title} number={locdata.Mathura.number} />} />
        <Route path="/whirlpool-ro-service-Mau" element={<UttarPradeshWhirlpool title={locdata.Mau.title} number={locdata.Mau.number} />} />
        <Route path="/whirlpool-ro-service-Meerut" element={<UttarPradeshWhirlpool title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/whirlpool-ro-service-Mirzapur" element={<UttarPradeshWhirlpool title={locdata.Mirzapur.title} number={locdata.Mirzapur.number} />} />
        <Route path="/whirlpool-ro-service-Moradabad" element={<UttarPradeshWhirlpool title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/whirlpool-ro-service-Muzaffarnagar" element={<UttarPradeshWhirlpool title={locdata.Muzaffarnagar.title} number={locdata.Muzaffarnagar.number} />} />
        <Route path="/whirlpool-ro-service-Pilibhit" element={<UttarPradeshWhirlpool title={locdata.Pilibhit.title} number={locdata.Pilibhit.number} />} />
        <Route path="/whirlpool-ro-service-Pratapgarh" element={<UttarPradeshWhirlpool title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/whirlpool-ro-service-Prayagraj" element={<UttarPradeshWhirlpool title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/whirlpool-ro-service-Rae-Bareli" element={<UttarPradeshWhirlpool title={locdata.RaeBareli.title} number={locdata.RaeBareli.number} />} />
        <Route path="/whirlpool-ro-service-Rampur" element={<UttarPradeshWhirlpool title={locdata.Rampur.title} number={locdata.Rampur.number} />} />
        <Route path="/whirlpool-ro-service-Saharanpur" element={<UttarPradeshWhirlpool title={locdata.Saharanpur.title} number={locdata.Saharanpur.number} />} />
        <Route path="/whirlpool-ro-service-Sant-Kabir-Nagar" element={<UttarPradeshWhirlpool title={locdata.SantKabirNagar.title} number={locdata.SantKabirNagar.number} />} />
        <Route path="/whirlpool-ro-service-Bhadohi" element={<UttarPradeshWhirlpool title={locdata.Bhadohi.title} number={locdata.Bhadohi.number} />} />
        <Route path="/whirlpool-ro-service-Sambhal" element={<UttarPradeshWhirlpool title={locdata.Sambhal.title} number={locdata.Sambhal.number} />} />
        <Route path="/whirlpool-ro-service-Shahjahanpur" element={<UttarPradeshWhirlpool title={locdata.Shahjahanpur.title} number={locdata.Shahjahanpur.number} />} />
        <Route path="/whirlpool-ro-service-Shamli" element={<UttarPradeshWhirlpool title={locdata.Shamli.title} number={locdata.Shamli.number} />} />
        <Route path="/whirlpool-ro-service-Shravasti" element={<UttarPradeshWhirlpool title={locdata.Shravasti.title} number={locdata.Shravasti.number} />} />
        <Route path="/whirlpool-ro-service-Siddharthnagar" element={<UttarPradeshWhirlpool title={locdata.Siddharthnagar.title} number={locdata.Siddharthnagar.number} />} />
        <Route path="/whirlpool-ro-service-Sitapur" element={<UttarPradeshWhirlpool title={locdata.Sitapur.title} number={locdata.Sitapur.number} />} />
        <Route path="/whirlpool-ro-service-Sonbhadra" element={<UttarPradeshWhirlpool title={locdata.Sonbhadra.title} number={locdata.Sonbhadra.number} />} />
        <Route path="/whirlpool-ro-service-Sultanpur" element={<UttarPradeshWhirlpool title={locdata.Sultanpur.title} number={locdata.Sultanpur.number} />} />
        <Route path="/whirlpool-ro-service-Unnao" element={<UttarPradeshWhirlpool title={locdata.Unnao.title} number={locdata.Unnao.number} />} />
        <Route path="/whirlpool-ro-service-Varanasi" element={<UttarPradeshWhirlpool title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/whirlpool-ro-service-Robertsganj" element={<UttarPradeshWhirlpool title={locdata.Robertsganj.title} number={locdata.Robertsganj.number} />} />
        <Route path="/whirlpool-ro-service-Bahjoi" element={<UttarPradeshWhirlpool title={locdata.Bahjoi.title} number={locdata.Bahjoi.number} />} />
        <Route path="/whirlpool-ro-service-Khalilabad" element={<UttarPradeshWhirlpool title={locdata.Khalilabad.title} number={locdata.Khalilabad.number} />} />
        <Route path="/whirlpool-ro-service-Akbarpur" element={<UttarPradeshWhirlpool title={locdata.Akbarpur.title} number={locdata.Akbarpur.number} />} />
        <Route path="/whirlpool-ro-service-Orai" element={<UttarPradeshWhirlpool title={locdata.Orai.title} number={locdata.Orai.number} />} />
        <Route path="/whirlpool-ro-service-Manjhanpur" element={<UttarPradeshWhirlpool title={locdata.Manjhanpur.title} number={locdata.Manjhanpur.number} />} />
        <Route path="/whirlpool-ro-service-Padarauna" element={<UttarPradeshWhirlpool title={locdata.Padarauna.title} number={locdata.Padarauna.number} />} />
        <Route path="/whirlpool-ro-service-Kheri" element={<UttarPradeshWhirlpool title={locdata.Kheri.title} number={locdata.Kheri.number} />} />
        <Route path="/whirlpool-ro-service-Fatehgarh" element={<UttarPradeshWhirlpool title={locdata.Fatehgarh.title} number={locdata.Fatehgarh.number} />} />
        <Route path="/whirlpool-ro-service-Noida" element={<UttarPradeshWhirlpool title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/whirlpool-ro-service-Greater-Noida" element={<UttarPradeshWhirlpool title={locdata.GreaterNoida.title} number={locdata.GreaterNoida.number} />} />

        <Route path="/whirlpool-ro-service-Indore" element={<MadhyaPradeshWhirlpool title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/whirlpool-ro-service-Bhopal" element={<MadhyaPradeshWhirlpool title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/whirlpool-ro-service-Jabalpur" element={<MadhyaPradeshWhirlpool title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/whirlpool-ro-service-Gwalior" element={<MadhyaPradeshWhirlpool title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/whirlpool-ro-service-Ujjain" element={<MadhyaPradeshWhirlpool title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/whirlpool-ro-service-Sagar" element={<MadhyaPradeshWhirlpool title={locdata.Sagar.title} number={locdata.Sagar.number} />} />
        <Route path="/whirlpool-ro-service-Dewas" element={<MadhyaPradeshWhirlpool title={locdata.Dewas.title} number={locdata.Dewas.number} />} />
        <Route path="/whirlpool-ro-service-Satna" element={<MadhyaPradeshWhirlpool title={locdata.Satna.title} number={locdata.Satna.number} />} />
        <Route path="/whirlpool-ro-service-Ratlam" element={<MadhyaPradeshWhirlpool title={locdata.Ratlam.title} number={locdata.Ratlam.number} />} />
        <Route path="/whirlpool-ro-service-Rewa" element={<MadhyaPradeshWhirlpool title={locdata.Rewa.title} number={locdata.Rewa.number} />} />
        <Route path="/whirlpool-ro-service-Katni" element={<MadhyaPradeshWhirlpool title={locdata.Katni.title} number={locdata.Katni.number} />} />
        <Route path="/whirlpool-ro-service-Singrauli" element={<MadhyaPradeshWhirlpool title={locdata.Singrauli.title} number={locdata.Singrauli.number} />} />
        <Route path="/whirlpool-ro-service-Burhanpur" element={<MadhyaPradeshWhirlpool title={locdata.Burhanpur.title} number={locdata.Burhanpur.number} />} />
        <Route path="/whirlpool-ro-service-Morena" element={<MadhyaPradeshWhirlpool title={locdata.Morena.title} number={locdata.Morena.number} />} />
        <Route path="/whirlpool-ro-service-Khandwa" element={<MadhyaPradeshWhirlpool title={locdata.Khandwa.title} number={locdata.Khandwa.number} />} />
        <Route path="/whirlpool-ro-service-Bhind" element={<MadhyaPradeshWhirlpool title={locdata.Bhind.title} number={locdata.Bhind.number} />} />
        <Route path="/whirlpool-ro-service-Chhindwara" element={<MadhyaPradeshWhirlpool title={locdata.Chhindwara.title} number={locdata.Chhindwara.number} />} />
        <Route path="/whirlpool-ro-service-Guna" element={<MadhyaPradeshWhirlpool title={locdata.Guna.title} number={locdata.Guna.number} />} />
        <Route path="/whirlpool-ro-service-Shivpuri" element={<MadhyaPradeshWhirlpool title={locdata.Shivpuri.title} number={locdata.Shivpuri.number} />} />
        <Route path="/whirlpool-ro-service-Vidisha" element={<MadhyaPradeshWhirlpool title={locdata.Vidisha.title} number={locdata.Vidisha.number} />} />
        <Route path="/whirlpool-ro-service-Chhatarpur" element={<MadhyaPradeshWhirlpool title={locdata.Chhatarpur.title} number={locdata.Chhatarpur.number} />} />
        <Route path="/whirlpool-ro-service-Damoh" element={<MadhyaPradeshWhirlpool title={locdata.Damoh.title} number={locdata.Damoh.number} />} />
        <Route path="/whirlpool-ro-service-Mandsaur" element={<MadhyaPradeshWhirlpool title={locdata.Mandsaur.title} number={locdata.Mandsaur.number} />} />
        <Route path="/whirlpool-ro-service-Khargone" element={<MadhyaPradeshWhirlpool title={locdata.Khargone.title} number={locdata.Khargone.number} />} />
        <Route path="/whirlpool-ro-service-Neemuch" element={<MadhyaPradeshWhirlpool title={locdata.Neemuch.title} number={locdata.Neemuch.number} />} />
        <Route path="/whirlpool-ro-service-Pithampur" element={<MadhyaPradeshWhirlpool title={locdata.Pithampur.title} number={locdata.Pithampur.number} />} />
        <Route path="/whirlpool-ro-service-Narmadapuram" element={<MadhyaPradeshWhirlpool title={locdata.Narmadapuram.title} number={locdata.Narmadapuram.number} />} />
        <Route path="/whirlpool-ro-service-Itarsi" element={<MadhyaPradeshWhirlpool title={locdata.Itarsi.title} number={locdata.Itarsi.number} />} />
        <Route path="/whirlpool-ro-service-Sehore" element={<MadhyaPradeshWhirlpool title={locdata.Sehore.title} number={locdata.Sehore.number} />} />
        <Route path="/whirlpool-ro-service-Betul" element={<MadhyaPradeshWhirlpool title={locdata.Betul.title} number={locdata.Betul.number} />} />
        <Route path="/whirlpool-ro-service-Seoni" element={<MadhyaPradeshWhirlpool title={locdata.Seoni.title} number={locdata.Seoni.number} />} />
        <Route path="/whirlpool-ro-service-Datia" element={<MadhyaPradeshWhirlpool title={locdata.Datia.title} number={locdata.Datia.number} />} />
        <Route path="/whirlpool-ro-service-Nagda" element={<MadhyaPradeshWhirlpool title={locdata.Nagda.title} number={locdata.Nagda.number} />} />
        <Route path="/whirlpool-ro-service-Shahdol" element={<MadhyaPradeshWhirlpool title={locdata.Shahdol.title} number={locdata.Shahdol.number} />} />
        <Route path="/whirlpool-ro-service-Dhar" element={<MadhyaPradeshWhirlpool title={locdata.Dhar.title} number={locdata.Dhar.number} />} />

        <Route path="/whirlpool-ro-service-Ajmer" element={<RajasthanWhirlpool title={locdata.Ajmer.title} number={locdata.Ajmer.number} />} />
        <Route path="/whirlpool-ro-service-Alwar" element={<RajasthanWhirlpool title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/whirlpool-ro-service-Balotra" element={<RajasthanWhirlpool title={locdata.Balotra.title} number={locdata.Balotra.number} />} />
        <Route path="/whirlpool-ro-service-Banswara" element={<RajasthanWhirlpool title={locdata.Banswara.title} number={locdata.Banswara.number} />} />
        <Route path="/whirlpool-ro-service-Baran" element={<RajasthanWhirlpool title={locdata.Baran.title} number={locdata.Baran.number} />} />
        <Route path="/whirlpool-ro-service-Barmer" element={<RajasthanWhirlpool title={locdata.Barmer.title} number={locdata.Barmer.number} />} />
        <Route path="/whirlpool-ro-service-Beawar" element={<RajasthanWhirlpool title={locdata.Beawar.title} number={locdata.Beawar.number} />} />
        <Route path="/whirlpool-ro-service-Bharatpur" element={<RajasthanWhirlpool title={locdata.Bharatpur.title} number={locdata.Bharatpur.number} />} />
        <Route path="/whirlpool-ro-service-Bhilwara" element={<RajasthanWhirlpool title={locdata.Bhilwara.title} number={locdata.Bhilwara.number} />} />
        <Route path="/whirlpool-ro-service-Bikaner" element={<RajasthanWhirlpool title={locdata.Bikaner.title} number={locdata.Bikaner.number} />} />
        <Route path="/whirlpool-ro-service-Bundi" element={<RajasthanWhirlpool title={locdata.Bundi.title} number={locdata.Bundi.number} />} />
        <Route path="/whirlpool-ro-service-Chittorgarh" element={<RajasthanWhirlpool title={locdata.Chittorgarh.title} number={locdata.Chittorgarh.number} />} />
        <Route path="/whirlpool-ro-service-Churu" element={<RajasthanWhirlpool title={locdata.Churu.title} number={locdata.Churu.number} />} />
        <Route path="/whirlpool-ro-service-Dausa" element={<RajasthanWhirlpool title={locdata.Dausa.title} number={locdata.Dausa.number} />} />
        <Route path="/whirlpool-ro-service-Deeg" element={<RajasthanWhirlpool title={locdata.Deeg.title} number={locdata.Deeg.number} />} />
        <Route path="/whirlpool-ro-service-Deedwana" element={<RajasthanWhirlpool title={locdata.Deedwana.title} number={locdata.Deedwana.number} />} />
        <Route path="/whirlpool-ro-service-Dholpur" element={<RajasthanWhirlpool title={locdata.Dholpur.title} number={locdata.Dholpur.number} />} />
        <Route path="/whirlpool-ro-service-Dungarpur" element={<RajasthanWhirlpool title={locdata.Dungarpur.title} number={locdata.Dungarpur.number} />} />
        <Route path="/whirlpool-ro-service-Hanumangarh" element={<RajasthanWhirlpool title={locdata.Hanumangarh.title} number={locdata.Hanumangarh.number} />} />
        <Route path="/whirlpool-ro-service-Jaipur" element={<RajasthanWhirlpool title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/whirlpool-ro-service-Jaisalmer" element={<RajasthanWhirlpool title={locdata.Jaisalmer.title} number={locdata.Jaisalmer.number} />} />
        <Route path="/whirlpool-ro-service-Jalore" element={<RajasthanWhirlpool title={locdata.Jalore.title} number={locdata.Jalore.number} />} />
        <Route path="/whirlpool-ro-service-Jhalawar" element={<RajasthanWhirlpool title={locdata.Jhalawar.title} number={locdata.Jhalawar.number} />} />
        <Route path="/whirlpool-ro-service-Jhunjhunu" element={<RajasthanWhirlpool title={locdata.Jhunjhunu.title} number={locdata.Jhunjhunu.number} />} />
        <Route path="/whirlpool-ro-service-Jodhpur" element={<RajasthanWhirlpool title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/whirlpool-ro-service-Karauli" element={<RajasthanWhirlpool title={locdata.Karauli.title} number={locdata.Karauli.number} />} />
        <Route path="/whirlpool-ro-service-Tijara" element={<RajasthanWhirlpool title={locdata.Tijara.title} number={locdata.Tijara.number} />} />
        <Route path="/whirlpool-ro-service-Kotputli" element={<RajasthanWhirlpool title={locdata.Kotputli.title} number={locdata.Kotputli.number} />} />
        <Route path="/whirlpool-ro-service-Kota" element={<RajasthanWhirlpool title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/whirlpool-ro-service-Nagaur" element={<RajasthanWhirlpool title={locdata.Nagaur.title} number={locdata.Nagaur.number} />} />
        <Route path="/whirlpool-ro-service-Pali" element={<RajasthanWhirlpool title={locdata.Pali.title} number={locdata.Pali.number} />} />
        <Route path="/whirlpool-ro-service-Phalodi" element={<RajasthanWhirlpool title={locdata.Phalodi.title} number={locdata.Phalodi.number} />} />
        <Route path="/whirlpool-ro-service-Pratapgarh" element={<RajasthanWhirlpool title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/whirlpool-ro-service-Rajsamand" element={<RajasthanWhirlpool title={locdata.Rajsamand.title} number={locdata.Rajsamand.number} />} />
        <Route path="/whirlpool-ro-service-Salumbar" element={<RajasthanWhirlpool title={locdata.Salumbar.title} number={locdata.Salumbar.number} />} />
        <Route path="/whirlpool-ro-service-Sawai-Madhopur" element={<RajasthanWhirlpool title={locdata.SawaiMadhopur.title} number={locdata.SawaiMadhopur.number} />} />
        <Route path="/whirlpool-ro-service-Sikar" element={<RajasthanWhirlpool title={locdata.Sikar.title} number={locdata.Sikar.number} />} />
        <Route path="/whirlpool-ro-service-Sirohi" element={<RajasthanWhirlpool title={locdata.Sirohi.title} number={locdata.Sirohi.number} />} />
        <Route path="/whirlpool-ro-service-Sri-Ganganagar" element={<RajasthanWhirlpool title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/whirlpool-ro-service-Tonk" element={<RajasthanWhirlpool title={locdata.Tonk.title} number={locdata.Tonk.number} />} />
        <Route path="/whirlpool-ro-service-Udaipur" element={<RajasthanWhirlpool title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />

        <Route path="/whirlpool-ro-service-Mumbai" element={<Whirlpoolro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/whirlpool-ro-service-Delhi" element={<Whirlpoolro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/whirlpool-ro-service-Bengaluru" element={<Whirlpoolro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/whirlpool-ro-service-Hyderabad" element={<Whirlpoolro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/whirlpool-ro-service-Ahmedabad" element={<Whirlpoolro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/whirlpool-ro-service-Chennai" element={<Whirlpoolro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/whirlpool-ro-service-Surat" element={<Whirlpoolro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/whirlpool-ro-service-Pune" element={<Whirlpoolro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/whirlpool-ro-service-Jaipur" element={<Whirlpoolro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/whirlpool-ro-service-Lucknow" element={<Whirlpoolro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/whirlpool-ro-service-Kanpur" element={<Whirlpoolro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/whirlpool-ro-service-Nagpur" element={<Whirlpoolro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/whirlpool-ro-service-Indore" element={<Whirlpoolro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/whirlpool-ro-service-Thane" element={<Whirlpoolro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/whirlpool-ro-service-Bhopal" element={<Whirlpoolro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/whirlpool-ro-service-Visakhapatnam" element={<Whirlpoolro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/whirlpool-ro-service-Vadodara" element={<Whirlpoolro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/whirlpool-ro-service-Ghaziabad" element={<Whirlpoolro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/whirlpool-ro-service-Ludhiana" element={<Whirlpoolro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/whirlpool-ro-service-Agra" element={<Whirlpoolro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/whirlpool-ro-service-Nashik" element={<Whirlpoolro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/whirlpool-ro-service-Faridabad" element={<Whirlpoolro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/whirlpool-ro-service-Meerut" element={<Whirlpoolro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/whirlpool-ro-service-Rajkot" element={<Whirlpoolro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/whirlpool-ro-service-Varanasi" element={<Whirlpoolro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/whirlpool-ro-service-Srinagar" element={<Whirlpoolro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/whirlpool-ro-service-Amritsar" element={<Whirlpoolro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/whirlpool-ro-service-Navi-Mumbai" element={<Whirlpoolro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/whirlpool-ro-service-Prayagraj" element={<Whirlpoolro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/whirlpool-ro-service-Jabalpur" element={<Whirlpoolro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/whirlpool-ro-service-Gwalior" element={<Whirlpoolro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/whirlpool-ro-service-Coimbatore" element={<Whirlpoolro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/whirlpool-ro-service-Vijayawada" element={<Whirlpoolro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/whirlpool-ro-service-Jodhpur" element={<Whirlpoolro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/whirlpool-ro-service-Madurai" element={<Whirlpoolro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/whirlpool-ro-service-Raipur" element={<Whirlpoolro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/whirlpool-ro-service-Kota" element={<Whirlpoolro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/whirlpool-ro-service-Guwahati" element={<Whirlpoolro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/whirlpool-ro-service-Chandigarh" element={<Whirlpoolro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/whirlpool-ro-service-Thiruvananthapuram" element={<Whirlpoolro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/whirlpool-ro-service-Solapur" element={<Whirlpoolro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/whirlpool-ro-service-Tiruppur" element={<Whirlpoolro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/whirlpool-ro-service-Moradabad" element={<Whirlpoolro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/whirlpool-ro-service-Mysore" element={<Whirlpoolro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/whirlpool-ro-service-Gurgaon" element={<Whirlpoolro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/whirlpool-ro-service-Aligarh" element={<Whirlpoolro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/whirlpool-ro-service-Jalandhar" element={<Whirlpoolro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/whirlpool-ro-service-Bhubaneswar" element={<Whirlpoolro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/whirlpool-ro-service-Noida" element={<Whirlpoolro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/whirlpool-ro-service-Jamshedpur" element={<Whirlpoolro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/whirlpool-ro-service-Cuttack" element={<Whirlpoolro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/whirlpool-ro-service-Bhavnagar" element={<Whirlpoolro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/whirlpool-ro-service-Dehradun" element={<Whirlpoolro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/whirlpool-ro-service-Rourkela" element={<Whirlpoolro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/whirlpool-ro-service-Jamnagar" element={<Whirlpoolro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/whirlpool-ro-service-Ujjain" element={<Whirlpoolro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/whirlpool-ro-service-Jammu" element={<Whirlpoolro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/whirlpool-ro-service-Mangalore" element={<Whirlpoolro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/whirlpool-ro-service-Erode" element={<Whirlpoolro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/whirlpool-ro-service-Udaipur" element={<Whirlpoolro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/whirlpool-ro-service-Rajahmundry" element={<Whirlpoolro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/whirlpool-ro-service-Patiala" element={<Whirlpoolro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/whirlpool-ro-service-Agartala" element={<Whirlpoolro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/whirlpool-ro-service-Tirupati" element={<Whirlpoolro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/whirlpool-ro-service-Sambalpur" element={<Whirlpoolro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/whirlpool-ro-service-Bilaspur" element={<Whirlpoolro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/whirlpool-ro-service-Alwar" element={<Whirlpoolro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/whirlpool-ro-service-Bardhaman" element={<Whirlpoolro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/whirlpool-ro-service-Sonipat" element={<Whirlpoolro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/whirlpool-ro-service-New-Delhi" element={<Whirlpoolro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/whirlpool-ro-service-Puducherry" element={<Whirlpoolro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/whirlpool-ro-service-Haridwar" element={<Whirlpoolro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/whirlpool-ro-service-Vijayanagaram" element={<Whirlpoolro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/whirlpool-ro-service-Sri-Ganganagar" element={<Whirlpoolro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/whirlpool-ro-service-Secunderabad" element={<Whirlpoolro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/whirlpool-ro-service-Yamunanagar" element={<Whirlpoolro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/whirlpool-ro-service-Gandhinagar" element={<Whirlpoolro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/whirlpool-ro-service-Ambala" element={<Whirlpoolro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/whirlpool-ro-service-Vellore" element={<Whirlpoolro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/whirlpool-ro-service-Shimla" element={<Whirlpoolro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/whirlpool-ro-service-Amaravati" element={<Whirlpoolro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />

        <Route path="/ro-service-AJCBoseroad" element={<Bengal title={locdata.AJCBoseroad.title} number={locdata.AJCBoseroad.number} />} />
        <Route path="/ro-service-AbinashChaowdhurylane" element={<Bengal title={locdata.AbinashChaowdhurylane.title} number={locdata.AbinashChaowdhurylane.number} />} />
        <Route path="/ro-service-Alipore" element={<Bengal title={locdata.Alipore.title} number={locdata.Alipore.number} />} />
        <Route path="/ro-service-AsylumLane" element={<Bengal title={locdata.AsylumLane.title} number={locdata.AsylumLane.number} />} />
        <Route path="/ro-service-Badartala" element={<Bengal title={locdata.Badartala.title} number={locdata.Badartala.number} />} />
        <Route path="/ro-service-Baghajatin" element={<Bengal title={locdata.Baghajatin.title} number={locdata.Baghajatin.number} />} />
        <Route path="/ro-service-BakeryRoad" element={<Bengal title={locdata.BakeryRoad.title} number={locdata.BakeryRoad.number} />} />
        <Route path="/ro-service-Ballygunge" element={<Bengal title={locdata.Ballygunge.title} number={locdata.Ballygunge.number} />} />
        <Route path="/ro-service-Bamboovila" element={<Bengal title={locdata.Bamboovila.title} number={locdata.Bamboovila.number} />} />
        <Route path="/ro-service-Barabazar" element={<Bengal title={locdata.Barabazar.title} number={locdata.Barabazar.number} />} />
        <Route path="/ro-service-Barisha" element={<Bengal title={locdata.Barisha.title} number={locdata.Barisha.number} />} />
        <Route path="/ro-service-Bartala" element={<Bengal title={locdata.Bartala.title} number={locdata.Bartala.number} />} />
        <Route path="/ro-service-Behala" element={<Bengal title={locdata.Behala.title} number={locdata.Behala.number} />} />
        <Route path="/ro-service-Beleghata" element={<Bengal title={locdata.Beleghata.title} number={locdata.Beleghata.number} />} />
        <Route path="/ro-service-BengalChemical" element={<Bengal title={locdata.BengalChemical.title} number={locdata.BengalChemical.number} />} />
        <Route path="/ro-service-Bhawanipore" element={<Bengal title={locdata.Bhawanipore.title} number={locdata.Bhawanipore.number} />} />
        <Route path="/ro-service-Bidhangarh" element={<Bengal title={locdata.Bidhangarh.title} number={locdata.Bidhangarh.number} />} />
        <Route path="/ro-service-Bijoygarh" element={<Bengal title={locdata.Bijoygarh.title} number={locdata.Bijoygarh.number} />} />
        <Route path="/ro-service-BosepukurRoad" element={<Bengal title={locdata.BosepukurRoad.title} number={locdata.BosepukurRoad.number} />} />
        <Route path="/ro-service-Bowbazar" element={<Bengal title={locdata.Bowbazar.title} number={locdata.Bowbazar.number} />} />
        <Route path="/ro-service-BraceBridge" element={<Bengal title={locdata.BraceBridge.title} number={locdata.BraceBridge.number} />} />
        <Route path="/ro-service-BrahmaSamajroad" element={<Bengal title={locdata.BrahmaSamajroad.title} number={locdata.BrahmaSamajroad.number} />} />
        <Route path="/ro-service-Chetla" element={<Bengal title={locdata.Chetla.title} number={locdata.Chetla.number} />} />
        <Route path="/ro-service-ChittaranjanAvenue" element={<Bengal title={locdata.ChittaranjanAvenue.title} number={locdata.ChittaranjanAvenue.number} />} />
        <Route path="/ro-service-CircusAvenue" element={<Bengal title={locdata.CircusAvenue.title} number={locdata.CircusAvenue.number} />} />
        <Route path="/ro-service-CmdaAbasan" element={<Bengal title={locdata.CmdaAbasan.title} number={locdata.CmdaAbasan.number} />} />
        <Route path="/ro-service-CollegeSquare" element={<Bengal title={locdata.CollegeSquare.title} number={locdata.CollegeSquare.number} />} />
        <Route path="/ro-service-Colootola" element={<Bengal title={locdata.Colootola.title} number={locdata.Colootola.number} />} />
        <Route path="/ro-service-CouncilHousestreet" element={<Bengal title={locdata.CouncilHousestreet.title} number={locdata.CouncilHousestreet.number} />} />
        <Route path="/ro-service-CustomsHouse" element={<Bengal title={locdata.CustomsHouse.title} number={locdata.CustomsHouse.number} />} />
        <Route path="/ro-service-Daulatpur" element={<Bengal title={locdata.Daulatpur.title} number={locdata.Daulatpur.number} />} />
        <Route path="/ro-service-Dhakuria" element={<Bengal title={locdata.Dhakuria.title} number={locdata.Dhakuria.number} />} />
        <Route path="/ro-service-Dharmatala" element={<Bengal title={locdata.Dharmatala.title} number={locdata.Dharmatala.number} />} />
        <Route path="/ro-service-DoverLane" element={<Bengal title={locdata.DoverLane.title} number={locdata.DoverLane.number} />} />
        <Route path="/ro-service-EKt" element={<Bengal title={locdata.EKttitle} number={locdata.EKtnumber} />} />
        <Route path="/ro-service-ElliotRoad" element={<Bengal title={locdata.ElliotRoad.title} number={locdata.ElliotRoad.number} />} />
        <Route path="/ro-service-Esplanade" element={<Bengal title={locdata.Esplanade.title} number={locdata.Esplanade.number} />} />
        <Route path="/ro-service-FortWilliam" element={<Bengal title={locdata.FortWilliam.title} number={locdata.FortWilliam.number} />} />
        <Route path="/ro-service-GangulyBagan" element={<Bengal title={locdata.GangulyBagan.title} number={locdata.GangulyBagan.number} />} />
        <Route path="/ro-service-GarchaRoad" element={<Bengal title={locdata.GarchaRoad.title} number={locdata.GarchaRoad.number} />} />
        <Route path="/ro-service-GardenReach" element={<Bengal title={locdata.GardenReach.title} number={locdata.GardenReach.number} />} />
        <Route path="/ro-service-Garfa" element={<Bengal title={locdata.Garfa.title} number={locdata.Garfa.number} />} />
        <Route path="/ro-service-GariaBt" element={<Bengal title={locdata.GariaBt.title} number={locdata.GariaBt.number} />} />
        <Route path="/ro-service-GariahatMarket" element={<Bengal title={locdata.GariahatMarket.title} number={locdata.GariahatMarket.number} />} />
        <Route path="/ro-service-GobindaKhatickroad" element={<Bengal title={locdata.GobindaKhatickroad.title} number={locdata.GobindaKhatickroad.number} />} />
        <Route path="/ro-service-GokhelRoad" element={<Bengal title={locdata.GokhelRoad.title} number={locdata.GokhelRoad.number} />} />
        <Route path="/ro-service-GolfGreen" element={<Bengal title={locdata.GolfGreen.title} number={locdata.GolfGreen.number} />} />
        <Route path="/ro-service-Golpark" element={<Bengal title={locdata.Golpark.title} number={locdata.Golpark.number} />} />
        <Route path="/ro-service-GreatEasternhotel" element={<Bengal title={locdata.GreatEasternhotel.title} number={locdata.GreatEasternhotel.number} />} />
        <Route path="/ro-service-Haltu" element={<Bengal title={locdata.Haltu.title} number={locdata.Haltu.number} />} />
        <Route path="/ro-service-Haridevpur" element={<Bengal title={locdata.Haridevpur.title} number={locdata.Haridevpur.number} />} />
        <Route path="/ro-service-Hastings" element={<Bengal title={locdata.Hastings.title} number={locdata.Hastings.number} />} />
        <Route path="/ro-service-HindustanBuilding" element={<Bengal title={locdata.HindustanBuilding.title} number={locdata.HindustanBuilding.number} />} />
        <Route path="/ro-service-HydeRoad" element={<Bengal title={locdata.HydeRoad.title} number={locdata.HydeRoad.number} />} />
        <Route path="/ro-service-IncomeTaxbuilding" element={<Bengal title={locdata.IncomeTaxbuilding.title} number={locdata.IncomeTaxbuilding.number} />} />
        <Route path="/ro-service-IndraniPark" element={<Bengal title={locdata.IndraniPark.title} number={locdata.IndraniPark.number} />} />
        <Route path="/ro-service-Intally" element={<Bengal title={locdata.Intally.title} number={locdata.Intally.number} />} />
        <Route path="/ro-service-Jadavgarh" element={<Bengal title={locdata.Jadavgarh.title} number={locdata.Jadavgarh.number} />} />
        <Route path="/ro-service-JadavpurUniversity" element={<Bengal title={locdata.JadavpurUniversity.title} number={locdata.JadavpurUniversity.number} />} />
        <Route path="/ro-service-Jairampur" element={<Bengal title={locdata.Jairampur.title} number={locdata.Jairampur.number} />} />
        <Route path="/ro-service-JayshreePark" element={<Bengal title={locdata.JayshreePark.title} number={locdata.JayshreePark.number} />} />
        <Route path="/ro-service-Jhowtala" element={<Bengal title={locdata.Jhowtala.title} number={locdata.Jhowtala.number} />} />
        <Route path="/ro-service-JodhpurPark" element={<Bengal title={locdata.JodhpurPark.title} number={locdata.JodhpurPark.number} />} />
        <Route path="/ro-service-Joramandir" element={<Bengal title={locdata.Joramandir.title} number={locdata.Joramandir.number} />} />
        <Route path="/ro-service-KCmills" element={<Bengal title={locdata.KCmills.title} number={locdata.KCmills.number} />} />
        <Route path="/ro-service-KGbosesarani" element={<Bengal title={locdata.KGbosesarani.title} number={locdata.KGbosesarani.number} />} />
        <Route path="/ro-service-KMhospital" element={<Bengal title={locdata.KMhospital.title} number={locdata.KMhospital.number} />} />
        <Route path="/ro-service-KPbazar" element={<Bengal title={locdata.KPbazar.title} number={locdata.KPbazar.number} />} />
        <Route path="/ro-service-KProylane" element={<Bengal title={locdata.KProylane.title} number={locdata.KProylane.number} />} />
        <Route path="/ro-service-Kalabagan" element={<Bengal title={locdata.Kalabagan.title} number={locdata.Kalabagan.number} />} />
        <Route path="/ro-service-Kalagachia" element={<Bengal title={locdata.Kalagachia.title} number={locdata.Kalagachia.number} />} />
        <Route path="/ro-service-Kalighat" element={<Bengal title={locdata.Kalighat.title} number={locdata.Kalighat.number} />} />
        <Route path="/ro-service-Kalikapur" element={<Bengal title={locdata.Kalikapur.title} number={locdata.Kalikapur.number} />} />
        <Route path="/ro-service-Kalimandir" element={<Bengal title={locdata.Kalimandir.title} number={locdata.Kalimandir.number} />} />
        <Route path="/ro-service-KalindiHousingestate" element={<Bengal title={locdata.KalindiHousingestate.title} number={locdata.KalindiHousingestate.number} />} />
        <Route path="/ro-service-Kankurgachi" element={<Bengal title={locdata.Kankurgachi.title} number={locdata.Kankurgachi.number} />} />
        <Route path="/ro-service-Kasba" element={<Bengal title={locdata.Kasba.title} number={locdata.Kasba.number} />} />
        <Route path="/ro-service-Kasthadanga" element={<Bengal title={locdata.Kasthadanga.title} number={locdata.Kasthadanga.number} />} />
        <Route path="/ro-service-Keoratala" element={<Bengal title={locdata.Keoratala.title} number={locdata.Keoratala.number} />} />
        <Route path="/ro-service-Khengrapatti" element={<Bengal title={locdata.Khengrapatti.title} number={locdata.Khengrapatti.number} />} />
        <Route path="/ro-service-Khiddirpore" element={<Bengal title={locdata.Khiddirpore.title} number={locdata.Khiddirpore.number} />} />
        <Route path="/ro-service-KolkataMint" element={<Bengal title={locdata.KolkataMint.title} number={locdata.KolkataMint.number} />} />
        <Route path="/ro-service-KolkataUniversity" element={<Bengal title={locdata.KolkataUniversity.title} number={locdata.KolkataUniversity.number} />} />
        <Route path="/ro-service-LRsarani" element={<Bengal title={locdata.LRsarani.title} number={locdata.LRsarani.number} />} />
        <Route path="/ro-service-LakeGardens" element={<Bengal title={locdata.LakeGardens.title} number={locdata.LakeGardens.number} />} />
        <Route path="/ro-service-LakeMarket" element={<Bengal title={locdata.LakeMarket.title} number={locdata.LakeMarket.number} />} />
        <Route path="/ro-service-Lalbazar" element={<Bengal title={locdata.Lalbazar.title} number={locdata.Lalbazar.number} />} />
        <Route path="/ro-service-LansdowneMarket" element={<Bengal title={locdata.LansdowneMarket.title} number={locdata.LansdowneMarket.number} />} />
        <Route path="/ro-service-LintonStreet" element={<Bengal title={locdata.LintonStreet.title} number={locdata.LintonStreet.number} />} />
        <Route path="/ro-service-LittleRusselstreet" element={<Bengal title={locdata.LittleRusselstreet.title} number={locdata.LittleRusselstreet.number} />} />
        <Route path="/ro-service-Madrassa" element={<Bengal title={locdata.Madrassa.title} number={locdata.Madrassa.number} />} />
        <Route path="/ro-service-MahendraBanerjeeroad" element={<Bengal title={locdata.MahendraBanerjeeroad.title} number={locdata.MahendraBanerjeeroad.number} />} />
        <Route path="/ro-service-Mansatala" element={<Bengal title={locdata.Mansatala.title} number={locdata.Mansatala.number} />} />
        <Route path="/ro-service-MemanpurChandannagar" element={<Bengal title={locdata.MemanpurChandannagar.title} number={locdata.MemanpurChandannagar.number} />} />
        <Route path="/ro-service-MiddletonRow" element={<Bengal title={locdata.MiddletonRow.title} number={locdata.MiddletonRow.number} />} />
        <Route path="/ro-service-Mominpur" element={<Bengal title={locdata.Mominpur.title} number={locdata.Mominpur.number} />} />
        <Route path="/ro-service-MottLane" element={<Bengal title={locdata.MottLane.title} number={locdata.MottLane.number} />} />
        <Route path="/ro-service-Mukundapur" element={<Bengal title={locdata.Mukundapur.title} number={locdata.Mukundapur.number} />} />
        <Route path="/ro-service-NRavenue" element={<Bengal title={locdata.NRavenue.title} number={locdata.NRavenue.number} />} />
        <Route path="/ro-service-Naktala" element={<Bengal title={locdata.Naktala.title} number={locdata.Naktala.number} />} />
        <Route path="/ro-service-Narkeldanga" element={<Bengal title={locdata.Narkeldanga.title} number={locdata.Narkeldanga.number} />} />
        <Route path="/ro-service-NatioinalLibrary" element={<Bengal title={locdata.NatioinalLibrary.title} number={locdata.NatioinalLibrary.number} />} />
        <Route path="/ro-service-NetajiNagar" element={<Bengal title={locdata.NetajiNagar.title} number={locdata.NetajiNagar.number} />} />
        <Route path="/ro-service-NewAlipore" element={<Bengal title={locdata.NewAlipore.title} number={locdata.NewAlipore.number} />} />
        <Route path="/ro-service-NewMarket" element={<Bengal title={locdata.NewMarket.title} number={locdata.NewMarket.number} />} />
        <Route path="/ro-service-NewSecretariatbldg" element={<Bengal title={locdata.NewSecretariatbldg.title} number={locdata.NewSecretariatbldg.number} />} />
        <Route path="/ro-service-PGreach" element={<Bengal title={locdata.PGreach.title} number={locdata.PGreach.number} />} />
        <Route path="/ro-service-Panchanantala" element={<Bengal title={locdata.Panchanantala.title} number={locdata.Panchanantala.number} />} />
        <Route path="/ro-service-Panchasayar" element={<Bengal title={locdata.Panchasayar.title} number={locdata.Panchasayar.number} />} />
        <Route path="/ro-service-ParkCircus" element={<Bengal title={locdata.ParkCircus.title} number={locdata.ParkCircus.number} />} />
        <Route path="/ro-service-ParkStreet" element={<Bengal title={locdata.ParkStreet.title} number={locdata.ParkStreet.number} />} />
        <Route path="/ro-service-ParnasreePally" element={<Bengal title={locdata.ParnasreePally.title} number={locdata.ParnasreePally.number} />} />
        <Route path="/ro-service-Parsibagan" element={<Bengal title={locdata.Parsibagan.title} number={locdata.Parsibagan.number} />} />
        <Route path="/ro-service-PaschimBarisha" element={<Bengal title={locdata.PaschimBarisha.title} number={locdata.PaschimBarisha.number} />} />
        <Route path="/ro-service-PaschimPutiari" element={<Bengal title={locdata.PaschimPutiari.title} number={locdata.PaschimPutiari.number} />} />
        <Route path="/ro-service-PghShahroad" element={<Bengal title={locdata.PghShahroad.title} number={locdata.PghShahroad.number} />} />
        <Route path="/ro-service-Phulbagan" element={<Bengal title={locdata.Phulbagan.title} number={locdata.Phulbagan.number} />} />
        <Route path="/ro-service-PrincepStreet" element={<Bengal title={locdata.PrincepStreet.title} number={locdata.PrincepStreet.number} />} />
        <Route path="/ro-service-RKsevapratisthan" element={<Bengal title={locdata.RKsevapratisthan.title} number={locdata.RKsevapratisthan.number} />} />
        <Route path="/ro-service-RNmukherjeeroad" element={<Bengal title={locdata.RNmukherjeeroad.title} number={locdata.RNmukherjeeroad.number} />} />
        <Route path="/ro-service-RabindraNagar" element={<Bengal title={locdata.RabindraNagar.title} number={locdata.RabindraNagar.number} />} />
        <Route path="/ro-service-RadhaBazar" element={<Bengal title={locdata.RadhaBazar.title} number={locdata.RadhaBazar.number} />} />
        <Route path="/ro-service-RaipurJorabaganroad" element={<Bengal title={locdata.RaipurJorabaganroad.title} number={locdata.RaipurJorabaganroad.number} />} />
        <Route path="/ro-service-RajaJnmarket" element={<Bengal title={locdata.RajaJnmarket.title} number={locdata.RajaJnmarket.number} />} />
        <Route path="/ro-service-RajaRammohansarani" element={<Bengal title={locdata.RajaRammohansarani.title} number={locdata.RajaRammohansarani.number} />} />
        <Route path="/ro-service-RajabaganDockyard" element={<Bengal title={locdata.RajabaganDockyard.title} number={locdata.RajabaganDockyard.number} />} />
        <Route path="/ro-service-RamKrishnasamadhiroad" element={<Bengal title={locdata.RamKrishnasamadhiroad.title} number={locdata.RamKrishnasamadhiroad.number} />} />
        <Route path="/ro-service-RamkrishnaPark" element={<Bengal title={locdata.RamkrishnaPark.title} number={locdata.RamkrishnaPark.number} />} />
        <Route path="/ro-service-RashBehariavenue" element={<Bengal title={locdata.RashBehariavenue.title} number={locdata.RashBehariavenue.number} />} />
        <Route path="/ro-service-RegentEstate" element={<Bengal title={locdata.RegentEstate.title} number={locdata.RegentEstate.number} />} />
        <Route path="/ro-service-RegentPark" element={<Bengal title={locdata.RegentPark.title} number={locdata.RegentPark.number} />} />
        <Route path="/ro-service-ReserveBankbuilding" element={<Bengal title={locdata.ReserveBankbuilding.title} number={locdata.ReserveBankbuilding.number} />} />
        <Route path="/ro-service-Russa" element={<Bengal title={locdata.Russa.title} number={locdata.Russa.number} />} />
        <Route path="/ro-service-RusselStreet" element={<Bengal title={locdata.RusselStreet.title} number={locdata.RusselStreet.number} />} />
        <Route path="/ro-service-SRFTI" element={<Bengal title={locdata.SRFTI.title} number={locdata.SRFTI.number} />} />
        <Route path="/ro-service-Sahanagar" element={<Bengal title={locdata.Sahanagar.title} number={locdata.Sahanagar.number} />} />
        <Route path="/ro-service-Sahapur" element={<Bengal title={locdata.Sahapur.title} number={locdata.Sahapur.number} />} />
        <Route path="/ro-service-SalesTaxbuilding" element={<Bengal title={locdata.SalesTaxbuilding.title} number={locdata.SalesTaxbuilding.number} />} />
        <Route path="/ro-service-Sankaritola" element={<Bengal title={locdata.Sankaritola.title} number={locdata.Sankaritola.number} />} />
        <Route path="/ro-service-Santoshpur" element={<Bengal title={locdata.Santoshpur.title} number={locdata.Santoshpur.number} />} />
        <Route path="/ro-service-SantoshpurAvenue" element={<Bengal title={locdata.SantoshpurAvenue.title} number={locdata.SantoshpurAvenue.number} />} />
        <Route path="/ro-service-SaratBoseroad" element={<Bengal title={locdata.SaratBoseroad.title} number={locdata.SaratBoseroad.number} />} />
        <Route path="/ro-service-Sarsoona" element={<Bengal title={locdata.Sarsoona.title} number={locdata.Sarsoona.number} />} />
        <Route path="/ro-service-SealLane" element={<Bengal title={locdata.SealLane.title} number={locdata.SealLane.number} />} />
        <Route path="/ro-service-Sealdah" element={<Bengal title={locdata.Sealdah.title} number={locdata.Sealdah.number} />} />
        <Route path="/ro-service-Senhati" element={<Bengal title={locdata.Senhati.title} number={locdata.Senhati.number} />} />
        <Route path="/ro-service-ShakespeareSarani" element={<Bengal title={locdata.ShakespeareSarani.title} number={locdata.ShakespeareSarani.number} />} />
        <Route path="/ro-service-Sirity" element={<Bengal title={locdata.Sirity.title} number={locdata.Sirity.number} />} />
        <Route path="/ro-service-Sonai" element={<Bengal title={locdata.Sonai.title} number={locdata.Sonai.number} />} />
        <Route path="/ro-service-SooterkinStreet" element={<Bengal title={locdata.SooterkinStreet.title} number={locdata.SooterkinStreet.number} />} />
        <Route path="/ro-service-SouthEasternrailway" element={<Bengal title={locdata.SouthEasternrailway.title} number={locdata.SouthEasternrailway.number} />} />
        <Route path="/ro-service-SouthernMarket" element={<Bengal title={locdata.SouthernMarket.title} number={locdata.SouthernMarket.number} />} />
        <Route path="/ro-service-StrandRoad" element={<Bengal title={locdata.StrandRoad.title} number={locdata.StrandRoad.number} />} />
        <Route path="/ro-service-SubhashSarabor" element={<Bengal title={locdata.SubhashSarabor.title} number={locdata.SubhashSarabor.number} />} />
        <Route path="/ro-service-TCroad" element={<Bengal title={locdata.TCroad.title} number={locdata.TCroad.number} />} />
        <Route path="/ro-service-TGroad" element={<Bengal title={locdata.TGroad.title} number={locdata.TGroad.number} />} />
        <Route path="/ro-service-Taltala" element={<Bengal title={locdata.Taltala.title} number={locdata.Taltala.number} />} />
        <Route path="/ro-service-Tangra" element={<Bengal title={locdata.Tangra.title} number={locdata.Tangra.number} />} />
        <Route path="/ro-service-TaratalaRoad" element={<Bengal title={locdata.TaratalaRoad.title} number={locdata.TaratalaRoad.number} />} />
        <Route path="/ro-service-TelecomFactory" element={<Bengal title={locdata.TelecomFactory.title} number={locdata.TelecomFactory.number} />} />
        <Route path="/ro-service-TelephoneBhawan" element={<Bengal title={locdata.TelephoneBhawan.title} number={locdata.TelephoneBhawan.number} />} />
        <Route path="/ro-service-Thakurpukur" element={<Bengal title={locdata.Thakurpukur.title} number={locdata.Thakurpukur.number} />} />
        <Route path="/ro-service-TirettaBazar" element={<Bengal title={locdata.TirettaBazar.title} number={locdata.TirettaBazar.number} />} />
        <Route path="/ro-service-Tollygunge" element={<Bengal title={locdata.Tollygunge.title} number={locdata.Tollygunge.number} />} />
        <Route path="/ro-service-TreasuryBuilding" element={<Bengal title={locdata.TreasuryBuilding.title} number={locdata.TreasuryBuilding.number} />} />
        <Route path="/ro-service-Viveknagar" element={<Bengal title={locdata.Viveknagar.title} number={locdata.Viveknagar.number} />} />
        <Route path="/ro-service-WBassemblyhouse" element={<Bengal title={locdata.WBassemblyhouse.title} number={locdata.WBassemblyhouse.number} />} />
        <Route path="/ro-service-WBgovernorscamp" element={<Bengal title={locdata.WBgovernorscamp.title} number={locdata.WBgovernorscamp.number} />} />
        <Route path="/ro-service-Watgunge" element={<Bengal title={locdata.Watgunge.title} number={locdata.Watgunge.number} />} />
        <Route path="/ro-service-WritersBuilding" element={<Bengal title={locdata.WritersBuilding.title} number={locdata.WritersBuilding.number} />} />
        <Route path="/ro-service-YogayogBhawan" element={<Bengal title={locdata.YogayogBhawan.title} number={locdata.YogayogBhawan.number} />} />
        
        
        <Route path="/ro-repair-Araria" element={<Bihar title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/ro-repair-Arwal" element={<Bihar title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/ro-repair-Aurangabad" element={<Bihar title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/ro-repair-Banka" element={<Bihar title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/ro-repair-Begusarai" element={<Bihar title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/ro-repair-Bhabua" element={<Bihar title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/ro-repair-Bhagalpur" element={<Bihar title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/ro-repair-Bhojpur" element={<Bihar title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/ro-repair-Bihar-Sharif" element={<Bihar title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/ro-repair-Buxar" element={<Bihar title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/ro-repair-Chhapra" element={<Bihar title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/ro-repair-Darbhanga" element={<Bihar title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/ro-repair-East-Champaran" element={<Bihar title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/ro-repair-Gaya" element={<Bihar title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/ro-repair-Gopalganj" element={<Bihar title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/ro-repair-Jamui" element={<Bihar title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/ro-repair-Jehanabad" element={<Bihar title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/ro-repair-Khagaria" element={<Bihar title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/ro-repair-Kishanganj" element={<Bihar title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/ro-repair-Kaimur" element={<Bihar title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/ro-repair-Katihar" element={<Bihar title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/ro-repair-Lakhisarai" element={<Bihar title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/ro-repair-Madhubani" element={<Bihar title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/ro-repair-Munger" element={<Bihar title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/ro-repair-Madhepura" element={<Bihar title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/ro-repair-Muzaffarpur" element={<Bihar title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/ro-repair-Nalanda" element={<Bihar title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/ro-repair-Nawada" element={<Bihar title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/ro-repair-Patna" element={<Bihar title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/ro-repair-Purnia" element={<Bihar title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/ro-repair-Rohtas" element={<Bihar title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/ro-repair-Saharsa" element={<Bihar title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/ro-repair-Samastipur" element={<Bihar title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/ro-repair-Sasaram" element={<Bihar title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/ro-repair-Sheohar" element={<Bihar title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/ro-repair-Sheikhpura" element={<Bihar title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/ro-repair-Saran" element={<Bihar title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/ro-repair-Sitamarhi" element={<Bihar title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/ro-repair-Supaul" element={<Bihar title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/ro-repair-Siwan" element={<Bihar title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/ro-repair-Vaishali" element={<Bihar title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/ro-repair-West-Champaran" element={<Bihar title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/ro-repair-Garhwa" element={<Jharkhand title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/ro-repair-Palamu" element={<Jharkhand title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/ro-repair-Latehar" element={<Jharkhand title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/ro-repair-Chatra" element={<Jharkhand title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/ro-repair-Hazaribagh" element={<Jharkhand title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/ro-repair-Jamshedpur" element={<Jharkhand title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/ro-repair-Koderma" element={<Jharkhand title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/ro-repair-Giridih" element={<Jharkhand title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/ro-repair-Ramgarh" element={<Jharkhand title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/ro-repair-Bokaro" element={<Jharkhand title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/ro-repair-Dhanbad" element={<Jharkhand title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/ro-repair-Lohardaga" element={<Jharkhand title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/ro-repair-Gumla" element={<Jharkhand title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/ro-repair-Simdega" element={<Jharkhand title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/ro-repair-Ranchi" element={<Jharkhand title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/ro-repair-Khunti" element={<Jharkhand title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/ro-repair-Singhbhum" element={<Jharkhand title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/ro-repair-Saraikela-Kharsawan" element={<Jharkhand title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/ro-repair-Jamtara" element={<Jharkhand title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/ro-repair-Deoghar" element={<Jharkhand title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/ro-repair-Dumka" element={<Jharkhand title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/ro-repair-Pakur" element={<Jharkhand title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/ro-repair-Godda" element={<Jharkhand title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/ro-repair-Sahebganj" element={<Jharkhand title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />

        <Route path="/ro-repair-Alipurduar" element={<Bengal title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/ro-repair-Bankura" element={<Bengal title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/ro-repair-Birbhum" element={<Bengal title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/ro-repair-Cooch-Behar" element={<Bengal title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/ro-repair-Dakshin-Dinajpur" element={<Bengal title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/ro-repair-Darjeeling" element={<Bengal title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/ro-repair-Durgapur" element={<Bengal title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/ro-repair-Hooghly" element={<Bengal title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/ro-repair-Howrah" element={<Bengal title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/ro-repair-Jalpaiguri" element={<Bengal title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/ro-repair-Jhargram" element={<Bengal title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/ro-repair-Kalimpong" element={<Bengal title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/ro-repair-Kolkata" element={<Bengal title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/ro-repair-Malda" element={<Bengal title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/ro-repair-Murshidabad" element={<Bengal title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/ro-repair-Nadia" element={<Bengal title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/ro-repair-North-24-Parganas" element={<Bengal title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/ro-repair-Paschim-Bardhaman" element={<Bengal title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/ro-repair-Paschim-Medinipur" element={<Bengal title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/ro-repair-Purba-Bardhaman" element={<Bengal title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/ro-repair-Purba-Medinipur" element={<Bengal title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/ro-repair-South-24-Parganas" element={<Bengal title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/ro-repair-Uttar-Dinajpur" element={<Bengal title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />

        <Route path="/ro-repair-Agra" element={<UttarPradesh title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/ro-repair-Aligarh" element={<UttarPradesh title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/ro-repair-Ambedkar-Nagar" element={<UttarPradesh title={locdata.AmbedkarNagar.title} number={locdata.AmbedkarNagar.number} />} />
        <Route path="/ro-repair-Ayodhya" element={<UttarPradesh title={locdata.Ayodhya.title} number={locdata.Ayodhya.number} />} />
        <Route path="/ro-repair-Amethi" element={<UttarPradesh title={locdata.Amethi.title} number={locdata.Amethi.number} />} />
        <Route path="/ro-repair-Amroha" element={<UttarPradesh title={locdata.Amroha.title} number={locdata.Amroha.number} />} />
        <Route path="/ro-repair-Auraiya" element={<UttarPradesh title={locdata.Auraiya.title} number={locdata.Auraiya.number} />} />
        <Route path="/ro-repair-Azamgarh" element={<UttarPradesh title={locdata.Azamgarh.title} number={locdata.Azamgarh.number} />} />
        <Route path="/ro-repair-Budaun" element={<UttarPradesh title={locdata.Budaun.title} number={locdata.Budaun.number} />} />
        <Route path="/ro-repair-Bagpat" element={<UttarPradesh title={locdata.Bagpat.title} number={locdata.Bagpat.number} />} />
        <Route path="/ro-repair-Bahraich" element={<UttarPradesh title={locdata.Bahraich.title} number={locdata.Bahraich.number} />} />
        <Route path="/ro-repair-Ballia" element={<UttarPradesh title={locdata.Ballia.title} number={locdata.Ballia.number} />} />
        <Route path="/ro-repair-Balrampur" element={<UttarPradesh title={locdata.Balrampur.title} number={locdata.Balrampur.number} />} />
        <Route path="/ro-repair-Banda" element={<UttarPradesh title={locdata.Banda.title} number={locdata.Banda.number} />} />
        <Route path="/ro-repair-Barabanki" element={<UttarPradesh title={locdata.Barabanki.title} number={locdata.Barabanki.number} />} />
        <Route path="/ro-repair-Bareilly" element={<UttarPradesh title={locdata.Bareilly.title} number={locdata.Bareilly.number} />} />
        <Route path="/ro-repair-Basti" element={<UttarPradesh title={locdata.Basti.title} number={locdata.Basti.number} />} />
        <Route path="/ro-repair-Bijnor" element={<UttarPradesh title={locdata.Bijnor.title} number={locdata.Bijnor.number} />} />
        <Route path="/ro-repair-Bulandshahr" element={<UttarPradesh title={locdata.Bulandshahr.title} number={locdata.Bulandshahr.number} />} />
        <Route path="/ro-repair-Chandauli" element={<UttarPradesh title={locdata.Chandauli.title} number={locdata.Chandauli.number} />} />
        <Route path="/ro-repair-Chitrakoot" element={<UttarPradesh title={locdata.Chitrakoot.title} number={locdata.Chitrakoot.number} />} />
        <Route path="/ro-repair-Deoria" element={<UttarPradesh title={locdata.Deoria.title} number={locdata.Deoria.number} />} />
        <Route path="/ro-repair-Etah" element={<UttarPradesh title={locdata.Etah.title} number={locdata.Etah.number} />} />
        <Route path="/ro-repair-Etawah" element={<UttarPradesh title={locdata.Etawah.title} number={locdata.Etawah.number} />} />
        <Route path="/ro-repair-Farrukhabad" element={<UttarPradesh title={locdata.Farrukhabad.title} number={locdata.Farrukhabad.number} />} />
        <Route path="/ro-repair-Fatehpur" element={<UttarPradesh title={locdata.Fatehpur.title} number={locdata.Fatehpur.number} />} />
        <Route path="/ro-repair-Firozabad" element={<UttarPradesh title={locdata.Firozabad.title} number={locdata.Firozabad.number} />} />
        <Route path="/ro-repair-Gautam-Buddha-Nagar" element={<UttarPradesh title={locdata.GautamBuddhaNagar.title} number={locdata.GautamBuddhaNagar.number} />} />
        <Route path="/ro-repair-Ghaziabad" element={<UttarPradesh title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/ro-repair-Ghazipur" element={<UttarPradesh title={locdata.Ghazipur.title} number={locdata.Ghazipur.number} />} />
        <Route path="/ro-repair-Gonda" element={<UttarPradesh title={locdata.Gonda.title} number={locdata.Gonda.number} />} />
        <Route path="/ro-repair-Gorakhpur" element={<UttarPradesh title={locdata.Gorakhpur.title} number={locdata.Gorakhpur.number} />} />
        <Route path="/ro-repair-Hamirpur" element={<UttarPradesh title={locdata.Hamirpur.title} number={locdata.Hamirpur.number} />} />
        <Route path="/ro-repair-Hapur" element={<UttarPradesh title={locdata.Hapur.title} number={locdata.Hapur.number} />} />
        <Route path="/ro-repair-Hardoi" element={<UttarPradesh title={locdata.Hardoi.title} number={locdata.Hardoi.number} />} />
        <Route path="/ro-repair-Hathras" element={<UttarPradesh title={locdata.Hathras.title} number={locdata.Hathras.number} />} />
        <Route path="/ro-repair-Jalaun" element={<UttarPradesh title={locdata.Jalaun.title} number={locdata.Jalaun.number} />} />
        <Route path="/ro-repair-Jaunpur" element={<UttarPradesh title={locdata.Jaunpur.title} number={locdata.Jaunpur.number} />} />
        <Route path="/ro-repair-Jhansi" element={<UttarPradesh title={locdata.Jhansi.title} number={locdata.Jhansi.number} />} />
        <Route path="/ro-repair-Kannauj" element={<UttarPradesh title={locdata.Kannauj.title} number={locdata.Kannauj.number} />} />
        <Route path="/ro-repair-Kanpur Dehat" element={<UttarPradesh title={locdata.KanpurDehat.title} number={locdata.KanpurDehat.number} />} />
        <Route path="/ro-repair-Kanpur" element={<UttarPradesh title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/ro-repair-Kasganj" element={<UttarPradesh title={locdata.Kasganj.title} number={locdata.Kasganj.number} />} />
        <Route path="/ro-repair-Kaushambi" element={<UttarPradesh title={locdata.Kaushambi.title} number={locdata.Kaushambi.number} />} />
        <Route path="/ro-repair-Kushinagar" element={<UttarPradesh title={locdata.Kushinagar.title} number={locdata.Kushinagar.number} />} />
        <Route path="/ro-repair-Lakhimpur-Kheri" element={<UttarPradesh title={locdata.LakhimpurKheri.title} number={locdata.LakhimpurKheri.number} />} />
        <Route path="/ro-repair-Lalitpur" element={<UttarPradesh title={locdata.Lalitpur.title} number={locdata.Lalitpur.number} />} />
        <Route path="/ro-repair-Lucknow" element={<UttarPradesh title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/ro-repair-Maharajganj" element={<UttarPradesh title={locdata.Maharajganj.title} number={locdata.Maharajganj.number} />} />
        <Route path="/ro-repair-Mahoba" element={<UttarPradesh title={locdata.Mahoba.title} number={locdata.Mahoba.number} />} />
        <Route path="/ro-repair-Mainpuri" element={<UttarPradesh title={locdata.Mainpuri.title} number={locdata.Mainpuri.number} />} />
        <Route path="/ro-repair-Mathura" element={<UttarPradesh title={locdata.Mathura.title} number={locdata.Mathura.number} />} />
        <Route path="/ro-repair-Mau" element={<UttarPradesh title={locdata.Mau.title} number={locdata.Mau.number} />} />
        <Route path="/ro-repair-Meerut" element={<UttarPradesh title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/ro-repair-Mirzapur" element={<UttarPradesh title={locdata.Mirzapur.title} number={locdata.Mirzapur.number} />} />
        <Route path="/ro-repair-Moradabad" element={<UttarPradesh title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/ro-repair-Muzaffarnagar" element={<UttarPradesh title={locdata.Muzaffarnagar.title} number={locdata.Muzaffarnagar.number} />} />
        <Route path="/ro-repair-Pilibhit" element={<UttarPradesh title={locdata.Pilibhit.title} number={locdata.Pilibhit.number} />} />
        <Route path="/ro-repair-Pratapgarh" element={<UttarPradesh title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/ro-repair-Prayagraj" element={<UttarPradesh title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/ro-repair-Rae Bareli" element={<UttarPradesh title={locdata.RaeBareli.title} number={locdata.RaeBareli.number} />} />
        <Route path="/ro-repair-Rampur" element={<UttarPradesh title={locdata.Rampur.title} number={locdata.Rampur.number} />} />
        <Route path="/ro-repair-Saharanpur" element={<UttarPradesh title={locdata.Saharanpur.title} number={locdata.Saharanpur.number} />} />
        <Route path="/ro-repair-Sant-Kabir-Nagar" element={<UttarPradesh title={locdata.SantKabirNagar.title} number={locdata.SantKabirNagar.number} />} />
        <Route path="/ro-repair-Bhadohi" element={<UttarPradesh title={locdata.Bhadohi.title} number={locdata.Bhadohi.number} />} />
        <Route path="/ro-repair-Sambhal" element={<UttarPradesh title={locdata.Sambhal.title} number={locdata.Sambhal.number} />} />
        <Route path="/ro-repair-Shahjahanpur" element={<UttarPradesh title={locdata.Shahjahanpur.title} number={locdata.Shahjahanpur.number} />} />
        <Route path="/ro-repair-Shamli" element={<UttarPradesh title={locdata.Shamli.title} number={locdata.Shamli.number} />} />
        <Route path="/ro-repair-Shravasti" element={<UttarPradesh title={locdata.Shravasti.title} number={locdata.Shravasti.number} />} />
        <Route path="/ro-repair-Siddharthnagar" element={<UttarPradesh title={locdata.Siddharthnagar.title} number={locdata.Siddharthnagar.number} />} />
        <Route path="/ro-repair-Sitapur" element={<UttarPradesh title={locdata.Sitapur.title} number={locdata.Sitapur.number} />} />
        <Route path="/ro-repair-Sonbhadra" element={<UttarPradesh title={locdata.Sonbhadra.title} number={locdata.Sonbhadra.number} />} />
        <Route path="/ro-repair-Sultanpur" element={<UttarPradesh title={locdata.Sultanpur.title} number={locdata.Sultanpur.number} />} />
        <Route path="/ro-repair-Unnao" element={<UttarPradesh title={locdata.Unnao.title} number={locdata.Unnao.number} />} />
        <Route path="/ro-repair-Varanasi" element={<UttarPradesh title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/ro-repair-Robertsganj" element={<UttarPradesh title={locdata.Robertsganj.title} number={locdata.Robertsganj.number} />} />
        <Route path="/ro-repair-Bahjoi" element={<UttarPradesh title={locdata.Bahjoi.title} number={locdata.Bahjoi.number} />} />
        <Route path="/ro-repair-Khalilabad" element={<UttarPradesh title={locdata.Khalilabad.title} number={locdata.Khalilabad.number} />} />
        <Route path="/ro-repair-Akbarpur" element={<UttarPradesh title={locdata.Akbarpur.title} number={locdata.Akbarpur.number} />} />
        <Route path="/ro-repair-Orai" element={<UttarPradesh title={locdata.Orai.title} number={locdata.Orai.number} />} />
        <Route path="/ro-repair-Manjhanpur" element={<UttarPradesh title={locdata.Manjhanpur.title} number={locdata.Manjhanpur.number} />} />
        <Route path="/ro-repair-Padarauna" element={<UttarPradesh title={locdata.Padarauna.title} number={locdata.Padarauna.number} />} />
        <Route path="/ro-repair-Kheri" element={<UttarPradesh title={locdata.Kheri.title} number={locdata.Kheri.number} />} />
        <Route path="/ro-repair-Fatehgarh" element={<UttarPradesh title={locdata.Fatehgarh.title} number={locdata.Fatehgarh.number} />} />
        <Route path="/ro-repair-Noida" element={<UttarPradesh title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/ro-repair-Greater-Noida" element={<UttarPradesh title={locdata.GreaterNoida.title} number={locdata.GreaterNoida.number} />} />
        <Route path="/ro-repair-Indore" element={<MadhyaPradesh title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/ro-repair-Bhopal" element={<MadhyaPradesh title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/ro-repair-Jabalpur" element={<MadhyaPradesh title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/ro-repair-Gwalior" element={<MadhyaPradesh title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/ro-repair-Ujjain" element={<MadhyaPradesh title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/ro-repair-Sagar" element={<MadhyaPradesh title={locdata.Sagar.title} number={locdata.Sagar.number} />} />
        <Route path="/ro-repair-Dewas" element={<MadhyaPradesh title={locdata.Dewas.title} number={locdata.Dewas.number} />} />
        <Route path="/ro-repair-Satna" element={<MadhyaPradesh title={locdata.Satna.title} number={locdata.Satna.number} />} />
        <Route path="/ro-repair-Ratlam" element={<MadhyaPradesh title={locdata.Ratlam.title} number={locdata.Ratlam.number} />} />
        <Route path="/ro-repair-Rewa" element={<MadhyaPradesh title={locdata.Rewa.title} number={locdata.Rewa.number} />} />
        <Route path="/ro-repair-Katni" element={<MadhyaPradesh title={locdata.Katni.title} number={locdata.Katni.number} />} />
        <Route path="/ro-repair-Singrauli" element={<MadhyaPradesh title={locdata.Singrauli.title} number={locdata.Singrauli.number} />} />
        <Route path="/ro-repair-Burhanpur" element={<MadhyaPradesh title={locdata.Burhanpur.title} number={locdata.Burhanpur.number} />} />
        <Route path="/ro-repair-Morena" element={<MadhyaPradesh title={locdata.Morena.title} number={locdata.Morena.number} />} />
        <Route path="/ro-repair-Khandwa" element={<MadhyaPradesh title={locdata.Khandwa.title} number={locdata.Khandwa.number} />} />
        <Route path="/ro-repair-Bhind" element={<MadhyaPradesh title={locdata.Bhind.title} number={locdata.Bhind.number} />} />
        <Route path="/ro-repair-Chhindwara" element={<MadhyaPradesh title={locdata.Chhindwara.title} number={locdata.Chhindwara.number} />} />
        <Route path="/ro-repair-Guna" element={<MadhyaPradesh title={locdata.Guna.title} number={locdata.Guna.number} />} />
        <Route path="/ro-repair-Shivpuri" element={<MadhyaPradesh title={locdata.Shivpuri.title} number={locdata.Shivpuri.number} />} />
        <Route path="/ro-repair-Vidisha" element={<MadhyaPradesh title={locdata.Vidisha.title} number={locdata.Vidisha.number} />} />
        <Route path="/ro-repair-Chhatarpur" element={<MadhyaPradesh title={locdata.Chhatarpur.title} number={locdata.Chhatarpur.number} />} />
        <Route path="/ro-repair-Damoh" element={<MadhyaPradesh title={locdata.Damoh.title} number={locdata.Damoh.number} />} />
        <Route path="/ro-repair-Mandsaur" element={<MadhyaPradesh title={locdata.Mandsaur.title} number={locdata.Mandsaur.number} />} />
        <Route path="/ro-repair-Khargone" element={<MadhyaPradesh title={locdata.Khargone.title} number={locdata.Khargone.number} />} />
        <Route path="/ro-repair-Neemuch" element={<MadhyaPradesh title={locdata.Neemuch.title} number={locdata.Neemuch.number} />} />
        <Route path="/ro-repair-Pithampur" element={<MadhyaPradesh title={locdata.Pithampur.title} number={locdata.Pithampur.number} />} />
        <Route path="/ro-repair-Narmadapuram" element={<MadhyaPradesh title={locdata.Narmadapuram.title} number={locdata.Narmadapuram.number} />} />
        <Route path="/ro-repair-Itarsi" element={<MadhyaPradesh title={locdata.Itarsi.title} number={locdata.Itarsi.number} />} />
        <Route path="/ro-repair-Sehore" element={<MadhyaPradesh title={locdata.Sehore.title} number={locdata.Sehore.number} />} />
        <Route path="/ro-repair-Betul" element={<MadhyaPradesh title={locdata.Betul.title} number={locdata.Betul.number} />} />
        <Route path="/ro-repair-Seoni" element={<MadhyaPradesh title={locdata.Seoni.title} number={locdata.Seoni.number} />} />
        <Route path="/ro-repair-Datia" element={<MadhyaPradesh title={locdata.Datia.title} number={locdata.Datia.number} />} />
        <Route path="/ro-repair-Nagda" element={<MadhyaPradesh title={locdata.Nagda.title} number={locdata.Nagda.number} />} />
        <Route path="/ro-repair-Shahdol" element={<MadhyaPradesh title={locdata.Shahdol.title} number={locdata.Shahdol.number} />} />
        <Route path="/ro-repair-Ajmer" element={<Rajasthan title={locdata.Ajmer.title} number={locdata.Ajmer.number} />} />
        <Route path="/ro-repair-Alwar" element={<Rajasthan title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/ro-repair-Balotra" element={<Rajasthan title={locdata.Balotra.title} number={locdata.Balotra.number} />} />
        <Route path="/ro-repair-Banswara" element={<Rajasthan title={locdata.Banswara.title} number={locdata.Banswara.number} />} />
        <Route path="/ro-repair-Baran" element={<Rajasthan title={locdata.Baran.title} number={locdata.Baran.number} />} />
        <Route path="/ro-repair-Barmer" element={<Rajasthan title={locdata.Barmer.title} number={locdata.Barmer.number} />} />
        <Route path="/ro-repair-Beawar" element={<Rajasthan title={locdata.Beawar.title} number={locdata.Beawar.number} />} />
        <Route path="/ro-repair-Bharatpur" element={<Rajasthan title={locdata.Bharatpur.title} number={locdata.Bharatpur.number} />} />
        <Route path="/ro-repair-Bhilwara" element={<Rajasthan title={locdata.Bhilwara.title} number={locdata.Bhilwara.number} />} />
        <Route path="/ro-repair-Bikaner" element={<Rajasthan title={locdata.Bikaner.title} number={locdata.Bikaner.number} />} />
        <Route path="/ro-repair-Bundi" element={<Rajasthan title={locdata.Bundi.title} number={locdata.Bundi.number} />} />
        <Route path="/ro-repair-Chittorgarh" element={<Rajasthan title={locdata.Chittorgarh.title} number={locdata.Chittorgarh.number} />} />
        <Route path="/ro-repair-Churu" element={<Rajasthan title={locdata.Churu.title} number={locdata.Churu.number} />} />
        <Route path="/ro-repair-Dausa" element={<Rajasthan title={locdata.Dausa.title} number={locdata.Dausa.number} />} />
        <Route path="/ro-repair-Deeg" element={<Rajasthan title={locdata.Deeg.title} number={locdata.Deeg.number} />} />
        <Route path="/ro-repair-Deedwana" element={<Rajasthan title={locdata.Deedwana.title} number={locdata.Deedwana.number} />} />
        <Route path="/ro-repair-Dholpur" element={<Rajasthan title={locdata.Dholpur.title} number={locdata.Dholpur.number} />} />
        <Route path="/ro-repair-Dungarpur" element={<Rajasthan title={locdata.Dungarpur.title} number={locdata.Dungarpur.number} />} />
        <Route path="/ro-repair-Hanumangarh" element={<Rajasthan title={locdata.Hanumangarh.title} number={locdata.Hanumangarh.number} />} />
        <Route path="/ro-repair-Jaipur" element={<Rajasthan title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/ro-repair-Jaisalmer" element={<Rajasthan title={locdata.Jaisalmer.title} number={locdata.Jaisalmer.number} />} />
        <Route path="/ro-repair-Jalore" element={<Rajasthan title={locdata.Jalore.title} number={locdata.Jalore.number} />} />
        <Route path="/ro-repair-Jhalawar" element={<Rajasthan title={locdata.Jhalawar.title} number={locdata.Jhalawar.number} />} />
        <Route path="/ro-repair-Jhunjhunu" element={<Rajasthan title={locdata.Jhunjhunu.title} number={locdata.Jhunjhunu.number} />} />
        <Route path="/ro-repair-Jodhpur" element={<Rajasthan title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/ro-repair-Karauli" element={<Rajasthan title={locdata.Karauli.title} number={locdata.Karauli.number} />} />
        <Route path="/ro-repair-Tijara" element={<Rajasthan title={locdata.Tijara.title} number={locdata.Tijara.number} />} />
        <Route path="/ro-repair-Kotputli" element={<Rajasthan title={locdata.Kotputli.title} number={locdata.Kotputli.number} />} />
        <Route path="/ro-repair-Kota" element={<Rajasthan title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/ro-repair-Nagaur" element={<Rajasthan title={locdata.Nagaur.title} number={locdata.Nagaur.number} />} />
        <Route path="/ro-repair-Pali" element={<Rajasthan title={locdata.Pali.title} number={locdata.Pali.number} />} />
        <Route path="/ro-repair-Phalodi" element={<Rajasthan title={locdata.Phalodi.title} number={locdata.Phalodi.number} />} />
        <Route path="/ro-repair-Pratapgarh" element={<Rajasthan title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/ro-repair-Rajsamand" element={<Rajasthan title={locdata.Rajsamand.title} number={locdata.Rajsamand.number} />} />
        <Route path="/ro-repair-Salumbar" element={<Rajasthan title={locdata.Salumbar.title} number={locdata.Salumbar.number} />} />
        <Route path="/ro-repair-Sawai-Madhopur" element={<Rajasthan title={locdata.SawaiMadhopur.title} number={locdata.SawaiMadhopur.number} />} />
        <Route path="/ro-repair-Sikar" element={<Rajasthan title={locdata.Sikar.title} number={locdata.Sikar.number} />} />
        <Route path="/ro-repair-Sirohi" element={<Rajasthan title={locdata.Sirohi.title} number={locdata.Sirohi.number} />} />
        <Route path="/ro-repair-Sri-Ganganagar" element={<Rajasthan title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/ro-repair-Tonk" element={<Rajasthan title={locdata.Tonk.title} number={locdata.Tonk.number} />} />
        <Route path="/ro-repair-Udaipur" element={<Rajasthan title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/ro-repair-Dhar" element={<MadhyaPradesh title={locdata.Dhar.title} number={locdata.Dhar.number} />} />

        <Route path="/water-purifier-services-Araria" element={<Bihar title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/water-purifier-services-Arwal" element={<Bihar title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/water-purifier-services-Aurangabad" element={<Bihar title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/water-purifier-services-Banka" element={<Bihar title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/water-purifier-services-Begusarai" element={<Bihar title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/water-purifier-services-Bhabua" element={<Bihar title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/water-purifier-services-Bhagalpur" element={<Bihar title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/water-purifier-services-Bhojpur" element={<Bihar title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/water-purifier-services-Bihar-Sharif" element={<Bihar title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/water-purifier-services-Buxar" element={<Bihar title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/water-purifier-services-Chhapra" element={<Bihar title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/water-purifier-services-Darbhanga" element={<Bihar title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/water-purifier-services-East-Champaran" element={<Bihar title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/water-purifier-services-Gaya" element={<Bihar title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/water-purifier-services-Gopalganj" element={<Bihar title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/water-purifier-services-Jamui" element={<Bihar title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/water-purifier-services-Jehanabad" element={<Bihar title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/water-purifier-services-Khagaria" element={<Bihar title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/water-purifier-services-Kishanganj" element={<Bihar title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/water-purifier-services-Kaimur" element={<Bihar title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/water-purifier-services-Katihar" element={<Bihar title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/water-purifier-services-Lakhisarai" element={<Bihar title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/water-purifier-services-Madhubani" element={<Bihar title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/water-purifier-services-Munger" element={<Bihar title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/water-purifier-services-Madhepura" element={<Bihar title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/water-purifier-services-Muzaffarpur" element={<Bihar title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/water-purifier-services-Nalanda" element={<Bihar title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/water-purifier-services-Nawada" element={<Bihar title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/water-purifier-services-Patna" element={<Bihar title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/water-purifier-services-Purnia" element={<Bihar title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/water-purifier-services-Rohtas" element={<Bihar title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/water-purifier-services-Saharsa" element={<Bihar title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/water-purifier-services-Samastipur" element={<Bihar title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/water-purifier-services-Sasaram" element={<Bihar title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/water-purifier-services-Sheohar" element={<Bihar title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/water-purifier-services-Sheikhpura" element={<Bihar title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/water-purifier-services-Saran" element={<Bihar title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/water-purifier-services-Sitamarhi" element={<Bihar title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/water-purifier-services-Supaul" element={<Bihar title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/water-purifier-services-Siwan" element={<Bihar title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/water-purifier-services-Vaishali" element={<Bihar title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/water-purifier-services-West-Champaran" element={<Bihar title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/water-purifier-services-Garhwa" element={<Jharkhand title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/water-purifier-services-Palamu" element={<Jharkhand title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/water-purifier-services-Latehar" element={<Jharkhand title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/water-purifier-services-Chatra" element={<Jharkhand title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/water-purifier-services-Hazaribagh" element={<Jharkhand title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/water-purifier-services-Jamshedpur" element={<Jharkhand title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/water-purifier-services-Koderma" element={<Jharkhand title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/water-purifier-services-Giridih" element={<Jharkhand title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/water-purifier-services-Ramgarh" element={<Jharkhand title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/water-purifier-services-Bokaro" element={<Jharkhand title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/water-purifier-services-Dhanbad" element={<Jharkhand title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/water-purifier-services-Lohardaga" element={<Jharkhand title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/water-purifier-services-Gumla" element={<Jharkhand title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/water-purifier-services-Simdega" element={<Jharkhand title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/water-purifier-services-Ranchi" element={<Jharkhand title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/water-purifier-services-Khunti" element={<Jharkhand title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/water-purifier-services-Singhbhum" element={<Jharkhand title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/water-purifier-services-Saraikela-Kharsawan" element={<Jharkhand title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/water-purifier-services-Jamtara" element={<Jharkhand title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/water-purifier-services-Deoghar" element={<Jharkhand title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/water-purifier-services-Dumka" element={<Jharkhand title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/water-purifier-services-Pakur" element={<Jharkhand title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/water-purifier-services-Godda" element={<Jharkhand title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/water-purifier-services-Sahebganj" element={<Jharkhand title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />

        <Route path="/water-purifier-services-Alipurduar" element={<Bengal title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/water-purifier-services-Bankura" element={<Bengal title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/water-purifier-services-Birbhum" element={<Bengal title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/water-purifier-services-Cooch-Behar" element={<Bengal title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/water-purifier-services-Dakshin-Dinajpur" element={<Bengal title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/water-purifier-services-Darjeeling" element={<Bengal title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/water-purifier-services-Durgapur" element={<Bengal title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/water-purifier-services-Hooghly" element={<Bengal title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/water-purifier-services-Howrah" element={<Bengal title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/water-purifier-services-Jalpaiguri" element={<Bengal title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/water-purifier-services-Jhargram" element={<Bengal title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/water-purifier-services-Kalimpong" element={<Bengal title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/water-purifier-services-Kolkata" element={<Bengal title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/water-purifier-services-Malda" element={<Bengal title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/water-purifier-services-Murshidabad" element={<Bengal title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/water-purifier-services-Nadia" element={<Bengal title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/water-purifier-services-North-24-Parganas" element={<Bengal title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/water-purifier-services-Paschim-Bardhaman" element={<Bengal title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/water-purifier-services-Paschim-Medinipur" element={<Bengal title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/water-purifier-services-Purba-Bardhaman" element={<Bengal title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/water-purifier-services-Purba-Medinipur" element={<Bengal title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/water-purifier-services-South-24-Parganas" element={<Bengal title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/water-purifier-services-Uttar-Dinajpur" element={<Bengal title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />

        <Route path="/water-purifier-services-Agra" element={<UttarPradesh title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/water-purifier-services-Aligarh" element={<UttarPradesh title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/water-purifier-services-Ambedkar-Nagar" element={<UttarPradesh title={locdata.AmbedkarNagar.title} number={locdata.AmbedkarNagar.number} />} />
        <Route path="/water-purifier-services-Ayodhya" element={<UttarPradesh title={locdata.Ayodhya.title} number={locdata.Ayodhya.number} />} />
        <Route path="/water-purifier-services-Amethi" element={<UttarPradesh title={locdata.Amethi.title} number={locdata.Amethi.number} />} />
        <Route path="/water-purifier-services-Amroha" element={<UttarPradesh title={locdata.Amroha.title} number={locdata.Amroha.number} />} />
        <Route path="/water-purifier-services-Auraiya" element={<UttarPradesh title={locdata.Auraiya.title} number={locdata.Auraiya.number} />} />
        <Route path="/water-purifier-services-Azamgarh" element={<UttarPradesh title={locdata.Azamgarh.title} number={locdata.Azamgarh.number} />} />
        <Route path="/water-purifier-services-Budaun" element={<UttarPradesh title={locdata.Budaun.title} number={locdata.Budaun.number} />} />
        <Route path="/water-purifier-services-Bagpat" element={<UttarPradesh title={locdata.Bagpat.title} number={locdata.Bagpat.number} />} />
        <Route path="/water-purifier-services-Bahraich" element={<UttarPradesh title={locdata.Bahraich.title} number={locdata.Bahraich.number} />} />
        <Route path="/water-purifier-services-Ballia" element={<UttarPradesh title={locdata.Ballia.title} number={locdata.Ballia.number} />} />
        <Route path="/water-purifier-services-Balrampur" element={<UttarPradesh title={locdata.Balrampur.title} number={locdata.Balrampur.number} />} />
        <Route path="/water-purifier-services-Banda" element={<UttarPradesh title={locdata.Banda.title} number={locdata.Banda.number} />} />
        <Route path="/water-purifier-services-Barabanki" element={<UttarPradesh title={locdata.Barabanki.title} number={locdata.Barabanki.number} />} />
        <Route path="/water-purifier-services-Bareilly" element={<UttarPradesh title={locdata.Bareilly.title} number={locdata.Bareilly.number} />} />
        <Route path="/water-purifier-services-Basti" element={<UttarPradesh title={locdata.Basti.title} number={locdata.Basti.number} />} />
        <Route path="/water-purifier-services-Bijnor" element={<UttarPradesh title={locdata.Bijnor.title} number={locdata.Bijnor.number} />} />
        <Route path="/water-purifier-services-Bulandshahr" element={<UttarPradesh title={locdata.Bulandshahr.title} number={locdata.Bulandshahr.number} />} />
        <Route path="/water-purifier-services-Chandauli" element={<UttarPradesh title={locdata.Chandauli.title} number={locdata.Chandauli.number} />} />
        <Route path="/water-purifier-services-Chitrakoot" element={<UttarPradesh title={locdata.Chitrakoot.title} number={locdata.Chitrakoot.number} />} />
        <Route path="/water-purifier-services-Deoria" element={<UttarPradesh title={locdata.Deoria.title} number={locdata.Deoria.number} />} />
        <Route path="/water-purifier-services-Etah" element={<UttarPradesh title={locdata.Etah.title} number={locdata.Etah.number} />} />
        <Route path="/water-purifier-services-Etawah" element={<UttarPradesh title={locdata.Etawah.title} number={locdata.Etawah.number} />} />
        <Route path="/water-purifier-services-Farrukhabad" element={<UttarPradesh title={locdata.Farrukhabad.title} number={locdata.Farrukhabad.number} />} />
        <Route path="/water-purifier-services-Fatehpur" element={<UttarPradesh title={locdata.Fatehpur.title} number={locdata.Fatehpur.number} />} />
        <Route path="/water-purifier-services-Firozabad" element={<UttarPradesh title={locdata.Firozabad.title} number={locdata.Firozabad.number} />} />
        <Route path="/water-purifier-services-Gautam-Buddha-Nagar" element={<UttarPradesh title={locdata.GautamBuddhaNagar.title} number={locdata.GautamBuddhaNagar.number} />} />
        <Route path="/water-purifier-services-Ghaziabad" element={<UttarPradesh title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/water-purifier-services-Ghazipur" element={<UttarPradesh title={locdata.Ghazipur.title} number={locdata.Ghazipur.number} />} />
        <Route path="/water-purifier-services-Gonda" element={<UttarPradesh title={locdata.Gonda.title} number={locdata.Gonda.number} />} />
        <Route path="/water-purifier-services-Gorakhpur" element={<UttarPradesh title={locdata.Gorakhpur.title} number={locdata.Gorakhpur.number} />} />
        <Route path="/water-purifier-services-Hamirpur" element={<UttarPradesh title={locdata.Hamirpur.title} number={locdata.Hamirpur.number} />} />
        <Route path="/water-purifier-services-Hapur" element={<UttarPradesh title={locdata.Hapur.title} number={locdata.Hapur.number} />} />
        <Route path="/water-purifier-services-Hardoi" element={<UttarPradesh title={locdata.Hardoi.title} number={locdata.Hardoi.number} />} />
        <Route path="/water-purifier-services-Hathras" element={<UttarPradesh title={locdata.Hathras.title} number={locdata.Hathras.number} />} />
        <Route path="/water-purifier-services-Jalaun" element={<UttarPradesh title={locdata.Jalaun.title} number={locdata.Jalaun.number} />} />
        <Route path="/water-purifier-services-Jaunpur" element={<UttarPradesh title={locdata.Jaunpur.title} number={locdata.Jaunpur.number} />} />
        <Route path="/water-purifier-services-Jhansi" element={<UttarPradesh title={locdata.Jhansi.title} number={locdata.Jhansi.number} />} />
        <Route path="/water-purifier-services-Kannauj" element={<UttarPradesh title={locdata.Kannauj.title} number={locdata.Kannauj.number} />} />
        <Route path="/water-purifier-services-Kanpur Dehat" element={<UttarPradesh title={locdata.KanpurDehat.title} number={locdata.KanpurDehat.number} />} />
        <Route path="/water-purifier-services-Kanpur" element={<UttarPradesh title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/water-purifier-services-Kasganj" element={<UttarPradesh title={locdata.Kasganj.title} number={locdata.Kasganj.number} />} />
        <Route path="/water-purifier-services-Kaushambi" element={<UttarPradesh title={locdata.Kaushambi.title} number={locdata.Kaushambi.number} />} />
        <Route path="/water-purifier-services-Kushinagar" element={<UttarPradesh title={locdata.Kushinagar.title} number={locdata.Kushinagar.number} />} />
        <Route path="/water-purifier-services-Lakhimpur-Kheri" element={<UttarPradesh title={locdata.LakhimpurKheri.title} number={locdata.LakhimpurKheri.number} />} />
        <Route path="/water-purifier-services-Lalitpur" element={<UttarPradesh title={locdata.Lalitpur.title} number={locdata.Lalitpur.number} />} />
        <Route path="/water-purifier-services-Lucknow" element={<UttarPradesh title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/water-purifier-services-Maharajganj" element={<UttarPradesh title={locdata.Maharajganj.title} number={locdata.Maharajganj.number} />} />
        <Route path="/water-purifier-services-Mahoba" element={<UttarPradesh title={locdata.Mahoba.title} number={locdata.Mahoba.number} />} />
        <Route path="/water-purifier-services-Mainpuri" element={<UttarPradesh title={locdata.Mainpuri.title} number={locdata.Mainpuri.number} />} />
        <Route path="/water-purifier-services-Mathura" element={<UttarPradesh title={locdata.Mathura.title} number={locdata.Mathura.number} />} />
        <Route path="/water-purifier-services-Mau" element={<UttarPradesh title={locdata.Mau.title} number={locdata.Mau.number} />} />
        <Route path="/water-purifier-services-Meerut" element={<UttarPradesh title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/water-purifier-services-Mirzapur" element={<UttarPradesh title={locdata.Mirzapur.title} number={locdata.Mirzapur.number} />} />
        <Route path="/water-purifier-services-Moradabad" element={<UttarPradesh title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/water-purifier-services-Muzaffarnagar" element={<UttarPradesh title={locdata.Muzaffarnagar.title} number={locdata.Muzaffarnagar.number} />} />
        <Route path="/water-purifier-services-Pilibhit" element={<UttarPradesh title={locdata.Pilibhit.title} number={locdata.Pilibhit.number} />} />
        <Route path="/water-purifier-services-Pratapgarh" element={<UttarPradesh title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/water-purifier-services-Prayagraj" element={<UttarPradesh title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/water-purifier-services-Rae Bareli" element={<UttarPradesh title={locdata.RaeBareli.title} number={locdata.RaeBareli.number} />} />
        <Route path="/water-purifier-services-Rampur" element={<UttarPradesh title={locdata.Rampur.title} number={locdata.Rampur.number} />} />
        <Route path="/water-purifier-services-Saharanpur" element={<UttarPradesh title={locdata.Saharanpur.title} number={locdata.Saharanpur.number} />} />
        <Route path="/water-purifier-services-Sant-Kabir-Nagar" element={<UttarPradesh title={locdata.SantKabirNagar.title} number={locdata.SantKabirNagar.number} />} />
        <Route path="/water-purifier-services-Bhadohi" element={<UttarPradesh title={locdata.Bhadohi.title} number={locdata.Bhadohi.number} />} />
        <Route path="/water-purifier-services-Sambhal" element={<UttarPradesh title={locdata.Sambhal.title} number={locdata.Sambhal.number} />} />
        <Route path="/water-purifier-services-Shahjahanpur" element={<UttarPradesh title={locdata.Shahjahanpur.title} number={locdata.Shahjahanpur.number} />} />
        <Route path="/water-purifier-services-Shamli" element={<UttarPradesh title={locdata.Shamli.title} number={locdata.Shamli.number} />} />
        <Route path="/water-purifier-services-Shravasti" element={<UttarPradesh title={locdata.Shravasti.title} number={locdata.Shravasti.number} />} />
        <Route path="/water-purifier-services-Siddharthnagar" element={<UttarPradesh title={locdata.Siddharthnagar.title} number={locdata.Siddharthnagar.number} />} />
        <Route path="/water-purifier-services-Sitapur" element={<UttarPradesh title={locdata.Sitapur.title} number={locdata.Sitapur.number} />} />
        <Route path="/water-purifier-services-Sonbhadra" element={<UttarPradesh title={locdata.Sonbhadra.title} number={locdata.Sonbhadra.number} />} />
        <Route path="/water-purifier-services-Sultanpur" element={<UttarPradesh title={locdata.Sultanpur.title} number={locdata.Sultanpur.number} />} />
        <Route path="/water-purifier-services-Unnao" element={<UttarPradesh title={locdata.Unnao.title} number={locdata.Unnao.number} />} />
        <Route path="/water-purifier-services-Varanasi" element={<UttarPradesh title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/water-purifier-services-Robertsganj" element={<UttarPradesh title={locdata.Robertsganj.title} number={locdata.Robertsganj.number} />} />
        <Route path="/water-purifier-services-Bahjoi" element={<UttarPradesh title={locdata.Bahjoi.title} number={locdata.Bahjoi.number} />} />
        <Route path="/water-purifier-services-Khalilabad" element={<UttarPradesh title={locdata.Khalilabad.title} number={locdata.Khalilabad.number} />} />
        <Route path="/water-purifier-services-Akbarpur" element={<UttarPradesh title={locdata.Akbarpur.title} number={locdata.Akbarpur.number} />} />
        <Route path="/water-purifier-services-Orai" element={<UttarPradesh title={locdata.Orai.title} number={locdata.Orai.number} />} />
        <Route path="/water-purifier-services-Manjhanpur" element={<UttarPradesh title={locdata.Manjhanpur.title} number={locdata.Manjhanpur.number} />} />
        <Route path="/water-purifier-services-Padarauna" element={<UttarPradesh title={locdata.Padarauna.title} number={locdata.Padarauna.number} />} />
        <Route path="/water-purifier-services-Kheri" element={<UttarPradesh title={locdata.Kheri.title} number={locdata.Kheri.number} />} />
        <Route path="/water-purifier-services-Fatehgarh" element={<UttarPradesh title={locdata.Fatehgarh.title} number={locdata.Fatehgarh.number} />} />
        <Route path="/water-purifier-services-Noida" element={<UttarPradesh title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/water-purifier-services-Greater-Noida" element={<UttarPradesh title={locdata.GreaterNoida.title} number={locdata.GreaterNoida.number} />} />
        <Route path="/water-purifier-services-Indore" element={<MadhyaPradesh title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/water-purifier-services-Bhopal" element={<MadhyaPradesh title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/water-purifier-services-Jabalpur" element={<MadhyaPradesh title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/water-purifier-services-Gwalior" element={<MadhyaPradesh title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/water-purifier-services-Ujjain" element={<MadhyaPradesh title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/water-purifier-services-Sagar" element={<MadhyaPradesh title={locdata.Sagar.title} number={locdata.Sagar.number} />} />
        <Route path="/water-purifier-services-Dewas" element={<MadhyaPradesh title={locdata.Dewas.title} number={locdata.Dewas.number} />} />
        <Route path="/water-purifier-services-Satna" element={<MadhyaPradesh title={locdata.Satna.title} number={locdata.Satna.number} />} />
        <Route path="/water-purifier-services-Ratlam" element={<MadhyaPradesh title={locdata.Ratlam.title} number={locdata.Ratlam.number} />} />
        <Route path="/water-purifier-services-Rewa" element={<MadhyaPradesh title={locdata.Rewa.title} number={locdata.Rewa.number} />} />
        <Route path="/water-purifier-services-Katni" element={<MadhyaPradesh title={locdata.Katni.title} number={locdata.Katni.number} />} />
        <Route path="/water-purifier-services-Singrauli" element={<MadhyaPradesh title={locdata.Singrauli.title} number={locdata.Singrauli.number} />} />
        <Route path="/water-purifier-services-Burhanpur" element={<MadhyaPradesh title={locdata.Burhanpur.title} number={locdata.Burhanpur.number} />} />
        <Route path="/water-purifier-services-Morena" element={<MadhyaPradesh title={locdata.Morena.title} number={locdata.Morena.number} />} />
        <Route path="/water-purifier-services-Khandwa" element={<MadhyaPradesh title={locdata.Khandwa.title} number={locdata.Khandwa.number} />} />
        <Route path="/water-purifier-services-Bhind" element={<MadhyaPradesh title={locdata.Bhind.title} number={locdata.Bhind.number} />} />
        <Route path="/water-purifier-services-Chhindwara" element={<MadhyaPradesh title={locdata.Chhindwara.title} number={locdata.Chhindwara.number} />} />
        <Route path="/water-purifier-services-Guna" element={<MadhyaPradesh title={locdata.Guna.title} number={locdata.Guna.number} />} />
        <Route path="/water-purifier-services-Shivpuri" element={<MadhyaPradesh title={locdata.Shivpuri.title} number={locdata.Shivpuri.number} />} />
        <Route path="/water-purifier-services-Vidisha" element={<MadhyaPradesh title={locdata.Vidisha.title} number={locdata.Vidisha.number} />} />
        <Route path="/water-purifier-services-Chhatarpur" element={<MadhyaPradesh title={locdata.Chhatarpur.title} number={locdata.Chhatarpur.number} />} />
        <Route path="/water-purifier-services-Damoh" element={<MadhyaPradesh title={locdata.Damoh.title} number={locdata.Damoh.number} />} />
        <Route path="/water-purifier-services-Mandsaur" element={<MadhyaPradesh title={locdata.Mandsaur.title} number={locdata.Mandsaur.number} />} />
        <Route path="/water-purifier-services-Khargone" element={<MadhyaPradesh title={locdata.Khargone.title} number={locdata.Khargone.number} />} />
        <Route path="/water-purifier-services-Neemuch" element={<MadhyaPradesh title={locdata.Neemuch.title} number={locdata.Neemuch.number} />} />
        <Route path="/water-purifier-services-Pithampur" element={<MadhyaPradesh title={locdata.Pithampur.title} number={locdata.Pithampur.number} />} />
        <Route path="/water-purifier-services-Narmadapuram" element={<MadhyaPradesh title={locdata.Narmadapuram.title} number={locdata.Narmadapuram.number} />} />
        <Route path="/water-purifier-services-Itarsi" element={<MadhyaPradesh title={locdata.Itarsi.title} number={locdata.Itarsi.number} />} />
        <Route path="/water-purifier-services-Sehore" element={<MadhyaPradesh title={locdata.Sehore.title} number={locdata.Sehore.number} />} />
        <Route path="/water-purifier-services-Betul" element={<MadhyaPradesh title={locdata.Betul.title} number={locdata.Betul.number} />} />
        <Route path="/water-purifier-services-Seoni" element={<MadhyaPradesh title={locdata.Seoni.title} number={locdata.Seoni.number} />} />
        <Route path="/water-purifier-services-Datia" element={<MadhyaPradesh title={locdata.Datia.title} number={locdata.Datia.number} />} />
        <Route path="/water-purifier-services-Nagda" element={<MadhyaPradesh title={locdata.Nagda.title} number={locdata.Nagda.number} />} />
        <Route path="/water-purifier-services-Shahdol" element={<MadhyaPradesh title={locdata.Shahdol.title} number={locdata.Shahdol.number} />} />
        <Route path="/water-purifier-services-Ajmer" element={<Rajasthan title={locdata.Ajmer.title} number={locdata.Ajmer.number} />} />
        <Route path="/water-purifier-services-Alwar" element={<Rajasthan title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/water-purifier-services-Balotra" element={<Rajasthan title={locdata.Balotra.title} number={locdata.Balotra.number} />} />
        <Route path="/water-purifier-services-Banswara" element={<Rajasthan title={locdata.Banswara.title} number={locdata.Banswara.number} />} />
        <Route path="/water-purifier-services-Baran" element={<Rajasthan title={locdata.Baran.title} number={locdata.Baran.number} />} />
        <Route path="/water-purifier-services-Barmer" element={<Rajasthan title={locdata.Barmer.title} number={locdata.Barmer.number} />} />
        <Route path="/water-purifier-services-Beawar" element={<Rajasthan title={locdata.Beawar.title} number={locdata.Beawar.number} />} />
        <Route path="/water-purifier-services-Bharatpur" element={<Rajasthan title={locdata.Bharatpur.title} number={locdata.Bharatpur.number} />} />
        <Route path="/water-purifier-services-Bhilwara" element={<Rajasthan title={locdata.Bhilwara.title} number={locdata.Bhilwara.number} />} />
        <Route path="/water-purifier-services-Bikaner" element={<Rajasthan title={locdata.Bikaner.title} number={locdata.Bikaner.number} />} />
        <Route path="/water-purifier-services-Bundi" element={<Rajasthan title={locdata.Bundi.title} number={locdata.Bundi.number} />} />
        <Route path="/water-purifier-services-Chittorgarh" element={<Rajasthan title={locdata.Chittorgarh.title} number={locdata.Chittorgarh.number} />} />
        <Route path="/water-purifier-services-Churu" element={<Rajasthan title={locdata.Churu.title} number={locdata.Churu.number} />} />
        <Route path="/water-purifier-services-Dausa" element={<Rajasthan title={locdata.Dausa.title} number={locdata.Dausa.number} />} />
        <Route path="/water-purifier-services-Deeg" element={<Rajasthan title={locdata.Deeg.title} number={locdata.Deeg.number} />} />
        <Route path="/water-purifier-services-Deedwana" element={<Rajasthan title={locdata.Deedwana.title} number={locdata.Deedwana.number} />} />
        <Route path="/water-purifier-services-Dholpur" element={<Rajasthan title={locdata.Dholpur.title} number={locdata.Dholpur.number} />} />
        <Route path="/water-purifier-services-Dungarpur" element={<Rajasthan title={locdata.Dungarpur.title} number={locdata.Dungarpur.number} />} />
        <Route path="/water-purifier-services-Hanumangarh" element={<Rajasthan title={locdata.Hanumangarh.title} number={locdata.Hanumangarh.number} />} />
        <Route path="/water-purifier-services-Jaipur" element={<Rajasthan title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/water-purifier-services-Jaisalmer" element={<Rajasthan title={locdata.Jaisalmer.title} number={locdata.Jaisalmer.number} />} />
        <Route path="/water-purifier-services-Jalore" element={<Rajasthan title={locdata.Jalore.title} number={locdata.Jalore.number} />} />
        <Route path="/water-purifier-services-Jhalawar" element={<Rajasthan title={locdata.Jhalawar.title} number={locdata.Jhalawar.number} />} />
        <Route path="/water-purifier-services-Jhunjhunu" element={<Rajasthan title={locdata.Jhunjhunu.title} number={locdata.Jhunjhunu.number} />} />
        <Route path="/water-purifier-services-Jodhpur" element={<Rajasthan title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/water-purifier-services-Karauli" element={<Rajasthan title={locdata.Karauli.title} number={locdata.Karauli.number} />} />
        <Route path="/water-purifier-services-Tijara" element={<Rajasthan title={locdata.Tijara.title} number={locdata.Tijara.number} />} />
        <Route path="/water-purifier-services-Kotputli" element={<Rajasthan title={locdata.Kotputli.title} number={locdata.Kotputli.number} />} />
        <Route path="/water-purifier-services-Kota" element={<Rajasthan title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/water-purifier-services-Nagaur" element={<Rajasthan title={locdata.Nagaur.title} number={locdata.Nagaur.number} />} />
        <Route path="/water-purifier-services-Pali" element={<Rajasthan title={locdata.Pali.title} number={locdata.Pali.number} />} />
        <Route path="/water-purifier-services-Phalodi" element={<Rajasthan title={locdata.Phalodi.title} number={locdata.Phalodi.number} />} />
        <Route path="/water-purifier-services-Pratapgarh" element={<Rajasthan title={locdata.Pratapgarh.title} number={locdata.Pratapgarh.number} />} />
        <Route path="/water-purifier-services-Rajsamand" element={<Rajasthan title={locdata.Rajsamand.title} number={locdata.Rajsamand.number} />} />
        <Route path="/water-purifier-services-Salumbar" element={<Rajasthan title={locdata.Salumbar.title} number={locdata.Salumbar.number} />} />
        <Route path="/water-purifier-services-Sawai-Madhopur" element={<Rajasthan title={locdata.SawaiMadhopur.title} number={locdata.SawaiMadhopur.number} />} />
        <Route path="/water-purifier-services-Sikar" element={<Rajasthan title={locdata.Sikar.title} number={locdata.Sikar.number} />} />
        <Route path="/water-purifier-services-Sirohi" element={<Rajasthan title={locdata.Sirohi.title} number={locdata.Sirohi.number} />} />
        <Route path="/water-purifier-services-Sri-Ganganagar" element={<Rajasthan title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/water-purifier-services-Tonk" element={<Rajasthan title={locdata.Tonk.title} number={locdata.Tonk.number} />} />
        <Route path="/water-purifier-services-Udaipur" element={<Rajasthan title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/water-purifier-services-Dhar" element={<MadhyaPradesh title={locdata.Dhar.title} number={locdata.Dhar.number} />} />
      </Routes>

      <Footerdetail data={landingPageData.Contact} />
      <Footer data={landingPageData.Footer} />
    </div>

  );
};


export default App;