import React from "react";
import "./ReadersRejoice.css";

const ReadersRejoice = (props) => {
    return (
        <div className="readerscard">
            <a href="https://www.readersrejoice.in" target="_blank" rel="noreferrer">
                <div className="readersimgback">
                    <div className="readersheader">
                        <img src="img/location/readerslogo.jpeg" alt="Reader's Rejoice Logo" className="readerslogo" />
                        <h1>READER'S REJOICE</h1>
                        <h2 className="readerstagline">पढ़ो और पढ़ने दो!</h2>
                        <p className="readerssubtitle">Library Cum Self Study Center</p>
                    </div>
                    <div className="readerscontent">
                        <div className="readerscontact-item">
                            📞 <a href="tel:+918002417417">+91-8002417417</a>
                        </div>
                        <div className="readerscontact-item">
                            ✉️ <a href="mailto:readersrejoice@gmail.com" >readersrejoice@gmail.com</a>
                        </div>
                        <div className="readerscontact-item">
                            🌍 <a href="https://www.readersrejoice.in">www.readersrejoice.in</a>
                        </div>
                    </div>
                </div>
                <div className="readersbranches">
                    <h3>OUR BRANCHES</h3>
                    <ul>
                        <li><a href="https://www.google.com/maps/place/Reader's+Rejoice+Library/@25.617664,85.121997,13z/data=!4m6!3m5!1s0x39ed592b7768516f:0x5a2e3636cd48a103!8m2!3d25.6176636!4d85.1219968!16s%2Fg%2F11h4dcgdb0?hl=en&entry=ttu&g_ep=EgoyMDI1MDIxMi4wIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noreferrer">🔸 Boring Canal Road</a></li>
                        <li><a href="https://www.google.com/maps/place/Reader's+Rejoice/@25.600428,85.155345,7z/data=!4m6!3m5!1s0x39ed59b3523f42bd:0x5487790dd48e9268!8m2!3d25.6004281!4d85.1553446!16s%2Fg%2F11sswvm4zk?hl=en&entry=ttu&g_ep=EgoyMDI1MDIxMi4wIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noreferrer">🔸 PC Colony Road</a></li>
                        <li><a href="https://www.google.com/maps/place/Reader's+Rejoice/@25.59513,85.16818,15z/data=!4m6!3m5!1s0x39ed59dccbc6e687:0x518dc14a4933b2ee!8m2!3d25.5951295!4d85.1681795!16s%2Fg%2F11ptq86f1j?hl=en&entry=ttu&g_ep=EgoyMDI1MDIxMi4wIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noreferrer">🔸 Kanti Factory Road</a></li>
                    </ul>
                </div>
            </a>
        </div>
    );
};

export default ReadersRejoice;
