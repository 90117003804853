import React, { useState } from 'react'

const CtBengalnearme = (props) => {
    return (
        <>
            <div id='footerdetail' >
                <div id='callus'>
                    <div className="container">
                        <div className="row">
                            {/*<!-- End Col --> */}
                            <div className="col-md-12 col-lg-12">

                                <div className="popular-city" style={{ display: 'flex' }}>
                                    <div className="footer-widget footer-left-widgetcties-pop popular-city-footers">
                                        <div className="section-heading">
                                            <h3>RO SERVICE IN KOLKATA</h3>
                                            <span className="animate-border border-black"></span>
                                        </div>
                                        <ul>
                                        <li> <a href="/ro-service-AJCBoseroad">A.J.C. Bose road</a></li>
                                        <li> <a href="/ro-service-AbinashChaowdhurylane">Abinash Chaowdhury lane</a></li>
                                        <li> <a href="/ro-service-Alipore">Alipore</a></li>
                                        <li> <a href="/ro-service-AsylumLane">Asylum Lane</a></li>
                                        <li> <a href="/ro-service-Badartala">Badartala</a></li>
                                        <li> <a href="/ro-service-Baghajatin">Baghajatin</a></li>
                                        <li> <a href="/ro-service-BakeryRoad">Bakery Road</a></li>
                                        <li> <a href="/ro-service-Ballygunge">Ballygunge</a></li>
                                        <li> <a href="/ro-service-Bamboovila">Bamboovila</a></li>
                                        <li> <a href="/ro-service-Barabazar">Barabazar</a></li>
                                        <li> <a href="/ro-service-Barisha">Barisha</a></li>
                                        <li> <a href="/ro-service-Bartala">Bartala</a></li>
                                        <li> <a href="/ro-service-Behala">Behala</a></li>
                                        <li> <a href="/ro-service-Beleghata">Beleghata</a></li>
                                        <li> <a href="/ro-service-BengalChemical">Bengal Chemical</a></li>
                                        <li> <a href="/ro-service-Bhawanipore">Bhawanipore</a></li>
                                        <li> <a href="/ro-service-Bidhangarh">Bidhangarh</a></li>
                                        <li> <a href="/ro-service-Bijoygarh">Bijoygarh</a></li>
                                        <li> <a href="/ro-service-BosepukurRoad">Bosepukur Road</a></li>
                                        <li> <a href="/ro-service-Bowbazar">Bowbazar</a></li>
                                        <li> <a href="/ro-service-BraceBridge">Brace Bridge</a></li>
                                        <li> <a href="/ro-service-BrahmaSamajroad">Brahma Samaj road</a></li>
                                        <li> <a href="/ro-service-Chetla">Chetla</a></li>
                                        <li> <a href="/ro-service-ChittaranjanAvenue">Chittaranjan Avenue</a></li>
                                        <li> <a href="/ro-service-CircusAvenue">Circus Avenue</a></li>
                                        <li> <a href="/ro-service-CmdaAbasan">Cmda Abasan</a></li>
                                        <li> <a href="/ro-service-CollegeSquare">College Square</a></li>
                                        <li> <a href="/ro-service-Colootola">Colootola</a></li>
                                        <li> <a href="/ro-service-CouncilHousestreet">Council House street</a></li>
                                        <li> <a href="/ro-service-CustomsHouse">Customs House</a></li>
                                        <li> <a href="/ro-service-Daulatpur">Daulatpur</a></li>
                                        <li> <a href="/ro-service-Dhakuria">Dhakuria</a></li>
                                        <li> <a href="/ro-service-Dharmatala">Dharmatala</a></li>
                                        <li> <a href="/ro-service-DoverLane">Dover Lane</a></li>
                                        <li> <a href="/ro-service-EKt">E.K.t</a></li>
                                        <li> <a href="/ro-service-ElliotRoad">Elliot Road</a></li>
                                        <li> <a href="/ro-service-Esplanade">Esplanade</a></li>
                                        <li> <a href="/ro-service-FortWilliam">Fort William</a></li>
                                        <li> <a href="/ro-service-GangulyBagan">Ganguly Bagan</a></li>
                                        <li> <a href="/ro-service-GarchaRoad">Garcha Road</a></li>
                                        <li> <a href="/ro-service-GardenReach">Garden Reach</a></li>
                                        <li> <a href="/ro-service-Garfa">Garfa</a></li>
                                        <li> <a href="/ro-service-GariaBt">Garia Bt</a></li>
                                        <li> <a href="/ro-service-GariahatMarket">Gariahat Market</a></li>
                                        <li> <a href="/ro-service-GobindaKhatickroad">Gobinda Khatick road</a></li>
                                        <li> <a href="/ro-service-GokhelRoad">Gokhel Road</a></li>
                                        <li> <a href="/ro-service-GolfGreen">Golf Green</a></li>
                                        <li> <a href="/ro-service-Golpark">Golpark</a></li>
                                        <li> <a href="/ro-service-GreatEasternhotel">Great Eastern hotel</a></li>
                                        <li> <a href="/ro-service-Haltu">Haltu</a></li>
                                        <li> <a href="/ro-service-Haridevpur">Haridevpur</a></li>
                                        <li> <a href="/ro-service-Hastings">Hastings</a></li>
                                        <li> <a href="/ro-service-HindustanBuilding">Hindustan Building</a></li>
                                        <li> <a href="/ro-service-HydeRoad">Hyde Road</a></li>
                                        <li> <a href="/ro-service-IncomeTaxbuilding">Income Tax building</a></li>
                                        <li> <a href="/ro-service-IndraniPark">Indrani Park</a></li>
                                        <li> <a href="/ro-service-Intally">Intally</a></li>
                                        <li> <a href="/ro-service-Jadavgarh">Jadavgarh</a></li>
                                        <li> <a href="/ro-service-JadavpurUniversity">Jadavpur University</a></li>
                                        <li> <a href="/ro-service-Jairampur">Jairampur</a></li>
                                        <li> <a href="/ro-service-JayshreePark">Jayshree Park</a></li>
                                        <li> <a href="/ro-service-Jhowtala">Jhowtala</a></li>
                                        <li> <a href="/ro-service-JodhpurPark">Jodhpur Park</a></li>
                                        <li> <a href="/ro-service-Joramandir">Joramandir</a></li>
                                        <li> <a href="/ro-service-KCmills">K.C.mills</a></li>
                                        <li> <a href="/ro-service-KGbosesarani">K.G bose sarani</a></li>
                                        <li> <a href="/ro-service-KMhospital">K.M.hospital</a></li>
                                        <li> <a href="/ro-service-KPbazar">K.P.bazar</a></li>
                                        <li> <a href="/ro-service-KProylane">K.P.roy lane</a></li>
                                        <li> <a href="/ro-service-Kalabagan">Kalabagan</a></li>
                                        <li> <a href="/ro-service-Kalagachia">Kalagachia</a></li>
                                        <li> <a href="/ro-service-Kalighat">Kalighat</a></li>
                                        <li> <a href="/ro-service-Kalikapur">Kalikapur</a></li>
                                        <li> <a href="/ro-service-Kalimandir">Kalimandir</a></li>
                                        <li> <a href="/ro-service-KalindiHousingestate">Kalindi Housing estate</a></li>
                                        <li> <a href="/ro-service-Kankurgachi">Kankurgachi</a></li>
                                        <li> <a href="/ro-service-Kasba">Kasba</a></li>
                                        <li> <a href="/ro-service-Kasthadanga">Kasthadanga</a></li>
                                        <li> <a href="/ro-service-Keoratala">Keoratala</a></li>
                                        <li> <a href="/ro-service-Khengrapatti">Khengrapatti</a></li>
                                        <li> <a href="/ro-service-Khiddirpore">Khiddirpore</a></li>
                                        <li> <a href="/ro-service-KolkataMint">Kolkata Mint</a></li>
                                        <li> <a href="/ro-service-KolkataUniversity">Kolkata University</a></li>
                                        <li> <a href="/ro-service-LRsarani">L.R.sarani</a></li>
                                        <li> <a href="/ro-service-LakeGardens">Lake Gardens</a></li>
                                        <li> <a href="/ro-service-LakeMarket">Lake Market</a></li>
                                        <li> <a href="/ro-service-Lalbazar">Lalbazar</a></li>
                                        <li> <a href="/ro-service-LansdowneMarket">Lansdowne Market</a></li>
                                        <li> <a href="/ro-service-LintonStreet">Linton Street</a></li>
                                        <li> <a href="/ro-service-LittleRusselstreet">Little Russel street</a></li>
                                        <li> <a href="/ro-service-Madrassa">Madrassa</a></li>
                                        <li> <a href="/ro-service-MahendraBanerjeeroad">Mahendra Banerjee road</a></li>
                                        <li> <a href="/ro-service-Mansatala">Mansatala</a></li>
                                        <li> <a href="/ro-service-MemanpurChandannagar">Memanpur Chandannagar</a></li>
                                        <li> <a href="/ro-service-MiddletonRow">Middleton Row</a></li>
                                        <li> <a href="/ro-service-Mominpur">Mominpur</a></li>
                                        <li> <a href="/ro-service-MottLane">Mott Lane</a></li>
                                        <li> <a href="/ro-service-Mukundapur">Mukundapur</a></li>
                                        <li> <a href="/ro-service-NRavenue">N.R.avenue</a></li>
                                        <li> <a href="/ro-service-Naktala">Naktala</a></li>
                                        <li> <a href="/ro-service-Narkeldanga">Narkeldanga</a></li>
                                        <li> <a href="/ro-service-NatioinalLibrary">Natioinal Library</a></li>
                                        <li> <a href="/ro-service-NetajiNagar">Netaji Nagar</a></li>
                                        <li> <a href="/ro-service-NewAlipore">New Alipore</a></li>
                                        <li> <a href="/ro-service-NewMarket">New Market</a></li>
                                        <li> <a href="/ro-service-NewSecretariatbldg">New Secretariat bldg.</a></li>
                                        <li> <a href="/ro-service-PGreach">P.G.reach</a></li>
                                        <li> <a href="/ro-service-Panchanantala">Panchanantala</a></li>
                                        <li> <a href="/ro-service-Panchasayar">Panchasayar</a></li>
                                        <li> <a href="/ro-service-ParkCircus">Park Circus</a></li>
                                        <li> <a href="/ro-service-ParkStreet">Park Street</a></li>
                                        <li> <a href="/ro-service-ParnasreePally">Parnasree Pally</a></li>
                                        <li> <a href="/ro-service-Parsibagan">Parsibagan</a></li>
                                        <li> <a href="/ro-service-PaschimBarisha">Paschim Barisha</a></li>
                                        <li> <a href="/ro-service-PaschimPutiari">Paschim Putiari</a></li>
                                        <li> <a href="/ro-service-PghShahroad">Pgh Shah road</a></li>
                                        <li> <a href="/ro-service-Phulbagan">Phulbagan</a></li>
                                        <li> <a href="/ro-service-PrincepStreet">Princep Street</a></li>
                                        <li> <a href="/ro-service-RKsevapratisthan">R.K.seva pratisthan</a></li>
                                        <li> <a href="/ro-service-RNmukherjeeroad">R.N. mukherjee road</a></li>
                                        <li> <a href="/ro-service-RabindraNagar">Rabindra Nagar</a></li>
                                        <li> <a href="/ro-service-RadhaBazar">Radha Bazar</a></li>
                                        <li> <a href="/ro-service-RaipurJorabagan road">Raipur Jorabagan road</a></li>
                                        <li> <a href="/ro-service-RajaJnmarket">Raja J.n.market</a></li>
                                        <li> <a href="/ro-service-RajaRammohansarani">Raja Ram mohan sarani</a></li>
                                        <li> <a href="/ro-service-RajabaganDockyard">Rajabagan Dock yard</a></li>
                                        <li> <a href="/ro-service-Ram Krishnasamadhiroad">Ram Krishna samadhi road</a></li>
                                        <li> <a href="/ro-service-RamkrishnaPark">Ramkrishna Park</a></li>
                                        <li> <a href="/ro-service-RashBehariavenue">Rash Behari avenue</a></li>
                                        <li> <a href="/ro-service-RegentEstate">Regent Estate</a></li>
                                        <li> <a href="/ro-service-RegentPark">Regent Park</a></li>
                                        <li> <a href="/ro-service-ReserveBankbuilding">Reserve Bank building</a></li>
                                        <li> <a href="/ro-service-Russa">Russa</a></li>
                                        <li> <a href="/ro-service-RusselStreet">Russel Street</a></li>
                                        <li> <a href="/ro-service-SRFTI">S.R.F.T.I.</a></li>
                                        <li> <a href="/ro-service-Sahanagar">Sahanagar</a></li>
                                        <li> <a href="/ro-service-Sahapur">Sahapur</a></li>
                                        <li> <a href="/ro-service-SalesTaxbuilding">Sales Tax building</a></li>
                                        <li> <a href="/ro-service-Sankaritola">Sankaritola</a></li>
                                        <li> <a href="/ro-service-Santoshpur">Santoshpur</a></li>
                                        <li> <a href="/ro-service-SantoshpurAvenue">Santoshpur Avenue</a></li>
                                        <li> <a href="/ro-service-SaratBoseroad">Sarat Bose road</a></li>
                                        <li> <a href="/ro-service-Sarsoona">Sarsoona</a></li>
                                        <li> <a href="/ro-service-SealLane">Seal Lane</a></li>
                                        <li> <a href="/ro-service-Sealdah">Sealdah</a></li>
                                        <li> <a href="/ro-service-Senhati">Senhati</a></li>
                                        <li> <a href="/ro-service-ShakespeareSarani">Shakespeare Sarani</a></li>
                                        <li> <a href="/ro-service-Sirity">Sirity</a></li>
                                        <li> <a href="/ro-service-Sonai">Sonai</a></li>
                                        <li> <a href="/ro-service-SooterkinStreet">Sooterkin Street</a></li>
                                        <li> <a href="/ro-service-SouthEasternrailway">South Eastern railway</a></li>
                                        <li> <a href="/ro-service-SouthernMarket">Southern Market</a></li>
                                        <li> <a href="/ro-service-StrandRoad">Strand Road</a></li>
                                        <li> <a href="/ro-service-SubhashSarabor">Subhash Sarabor</a></li>
                                        <li> <a href="/ro-service-TCroad">T.C.road</a></li>
                                        <li> <a href="/ro-service-TGroad">T.G.road</a></li>
                                        <li> <a href="/ro-service-Taltala">Taltala</a></li>
                                        <li> <a href="/ro-service-Tangra">Tangra</a></li>
                                        <li> <a href="/ro-service-TaratalaRoad">Taratala Road</a></li>
                                        <li> <a href="/ro-service-TelecomFactory">Telecom Factory</a></li>
                                        <li> <a href="/ro-service-TelephoneBhawan">Telephone Bhawan</a></li>
                                        <li> <a href="/ro-service-Thakurpukur">Thakurpukur</a></li>
                                        <li> <a href="/ro-service-TirettaBazar">Tiretta Bazar</a></li>
                                        <li> <a href="/ro-service-Tollygunge">Tollygunge</a></li>
                                        <li> <a href="/ro-service-TreasuryBuilding">Treasury Building</a></li>
                                        <li> <a href="/ro-service-Viveknagar">Viveknagar</a></li>
                                        <li> <a href="/ro-service-WBassemblyhouse">W.B.assembly house</a></li>
                                        <li> <a href="/ro-service-WBgovernorscamp">W.B.governors camp.</a></li>
                                        <li> <a href="/ro-service-Watgunge">Watgunge</a></li>
                                        <li> <a href="/ro-service-WritersBuilding">Writer's Building</a></li>
                                        <li> <a href="/ro-service-YogayogBhawan">Yogayog Bhawan</a></li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default CtBengalnearme;