import React, { useState } from 'react'

const MadhyaPradeshnearme = (props) => {
    return (
        <>
            <div id='footerdetail' >
                <div id='callus'>
                    <div className="container">
                        <div className="row">
                            {/*<!-- End Col --> */}
                            <div className="col-md-12 col-lg-12">

                                <div className="popular-city" style={{ display: 'flex' }}>
                                    <div className="footer-widget footer-left-widgetcties-pop popular-city-footers">
                                        <div className="section-heading">
                                            <h3>RO Service in Near you</h3>
                                            <span className="animate-border border-black"></span>
                                        </div>
                                        <ul>
                                        <li> 
                                            <a href="/ro-service-Indore">Indore</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Bhopal">Bhopal</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Jabalpur">Jabalpur</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Gwalior">Gwalior</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Ujjain">Ujjain</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Sagar">Sagar</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Dewas">Dewas</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Satna">Satna</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Ratlam">Ratlam</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Rewa">Rewa</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Katni">Katni</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Singrauli">Singrauli</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Burhanpur">Burhanpur</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Morena">Morena</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Khandwa">Khandwa</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Bhind">Bhind</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Chhindwara">Chhindwara</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Guna">Guna</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Shivpuri">Shivpuri</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Vidisha">Vidisha</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Chhatarpur">Chhatarpur</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Damoh">Damoh</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Mandsaur">Mandsaur</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Khargone">Khargone</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Neemuch">Neemuch</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Pithampur">Pithampur</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Narmadapuram">Narmadapuram</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Itarsi">Itarsi</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Sehore">Sehore</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Betul">Betul</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Seoni">Seoni</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Datia">Datia</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Nagda">Nagda</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Shahdol">Shahdol</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Narmadapuram">Narmadapuram</a>
                                        </li>
                                        <li> 
                                            <a href="/ro-service-Dhar">Dhar</a>
                                        </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default MadhyaPradeshnearme;